import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import { BlockShortcutImages } from 'components/BlockShortcutImages';
import ComponentPlaceholder from 'components/ComponentPlaceholder';
import { LayoutContainer } from 'components/Layout';
import Text from 'components/Text';
import type { JulaComponentProps } from 'lib/component-props';
import type { DigizuiteAssetList } from 'models/asset';
import { getEditorMargin } from 'utils/business-logic';
import { filterTruthy } from 'utils/collection';
import { getAsset } from 'utils/helpers';

type CatalogReference = {
	id: string;
	url: string;
	name: string;
	displayName: string;
	fields?: {
		id?: Field<string>;
		longTitle?: Field<string>;
		thumbnailImage: DigizuiteAssetList;
		title?: Field<string>;
	};
};

type Props = JulaComponentProps & {
	fields?: {
		heading?: Field<string>;
		focus?: Field<boolean>;
		catalogReferences?: CatalogReference[];
	};
};

export default function ShortcutImages({ fields, params }: Props) {
	if (!fields) {
		return null;
	}

	const { heading, catalogReferences, focus } = fields;
	// A visual appearance not related to focused elements in the DOM.
	const isFocusVariant = Boolean(focus?.value);

	const items = filterTruthy(
		catalogReferences?.map((ref) => ({
			href: ref.url,
			imgSrc: getAsset('Templated 2:1', ref.fields?.thumbnailImage)?.src,
			text: ref.fields?.title?.value,
		})),
		'href',
		'imgSrc',
		'text',
	);
	// const itemCount = isFocusVariant ? 4 : 6;

	if (items.length === 0) {
		return (
			<ComponentPlaceholder
				className={getEditorMargin(params)}
				componentName="ShortcutImages"
				// description={`Requires at least ${itemCount} functional links with images`}
			/>
		);
	}

	return (
		<LayoutContainer
			id={params?.anchor}
			outerClassName={getEditorMargin(params)}
		>
			<Text as={params?.heading || 'h2'} field={heading} className="mb-6" />
			<BlockShortcutImages
				items={items}
				size={isFocusVariant ? 'large' : 'regular'}
			/>
		</LayoutContainer>
	);
}
ShortcutImages.displayName = 'ShortcutImages';
