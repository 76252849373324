/**
 * TabPanel
 */

import React from 'react';
import type { ReactNode } from 'react';

import { getPanelId, getTabId } from './helpers';

interface Props {
	/** Panel content. */
	children: ReactNode;

	/** Content wrapper class name */
	className?: string;

	/** If the panel belongs to the currently selected tab */
	isSelected: boolean;

	/** Tab base ID */
	tabId: string;
}

/** The content for a tab. */
export default function TabPanel({
	children,
	className,
	isSelected,
	tabId,
}: Props) {
	return (
		<div
			role="tabpanel"
			id={getPanelId(tabId)}
			aria-labelledby={getTabId(tabId)}
			tabIndex={0}
			hidden={!isSelected}
			className={className}
		>
			{children}
		</div>
	);
}
TabPanel.displayName = 'TabPanel';
