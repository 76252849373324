import { PaymentMachineState } from './payment.machine';

export const selectAvailableDeliveryMethods = (state: PaymentMachineState) =>
	state.context.availablePaymentMethods;

export const selectSelectedPaymentMethod = (state: PaymentMachineState) =>
	state.context.selectedPayment;

export const selectSelectedAdyenPaymentMethod = (state: PaymentMachineState) =>
	state.context.availablePaymentMethods?.adyenPaymentMethods?.paymentMethods?.find(
		(paymentMethod) =>
			paymentMethod.type ===
			state.context.selectedPayment?.selectedPaymentType.toLocaleLowerCase(),
	);

export const selectPaymentResult = (state: PaymentMachineState) =>
	state.context.paymentResult;

export const selectPaymentHasAdditionalAction = (state: PaymentMachineState) =>
	state.context.paymentResult?.resultCode === 'Pending' &&
	state.context.paymentResult?.action?.type === 'await';

export const selectErrors = (state: PaymentMachineState) =>
	state.context.errors;

export const selectStateIsWaitingForVerifyPaymentEvent = (
	state: PaymentMachineState,
) => state.matches('waitingForVerifyPaymentEvent');
