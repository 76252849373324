/**
 * BlockBrandHeading
 */

import React from 'react';

import ExpandableDescription from 'components/ExpandableDescription';
import Img from 'components/Img';
import Text from 'components/Text';

interface Props {
	/** Brand description */
	description: string | undefined;

	/** Image source */
	image: string;

	/** Image alt-text */
	imageAlt?: string;

	/** Logo source */
	logo: string;

	/** Logo alt-text */
	logoAlt?: string;

	title?: string;
}

/** Block brand heading component. */
export default function BlockBrandHeading({
	description,
	image,
	imageAlt,
	logo,
	logoAlt,
	title,
}: Props) {
	return (
		<div className="grid grid-cols-4 gap-x-4 md:grid-cols-12 md:gap-6">
			<Img
				className="col-span-full h-auto w-full object-scale-down md:hidden"
				src={image}
				alt={imageAlt}
				width={532}
				height={266}
				service="nextjs"
			/>
			<div className="col-span-full md:col-span-6">
				{logo && (
					<Img
						className="mt-4 max-h-20 max-w-[10rem] md:mt-0 md:max-h-32 md:max-w-[16rem]"
						src={logo}
						alt={logoAlt}
					/>
				)}
				{description && (
					<ExpandableDescription
						beforeDescription={title ? <Text as="h1" text={title} /> : null}
						description={description}
						textLengthBreakPoint={250}
						descriptionClassName="text-lg"
						buttonVariant="text"
						buttonPlacement="left"
						textHeight="short"
						className={logo ? 'mt-6' : 'max-md:mt-4'}
					/>
				)}
			</div>
			<Img
				className="col-span-6 h-auto w-full justify-self-end object-scale-down max-md:hidden"
				src={image}
				alt={imageAlt}
				width={822}
				height={411}
				service="nextjs"
			/>
		</div>
	);
}
BlockBrandHeading.displayName = 'BlockBrandHeading';
