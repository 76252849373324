import React from 'react';

import Gauge from 'components/Gauge';
import InfoBox from 'components/InfoBox';
import { AccountLayoutContainer } from 'components/Layout';
import LoadingSpinner from 'components/LoadingSpinner';
import { useBonusBalance } from 'hooks';
import type { JulaComponentProps } from 'lib/component-props';
import { useI18n } from 'utils/i18n';

type Props = JulaComponentProps & {};

export default function AccountBonus({ rendering }: Props) {
	const { t } = useI18n();

	const {
		bonus,
		isLoading: isLoadingBonusBalance,
		error: errorBonusBalance,
	} = useBonusBalance();

	return (
		<AccountLayoutContainer
			rendering={rendering}
			heading={t('account_bonus_heading')}
		>
			{isLoadingBonusBalance && (
				<LoadingSpinner className="ml-16 mt-10 max-sm:flex max-sm:justify-center md:mt-16" />
			)}
			{!isLoadingBonusBalance && !errorBonusBalance && (
				<div className="mt-10 max-sm:flex max-sm:justify-center md:ml-16 md:mt-16">
					<Gauge
						type="bonus"
						count={bonus?.bonusBalance}
						secondaryCount={bonus?.leftToNextBonusCheck}
						size="large"
					/>
				</div>
			)}
			{errorBonusBalance && (
				<InfoBox
					icon="error"
					variant="error"
					message={t('account_generic_fetch_error_text')}
					className="mt-4"
				/>
			)}
		</AccountLayoutContainer>
	);
}
AccountBonus.displayName = 'AccountBonus';
