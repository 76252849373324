/**
 * ThumbnailList
 */

import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useKeenSlider } from 'keen-slider/react';

import IconButton from 'components/IconButton';
import { useEffectOnce } from 'hooks';
import type { Thumbnail } from 'utils/business-logic';
import { useI18n } from 'utils/i18n';

import ThumbnailItem from './ThumbnailItem';

interface Props {
	carouselIndex: number;

	/** Extra container class names */
	className?: string;

	/** Image objects */
	images: Thumbnail[];

	/** Callback for when a button is clicked */
	onImageClick: (imageId: number) => void;

	/** Callback for when the visible thumbnails change */
	onSlideChange: (index: number) => void;

	/** ID for the `images` item that should be selected */
	selectedImageId: number;
}

/** List of image thumbnails */
export default function ThumbnailList({
	carouselIndex,
	className,
	images,
	onImageClick,
	onSlideChange,
	selectedImageId,
}: Props) {
	const { t } = useI18n();
	const slidesPerView = 5;

	const [currentSlide, setCurrentSlide] = useState(0);
	const [sliderRef, instanceRef] = useKeenSlider({
		mode: 'snap',
		vertical: true,
		initial: 0,
		slides: {
			perView: slidesPerView,
			spacing: 8,
		},
		slideChanged(slider) {
			onSlideChange(slider.track.absToRel(slider.track.details.abs));
			setCurrentSlide(slider.track.details.rel);
		},
	});
	const keenSlider = instanceRef.current;
	const hasSliderPages = images.length > slidesPerView;

	useEffect(() => {
		keenSlider?.moveToIdx(carouselIndex);
	}, [carouselIndex, keenSlider]);

	// Enable slider on initial render and only if necessary.
	const [shouldEnableSlider, setShouldEnableSlider] = useState(false);
	useEffectOnce(() => {
		if (hasSliderPages) {
			setShouldEnableSlider(true);
		}
	});

	return (
		<div
			className={clsx('flex w-[64px] flex-col items-center gap-y-4', className)}
		>
			{hasSliderPages && (
				<IconButton
					size="small"
					icon="arrow"
					iconDirection="up"
					text={t('slider_prev_button')}
					disabled={currentSlide === 0}
					className={clsx(!shouldEnableSlider && 'invisible')}
					onClick={() => {
						keenSlider?.prev();
					}}
				/>
			)}
			<div
				ref={shouldEnableSlider ? sliderRef : undefined}
				className={clsx(
					'h-[352px] overflow-hidden',
					!shouldEnableSlider && 'flex flex-col gap-y-2',
					shouldEnableSlider && 'keen-slider',
				)}
			>
				{images.map((image, i) => (
					<ThumbnailItem
						className={clsx(shouldEnableSlider && 'keen-slider__slide')}
						key={image.src}
						src={image.src}
						isSelected={selectedImageId === image.id}
						alt={image.alt}
						onClick={() => {
							onImageClick(image.id);
						}}
						label={t('product_details_show_image_number_label', {
							num: i + 1,
						})}
					/>
				))}
			</div>
			{hasSliderPages && (
				<IconButton
					size="small"
					icon="arrow"
					iconDirection="down"
					text={t('slider_next_button')}
					disabled={currentSlide === keenSlider?.track?.details?.maxIdx}
					className={clsx(!shouldEnableSlider && 'invisible')}
					onClick={() => {
						keenSlider?.next();
					}}
				/>
			)}
		</div>
	);
}
ThumbnailList.displayName = 'ThumbnailList';
