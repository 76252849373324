/**
 * ComboboxOptionGroup
 */

import React from 'react';
import type { ReactNode } from 'react';

import { HTMLAttributes } from 'types';

interface Props extends HTMLAttributes<HTMLUListElement> {
	/** Group options */
	children: ReactNode;

	/** HTML ID of the title text */
	titleId: string;

	/** Title text for the group */
	groupTitle: ReactNode;
}

/** A titled group of multiple combobox options. */
export default function ComboboxOptionGroup({
	children,
	groupTitle,
	titleId,
	...attrs
}: Props) {
	return (
		<ul {...attrs} role="group" aria-labelledby={titleId}>
			<li role="presentation" id={titleId}>
				{groupTitle}
			</li>
			{children}
		</ul>
	);
}
ComboboxOptionGroup.displayName = 'ComboboxOptionGroup';
