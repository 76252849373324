/**
 * IconList
 */

import React from 'react';
import clsx from 'clsx';

import Icon, {
	type IconColor,
	type IconSize,
	type IconType,
} from 'components/Icon';

type Size = 'small' | 'regular';

interface Props {
	backgroundColor?: 'grey';
	className?: string;
	iconColor?: IconColor;
	iconName: IconType;
	isInline?: boolean;
	items: React.ReactNode[];
	size?: Size;
}

const ICON_SIZES: Record<Size, IconSize> = {
	small: 16,
	regular: 24,
};

/**
 * Component that renders a list of something where each item has an icon with
 * a settable color and an optional background.
 */
export default function IconList({
	backgroundColor,
	className,
	iconColor,
	iconName,
	isInline = false,
	items,
	size = 'regular',
}: Props) {
	return (
		<ul
			className={clsx(
				className,
				isInline && 'inline-flex',
				!isInline && 'flex',
				'flex-col',
				size === 'small' && 'gap-1 text-sm',
				size === 'regular' && 'gap-2 text-base',
				backgroundColor && [
					backgroundColor === 'grey' && 'bg-greyLighter',
					// Assume the icon has whitespace and use more right padding for visual balance
					size === 'small' && 'rounded-md p-2 pr-3',
					size === 'regular' && 'rounded-xl p-3 pr-4',
				],
			)}
		>
			{items.map((item, index) => (
				<li className="flex items-start" key={index}>
					<Icon
						icon={iconName}
						color={iconColor}
						size={ICON_SIZES[size]}
						className={clsx(
							size === 'small' && 'mr-1 mt-0.5',
							size === 'regular' && 'mr-2',
						)}
					/>
					{item}
				</li>
			))}
		</ul>
	);
}
IconList.displayName = 'IconList';
