import { useCallback, useEffect, useState } from 'react';
import useSWRImmutable from 'swr/immutable';

import { useFileUpload, useValueChangeEffect } from 'hooks';
import {
	CreateCustomizationRequest,
	CreateCustomizationRequestItem,
	CustomizationPlacement,
	ProductCustomizationResponse,
} from 'models/api/ProductCustomization';
import { StoredFileResponse } from 'models/api/userFileStorage';
import { ActionButtonState } from 'state-machines/ActionButton.machine';
import fetchData, { API_URL, FormattedValidationErrors } from 'utils/fetchData';
import fetchResult from 'utils/fetchResult';
import { ignorePromiseRejection, is } from 'utils/helpers';
import { createUrl } from 'utils/url';

const fileStorageApiUrl = `${API_URL}UserFileStorage/ProductCustomization`;
const LATEST_PRINT_CONFIG_ID_STORAGE_KEY = 'latestPrintConfigId';

function mapCreateCustomizationRequestItems(
	printPlacements: CustomizationPlacement[] | undefined,
): CreateCustomizationRequestItem[] {
	return (
		printPlacements?.map((printPlacement) => ({
			placementId: printPlacement.id,
			optionKey: printPlacement.option?.key,
			printImageFilename: printPlacement.printImageFilename?.fileName,
		})) ?? []
	);
}

export default function useProductPrint({
	id,
	isActive,
}: {
	id: string;
	isActive: boolean;
}) {
	const [print, setPrint] = useState<StoredFileResponse | undefined>(undefined);
	const [totalCost, setTotalCost] = useState<string | undefined>(undefined);
	const [currentPrintPlacements, setCurrentPrintPlacements] = useState<
		CustomizationPlacement[] | undefined
	>();
	const [currentCustomizationId, setCurrentCustomizationId] = useState<
		string | undefined
	>();

	const [addPrintPlacementIsLoading, setAddPrintPlacementIsLoading] =
		useState(false);
	const [addPrintPlacementErrors, setAddPrintPlacementErrors] = useState<
		FormattedValidationErrors | undefined
	>();
	const addPrintPlacementButtonState: ActionButtonState =
		addPrintPlacementIsLoading
			? 'loading'
			: addPrintPlacementErrors
				? 'failure'
				: 'success';
	const [latestPrintConfigId, setLatestPrintConfigId] = useState<
		string | null | undefined
	>(undefined);
	const hasCheckedLatestPrintConfigId =
		is.string(latestPrintConfigId) || is.null(latestPrintConfigId);
	const printCustomizationApiUrl = `${API_URL}ProductCustomization/${id}`;

	useEffect(() => {
		// Treat empty string as if it's not set at all.
		setLatestPrintConfigId(
			localStorage.getItem(LATEST_PRINT_CONFIG_ID_STORAGE_KEY) || null,
		);
	}, []);

	// Get print customization for product with possible existing print placements
	const {
		data: productCustomization,
		error: productCustomizationError,
		isLoading: isLoadingProductCustomization,
	} = useSWRImmutable(
		isActive && hasCheckedLatestPrintConfigId
			? createUrl(printCustomizationApiUrl, {
					customizationId: latestPrintConfigId,
				})
			: null,
		fetchData<ProductCustomizationResponse>,
	);

	const [isInitialLoading, setIsInitialLoading] = useState(isActive);

	useValueChangeEffect(isLoadingProductCustomization, () => {
		setIsInitialLoading(isLoadingProductCustomization);
	});

	// Get possible stored prints
	const { data: storedPrints, mutate: mutateStoredPrints } = useSWRImmutable(
		isActive ? fileStorageApiUrl : null,
		fetchData<StoredFileResponse[]>,
	);

	// Print upload
	const {
		errors: fileUploadErrors,
		hasError: hasFileUploadErrors,
		isLoading: fileUploadIsLoading,
		uploadedFiles,
		uploadFile,
	} = useFileUpload({
		url: fileStorageApiUrl,
	});

	useEffect(() => {
		if (uploadedFiles.length > 0) {
			setPrint(uploadedFiles.at(0));
			ignorePromiseRejection(mutateStoredPrints());
		}
	}, [mutateStoredPrints, uploadedFiles]);

	const reusePrint = useCallback(
		(printId: string | undefined) => {
			const existingPrint = storedPrints?.find(
				(file) => file.fileName === printId,
			);
			if (existingPrint) {
				setPrint(existingPrint);
			}
		},
		[storedPrints],
	);

	const removePrint = useCallback(() => {
		setPrint(undefined);
	}, []);

	const requestPrintPlacementUpdate = useCallback(
		async (printPlacements: CreateCustomizationRequest) => {
			setAddPrintPlacementIsLoading(true);
			const res = await fetchResult<ProductCustomizationResponse>(
				printCustomizationApiUrl,
				{ method: 'POST', body: JSON.stringify(printPlacements) },
			);
			setAddPrintPlacementIsLoading(false);
			if (res.isErr()) {
				setAddPrintPlacementErrors(res.error);
				return;
			}
			if (res.isOk()) {
				const { customization } = res.value;
				setCurrentPrintPlacements(customization?.placements);
				setTotalCost(customization?.price?.priceIncVat?.displayValue);
				setCurrentCustomizationId(customization?.id);
				localStorage.setItem(
					LATEST_PRINT_CONFIG_ID_STORAGE_KEY,
					customization?.id || '',
				);
			}
		},
		[printCustomizationApiUrl],
	);

	const addPrintPlacement = useCallback(
		(printPlacement: CreateCustomizationRequestItem) => {
			ignorePromiseRejection(
				requestPrintPlacementUpdate([
					...mapCreateCustomizationRequestItems(currentPrintPlacements),
					printPlacement,
				]),
			);
		},
		[currentPrintPlacements, requestPrintPlacementUpdate],
	);

	const removePrintPlacement = useCallback(
		(placementId: string) => {
			ignorePromiseRejection(
				requestPrintPlacementUpdate(
					mapCreateCustomizationRequestItems(
						currentPrintPlacements?.filter(
							(placement) => placement.id !== placementId,
						),
					),
				),
			);
		},
		[currentPrintPlacements, requestPrintPlacementUpdate],
	);

	return {
		// it is still not complete clear if swr caches 204 responses
		// if it does there could be cases where this leads to a forever loading state
		// since the initial loading state is never updated because the request is not made and no loading state change
		// is triggered, and since the empty response is cached there is never any `productCustomization` data
		isInitialLoading:
			isInitialLoading && !productCustomization && !productCustomizationError,
		hasError: Boolean(productCustomizationError),
		uploadPrint: uploadFile,
		print,
		storedPrints,
		customizationId: currentCustomizationId,
		existingPrintPlacements: productCustomization?.customization?.placements,
		addPrintPlacementButtonState,
		addPrintPlacementErrors,
		printUploadButtonState: (fileUploadIsLoading
			? 'loading'
			: hasFileUploadErrors
				? 'failure'
				: 'success') as ActionButtonState,
		printUploadErrors: fileUploadErrors,
		addPrintPlacement,
		removePrintPlacement,
		reusePrint,
		removePrint,
		placements:
			productCustomization?.variantCustomization.printOnClothes.placements,
		printPlacements: currentPrintPlacements,
		totalCost,
	};
}
