import { UserManagementMachineState } from './userManagement.machine';

export const selectStateIsLoadingUserList = (
	state: UserManagementMachineState,
) => state.matches('listingUsers.loadUserList');

export const selectUserList = (state: UserManagementMachineState) =>
	state.context.userList;

export const selectSubmitAddUserButtonState = (
	state: UserManagementMachineState,
) => state.context.addUserButtonState;

export const selectStateIsLoadingUser = (state: UserManagementMachineState) =>
	state.matches('viewingUser.viewUserFlow.loadingUser');

export const selectStateIsErrorGettingUser = (
	state: UserManagementMachineState,
) => state.matches('viewingUser.viewUserFlow.errorGettingUser');

export const selectUser = (state: UserManagementMachineState) =>
	state.context.selectedUser;

export const selectInviteButtonState = (state: UserManagementMachineState) =>
	state.context.inviteButtonState;

export const selectRemoveUserButtonState = (
	state: UserManagementMachineState,
) => state.context.removeUserButtonState;
