import React from 'react';

import ListItem from 'components/ListItem';
import Price from 'components/Price';
import Text from 'components/Text';
import { Option } from 'models/api/ProductCustomization';
import { getPriceProps } from 'utils/business-logic';

interface Props {
	heading: string | undefined;
	onSizeClick: (sizeOption: Option) => void;
	sizes: Option[] | undefined;
}

export default function SizeView({ heading, onSizeClick, sizes }: Props) {
	return (
		<div>
			<Text as="h3" text={heading} />
			<ul className="mt-6 flex flex-col gap-2">
				{sizes?.map((size) => (
					<ListItem
						onClick={() => onSizeClick(size)}
						as="li"
						title={size.text || ''}
						key={size.key}
						className="rounded-border border-1 border-grey p-4"
						endContent={<Price {...getPriceProps(size.price)} size="micro" />}
					/>
				))}
			</ul>
		</div>
	);
}

SizeView.displayName = 'ProductPrintPopover_SizeView';
