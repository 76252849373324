import { FormApi } from 'final-form';

import { CartDeliveryAddress } from 'models/api';

export function updateAddress({
	batch,
	cartLatestAddress,
	change,
	formName,
}: {
	batch: FormApi['batch'];
	cartLatestAddress: CartDeliveryAddress | undefined;
	change: FormApi['change'];
	formName: string;
}) {
	const inputsToUpdate = ['city', 'postalCode'];
	batch(() => {
		inputsToUpdate.forEach((inputName) => {
			change(`${formName}.${inputName}`, cartLatestAddress?.[inputName]);
		});
	});
}
