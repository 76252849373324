/**
 * Gauge
 */

import React from 'react';
import clsx from 'clsx';

import Text from 'components/Text';
import { useI18n } from 'utils/i18n';

interface Props {
	title?: string;
	count?: number;
	secondaryText?: string;
	secondaryCount?: number;
	type?: string;
	color?: string;
	size?: 'small' | 'large';
	className?: string;
}

const getPercentage = (count) => {
	const level = Math.ceil(count / 2500);
	const maxLevel = 2500 * level;
	const percent = Math.floor((count / maxLevel) * 100);

	return percent === 100 ? (count / maxLevel) * 99 : percent;
};

const cleanPercentage = (percentage) => {
	const isNegativeOrNaN = !Number.isFinite(+percentage) || percentage < 0; // we can set non-numbers to 0 here
	const isTooHigh = percentage > 100;
	return isNegativeOrNaN ? 0 : isTooHigh ? 100 : +percentage;
};

interface PercentageTextProps {
	count: string;
	countColor: string;
	secondaryCount?: number;
	secondaryText?: string;
	size: string;
	text?: string;
}

function PercentageText({
	count,
	countColor,
	secondaryCount,
	secondaryText,
	size,
	text,
}: PercentageTextProps) {
	return (
		<div className="flex h-full flex-col justify-center text-center">
			<Text
				as="p"
				text={text}
				className={clsx(
					`mb-2 font-bold ${size === 'small' ? 'text-sm' : 'text-base'}`,
				)}
			/>
			<Text
				as="p"
				text={count}
				className={clsx(
					`mb-2 font-bold ${
						size === 'small' ? 'text-[1.9rem]' : 'text-[2.7rem]'
					} tracking-tight text-${countColor}`,
				)}
			/>
			{secondaryCount && secondaryText && size === 'large' && (
				<Text as="p" text={`${secondaryCount} ${secondaryText}`} />
			)}
		</div>
	);
}
PercentageText.displayName = 'Gauge_PercentageText';

interface CircleProps {
	percentage: number;
	r: number;
	circ: number;
	strokePct: number;
	className?: string;
	size: string;
}

function Circle({
	percentage,
	r,
	circ,
	strokePct,
	className,
	size,
}: CircleProps) {
	return (
		<circle
			r={r}
			cx={100}
			cy={100}
			fill="transparent"
			strokeWidth={size === 'small' ? '0.5rem' : '0.8rem'}
			strokeDasharray={circ}
			strokeDashoffset={percentage && strokePct ? strokePct : 0}
			className={className}
		/>
	);
}
Circle.displayName = 'Gauge_Circle';

/** A percentage gauge. */
function Gauge({
	type = 'bonus',
	title,
	count,
	secondaryText,
	secondaryCount,
	color = 'julaRed',
	size = 'small',
	className = '',
}: Props) {
	const { t } = useI18n();
	const percentage = getPercentage(count);
	const pct = cleanPercentage(percentage);

	const r = size === 'small' ? 60.95 : 130;
	const circ = 2 * Math.PI * r;
	const strokePct = ((100 - (percentage || 0)) * circ) / 100; // where stroke will start, e.g. from 15% to 100%.

	return (
		<div
			className={clsx(
				'relative',
				className,
				`${
					size === 'small'
						? 'h-[8.2rem] w-[8.2rem]'
						: 'h-[17.25rem] w-[17.25rem]'
				}`,
			)}
		>
			<svg className={`absolute z-[-1] h-full w-full ${className}`}>
				<g
					transform={`rotate(-90 ${`${
						size === 'small' ? 64.95 : 136.95
					} ${100}`})`}
				>
					<Circle
						percentage={100}
						r={r}
						circ={0}
						strokePct={percentage}
						className={clsx(`stroke-${'greyLight'} stroke-6`)}
						size={size}
					/>
					<Circle
						percentage={pct}
						r={r}
						circ={circ}
						strokePct={strokePct}
						className={clsx(
							`stroke-${strokePct === circ ? '' : color} stroke-6`,
						)}
						size={size}
					/>
				</g>
			</svg>
			<PercentageText
				text={type === 'bonus' ? t('account_bonus_points_label') : title}
				secondaryText={
					type === 'bonus' ? t('account_bonus_gauge_text') : secondaryText
				}
				count={count?.toString() || '0'}
				countColor={color}
				secondaryCount={secondaryCount}
				size={size}
			/>
		</div>
	);
}
Gauge.displayName = 'Gauge';

export default Gauge;
