import type { JulaProCustomerContact } from 'models/api';
import fetchData, { API_URL } from 'utils/fetchData';

import type { AddUserRequestParams } from './userManagement.types';

/** Gets the user list */
export const requestGetUsers = (): Promise<JulaProCustomerContact[]> => {
	const apiPath = `${API_URL}Customer/julapro/contact`;
	return new Promise<JulaProCustomerContact[]>((resolve, reject) => {
		fetchData(apiPath)
			.then((data) => {
				const userList = data as unknown as JulaProCustomerContact[];
				resolve(userList);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

/** Gets a single user by id */
export const requestGetUser = (
	customerContactId: string,
): Promise<JulaProCustomerContact> => {
	const apiPath = `${API_URL}Customer/julapro/contact/${customerContactId}`;
	return new Promise<JulaProCustomerContact>((resolve, reject) => {
		fetchData(apiPath)
			.then((data) => {
				const user = data as unknown as JulaProCustomerContact;
				resolve(user);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const requestAddUser = (
	addUserRequestParams: AddUserRequestParams,
): Promise<void> => {
	const apiPath = `${API_URL}Customer/julapro/contact`;
	return new Promise<void>((resolve, reject) => {
		fetchData(apiPath, {
			method: 'POST',
			body: JSON.stringify(addUserRequestParams),
		})
			.then((data) => {
				resolve(data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

/** Invite user to ecom */
export const requestInviteUserToEcom = (
	customerContactId: string,
): Promise<void> => {
	const apiPath = `${API_URL}Customer/julapro/contact/${customerContactId}?allowOnlinePurchase=true`;
	return new Promise((resolve, reject) => {
		fetchData(apiPath, { method: 'PUT' })
			.then(() => {
				resolve();
			})
			.catch((error) => {
				reject(error);
			});
	});
};

/** Revoke user from ecom */
export const requestRevokeUserFromEcom = (
	customerContactId: string,
): Promise<void> => {
	const apiPath = `${API_URL}Customer/julapro/contact/${customerContactId}?allowOnlinePurchase=false`;
	return new Promise((resolve, reject) => {
		fetchData(apiPath, { method: 'PUT' })
			.then(() => {
				resolve();
			})
			.catch((error) => {
				reject(error);
			});
	});
};

/** Remove user */
export const requestRemoveUser = (customerContactId: string): Promise<void> => {
	const apiPath = `${API_URL}Customer/julapro/contact/${customerContactId}`;
	return new Promise((resolve, reject) => {
		fetchData(apiPath, { method: 'DELETE' })
			.then(() => {
				resolve();
			})
			.catch((error) => {
				reject(error);
			});
	});
};
