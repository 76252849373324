/**
 * ProductCardRow
 */

import React from 'react';
import type { MouseEventHandler, ReactNode } from 'react';
import clsx from 'clsx';

import ActionButton, { type ActionButtonState } from 'components/ActionButton';
import Img from 'components/Img';
import Link from 'components/Link';
import {
	PRODUCT_REVIEWS_ID,
	PRODUCT_TECHNICAL_ATTRIBUTES_ID,
} from 'components/ProductDetails/';
import ProductFlag, { type ProductFlagVariant } from 'components/ProductFlag';
import Rating from 'components/Rating';
import Text from 'components/Text';
import type { ProductTag } from 'models/product';
import { useI18n } from 'utils/i18n';

import ProductCardTags from './ProductCardTags';

interface Props {
	actionButtonState?: ActionButtonState;

	/** The text inside of the button */
	buttonText?: string;

	/** Extra container class names */
	className?: string;

	/** Is product disabled? */
	disabled?: boolean;

	/** Energy Symbol */
	energySymbolAlt?: string;

	/** Energy Symbol */
	energySymbolSrc?: string;

	/** Set which news/outlet etc. flag to display, if any. @deprecated */
	flagVariant?: ProductFlagVariant;

	/** The heading in the component */
	heading?: string;

	/** The link to the product page */
	href: string;

	/** Image alt text */
	imageAlt?: string;

	/** Height for the image */
	imageHeight: number;

	/** Image src attribute */
	imageSrc: string;

	/** Width for the image */
	imageWidth: number;

	/** Add to cart callback */
	onAddToCartClick?: MouseEventHandler<HTMLButtonElement>;

	/** Link click callback */
	onLinkClick?: MouseEventHandler<HTMLAnchorElement>;

	/** The price in the component */
	price?: ReactNode;

	/** Link to the product sheet */
	productSheetLink?: string;

	/** Link text for the product sheet link */
	productSheetLinkDescription?: string;

	/** Sets the number of reviews */
	reviews?: number;

	/** Sets the numbers of score/stars */
	score?: number;

	/** Show and hide options for the button */
	showAddToCartButton?: boolean;

	/** The sub heading in the component */
	subHeading?: string;

	/** Product tags and flags */
	tags?: ProductTag[];
}

/** Product card with a horizontal layout. */
export default function ProductCardRow({
	actionButtonState,
	buttonText,
	className,
	disabled,
	energySymbolAlt = '',
	energySymbolSrc,
	flagVariant,
	heading,
	href,
	imageAlt = '',
	imageHeight,
	imageSrc,
	imageWidth,
	onAddToCartClick,
	onLinkClick,
	price,
	productSheetLink,
	productSheetLinkDescription,
	reviews,
	score,
	showAddToCartButton = false,
	subHeading,
	tags,
}: Props) {
	const { t } = useI18n();

	// Enlarge the click area for some targets.
	const enlargedTargetClasses =
		'relative z-3 before:absolute before:inset-x-0 before:-inset-y-2';

	return (
		<article
			className={clsx(className, 'relative flex w-full pb-8')}
			aria-label={subHeading}
		>
			<div className="relative mr-4 shrink-0">
				{flagVariant && (
					<ProductFlag
						size="small"
						variant={flagVariant}
						className="absolute left-0 top-0"
					/>
				)}
				<Link href={href} onClick={onLinkClick} isScreenReaderHidden>
					<Img
						src={imageSrc}
						alt={imageAlt}
						width={imageWidth}
						height={imageHeight}
						service="nextjs"
						useFallbackOnError
						className="h-24 w-24 object-contain"
					/>
				</Link>
			</div>

			<div className="grow">
				{tags && <ProductCardTags tags={tags} className="mb-2" />}
				<header className={clsx('relative', !score && 'mb-4')}>
					{heading && (
						<Text as="p" styleAs="h6" className="mb-2">
							<Link
								href={href}
								onClick={onLinkClick}
								cover
								className="hover:underline"
							>
								{heading}
							</Link>
						</Text>
					)}
					{subHeading && (
						<Text as="pXSmall" className="mb-1">
							{subHeading}
						</Text>
					)}
				</header>

				{Boolean(score && score > 0) && (
					<Rating
						size="small"
						score={score}
						reviewCount={reviews}
						href={`${href}#${PRODUCT_REVIEWS_ID}`}
						onClick={onLinkClick}
						className={enlargedTargetClasses}
					/>
				)}

				<div className="mt-2 flex items-center justify-between">
					{price}
					{showAddToCartButton && (
						<ActionButton
							data-cid="addToCart"
							size="medium"
							variant="cta"
							disabled={disabled}
							onClick={onAddToCartClick}
							customState={actionButtonState}
							minimunLoadingTime={1000}
							// Avoid layout shifting when the text is changed to a spinner.
							className="min-w-[5em]"
						>
							{buttonText || t('product_Card_buy_button')}
						</ActionButton>
					)}
				</div>

				{Boolean(energySymbolSrc || productSheetLink) && (
					<div className="relative z-3 mt-6 flex items-center gap-3 text-xs">
						{energySymbolSrc && (
							<Link
								href={`${href}#${PRODUCT_TECHNICAL_ATTRIBUTES_ID}`}
								onClick={onLinkClick}
								className={clsx(enlargedTargetClasses, 'hover:opacity-80')}
							>
								<Img
									src={energySymbolSrc}
									alt={
										energySymbolAlt ||
										t('product_details_technical_specification_heading')
									}
									className="h-6 object-contain"
								/>
							</Link>
						)}
						{productSheetLink && (
							<Link
								href={productSheetLink}
								target="_blank"
								rel="nofollow"
								underline
								className={enlargedTargetClasses}
							>
								{productSheetLinkDescription ||
									t('product_details_product_sheet_button')}
							</Link>
						)}
					</div>
				)}
			</div>
		</article>
	);
}
ProductCardRow.displayName = 'ProductCardRow';
