import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import ComponentPlaceholder from 'components/ComponentPlaceholder';
import { LayoutContainer } from 'components/Layout';
import { MicroContentMedia } from 'components/MicroContent';
import Text from 'components/Text';
import type { JulaComponentProps } from 'lib/component-props';
import type { InspirationPage } from 'models/sitecore';
import { getEditorMargin } from 'utils/business-logic';
import { getAsset, is } from 'utils/helpers';

type Props = JulaComponentProps & {
	fields?: {
		heading?: Field<string>;
		inspirationPages?: InspirationPage[];
	};
};

export default function ShortcutInspiration({ fields, params }: Props) {
	if (!fields) {
		return null;
	}

	const { heading, inspirationPages } = fields;

	if (!is.arrayWithLength(inspirationPages)) {
		return (
			<ComponentPlaceholder
				componentName="ShortcutInspiration"
				className={getEditorMargin(params)}
				description="Add inspiration pages to this component"
			/>
		);
	}

	return (
		<LayoutContainer
			id={params?.anchor}
			outerClassName={getEditorMargin(params)}
			withGrid
			withVerticalGridGap
		>
			<Text
				className="col-span-full mb-6 text-center"
				field={heading}
				as="h2"
			/>
			{inspirationPages.map((item) => {
				const image = getAsset('Templated 2:1', item.fields?.landscapeImage);
				const text = item.fields?.navigationTitle?.value;
				const colClasses = 'col-span-full sm:col-span-2 md:col-span-3';

				if (!image || !text || !item.url) {
					return (
						<ComponentPlaceholder
							key={item.id}
							componentName="Inspiration item"
							description="Missing image, text or URL"
							className={colClasses}
						/>
					);
				}

				return (
					<MicroContentMedia
						key={item.id}
						className={colClasses}
						href={item.url}
						text={text}
						imgSrc={image.src}
						imgWidth={393}
						imgHeight={197}
					/>
				);
			})}
		</LayoutContainer>
	);
}
ShortcutInspiration.displayName = 'ShortcutInspiration';
