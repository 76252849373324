import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import { LayoutContainer } from 'components/Layout';
import Slider from 'components/Slider';
import Text from 'components/Text';
import type { HeadingLevel } from 'lib/component-props';

import TeaserItem, { Props as TeaserItemProps } from './TeaserItem';

interface Props {
	className?: string;
	heading?: Field<string>;
	headingLevel?: HeadingLevel;
	id?: string;
	items: TeaserItemProps[];
}

export default function BlockTeaserCarousel({
	className,
	heading,
	headingLevel = 'h2',
	id,
	items,
}: Props) {
	const hasHeadings = items.some((item) => item.heading?.value);
	const arrowButtonClasses = hasHeadings
		? 'top-[calc(50%-3.5rem)]'
		: 'top-[calc(50%-1.5rem)]';

	return (
		<LayoutContainer id={id} className={className}>
			<Text as={headingLevel} styleAs="h1" field={heading} className="mb-8" />
			<Slider
				items={items.map((item, i) => (
					<TeaserItem
						key={item.link.text}
						imageLoading={i === 0 || i === 1 ? 'eager' : 'lazy'}
						{...item}
					/>
				))}
				sizeClasses="w-2/3 sm:w-1/3 md:w-1/4"
				buttonLeftClassName={arrowButtonClasses}
				buttonRightClassName={arrowButtonClasses}
			/>
		</LayoutContainer>
	);
}
BlockTeaserCarousel.displayName = 'BlockTeaserCarousel';
