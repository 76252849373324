import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import BlockInspirationLandscape from 'components/BlockInspirationLandscape';
import ComponentPlaceholder from 'components/ComponentPlaceholder';
import { useEffectOnce, useProductListGTMEvents } from 'hooks';
import type { ColorField, JulaComponentProps } from 'lib/component-props';
import type { DigizuiteAssetList } from 'models/asset';
import type { ProductCard } from 'models/productCard';
import type { SitecoreLink } from 'models/sitecore';
import { getEditorMargin } from 'utils/business-logic';
import { filterTruthy } from 'utils/collection';
import { getAsset, is } from 'utils/helpers';

type Props = JulaComponentProps & {
	fields: {
		campaignBubbleLabel?: Field<string>;
		campaignBubbleText?: Field<string>;
		datasourceName?: string;
		description?: Field<string>;
		heading?: Field<string>;
		isCampaignSpinner?: Field<boolean>;
		mirror?: Field<boolean>;
		preventAutoplay?: Field<boolean>;
		primaryImage: DigizuiteAssetList;
		primaryLink?: Field<SitecoreLink>;
		products?: ProductCard[];
		quaternaryLink?: Field<SitecoreLink>;
		secondaryImage: DigizuiteAssetList;
		secondaryLink?: Field<SitecoreLink>;
		tertiaryLink?: Field<SitecoreLink>;
		textBackgroundColor?: ColorField;
		video?: DigizuiteAssetList;
	};
};

export default function InspirationLandscape({ fields, params }: Props) {
	const {
		datasourceName,
		heading,
		primaryLink,
		products,
		quaternaryLink,
		secondaryLink,
		tertiaryLink,
	} = fields;

	const isCampaignSpinner = Boolean(fields.isCampaignSpinner?.value);
	const primaryImg = getAsset('Templated 2:1', fields.primaryImage);

	const { gtmItemListId, gtmItemListName, sendViewItemListEvent } =
		useProductListGTMEvents(
			'block',
			`Block: ${datasourceName ?? 'InspirationLandscapeUnknown'}`,
		);
	useEffectOnce(() => {
		// Shop by media sends event on popover open.
		if (isCampaignSpinner && products?.length) {
			sendViewItemListEvent(products, products.length);
		}
	});

	if (!primaryImg || !heading?.value) {
		return (
			<ComponentPlaceholder
				componentName="InspirationLandscape"
				description="Requires a primary image and a heading"
				className={getEditorMargin(params)}
			/>
		);
	}

	const secondaryImg = getAsset('Templated 2:1', fields.secondaryImage);
	const video = getAsset('Source Copy', fields.video);
	const mainLinks = filterTruthy(
		[primaryLink, secondaryLink, tertiaryLink, quaternaryLink]
			.filter(is.truthy)
			.map((link) => link.value),
		'href',
		'text',
	);
	const primaryLinkHref = primaryLink?.value.href;

	return (
		<BlockInspirationLandscape
			basePriceTextBackground={
				fields.textBackgroundColor?.fields?.colorHex?.value
			}
			campaignBubbleLabel={fields.campaignBubbleLabel?.value}
			campaignBubbleText={fields.campaignBubbleText?.value}
			className={getEditorMargin(params)}
			gtmItemListId={gtmItemListId}
			gtmItemListName={gtmItemListName}
			id={params?.anchor}
			heading={heading}
			headingLevel={params?.heading ?? 'h2'}
			isMirrored={fields.mirror?.value}
			links={mainLinks}
			preventAutoplay={fields.preventAutoplay?.value}
			primaryImageAlt={primaryImg.alt}
			primaryImageSrc={primaryImg.src}
			primaryLinkHref={primaryLinkHref}
			products={products}
			productsDisplayType={
				isCampaignSpinner ? 'campaignSpinner' : 'shopByMedia'
			}
			secondaryImageAlt={secondaryImg?.alt}
			secondaryImageSrc={secondaryImg?.src}
			text={fields.description}
			video={video}
		/>
	);
}
InspirationLandscape.displayName = 'InspirationLandscape';
