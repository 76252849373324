import React, { InputHTMLAttributes } from 'react';

import cn from 'utils/cn';

import { getCheckboxRadioClassName, type RadioCheckboxColor } from './helpers';

export interface Props
	extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
	color?: RadioCheckboxColor;
	id: string;
	inputClassName?: string;
	label: string;
	labelClassName?: string;
	name: string;
}

export default function Radio({
	className,
	color = 'red',
	disabled,
	id,
	inputClassName,
	label,
	labelClassName,
	...rest
}: Props) {
	return (
		<div className={cn('flex items-start', className)}>
			<input
				{...rest}
				type="radio"
				className={getCheckboxRadioClassName({
					color,
					extra: inputClassName,
				})}
				disabled={disabled}
				id={id}
			/>
			<label
				htmlFor={id}
				className={cn(
					'cursor-pointer pl-2',
					disabled && 'opacity-50',
					labelClassName,
				)}
			>
				{label}
			</label>
		</div>
	);
}
Radio.displayName = 'FormUI_Radio';
