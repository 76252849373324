import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import BlockContentHeading from 'components/BlockContentHeading';
import { LayoutContainer } from 'components/Layout';
import type { JulaComponentProps } from 'lib/component-props';
import { getEditorMargin } from 'utils/business-logic';

type Props = JulaComponentProps & {
	fields?: {
		description: Field<string>;
		heading: Field<string>;
	};
};

export default function ContentHeading({ fields, params }: Props) {
	if (!fields) {
		return null;
	}

	return (
		<LayoutContainer
			id={params?.anchor}
			outerClassName={getEditorMargin(params)}
			withGrid
			withPopoverLayout
		>
			<BlockContentHeading
				heading={fields.heading}
				headingLevel={params?.heading}
				description={fields.description}
				className="col-span-full md:col-span-6"
			/>
		</LayoutContainer>
	);
}
ContentHeading.displayName = 'ContentHeading';
