/**
 * UserInfo
 */

import React from 'react';
import { useSelector } from '@xstate/react';

import { useCheckoutContext, useGlobalStateContext } from 'contexts';
import { selectIsAuthenticatedUser } from 'state-machines/authentication';
import { selectUserInfoActor } from 'state-machines/checkout';
import { selectAlternativeDeliveryAddress } from 'state-machines/checkout/userInfo';

import AnonUserForm from './AnonUserForm';
import ClubProUserForm from './ClubProUserForm';
import DeliveryAddressForm from './DeliveryAddressForm';
import { getTestDataAttrFrom } from 'utils/helpers';

interface Props {
	className?: string;
}

/** Wrapper for Personalinformation forms that handles change and sets data */
export default function UserInfo({ className }: Props) {
	const { userService } = useGlobalStateContext();
	const { checkoutService } = useCheckoutContext();
	const isAuthenticatedUser = useSelector(
		userService,
		selectIsAuthenticatedUser,
	);
	const userInfoActor = useSelector(checkoutService, selectUserInfoActor);
	const alternativeDeliveryAddress = useSelector(
		userInfoActor,
		selectAlternativeDeliveryAddress,
	);

	return (
		<div
			data-cy={getTestDataAttrFrom('checkout-user-info')}
			className={className}
		>
			{isAuthenticatedUser && <ClubProUserForm />}
			{!isAuthenticatedUser && <AnonUserForm />}
			{alternativeDeliveryAddress && <DeliveryAddressForm />}
		</div>
	);
}
UserInfo.displayName = 'UserInfo';
