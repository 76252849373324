import React from 'react';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import { JulaComponentProps } from 'lib/component-props';

type Props = JulaComponentProps & {
	fields?: {
		triggerbeeCampaignId?: Field<string>;
	};
};

export default function TriggerbeeCampaign({ fields }: Props) {
	const id = fields?.triggerbeeCampaignId?.value;
	if (!id) {
		return null;
	}
	return <div id={id} />;
}
TriggerbeeCampaign.displayName = 'TriggerbeeCampaign';
