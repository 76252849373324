import React from 'react';
import clsx from 'clsx';

import Text from 'components/Text';

export const renderDescription = (
	description: string | undefined,
	name?: string,
	className?: string,
) => (
	<Text as="pSmall" className={clsx(className)}>
		{name ? (description || '') + name : description}
	</Text>
);
export const renderHeader = (
	header: string | undefined,
	className?: string,
) => (
	<Text as="h2" styleAs="h2" className={clsx(className)}>
		{header}
	</Text>
);
