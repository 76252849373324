import React from 'react';

import Button from 'components/Button';
import InfoBox from 'components/InfoBox';
import LinearGauge from 'components/LinearGauge';
import { Skeleton, SkeletonItem } from 'components/Skeleton';
import Text from 'components/Text';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

interface Props {
	heading: string | undefined;
	notCreditCustomerInfoText: string | undefined;
	isCreditCustomer: boolean | undefined;
	isValidCreditCustomer: boolean | undefined;
	creditLimit: number | undefined;
	availableCreditBalance: number | undefined;
	loading: boolean | undefined;
	hasError: boolean;
	raiseCreditButtonOnClick: () => void;
	applyForCreditButtonOnClick: () => void;
}

function AccountOverviewCreditInformation({
	heading,
	notCreditCustomerInfoText,
	loading,
	hasError,
	isCreditCustomer,
	isValidCreditCustomer,
	creditLimit,
	availableCreditBalance,
	raiseCreditButtonOnClick,
	applyForCreditButtonOnClick,
}: Props) {
	const { t } = useI18n();
	const loadedAndOk = !loading && !hasError;
	return (
		<>
			<div className="mt-9 flex justify-between md:mt-14">
				<Text as="h2">{heading}</Text>
				{loadedAndOk && isCreditCustomer && isValidCreditCustomer && (
					<Button
						variant="text"
						size="medium"
						onClick={raiseCreditButtonOnClick}
					>
						{t('account_raise_credit_button')}
					</Button>
				)}
			</div>
			{loadedAndOk && !isCreditCustomer && (
				<div className="mt-4">
					<InfoBox
						icon="info"
						heading={t('account_details_invoice_heading')}
						variant="information"
					>
						<Text as="pSmall" className="mb-2">
							{notCreditCustomerInfoText}
						</Text>
						<Button
							size="small"
							variant="primary"
							onClick={applyForCreditButtonOnClick}
						>
							{t('account_apply_for_credit_button')}
						</Button>
					</InfoBox>
				</div>
			)}
			{loadedAndOk &&
				isCreditCustomer &&
				is.defined(creditLimit) &&
				is.defined(availableCreditBalance) && (
					<div className="mt-4">
						<Text as="p" className="mb-4 font-bold">
							{t('account_details_credit_space_text')}
						</Text>
						<Text as="p" className="mb-6">
							{`${creditLimit}.-`}
						</Text>
						<LinearGauge
							descriptiveText={t('account_available_credit_text')}
							unitCount={availableCreditBalance}
							unitMax={creditLimit}
						/>
					</div>
				)}
			{loading && (
				<Skeleton>
					<SkeletonItem height="1rem" className="mt-4" />
					<SkeletonItem height="1rem" className="mb-8 mt-2" />
					<SkeletonItem height="1rem" className="mt-4" />
					<SkeletonItem height="1rem" className="mb-8 mt-2" />
				</Skeleton>
			)}
			{hasError && (
				<InfoBox
					icon="error"
					variant="error"
					message={t('account_generic_fetch_error_text')}
				/>
			)}
		</>
	);
}

export default AccountOverviewCreditInformation;
AccountOverviewCreditInformation.displayName =
	'AccountOverviewCreditInformation';
