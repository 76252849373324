import React from 'react';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import IconLink from 'components/IconLink';
import { LayoutContainer } from 'components/Layout';
import { JulaComponentProps } from 'lib/component-props';
import { DigizuiteAssetList } from 'models/asset';
import { getEditorMargin } from 'utils/business-logic';
import { getAsset } from 'utils/helpers';

type Props = JulaComponentProps & {
	fields?: {
		document: DigizuiteAssetList;
		name: Field<string>;
	};
};
export default function DocumentLink({ params, fields }: Props) {
	if (!fields) {
		return null;
	}
	const { document, name } = fields;
	const doc = getAsset('Source Copy', document);
	if (!doc) return null;
	return (
		<LayoutContainer
			outerClassName={getEditorMargin(params)}
			id={params?.anchor}
			className="grid grid-cols-4 gap-4 sm:grid-cols-8 md:grid-cols-12 md:gap-6"
		>
			<IconLink
				href={doc.src}
				target="_blank"
				field={name}
				icon="file"
				className="col-span-full md:col-start-4"
			/>
		</LayoutContainer>
	);
}
DocumentLink.displayName = 'DocumentLink';
