const CURRENCY_SYMBOL_REGEXP = /[,.:;]-/g;

/**
 * Remove any 'graphical' currency symbols like .- from a string.
 *
 * The frontend knows a bit too much here since the symbol always comes from
 * the backend, but it's necessary for screen readers since the symbol can
 * be read literally like 'period hyphen' in some cases.
 */
export function removeCurrencySymbols(text: string): string {
	// Maybe the component knows a bit too much here, but the bottom
	// tag is often used to display savings where the string contains
	// the price symbol (e.g. .-) that can be problematic for screen
	// readers. Just remove instead of replacing with a proper currency;
	// the context from the main price should make it clear that this
	// is related and having the full 'euros and cents' equivalent read
	// again is needlessly verbose.
	return text.replaceAll(CURRENCY_SYMBOL_REGEXP, '');
}
