/**
 * ProductCardColumn
 */

import React, { type MouseEventHandler, type ReactNode } from 'react';
import clsx from 'clsx';

import ActionButton, { type ActionButtonState } from 'components/ActionButton';
import Icon from 'components/Icon';
import Img from 'components/Img';
import Link from 'components/Link';
import OptionalContainer from 'components/OptionalContainer';
import {
	PRODUCT_REVIEWS_ID,
	PRODUCT_TECHNICAL_ATTRIBUTES_ID,
} from 'components/ProductDetails/';
import ProductFlag, { type ProductFlagVariant } from 'components/ProductFlag';
import Rating from 'components/Rating';
import Text from 'components/Text';
import type { ProductTag } from 'models/product';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

import ProductCardStockInfo, { type StockInfo } from './ProductCardStockInfo';
import ProductCardTags from './ProductCardTags';

interface Props {
	actionButtonState?: ActionButtonState;

	/** The list of items from checklist  */
	checklist?: string[];

	/** Extra container class names */
	className?: string;

	/** Is product disabled? */
	disabled?: boolean;

	/** Energy Symbol */
	energySymbolAlt?: string;

	/** Energy Symbol */
	energySymbolSrc?: string;

	/** Set which news/outlet etc. flag to display, if any. @deprecated */
	flagVariant?: ProductFlagVariant;

	/** Product has multiple variants ? */
	hasMultipleVariants?: boolean;

	/** The heading in the component */
	heading?: string;

	/** The link to the product page */
	href: string;

	/** Image alt text */
	imageAlt?: string;

	/** Height for the image */
	imageHeight: number;

	/** Image src attribute */
	imageSrc: string;

	/** Width for the image */
	imageWidth: number;

	/** Add to cart callback */
	onAddToCartClick?: MouseEventHandler<HTMLButtonElement>;

	/** Link click callback */
	onLinkClick?: MouseEventHandler<HTMLAnchorElement>;

	/** The price in the component */
	price: ReactNode;

	/** The product's unique ID */
	productId: string;

	/** Link to product sheet */
	productSheetLink?: string;

	/** Product sheet link text */
	productSheetLinkDescription?: string;

	/** Sets the number of reviews */
	reviews?: number;

	/** Sets the numbers of score/stars */
	score?: number;

	/** Show and hide options for the button */
	showAddToCartButton?: boolean;

	/** Stock object for stock section stock info */
	stockInfo: StockInfo | undefined;

	/** Display information related to store */
	storesEnabled: boolean;

	/** The sub heading in the component */
	subHeading?: string;

	/** Product tags and flags */
	tags?: ProductTag[];
}

/** Product card with a vertical layout. */
export default function ProductCardColumn({
	actionButtonState,
	checklist,
	className,
	disabled,
	energySymbolAlt,
	energySymbolSrc,
	flagVariant,
	hasMultipleVariants = false,
	heading,
	href,
	imageAlt = '',
	imageHeight,
	imageSrc,
	imageWidth,
	onAddToCartClick,
	onLinkClick,
	price,
	productId,
	productSheetLink,
	productSheetLinkDescription,
	reviews,
	score = 0,
	showAddToCartButton = false,
	stockInfo,
	storesEnabled,
	subHeading,
	tags,
}: Props) {
	const { t } = useI18n();
	const hasReviews = score > 0;
	const hasTags = is.arrayWithLength(tags);

	// The primary link covers the entire card, place any other clickable
	// components above it and expand their hit area to hopefully reduce
	// accidental clicks on the below primary link.
	const innerTargetClasses =
		'relative z-3 before:absolute before:-inset-2 before:-left-1';

	return (
		<article
			className={clsx(className, 'relative w-full md:w-auto')}
			aria-label={subHeading}
		>
			{flagVariant && (
				<>
					<ProductFlag
						size="small"
						variant={flagVariant}
						className="absolute left-0 top-0 z-1 md:hidden"
					/>
					<ProductFlag
						size="medium"
						variant={flagVariant}
						className="absolute left-0 top-0 z-1 max-md:hidden"
					/>
				</>
			)}
			<OptionalContainer
				// Normally an unnecessary optimization to be stringent about a
				// wrapping div, but there can be a lot of product cards on a page
				// and the site's DOM is too big as it is.
				as="div"
				hasContainer={hasTags}
				className="relative mb-2"
			>
				<Img
					src={imageSrc}
					alt={imageAlt}
					width={imageWidth}
					height={imageHeight}
					service="nextjs"
					useFallbackOnError
					className={clsx(
						'block object-contain sm:max-w-[60%] md:h-40',
						!hasTags && 'mb-2',
					)}
				/>
				{tags && (
					<ProductCardTags
						tags={tags}
						className="absolute bottom-0 left-0 z-1"
					/>
				)}
			</OptionalContainer>

			<header className="mt-2">
				{heading && (
					<Text as="p" styleAs="h6" className="mb-2">
						<Link
							href={href}
							onClick={onLinkClick}
							cover
							className="hover:underline"
						>
							{heading}
						</Link>
					</Text>
				)}
				{subHeading && (
					<Text as="pXSmall" className="mb-2">
						{subHeading}
					</Text>
				)}
			</header>
			{hasReviews && (
				<div className="mt-2 flex items-start">
					<Rating
						size="small"
						score={score}
						reviewCount={reviews}
						href={`${href}#${PRODUCT_REVIEWS_ID}`}
						onClick={onLinkClick}
						className={innerTargetClasses}
					/>
				</div>
			)}

			<div className="my-2">{price}</div>
			{checklist && (
				<ul className="mt-3 flex list-inside list-disc flex-col gap-1 text-sm">
					{checklist.map((item) => (
						<li key={item}>{item}</li>
					))}
				</ul>
			)}

			{(energySymbolSrc || productSheetLink) && (
				<div
					className={clsx(
						'mt-4 flex items-center gap-3 text-xs',
						// The energy symbol as some size to it but the sheet link is small
						// and feels a bit flimsy to hit even with an expanded hit area.
						// Place the entire container as a blocking layer above the primary
						// link when there is a sheet link.
						productSheetLink && 'relative z-3',
					)}
				>
					{energySymbolSrc && (
						<Link
							href={`${href}#${PRODUCT_TECHNICAL_ATTRIBUTES_ID}`}
							onClick={onLinkClick}
							className={clsx(innerTargetClasses, 'hover:opacity-80')}
						>
							<Img
								src={energySymbolSrc}
								alt={
									energySymbolAlt ||
									t('product_details_technical_specification_heading')
								}
								className="h-6 object-contain"
							/>
						</Link>
					)}
					{productSheetLink && (
						<Link
							href={productSheetLink}
							target="_blank"
							rel="nofollow"
							underline
							className={innerTargetClasses}
						>
							{productSheetLinkDescription ||
								t('product_details_product_sheet_button')}
						</Link>
					)}
				</div>
			)}

			{stockInfo && !hasMultipleVariants && (
				<ProductCardStockInfo
					storeStockVisible={storesEnabled}
					stockInfo={stockInfo}
					productId={productId}
					className="mt-4"
				/>
			)}
			{hasMultipleVariants && (
				<p className="mt-4 flex items-center text-xs">
					<Icon
						size={16}
						icon="arrow"
						color="white"
						backgroundColor="julaRed"
						className="mr-1"
					/>
					{t('product_list_multiple_variants_label')}
				</p>
			)}

			{showAddToCartButton && (
				<ActionButton
					data-cid="addToCart"
					className={clsx('mt-4', innerTargetClasses)}
					size="small"
					variant="cta"
					customState={actionButtonState}
					disabled={disabled}
					onClick={onAddToCartClick}
				>
					{t('product_Card_buy_button')}
				</ActionButton>
			)}
		</article>
	);
}
ProductCardColumn.displayName = 'ProductCardColumn';
