/**
 * Hr
 */

import React from 'react';
import clsx from 'clsx';

interface Props {
	className?: string;
}

/** Styled Hr. */
export default function Hr({ className }: Props) {
	return <hr className={clsx('h-px w-full border-grey', className)} />;
}
Hr.displayName = 'Hr';
