/**
 * SearchResultsPage
 */

import React from 'react';
import { useRouter } from 'next/router';

import Link from 'components/Link';
import ProductListComponent from 'components/ProductList';
import Text from 'components/Text';
import { withRequiredProps } from 'hoc';
import type { JulaComponentProps } from 'lib/component-props';
import type { ProductList as ProductListModel } from 'models/sitecore';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

type Props = JulaComponentProps & {
	fields: ProductListModel & {
		spellingSuggestions?: string[];
	};
};

function SearchResultsPage({ fields, params }: Props) {
	const { t } = useI18n();
	const router = useRouter();
	const query = router.query.query?.toString() || '';
	const { spellingSuggestions } = fields;

	if (!query) {
		return (
			<div className="mx-4 my-20 text-center">
				<Text as="h1" text={t('search_no_search_results_text')} />
			</div>
		);
	}

	return (
		<ProductListComponent
			key={query}
			editorMargin={params?.margin}
			id={params?.anchor}
			fields={fields}
			gtmItemListId="search"
			gtmItemListName="Search result"
			placeholderComponentName="SearchResultsPage"
			searchQuery={query}
			layoutArea1Content={
				<>
					<Text as="h1" className="text-left">
						<>
							{t('search_query_label')}{' '}
							<span className="[quotes:auto] before:content-[open-quote] after:content-[close-quote]">
								{query}
							</span>
						</>
					</Text>
					{is.arrayWithLength(spellingSuggestions) && (
						<Text as="p" className="mt-4 text-left">
							{t('search_results_spelling_suggestion_text')}{' '}
							{spellingSuggestions.map((suggestion, index) => (
								<React.Fragment key={suggestion}>
									<Link
										href={`/search/?query=${suggestion}`}
										className="underline hover:no-underline"
									>
										{suggestion}
									</Link>
									{index < spellingSuggestions.length - 1 ? ', ' : ''}
								</React.Fragment>
							))}
							?
						</Text>
					)}
				</>
			}
		/>
	);
}
SearchResultsPage.displayName = 'SearchResultsPage';

export default withRequiredProps(SearchResultsPage, 'fields');
