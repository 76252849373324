/**
 * BlockOpeningHours
 */

import React, { useId } from 'react';

import Accordion from 'components/Accordion';
import OpeningHoursTable, { type Row } from 'components/OpeningHoursTable';
import Status from 'components/Status';
import type { OpeningHours, OpeningHourSection } from 'models/store';
import { filterTruthy } from 'utils/collection';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

function mapHoursToTableRow(hours: OpeningHours): Row {
	return {
		key: `${hours.title}-${hours.type}-${hours.date}-${hours.description}`,
		title: hours.title || hours.type || '-',
		description: hours.shortDescription || '-',
	};
}

interface Props {
	sections: OpeningHourSection[] | undefined;
	special: OpeningHourSection | undefined;
}

export default function BlockOpeningHours({ sections, special }: Props) {
	const { t } = useI18n();
	const id = useId();

	const filteredSections = sections?.filter((section) =>
		is.arrayWithLength(section.openingHours),
	);
	const todaysHours = filterTruthy(filteredSections, 'todaysOpeningHours');

	return (
		<>
			{todaysHours.map(
				({ title, todaysOpeningHours: { state, description } }) => (
					<React.Fragment key={title}>
						<p className="mt-6 font-bold">{title}</p>
						<Status
							className="mt-1"
							color={state?.toUpperCase() === 'OPEN' ? 'green' : 'red'}
							text={description}
						/>
					</React.Fragment>
				),
			)}

			<Accordion
				id={`opening-hours-accordion-${id}`}
				title={t('opening_hours_accordion_title')}
				color="white"
				size="small"
				className="mt-8 max-w-[16rem]"
				contentClassName="space-y-6 rounded-border bg-greyLighter p-4"
			>
				{is.arrayWithLength(special?.openingHours) && (
					<OpeningHoursTable
						caption={special.title}
						rows={special.openingHours.map(mapHoursToTableRow)}
					/>
				)}
				{sections?.map((section) => (
					<OpeningHoursTable
						key={section.title}
						caption={section.title}
						rows={section.openingHours.map(mapHoursToTableRow)}
					/>
				))}
			</Accordion>
		</>
	);
}
BlockOpeningHours.displayName = 'BlockOpeningHours';
