import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import clsx from 'clsx';

import type { JulaComponentProps } from 'lib/component-props';

type Spaces = 'half' | 'single' | 'double';

type Props = JulaComponentProps & {
	fields: {
		space: Field<Spaces>;
	};
};

export default function Spacer(props: Props): JSX.Element {
	const { space } = props.fields;

	return (
		<div
			className={clsx(
				space.value === 'half' && 'h-6',
				space.value === 'single' && 'h-10',
				space.value === 'double' && 'h-20',
			)}
		/>
	);
}
Spacer.displayName = 'Spacer';
