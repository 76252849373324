import React from 'react';

import {
	ProductCardWebStockStatusType,
	VariantPickerWebStockStatusType,
} from 'utils/business-logic/stock';
import { assertUnreachable } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

import StockBalanceConcise from './StockBalanceConcise';

interface Props {
	status: ProductCardWebStockStatusType | VariantPickerWebStockStatusType;
}
export default function WebStockBalanceConcise({ status }: Props) {
	const { t } = useI18n();
	switch (status) {
		case 'InStock':
			return (
				<StockBalanceConcise
					inStock
					text={t('stock_online_in_stock_general')}
				/>
			);
		case 'OutOfStock':
			return (
				<StockBalanceConcise
					inStock={false}
					text={t('stock_online_out_of_stock_general')}
				/>
			);
		case 'ReStock-NotWatchable':
		case 'ReStock':
			return (
				<StockBalanceConcise
					inStock={false}
					text={t('stock_online_re_stock')}
				/>
			);
		case 'InStoreOnly':
			return (
				<StockBalanceConcise
					inStock={false}
					text={t('stock_online_store_only')}
				/>
			);
		case 'MatchFailed':
			return '';
		default:
			assertUnreachable(status);
	}
}
WebStockBalanceConcise.displayName = 'WebStockBalanceConcise';
