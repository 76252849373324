import React, { createContext, useContext } from 'react';

interface GlobalPopoverContextProps {
	setTopMessage: (component: React.ReactNode) => void | undefined;
	topMessage: React.ReactNode;
}
export const GlobalPopoverContext = createContext<
	GlobalPopoverContextProps | undefined
>(undefined);

export function useGlobalPopoverContext() {
	return useContext(GlobalPopoverContext);
}
