import React from 'react';

import Button from 'components/Button';
import { Skeleton, SkeletonItem } from 'components/Skeleton';

import StockStatus from './StockStatus';

interface Props {
	buttonText?: string;
	inStock: boolean;
	isLoading?: boolean;
	onStatusClick?: () => void;
	text: string;
}

export default function StockBalanceConcise({
	buttonText,
	inStock,
	isLoading,
	onStatusClick,
	text: rawText,
}: Props) {
	if (isLoading) {
		return (
			<Skeleton>
				<SkeletonItem height="0.9rem" width="5rem" />
			</Skeleton>
		);
	}
	const text =
		!onStatusClick && !rawText.endsWith('.') ? `${rawText}.` : rawText;
	return (
		<StockStatus
			textSize="small"
			inStock={inStock}
			text={
				<>
					{!onStatusClick && text}
					{onStatusClick && (
						<Button
							variant="text-inline"
							size="small"
							underline={false}
							className="group/stock-btn min-h-4 py-0"
							hasExpandedHitArea
							onClick={onStatusClick}
						>
							{text}&nbsp;
							<span className="inline-block underline group-hover/stock-btn:no-underline">
								{buttonText}
							</span>
						</Button>
					)}
				</>
			}
		/>
	);
}
StockBalanceConcise.displayName = 'StockBalanceConcise';
