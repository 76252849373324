/**
 * Popover
 */

import React, {
	type MouseEventHandler,
	type ReactNode,
	useCallback,
	useId,
	useRef,
} from 'react';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import clsx from 'clsx';

import IconButton from 'components/IconButton';
import Portal from 'components/Portal';
import Text from 'components/Text';
import { POPOVERS_CONTAINER_ID } from 'constants/ids';
import { useDebounce, useDialog, useValueChangeEffect } from 'hooks';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

export type PopoverVariant = 'sidePanel' | 'window';
export type PopoverLayer = 1 | 2 | 3;

const Z_INDEX_CLASS: Record<PopoverLayer, string> = {
	1: 'z-popover',
	2: 'z-popoverLayer2',
	3: 'z-popoverLayer3',
};

interface ExtraPageParts {
	/** Content after the heading and close button for this page. */
	afterHeader?: ReactNode;

	/** Content before the heading and close button for this page. */
	beforeHeader?: ReactNode;

	/** Content after the main content for this page. */
	footer?: ReactNode;

	/** If the `beforeHeader` content should have the content padding. */
	padAfterHeader?: boolean;

	/** If the `afterHeader` content should have the content padding. */
	padBeforeHeader?: boolean;

	/** If the main content should have padding. */
	padContent?: boolean;

	/** If the `footer` content should have the content padding. */
	padFooter?: boolean;
}

interface BaseProps extends ExtraPageParts {
	/** Occupy the full height on small screens? */
	fullSizeSmall?: boolean;

	/** The popover container ID. */
	headerColor?: 'white' | 'red';

	/** The popover container ID. */
	id?: string;

	/** True if the Popover should be open. */
	isOpen: boolean;

	/** Layer when nesting popovers. Avoid when possible. */
	layer?: PopoverLayer;

	/** Callback function when the popover should close. */
	onClose?: () => void;

	/** Callback function when the popover has been closed and the transition is done. */
	onCloseDone?: () => void;

	/** Callback function when the popover is opened. */
	onOpen?: () => void;

	/** If the popover should close when the URL is updated. */
	shouldCloseOnNavigation?: boolean;

	/** If the popover should close when clicking on the background outside it. */
	shouldCloseOnOutsideClick?: boolean;

	/** Custom ID for the title element, is otherwise generated. */
	titleId?: string;

	/** The style of popover to use. */
	variant?: PopoverVariant;
}

interface PopoverPage extends ExtraPageParts {
	/** Main content for this page. */
	content: ReactNode;

	/** A unique page ID. */
	id?: string;

	/** Modal title for this page. */
	title: string;
}

interface PropsWithChildren extends BaseProps {
	/** Main content. */
	children: ReactNode;

	currentPageIndex?: never;
	onBackClick?: never;
	pages?: never;

	/** The modal title. */
	title: string;
}
interface PropsWithPages extends BaseProps {
	children?: never;

	/** The currently active page to display. */
	currentPageIndex: number;

	/** Back button click handler. */
	onBackClick?: () => void;

	/** Pages when a popover contains multiple steps. */
	pages: (PopoverPage | null | undefined | false)[];

	title?: never;
}

type Props = PropsWithChildren | PropsWithPages;

/** Dialog that slides in from the edge. */
export default function Popover(props: Props) {
	const { t } = useI18n();
	const scrollRef = useRef<HTMLDivElement>(null);
	const fallbackId = useId();

	const {
		currentPageIndex = 0,
		fullSizeSmall,
		headerColor = 'white',
		id,
		isOpen,
		layer = 1,
		onBackClick,
		onClose,
		onCloseDone,
		onOpen,
		shouldCloseOnNavigation,
		shouldCloseOnOutsideClick = true,
		titleId,
		variant = 'sidePanel',
	} = props;

	const popoverId = id || `popover-${fallbackId}`;
	const headingId =
		titleId || (id ? `${id}-heading` : `popover-heading-${fallbackId}`);

	const basePageData = {
		afterHeader: props.afterHeader,
		beforeHeader: props.beforeHeader,
		footer: props.footer,
		padAfterHeader: props.padAfterHeader,
		padBeforeHeader: props.padBeforeHeader,
		padContent: props.padContent,
		padFooter: props.padFooter,
	} as const;

	const pages: PopoverPage[] = props.pages
		? props.pages.filter(is.truthy).map((page, i) => ({
				// Use the main props as default values, e.g. to have the same footer
				// on all pages.
				...basePageData,
				id: `popover-page-${fallbackId}-${i}`,
				...page,
			}))
		: [
				{
					...basePageData,
					id: popoverId,
					content: props.children,
					title: props.title,
				},
			];

	const currentPage = pages[currentPageIndex] ?? pages[0]!;
	const {
		afterHeader,
		beforeHeader,
		content,
		footer,
		padAfterHeader,
		padBeforeHeader,
		padContent = true,
		padFooter,
		title,
	} = currentPage;

	// Only check if props.pages is set, it may only have a length of one due
	// to conditionals.
	const hasBackButton = Boolean(
		props.pages && currentPageIndex > 0 && onBackClick,
	);
	const backButtonRef = useRef<HTMLButtonElement>(null);
	const closeButtonRef = useRef<HTMLButtonElement>(null);

	const transitionDuration = 200;
	const transitionDurationClass = 'duration-200';

	const onDialogOpen = useCallback(() => {
		if (scrollRef.current) {
			disableBodyScroll(scrollRef.current, { reserveScrollBarGap: true });
		}
		onOpen?.();
	}, [onOpen]);

	const onDialogClose = useCallback(() => {
		if (layer === 1) {
			clearAllBodyScrollLocks();
		}
		onClose?.();
		if (onCloseDone) {
			setTimeout(() => {
				onCloseDone();
			}, transitionDuration);
		}
	}, [layer, onClose, onCloseDone]);

	const { dialogProps, dialogRef, focusTrapEndProps, focusTrapStartProps } =
		useDialog({
			'aria-labelledby': headingId,
			'id': popoverId,
			isOpen,
			'onOpen': onDialogOpen,
			'onClose': onDialogClose,
			'shouldCloseOnEscape': Boolean(onClose),
			shouldCloseOnNavigation,
		});

	// Move focus to the close button when the back button disappears while it
	// has focus.
	useValueChangeEffect(hasBackButton, (prevHasBackButton) => {
		if (
			prevHasBackButton &&
			!hasBackButton &&
			backButtonRef.current &&
			document.activeElement === backButtonRef.current
		) {
			if (closeButtonRef.current) {
				closeButtonRef.current.focus();
			} else {
				dialogRef.current?.focus();
			}
		}
	});

	// Set focus to the back or close button if focus is lost when changing pages.
	useValueChangeEffect(currentPageIndex, () => {
		if (
			dialogRef.current &&
			!dialogRef.current.contains(document.activeElement)
		) {
			// Let the back button start its transition before trying to focus,
			// otherwise focusing can fail due to visibility hidden.
			setTimeout(() => {
				if (hasBackButton && backButtonRef.current) {
					backButtonRef.current.focus();
				} else if (closeButtonRef.current) {
					closeButtonRef.current.focus();
				} else {
					dialogRef.current?.focus();
				}
			}, transitionDuration / 2);
		}
	});

	// Close the popover if clicking outside of its content element.
	const handleBackgroundClick: MouseEventHandler<HTMLDivElement> = (e) => {
		// Stop all bubbling to parent popovers.
		e.stopPropagation();
		if (!onClose || !shouldCloseOnOutsideClick) {
			return;
		}
		// If offsetParent is null the ref element is hidden and should be ignored.
		if (
			isOpen &&
			dialogRef.current?.offsetParent &&
			is.node(e.target) &&
			!dialogRef.current.contains(e.target)
		) {
			onDialogClose();
		}
	};

	const isOpenDebounced = useDebounce(isOpen, transitionDuration);
	const hasContent = isOpen || isOpenDebounced;

	return (
		<Portal selector={`#${POPOVERS_CONTAINER_ID}`} portalKey={popoverId}>
			{/* Keyboard users use other methods to close. */}
			{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
			<div
				onClick={handleBackgroundClick}
				className={clsx(
					'fixed inset-0 flex items-end bg-black/20',
					'transition-fadeTransform',
					transitionDurationClass,
					Z_INDEX_CLASS[layer],
					variant === 'sidePanel' && 'justify-end',
					variant === 'window' && 'justify-center sm:items-center',
					!isOpen && 'pointer-events-none invisible opacity-0',
				)}
			>
				<div {...focusTrapStartProps} />

				<div
					{...dialogProps}
					className={clsx(
						'flex flex-col bg-white shadow-topBottom outline-none sm:shadow-center',
						'w-full',
						fullSizeSmall ? 'h-full' : 'h-5/6',
						'transition-fadeTransform',
						transitionDurationClass,
						!isOpen && 'invisible translate-y-[5%] opacity-0',
						variant === 'sidePanel' && [
							'sm:h-full sm:w-[27rem]',
							!isOpen && 'sm:translate-x-[10%] sm:translate-y-0',
						],
						variant === 'window' && [
							'max-w-screen-md',
							'sm:h-auto sm:max-h-[calc(100%-4rem)] sm:w-[calc(100%-4rem)] sm:rounded-border',
						],
					)}
				>
					{hasContent &&
						beforeHeader &&
						(padBeforeHeader ? (
							<div className="border-b p-4 lg:px-6">{beforeHeader}</div>
						) : (
							beforeHeader
						))}

					<div
						className={clsx(
							'relative flex min-h-[3.5rem] shrink-0 items-center px-4 py-1 md:min-h-[4rem] lg:px-6',
							variant === 'window' && 'sm:rounded-t-border',
							headerColor === 'white' && 'border-b',
							headerColor === 'red' && 'bg-julaRed text-white',
						)}
					>
						<Text
							as="h2"
							styleAs="h4"
							className={clsx(
								'pr-2',
								// Transition triggers on render, avoid having the title fading
								// in when the popover opens.
								isOpenDebounced && [
									'transition-fadeTransform',
									!hasBackButton && [transitionDurationClass, 'delay-100'],
								],
								hasBackButton && '-translate-x-2 opacity-0 duration-0',
							)}
							id={headingId}
						>
							{title}
						</Text>
						{onBackClick && (
							<IconButton
								ref={backButtonRef}
								icon="arrow"
								iconDirection="left"
								text={t('header_product_listings_back_button')}
								textClassName="text-sm"
								className={clsx(
									'absolute left-4 top-1/2 -translate-y-1/2 lg:left-6',
									// Visually align icon edge
									'-ml-4 pl-0',
									// Transition triggers on render, avoid having the button
									// slide in when the popover opens.
									isOpenDebounced && [
										'transition-fadeTransform',
										transitionDurationClass,
									],
									!hasBackButton && 'invisible translate-x-3 opacity-0',
								)}
								hoverClasses={
									headerColor === 'red'
										? '[@media(hover:hover)]:hover:bg-white/20'
										: undefined
								}
								visibleText
								onClick={onBackClick}
							/>
						)}

						{onClose && (
							<IconButton
								ref={closeButtonRef}
								icon="close"
								text={t('popover_close_label')}
								// Visually align icon edge
								className="-mr-4 ml-auto"
								hoverClasses={
									headerColor === 'red'
										? '[@media(hover:hover)]:hover:bg-white/20'
										: undefined
								}
								onClick={() => {
									onDialogClose();
								}}
							/>
						)}
					</div>

					{hasContent &&
						afterHeader &&
						(padAfterHeader ? (
							<div className="border-b p-4 lg:px-6">{afterHeader}</div>
						) : (
							afterHeader
						))}

					<div
						ref={scrollRef}
						className={clsx(
							'grow overflow-y-auto overscroll-contain @container/popover',
							padContent && 'p-4 lg:px-6',
						)}
					>
						{hasContent && content}
					</div>

					{hasContent &&
						footer &&
						(padFooter ? (
							<div className="border-t p-4 lg:px-6">{footer}</div>
						) : (
							footer
						))}
				</div>

				<div {...focusTrapEndProps} />
			</div>
		</Portal>
	);
}
Popover.displayName = 'Popover';
