import React from 'react';

import Button from 'components/Button';

interface Props {
	onRemoveVariant?: () => void;
	removeVariantButtonText?: string;
	onMoveVariant: (() => void) | undefined;
	moveVariantButtonText: string;
	className?: string;
}
export default function MoveRemoveButtons({
	onRemoveVariant,
	removeVariantButtonText,
	onMoveVariant,
	moveVariantButtonText,
	className,
}: Props) {
	return (
		<div className={className}>
			{onRemoveVariant && (
				<Button variant="text" size="small" onClick={onRemoveVariant}>
					{removeVariantButtonText}
				</Button>
			)}
			{onMoveVariant && (
				<Button
					variant="text"
					size="small"
					className="ml-4"
					onClick={onMoveVariant}
				>
					{moveVariantButtonText}
				</Button>
			)}
		</div>
	);
}
MoveRemoveButtons.displayName = 'MoveRemoveButtons';
