/**
 * ButtonLink
 */

import React from 'react';
import type {
	AnchorHTMLAttributes,
	KeyboardEventHandler,
	MouseEventHandler,
} from 'react';

import Link from 'components/Link';
import { isHoldingNewTabKey } from 'utils/helpers';

interface Props {
	/** Link content */
	children: React.ReactNode;

	/** Link href */
	href: string;

	/** Click handler. Will add button behavior if set. */
	onClick?: MouseEventHandler<HTMLAnchorElement>;

	/** Keydown handler. Will not respond to a spacebar press since that's handled internally. */
	onKeyDown?: KeyboardEventHandler<HTMLAnchorElement>;
}

/**
 * Anchor link that can behave like a button.
 *
 * Is a regular link by default but will set an ARIA role and handle button
 * keyboard interactions if an `onClick` handler is set.
 */
const ButtonLink = React.forwardRef<
	HTMLAnchorElement,
	Props & AnchorHTMLAttributes<HTMLAnchorElement>
>(({ children, href, onClick, onKeyDown, ...props }, ref) => {
	const handleClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
		// Let the natural link click event pass through for new tabs.
		if (isHoldingNewTabKey(e)) {
			return;
		}
		if (onClick) {
			e.preventDefault();
			onClick(e);
		}
	};

	const handleKeydown: KeyboardEventHandler<HTMLAnchorElement> = (e) => {
		// Spacebar triggers native buttons.
		if (e.key === ' ') {
			e.currentTarget.click();
		} else if (onKeyDown) {
			onKeyDown(e);
		}
	};

	const buttonProps: AnchorHTMLAttributes<HTMLAnchorElement> = {};
	if (onClick) {
		buttonProps.role = 'button';
		buttonProps.onClick = handleClick;
		buttonProps.onKeyDown = handleKeydown;
	}
	if (onKeyDown) {
		buttonProps.onKeyDown = handleKeydown;
	}

	return (
		<Link href={href} {...props} {...buttonProps} ref={ref}>
			{children}
		</Link>
	);
});
ButtonLink.displayName = 'ButtonLink';

export default ButtonLink;
