import React from 'react';

import InfoBox from 'components/InfoBox';
import { AccountLayoutContainer } from 'components/Layout';
import LoadingSpinner from 'components/LoadingSpinner';
import Placeholder from 'components/Placeholder';
import { useInvoices } from 'hooks';
import type { JulaComponentProps } from 'lib/component-props';
import { useI18n } from 'utils/i18n';

import InvoiceList from './InvoiceList';

type Props = JulaComponentProps;

export default function AccountJulaProInvoices({ rendering }: Props) {
	const { t } = useI18n();

	const {
		items: invoices,
		isLoading,
		isLoadingMore,
		error,
		loadMore,
		hasNextPage,
	} = useInvoices();
	const hasInvoices = invoices && invoices.length > 0;

	return (
		<AccountLayoutContainer
			rendering={rendering}
			heading={t('jula_pro_invoices_heading')}
		>
			<Placeholder
				rendering={rendering}
				name="jula-account-details"
				isInNestedPlaceholder
			/>
			<div className="mt-14">
				{isLoading && !error && (
					<div className="flex items-center justify-center">
						<LoadingSpinner />
					</div>
				)}
				{!isLoading && !error && (
					<>
						{hasInvoices && (
							<InvoiceList
								invoices={invoices}
								hasLoadMoreButton={hasNextPage}
								isLoadingMore={isLoadingMore}
								onLoadMoreClick={loadMore}
							/>
						)}
						{!hasInvoices && (
							<InfoBox
								icon="info"
								message={t('jula_pro_invoices_no_invoices_text')}
							/>
						)}
					</>
				)}
				{error && (
					<InfoBox
						icon="error"
						variant="error"
						className="mt-3"
						message={t('account_generic_fetch_error_text')}
					/>
				)}
			</div>
		</AccountLayoutContainer>
	);
}
AccountJulaProInvoices.displayName = 'AccountJulaProInvoices';
