/**
 * ProductCard updated
 */

import React from 'react';
import type { MouseEventHandler } from 'react';

import { ActionButtonState } from 'components/ActionButton';
import ErrorBoundary from 'components/ErrorBoundary';
import Price, { BasePriceText } from 'components/Price';
import { getFlagVariant } from 'components/ProductFlag';
import {
	useFeatureToggle,
	useGlobalStateContext,
	useSelectedStore,
} from 'contexts';
import { useProductListGTMEvents } from 'hooks';
import {
	ProductCard as ProductCardType,
	RelatedProductCard,
} from 'models/productCard';
import {
	checkSupercheap,
	getPriceProps,
	getProductCardImage,
} from 'utils/business-logic';
import type { GTMItemListId, GTMItemListName } from 'utils/GoogleTagManager';

import ProductCardColumn from './ProductCardColumn';
import ProductCardRow from './ProductCardRow';

interface BaseProps {
	/** Control the ActionButton from the 'outside' state */
	actionButtonState?: ActionButtonState;

	/** CTA button text. ONLY concerns orientation 'row'. */
	buttonText?: string;

	/** Extra container class names */
	className?: string;

	/** List ID for GTM events */
	gtmItemListId: GTMItemListId;

	/** List name for GTM events */
	gtmItemListName: GTMItemListName;

	/** Link click callback */
	onLinkClick?: MouseEventHandler<HTMLAnchorElement>;

	/** Sets the orientation of the component */
	orientation?: 'column' | 'row';

	/** Product data */
	product: ProductCardType | RelatedProductCard;

	/** Zero based index in list */
	productListIndex: number;

	/** Should we open the additional sales modal? */
	requestAdditionalSales?: boolean;

	/** Should the button be rendered? */
	showAddToCartButton?: boolean;
}

interface WithPurchaseButtonCallback extends BaseProps {
	onAddToCart?: never;
	/** Custom add to cart button click handler */
	onPurchaseButtonClick?: () => void;
}
interface WithDefaultButtonCallback extends BaseProps {
	/** Add to cart callback for default button click handler */
	onAddToCart?: () => void;
	onPurchaseButtonClick?: never;
}

type Props = WithPurchaseButtonCallback | WithDefaultButtonCallback;

/** ProductCard component. */
export default function ProductCard({
	actionButtonState,
	buttonText,
	className,
	gtmItemListId,
	gtmItemListName,
	onAddToCart,
	onLinkClick,
	onPurchaseButtonClick,
	orientation = 'column',
	product,
	productListIndex,
	requestAdditionalSales = false,
	showAddToCartButton,
}: Props) {
	const { cartService } = useGlobalStateContext();
	const { selectedStore } = useSelectedStore();
	const { onlineCommerceEnabled, storesEnabled } = useFeatureToggle();
	const { sendAddToCartEvent, sendSelectItemEvent } = useProductListGTMEvents(
		gtmItemListId,
		gtmItemListName,
	);

	if (!product) {
		return null;
	}

	const handleAddToCartClick =
		onPurchaseButtonClick ??
		(() => {
			cartService.send({
				type: 'ADD_ONE_FROM_BUY_BUTTON',
				variantId: product.id,
				requestAdditionalSales,
				showToast: true,
				metaData: {
					gtmItemListId,
					gtmItemListName,
				},
			});
			sendAddToCartEvent(product, 1);
			onAddToCart?.();
		});

	const handleLinkClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
		sendSelectItemEvent(product, productListIndex);
		onLinkClick?.(e);
	};

	const energySymbolSrc = product.energySymbol?.[0]?.location;
	const flagVariant = getFlagVariant(product);

	const stockInfo =
		'productInStockAtStores' in product
			? {
					storeStock: {
						name: selectedStore?.name,
						inStock: Boolean(
							product.productInStockAtStores?.some(
								({ id }) => id.toString() === selectedStore?.id,
							),
						),
						isSellableInStore: product.isSellableInStore,
						productInStockAtStores: product.productInStockAtStoresCount ?? 0,
						isSellableOnWeb: product.isSellableOnWeb,
					},
					webStock: {
						isSellable: product.isSellable,
						isSellableOnWeb: product.isSellableOnWeb,
						productReStockWeb: product.productReStockWeb,
						isSellableInStore: product.isSellableInStore,
					},
				}
			: undefined;

	const isExpired = 'expired' in product ? product.expired : false;
	const isSellable = 'isSellable' in product ? product.isSellable : true;
	const usps = 'usPs' in product ? product.usPs : undefined;
	const basePrice = 'basePrice' in product ? product.basePrice : undefined;
	const hasMultipleVariants =
		'hasMultipleVariants' in product ? product.hasMultipleVariants : false;
	const price = !isExpired && product.listPrice && (
		<Price
			{...getPriceProps(product.listPrice, false, checkSupercheap(product))}
			size="small"
		/>
	);

	const heading =
		'titleExclModel' in product
			? product.subTitle && product.titleExclModel
				? `${product.titleExclModel} ${product.subTitle}`
				: (product.titleExclModel ?? product.title)
			: product.title;
	const subheading =
		'model' in product
			? product.model
				? `${product.brand?.title} | ${product.model}`
				: product.brand?.title
			: product.brand?.title;

	return (
		<ErrorBoundary>
			{orientation === 'row' ? (
				<ProductCardRow
					actionButtonState={actionButtonState}
					className={className}
					imageSrc={
						getProductCardImage(product) ||
						'/assets/images/placeholder-image.png'
					}
					href={product.url || '#'}
					imageWidth={160}
					imageHeight={160}
					heading={heading}
					subHeading={subheading}
					price={price}
					score={product.reviewScore?.score ?? 0}
					reviews={product.reviewScore?.count}
					buttonText={buttonText}
					showAddToCartButton={!isExpired && showAddToCartButton}
					flagVariant={flagVariant}
					energySymbolSrc={energySymbolSrc}
					productSheetLink={product.productSheetLink}
					productSheetLinkDescription={product.productSheetLinkDescription}
					onAddToCartClick={handleAddToCartClick}
					disabled={!isSellable || !onlineCommerceEnabled}
					onLinkClick={handleLinkClick}
					tags={product.productTags}
				/>
			) : (
				<ProductCardColumn
					actionButtonState={actionButtonState}
					className={className}
					productId={product.id}
					imageSrc={
						getProductCardImage(product) ||
						'/assets/images/placeholder-image.png'
					}
					imageWidth={160}
					href={product.url || '#'}
					imageHeight={160}
					heading={heading}
					subHeading={subheading}
					hasMultipleVariants={hasMultipleVariants}
					price={
						<>
							{price}
							<BasePriceText
								basePrice={basePrice}
								displayedPriceType={product.listPrice?.priceType}
								recentLowestPriceKey="product_list_recent_lowest_price_text"
								className="mt-1 text-xs"
							/>
						</>
					}
					score={product.reviewScore?.score ?? 0}
					reviews={product.reviewScore?.count}
					checklist={usps}
					energySymbolSrc={energySymbolSrc}
					flagVariant={flagVariant}
					productSheetLink={product.productSheetLink}
					productSheetLinkDescription={product.productSheetLinkDescription}
					onAddToCartClick={handleAddToCartClick}
					showAddToCartButton={!isExpired && showAddToCartButton}
					disabled={!isSellable || !onlineCommerceEnabled}
					storesEnabled={storesEnabled}
					onLinkClick={handleLinkClick}
					stockInfo={stockInfo}
					tags={product.productTags}
				/>
			)}
		</ErrorBoundary>
	);
}
ProductCard.displayName = 'ProductCard';
