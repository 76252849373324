/**
 * Breadcrumbs
 */

import React from 'react';

import { LayoutContainer } from 'components/Layout';
import { useI18n } from 'utils/i18n';

interface Props {
	/** The aria-label for the nav-tag. */
	ariaLabel?: string;

	/** React children */
	children?: React.ReactNode;

	/** Additional class names */
	className?: string;
}

/** Breadcrumbs component */
export default function Breadcrumbs({ ariaLabel, children, className }: Props) {
	const { t } = useI18n();
	return (
		<LayoutContainer
			outerComponent="nav"
			aria-label={ariaLabel || t('breadcrumbs_label')}
			itemType="http://schema.org/BreadcrumbList"
			outerClassName={className}
		>
			<ol className="md:flex md:flex-wrap">{children}</ol>
		</LayoutContainer>
	);
}
Breadcrumbs.displayName = 'Breadcrumbs';
