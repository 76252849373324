/**
 * BlockShortcutImages
 */

import React from 'react';

import ImagesGrid from './ImagesGrid';
import ShortcutImage from './ShortcutImage';
import type { ItemProps, ItemSize } from './types';

interface Props {
	className?: string;
	items: ItemProps[];
	size?: ItemSize;
}

/** Linked images with an arrow icon and text below. */
export default function BlockShortcutImages({
	className,
	items,
	size = 'regular',
}: Props) {
	return (
		<ImagesGrid as="ul" size={size} className={className}>
			{items.map((item) => (
				<li key={item.key || item.href} className="col-span-1">
					<ShortcutImage
						{...item}
						imgWidth={size === 'large' ? 393 : 254}
						imgHeight={size === 'large' ? 197 : 127}
						hideIconWhenSmall={size === 'regular'}
					/>
				</li>
			))}
		</ImagesGrid>
	);
}
BlockShortcutImages.displayName = 'BlockShortcutImages';
