/**
 * IconMenu
 */

import React from 'react';

interface Props {
	children?: React.ReactNode;
	className?: string;
}

/** Container for IconMenuItem components. */
export default function IconMenu({ children, className }: Props) {
	return <ul className={className}>{children}</ul>;
}
IconMenu.displayName = 'IconMenu';
