/**
 * ThemedBlockContainer
 */

import React, { type ReactNode } from 'react';
import clsx from 'clsx';

import Img from 'components/Img';

interface Props {
	bottomRightImageClassName?: string;
	bottomRightImageSrc?: string;
	children: ReactNode;
	className?: string;
	color: string;
	contentClassName?: string;
	topLeftImageClassName?: string;
	topLeftImageSrc?: string;
}

export default function ThemedBlockContainer({
	bottomRightImageClassName,
	bottomRightImageSrc,
	children,
	className,
	color,
	contentClassName,
	topLeftImageClassName,
	topLeftImageSrc,
}: Props) {
	return (
		<div
			style={{ backgroundColor: color }}
			className={clsx('relative', className)}
		>
			{topLeftImageSrc && (
				<Img
					src={topLeftImageSrc}
					width={810}
					height={405}
					service="nextjs"
					jpgOptimized={false}
					className={clsx(
						'pointer-events-none absolute left-0 top-0 max-h-full w-auto',
						topLeftImageClassName,
					)}
				/>
			)}

			<div className={clsx('relative z-1', contentClassName)}>{children}</div>

			{bottomRightImageSrc && (
				<Img
					src={bottomRightImageSrc}
					width={810}
					height={405}
					service="nextjs"
					jpgOptimized={false}
					className={clsx(
						'pointer-events-none absolute bottom-0 right-0 max-h-full w-auto',
						bottomRightImageClassName,
					)}
				/>
			)}
		</div>
	);
}
ThemedBlockContainer.displayName = 'ThemedBlockContainer';
