import React from 'react';
import clsx from 'clsx';

import Icon from 'components/Icon';
import { IconLinkField } from 'models/sitecore';
import { useI18n } from 'utils/i18n';

import { flattenIconLinks } from './helpers';

interface Props {
	className?: string;
	links: IconLinkField[];
}

export default function PageFooterSocial({ className, links }: Props) {
	const { t } = useI18n();
	const items = flattenIconLinks(links);

	if (items.length === 0) {
		return null;
	}

	// Pull margins to visually align the edge with the icons.
	return (
		<div className={clsx('-mx-3 flex', className)}>
			{items.map((item) => (
				<a
					key={`${item.href}-${item.text}`}
					href={item.href}
					target={item.target}
					className="inline-flex h-12 w-12 items-center justify-center rounded-full hover:bg-white/20"
				>
					<Icon icon={item.iconName} color="white" />
					<span className="sr-only">
						{t('follow_us_label')} {item.text}
					</span>
				</a>
			))}
		</div>
	);
}
PageFooterSocial.displayName = 'PageFooterSocial';
