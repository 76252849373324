/**
 * BlogItem
 */

import React from 'react';

import ArrowLink from 'components/ArrowLink';
import Img from 'components/Img';
import { OptionalLink } from 'components/Link';
import RichText from 'components/RichText';
import Text from 'components/Text';
import { DigizuiteAsset } from 'models/asset';
import { getAsset, is } from 'utils/helpers';

interface Props {
	className?: string;
	content: string;
	heading: string;
	images: DigizuiteAsset[][] | undefined;
	overviewLinkHref?: string;
	overviewLinkText?: string;
	published: string;
	tags?: string[] | undefined;
	url?: string;
	viewType?: 'overview' | 'details';
}

/** A blog item used for blog overview and blog page */
export default function BlogDetails({
	className,
	content,
	heading,
	images,
	published,
	tags,
	url,
	viewType,
	overviewLinkHref,
	overviewLinkText,
}: Props) {
	const filteredImages = images
		?.map(
			(assetList) =>
				getAsset('Templated 2:1', assetList) ??
				getAsset('Templated 1:1', assetList),
		)
		.filter(is.truthy);

	return (
		<div className={className}>
			<OptionalLink href={url}>
				<Text as={viewType === 'details' ? 'h1' : 'h2'} text={heading} />
			</OptionalLink>
			<Text
				as="p"
				className={viewType === 'details' ? 'mt-4' : 'mt-2'}
				text={published}
			/>
			<RichText className="mt-6" html={content} />
			{is.arrayWithLength(filteredImages) && (
				<div className="mt-6 flex flex-wrap gap-6">
					{filteredImages.map((image) => (
						<Img
							key={image.src}
							src={image.src}
							service="nextjs"
							height={image.formatName === 'Templated 1:1' ? 393 : 405}
							width={image.formatName === 'Templated 1:1' ? 393 : 810}
							alt={image.alt}
						/>
					))}
				</div>
			)}
			{is.arrayWithLength(tags) && (
				<div className="mt-6">
					{tags.map((tag) => (
						<Text key={tag} as="span" className="mr-2" text={tag} />
					))}
				</div>
			)}
			{overviewLinkHref && overviewLinkText && (
				<div className="mt-6 border-t border-grey pt-6">
					<ArrowLink href={overviewLinkHref} text={overviewLinkText} />
				</div>
			)}
		</div>
	);
}

BlogDetails.displayName = 'BlogDetails';
