/**
 * StorePopover
 */

import React, {
	type ReactElement,
	useCallback,
	useEffect,
	useId,
	useState,
} from 'react';

import Button from 'components/Button';
import Popover from 'components/Popover';
import ScreenReaderAnnouncementText from 'components/ScreenReaderAnnouncementText';
import SearchField from 'components/SearchField';
import { Skeleton, SkeletonItem } from 'components/Skeleton';
import StoreInfo from 'components/StoreInfo';
import Tabs from 'components/Tabs';
import type { Store } from 'models/store';
import { range } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

interface StoreNode {
	filterKey: string;
	node: ReactElement;
}
type AllStoresTabId = `store-popover-all-${string}`;
type NearbyStoresTabId = `store-popover-nearby-${string}`;
type TabId = AllStoresTabId | NearbyStoresTabId;

interface Props {
	allStores: StoreNode[] | undefined;
	hasSelectedStore: boolean;
	isLoading: boolean;
	isOpen: boolean;
	nearbyStores?: StoreNode[];
	onBackClick?: () => void;
	onClose: () => void;
	onSearch: (searchValue: string) => void;
	onTabChange?: (newTabId: TabId) => void;
	storeDetails?: Store;
}

function StoreList({
	isLoading,
	loaderAmount,
	stores,
}: {
	isLoading: boolean;
	loaderAmount: number;
	stores: StoreNode[] | undefined;
}) {
	const { t } = useI18n();

	return (
		<>
			<ScreenReaderAnnouncementText
				as="p"
				// TODO: proper plural
				text={stores ? t('stores_count_text', { num: stores.length }) : ''}
				hidden
				atomic
			/>
			{isLoading && (
				<Skeleton>
					{range(loaderAmount).map((i) => (
						<SkeletonItem key={i} height="4.8rem" className="mt-2" />
					))}
				</Skeleton>
			)}
			{!isLoading && (
				<ul className="flex flex-col gap-y-2">
					{stores?.map((store) => store.node)}
				</ul>
			)}
		</>
	);
}
StoreList.displayName = 'StorePopover_StoreList';

/** Shows a filterable list of stores with a possibility to add nearby stores in a tab */
export default function StorePopover({
	allStores,
	hasSelectedStore,
	isLoading,
	isOpen,
	nearbyStores,
	onBackClick,
	onClose: onCloseProp,
	onSearch,
	onTabChange,
	storeDetails,
}: Props) {
	const { t } = useI18n();
	const id = useId();

	const baseId = `store-popover-${id}`;
	const titleId = `store-popover-title-${id}`;
	const allStoresTabId: AllStoresTabId = `store-popover-all-${id}`;
	const nearbyStoresTabId: NearbyStoresTabId = `store-popover-nearby-${id}`;
	const initialTab = hasSelectedStore ? nearbyStoresTabId : allStoresTabId;

	const [selectedTab, setSelectedTab] = useState(initialTab);
	const [searchValue, setSearchValue] = useState('');

	const filterStores = (stores: StoreNode[] | undefined) =>
		stores?.filter((storeNode) =>
			storeNode.filterKey.toLowerCase().includes(searchValue.toLowerCase()),
		);
	const onClose = useCallback(() => {
		setSearchValue('');
		onCloseProp();
	}, [onCloseProp]);

	useEffect(() => {
		setSelectedTab(hasSelectedStore ? nearbyStoresTabId : allStoresTabId);
	}, [allStoresTabId, hasSelectedStore, nearbyStoresTabId]);

	// Selecting a tab moves focus to it. Doing it on close steals focus from
	// the popover trigger so wait until closing is done.
	const onCloseDone = useCallback(() => {
		setSelectedTab(initialTab);
	}, [initialTab]);

	useEffect(() => {
		if (storeDetails) {
			setSearchValue('');
		}
	}, [storeDetails]);

	const title = hasSelectedStore
		? t('popover_change_warehouse_header')
		: t('popover_choose_warehouse_header');

	return (
		<Popover
			isOpen={isOpen}
			id={baseId}
			titleId={titleId}
			onClose={onClose}
			onBackClick={onBackClick}
			onCloseDone={onCloseDone}
			// Force first page if loading since it has the skeleton.
			currentPageIndex={isLoading ? 0 : storeDetails ? 1 : 0}
			pages={[
				{
					title,
					content: (
						<>
							<SearchField
								inputLabel={t('store_popover_search_input_label')}
								className="mb-6 mt-2"
								hasSubmitButton={false}
								hasSearchIcon
								id="store-search"
								inputClassName="text-greyDarker border-grey"
								value={searchValue}
								onChange={(event) => {
									onSearch(event.target.value);
									setSearchValue(event.target.value);
								}}
								handleInputClearClick={() => setSearchValue('')}
								placeholder={t('store_popover_search_input_label')}
							/>
							{searchValue && (
								<StoreList
									isLoading={isLoading}
									stores={filterStores(allStores)}
									loaderAmount={15}
								/>
							)}
							{!searchValue && (
								<Tabs
									activeTabId={selectedTab}
									tabListLabelledBy={titleId}
									onTabChange={(newTabId) => {
										setSelectedTab(newTabId as TabId);
										onTabChange?.(newTabId as TabId);
									}}
									items={[
										{
											id: allStoresTabId,
											title: t('store_popover_all_stores_tab_header'),
											content: (
												<StoreList
													isLoading={isLoading}
													stores={allStores}
													loaderAmount={15}
												/>
											),
										},
										hasSelectedStore && {
											id: nearbyStoresTabId,
											title: t('store_popover_nearby_stores_tab_header'),
											content: (
												<StoreList
													isLoading={isLoading}
													stores={nearbyStores}
													loaderAmount={4}
												/>
											),
										},
									]}
								/>
							)}
						</>
					),
				},
				{
					title,
					content: storeDetails && (
						<>
							<StoreInfo
								name={storeDetails.name}
								todaysOpeningHours={storeDetails.todaysOpeningHours}
								storeArea={storeDetails.storeArea}
								streetAddress={storeDetails.streetAddress}
								postalCode={storeDetails.postalCode}
								city={storeDetails.city}
								latitude={storeDetails.latitude}
								longitude={storeDetails.longitude}
								regularOpeningHours={storeDetails.regularOpeningHours}
								specialOpeningHours={storeDetails.specialOpeningHours}
								addressSectionClassName="flex justify-between"
								iconClassName="flex-col"
							/>
							<Button
								className="mt-4"
								displayWidth="full"
								variant="primary"
								href={storeDetails.url}
							>
								{t('store_popover_visit_store_button')}
							</Button>
							{onBackClick && (
								<Button
									onClick={onBackClick}
									variant="text"
									displayWidth="full"
									className="mt-6"
								>
									{t('store_popover_choose_other_store_button')}
								</Button>
							)}
						</>
					),
				},
			]}
		/>
	);
}
StorePopover.displayName = 'StorePopover';
