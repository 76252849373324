/**
 * LinearGuage
 */

import React from 'react';

import Text from 'components/Text';

interface Props {
	descriptiveText: string;
	gaugeColor?: string;
	unitCount: number;
	unitMax: number;
}

export default function LinearGauge({
	descriptiveText,
	gaugeColor,
	unitCount,
	unitMax,
}: Props) {
	return (
		<div className="w-full">
			<Text as="span" text={descriptiveText} />
			<Text as="span" text={`${unitCount}.-`} className="mb-2 font-bold" />

			<div className="relative pt-1">
				<div className="flex h-4 overflow-hidden rounded-full bg-greyLight">
					<div
						style={{
							width: `${Math.round((unitCount / unitMax) * 100).toString()}%`,
						}}
						className={`flex flex-col rounded-full shadow-none ${
							gaugeColor ? `bg-${gaugeColor}` : 'bg-julaRed'
						}`}
					/>
				</div>
			</div>
		</div>
	);
}
LinearGauge.displayName = 'LinearGauge';
