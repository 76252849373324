import React from 'react';

import { LayoutContainer } from 'components/Layout';

export default function CookieInformation() {
	return (
		<LayoutContainer maxWidth="narrow">
			<div id="ot-sdk-cookie-policy" />
		</LayoutContainer>
	);
}
CookieInformation.displayName = 'CookieInformation';
