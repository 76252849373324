import React from 'react';
import clsx from 'clsx';

import ActionButton, { type ActionButtonState } from 'components/ActionButton';
import Button from 'components/Button';
import GenericForm from 'components/GenericForm';
import Icon from 'components/Icon';
import { Step, Stepper } from 'components/Stepper';
import Text from 'components/Text';
import { publicRuntimeConfig } from 'config';
import type { FormFields, Section } from 'models/sitecore';
import type {
	CustomerInfo,
	Worksite,
} from 'state-machines/createJulaProCustomer';
import { FormattedValidationErrors } from 'utils/fetchData';
import { formatPrice } from 'utils/format';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

import { renderHeader as baseRenderHeader } from './helpers';

interface Props {
	addCustomerButtonState: ActionButtonState;
	creditFunctionsEnabled: boolean;
	customerInfo: CustomerInfo | null;
	renderHeader: typeof baseRenderHeader;
	resetFromStepThree: () => void;
	section: Section | undefined;
	selectedCredit: number | null;
	selectedWorksite: Worksite | undefined;
	submitBecomeProMemberForm: (
		form: FormFields,
		values: Record<string, unknown>,
	) => Promise<FormattedValidationErrors['fieldValidationErrors']>;
}

const rendrDoubleLine = (
	firText: string,
	secondText: string,
	isFirst: boolean,
) => (
	<div className={clsx(isFirst ? 'mt-2' : 'mt-6', 'mb-6')}>
		<Text as="p" className="mb-1 text-greyDarker">
			{firText}
		</Text>
		<Text as="p" className="text-greyDarker">
			{secondText}
		</Text>
	</div>
);

export default function StepThreeContent({
	addCustomerButtonState,
	creditFunctionsEnabled,
	customerInfo,
	renderHeader,
	resetFromStepThree,
	section,
	selectedCredit,
	selectedWorksite,
	submitBecomeProMemberForm,
}: Props) {
	const { t } = useI18n();

	if (!section) {
		return null;
	}

	const { backText, form, submitText } = section;

	return (
		<div className="mb-14 h-full">
			{creditFunctionsEnabled && (
				<div className={clsx('mx-8 mt-6')}>
					<Stepper className="mt-4 flex items-center">
						<Step number={1} status="done" />
						<Step number={2} status="done" />
						<Step number={3} status="active" />
					</Stepper>
				</div>
			)}
			<div className={clsx(creditFunctionsEnabled ? 'mt-9' : 'mt-4')}>
				{renderHeader(t('julapro_stepper_step_three_oranization_heading'))}
			</div>
			<div>
				{rendrDoubleLine(
					selectedWorksite?.companyName!,
					selectedWorksite?.organizationNumber!,
					true,
				)}
			</div>
			<div>
				{rendrDoubleLine(
					selectedWorksite?.address!,
					`${selectedWorksite?.postalNumber!}, ${selectedWorksite?.city!}`,
					false,
				)}
			</div>
			{is.defined(selectedCredit) && selectedCredit > 0 && (
				<div className="my-6">
					<Text as="span" styleAs="h6" className="text-greyDarker">
						{t('julapro_wished_creditlevel')}{' '}
					</Text>
					<Text as="span" className="text-greyDarker">
						{formatPrice(selectedCredit)}
						.-
					</Text>
				</div>
			)}
			<div className="mt-8">
				{renderHeader(t('julapro_stepper_step_three_admin_info_heading'))}
			</div>
			{customerInfo && (
				<div className="mb-8">
					{rendrDoubleLine(customerInfo?.name, customerInfo?.contactId, true)}
				</div>
			)}
			<GenericForm
				inputSpace="my-4"
				fields={form}
				initialValues={{ offers: false, termsAndConditionsAccepted: false }}
				onSubmitCallback={(values) => submitBecomeProMemberForm(form, values)}
				button={
					<ActionButton
						data-cid="julaproBecomeMember"
						displayWidth="full"
						type="submit"
						size="large"
						variant="cta"
						customState={addCustomerButtonState}
						className="mt-6"
					>
						{publicRuntimeConfig?.NEXT_PUBLIC_JULA_MARKET_CODE === 'SE' && (
							<Icon icon="bankIdSe" color="white" className="mr-2" />
						)}
						{submitText.value}
					</ActionButton>
				}
			/>
			<div className="mt-6 flex justify-center">
				<Button variant="text" size="small" onClick={resetFromStepThree}>
					{backText.value}
				</Button>
			</div>
		</div>
	);
}
StepThreeContent.displayName = 'AccountJulaProSignupForm_StepThreeContent';
