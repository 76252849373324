import React, { useEffect } from 'react';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import { useMachine, useSelector } from '@xstate/react';
import { waitFor } from 'xstate/lib/waitFor';

import ActionButton from 'components/ActionButton';
import GenericForm, { getValuesForSubmit } from 'components/GenericForm';
import {
	ErrorMessageInfoBox,
	useGlobalPopoverContext,
} from 'components/GlobalPopover';
import Text from 'components/Text';
import { useGlobalStateContext } from 'contexts';
import type { JulaComponentProps } from 'lib/component-props';
import type { FormFields } from 'models/sitecore';
import { selectIsClosing } from 'state-machines/global-popover.machine';
import {
	selectSubmitAddUserButtonState,
	userManagementMachine,
} from 'state-machines/userManagement';
import { is } from 'utils/helpers';

type Props = JulaComponentProps & {
	fields: {
		description: Field<string>;
		form: FormFields;
		heading: Field<string>;
		name: Field<string>;
		sections: any[];
		submitText: Field<string>;
	};
};

export default function AccountJulaProAddUserForm({ fields }: Props) {
	const popoverContext = useGlobalPopoverContext();
	const { globalPopoverService } = useGlobalStateContext();
	const isClosing = useSelector(globalPopoverService, selectIsClosing);

	const { description, form, heading, submitText } = fields;
	const [current, send, userManagementService] = useMachine(
		userManagementMachine,
		{
			devTools: true,
			id: 'julaProAddUserFormUserManagementService',
		},
	);
	const submitAddUser = async (values: Record<string, unknown>) => {
		popoverContext?.setTopMessage(undefined);
		send({
			type: 'ADD_USER',
			...getValuesForSubmit(form, values),
		});

		const doneData = await waitFor(
			userManagementService,
			(state) => state.hasTag('addUserRequestCompleted'),
			{ timeout: 120_000 },
		);

		if (
			doneData.context?.errors?.fieldValidationErrors ||
			doneData.context?.errors?.businessLogicErrors
		) {
			if (is.arrayWithLength(doneData.context?.errors?.businessLogicErrors)) {
				popoverContext?.setTopMessage(
					<ErrorMessageInfoBox
						errors={doneData.context?.errors?.businessLogicErrors}
					/>,
				);
			}
			if (is.objectWithKeys(doneData.context?.errors?.fieldValidationErrors)) {
				return doneData.context?.errors?.fieldValidationErrors;
			}
			return { FORM_ERROR: 'businessLogicErrors' };
		}
		globalPopoverService.send('CLOSE');
		popoverContext?.setTopMessage(undefined);
		return undefined;
	};
	const submitAddUserButtonState = useSelector(
		userManagementService,
		selectSubmitAddUserButtonState,
	);
	useEffect(() => {
		popoverContext?.setTopMessage(undefined);
	}, [isClosing]);

	return (
		<div>
			<div className="mb-4">
				<Text className="mb-2" as="h2" field={heading} />
				<Text className="mb-2" as="p" field={description} />
			</div>
			<GenericForm
				fields={form}
				button={
					<ActionButton
						data-cid="julaClubBecomeMember"
						displayWidth="full"
						type="submit"
						size="large"
						variant="cta"
						minimunLoadingTime={0}
						customState={submitAddUserButtonState}
						className="m-0"
					>
						{submitText.value}
					</ActionButton>
				}
				onSubmitCallback={submitAddUser}
				className="space-y-6 pb-4"
			/>
		</div>
	);
}
AccountJulaProAddUserForm.displayName = 'AccountJulaProAddUserForm';
