/**
 * Get a video ID from a URL. Currently supports YouTube.
 *
 * Handled URL formats:
 *
 * - https://www.youtube.com/watch?v=VIDEO_ID
 * - https://youtu.be/VIDEO_ID
 * - https://www.youtube.com/embed/VIDEO_ID
 */
export function getVideoId(url: string): string {
	const delimiters = ['watch?v=', 'youtu.be/', 'youtube.com/embed/'];
	for (const delimiter of delimiters) {
		if (url.includes(delimiter)) {
			// Split at the delimiter to get the start of the video ID, e.g.
			// ['https://www.youtube.com', 'VIDEO_ID'] for `watch?v=`, then
			// remove any additional query string arguments.
			return url.split(delimiter)[1]?.split('?')[0]?.split('&')[0] || '';
		}
	}
	return '';
}

/**
 * Get a video embed URL from a regular video URL. Currently supports YouTube.
 *
 * Any existing query parameters are ignored.
 *
 * @see getVideoId() for supported formats.
 */
export function getVideoEmbedUrl(url: string): string {
	const videoId = getVideoId(url);
	// https://developers.google.com/youtube/player_parameters
	const embedParams = { rel: 0 };
	const urlParams = Object.entries(embedParams)
		.map(([key, val]) => `${key}=${val}`)
		.join('&');

	return `https://www.youtube-nocookie.com/embed/${videoId}?${urlParams}`;
}
