import { assign, createMachine, type StateFrom } from 'xstate';

import type { ActionButtonState } from 'components/ActionButton';

import {
	requestAddReference,
	requestGetContacts,
	requestGetReference,
	requestGetReferences,
	requestRemoveReference,
	requestUpdateReference,
} from './referenceManagement.services';
import type {
	ReferenceManagementMachineContext,
	ReferenceManagementMachineEvents,
} from './referenceManagement.types';

export const referenceManagementMachine = createMachine(
	{
		id: 'referenceManagementMachine',
		initial: 'idle',
		schema: {
			context: {} as ReferenceManagementMachineContext,
			events: {} as ReferenceManagementMachineEvents,
		},
		context: {
			referenceList: null,
			contactList: null,
			selectedReference: null,
			selectedReferenceUpdatedCode: null,
			referenceUpdated: false,
			referenceWasAdded: false,
			updateButtonState: 'idle',
			removeReferenceButtonState: 'idle',
			addReferenceButtonState: 'idle',
		},
		tsTypes: {} as import('./referenceManagement.machine.typegen').Typegen0,
		// predictableActionArguments: true,
		preserveActionOrder: true,
		states: {
			idle: {
				description: 'Waiting for events',
			},
			listingReferences: {
				type: 'parallel',
				description: 'Listing references state',
				states: {
					referenceListFlow: {
						initial: 'loadingReferenceList',
						description: 'State which loads the reference list',
						states: {
							loadingReferenceList: {
								id: 'loadingReferenceList',
								invoke: {
									src: 'getReferences',
									id: 'getReferences',
									onDone: [
										{
											target: 'doneGettingReferenceList',
											actions: ['setReferenceList'],
										},
									],
									onError: [
										{
											target: 'errorGettingReferenceList',
										},
									],
								},
								description: 'Gets the list of references',
							},
							errorGettingReferenceList: {
								id: 'errorGettingReferenceList',
								description:
									'The request for getting list of references failed',
							},
							doneGettingReferenceList: {
								description: 'Successfully loaded the list of references',
							},
						},
					},
					addReferenceFlow: {
						initial: 'idle',
						description: 'State for handeling adding new references',
						states: {
							idle: {
								description: 'Waiting for events',
								always: {
									target:
										'#referenceManagementMachine.listingReferences.referenceListFlow',
									cond: 'referenceWasAdded',
									actions: ['unsetReferenceWasAdded'],
								},
							},
							loadingContacts: {
								description:
									'Loads the list of contacts (needed by the add reference form)',
								invoke: {
									src: 'loadContacts',
									id: 'loadContacts',
									onDone: [
										{
											target: 'doneLoadingContacts',
											actions: ['setContactList'],
										},
									],
									onError: [
										{
											target: 'errorLoadingContacts',
										},
									],
								},
							},
							doneLoadingContacts: {
								description: 'Successfully loaded the list of contacts',
							},
							errorLoadingContacts: {
								description: 'Failed to load the list of contacts',
							},
							addingReference: {
								description: 'Adding a new reference',
								entry: ['setAddReferenceButtonStateLoading'],
								invoke: {
									src: 'addReference',
									id: 'addReference',
									onDone: [
										{
											target: 'doneAddingReference',
											actions: ['unsetAddReferenceError'],
										},
									],
									onError: [
										{
											target: 'errorAddingReference',
											actions: ['setAddReferenceError'],
										},
									],
								},
							},
							doneAddingReference: {
								tags: 'addReferenceRequestEnded',
								entry: [
									'setReferenceWasAdded',
									'setAddReferenceButtonStateSuccess',
								],
								description: 'Successfully added the reference',
								after: {
									'500': {
										target:
											'#referenceManagementMachine.listingReferences.addReferenceFlow.idle',
										actions: [],
										internal: false,
									},
								},
							},
							errorAddingReference: {
								tags: 'addReferenceRequestEnded',
								description: 'The request to add reference failed',
								entry: ['setAddReferenceButtonStateError'],
							},
						},
						on: {
							ADD_REFERENCE: {
								target: '.addingReference',
							},
							GET_CONTACTS: {
								target: '.loadingContacts',
							},
						},
					},
				},
			},
			viewingReference: {
				type: 'parallel',
				description: 'Viewing reference state',
				states: {
					viewReferenceFlow: {
						initial: 'loadingReference',
						description: 'State which loads a single reference',
						states: {
							loadingReference: {
								invoke: {
									src: 'getReference',
									id: 'getReference',
									onDone: [
										{
											target: 'doneGettingReference',
											actions: [
												'setSelectedReference',
												'unsetSelectedReferenceUpdatedCode',
											],
										},
									],
									onError: [
										{
											target: 'errorGettingReference',
											actions: ['unsetSelectedReferenceUpdatedCode'],
										},
									],
								},
								description: 'Loads a single reference',
							},
							doneGettingReference: {
								entry: ['updateUrlWithUpdatedReferenceCode'],
							},
							errorGettingReference: {
								description: 'The request for getting the reference failed',
							},
						},
					},
					removeReferenceFlow: {
						initial: 'idle',
						description: 'State for handeling reference removal',
						states: {
							removingReference: {
								entry: ['setRemoveReferenceButtonStateLoading'],
								invoke: {
									src: 'removeReference',
									id: 'removeReference',
									onDone: [
										{
											target: 'doneRemovingReference',
											actions: ['setReferenceList'],
										},
									],
									onError: [
										{
											target: 'errorRemovingReference',
										},
									],
								},
								description: 'Removing a reference',
							},
							doneRemovingReference: {
								description: 'Successfully removed the reference',
								entry: [
									'setRemoveReferenceButtonStateSuccess',
									'redirectToReferenceList',
								],
							},
							errorRemovingReference: {
								description: 'The request to remove reference failed',
								entry: ['setRemoveReferenceButtonStateError'],
							},
							idle: {
								description: 'Waiting for events',
							},
						},
						on: {
							REMOVE_REFERENCE: {
								target: '.removingReference',
							},
						},
					},
					editReferenceFlow: {
						initial: 'idle',
						description: 'State for editing a reference',
						states: {
							idle: {
								description: 'Waiting for events',
								always: {
									target:
										'#referenceManagementMachine.viewingReference.viewReferenceFlow',
									cond: 'referenceWasUpdated',
									actions: ['unsetReferenceUpdated'],
								},
							},
							updatingReference: {
								entry: [
									'setUpdateButtonStateLoading',
									'setSelectedReferenceUpdatedCode',
								],
								invoke: {
									src: 'updateReference',
									id: 'updatingReference',
									onDone: [
										{
											target: 'doneUpdatingReference',
											actions: ['unsetEditReferenceError'],
										},
									],
									onError: [
										{
											target: 'errorUpdatingReference',
											actions: [
												'unsetSelectedReferenceUpdatedCode',
												'setEditReferenceError',
											],
										},
									],
								},
								description: 'Updates a reference',
							},
							doneUpdatingReference: {
								description: 'Successfully updated the reference',
								tags: 'editReferenceRequestEnded',
								entry: ['setReferenceUpdated', 'setUpdateButtonStateSuccess'],
								after: {
									'500': {
										target:
											'#referenceManagementMachine.viewingReference.editReferenceFlow.idle',
										internal: false,
									},
								},
							},
							errorUpdatingReference: {
								description: 'The request to update reference failed',
								tags: 'editReferenceRequestEnded',
								entry: ['setUpdateButtonStateError'],
							},
						},
						on: {
							UPDATE_REFERENCE: {
								target: '.updatingReference',
							},
						},
					},
				},
			},
		},
		on: {
			GET_REFERENCE_LIST: {
				target: '.listingReferences',
				description: 'Get reference list',
			},
			GET_REFERENCE: {
				target: '.viewingReference',
				description: 'Get reference by ID',
			},
		},
	},
	{
		services: {
			getReferences: async (_context, _event) => requestGetReferences(),
			addReference: async (_context, event) => requestAddReference(event.value),
			getReference: async (context, _event) => {
				const event = _event as any;
				const code =
					event.code ||
					context.selectedReferenceUpdatedCode ||
					context.selectedReference?.code;
				return requestGetReference(code);
			},
			removeReference: async (_context, _event) => {
				const event = _event as any;
				return requestRemoveReference(event.code);
			},
			updateReference: async (_context, _event) => {
				const event = _event as any;
				return requestUpdateReference(event.code, event.reference);
			},
			loadContacts: async (_context, _event) => requestGetContacts(),
		},
		actions: {
			setReferenceList: assign({
				referenceList: (_context, _event) => {
					const event = _event as any;
					return event.data;
				},
			}),
			setContactList: assign({
				contactList: (_context, _event) => {
					const event = _event as any;
					return event.data;
				},
			}),
			setSelectedReference: assign({
				selectedReference: (_context, _event) => {
					const event = _event as any;
					return event.data;
				},
			}),
			setReferenceWasAdded: assign({
				referenceWasAdded: (_context, _event) => true,
			}),
			unsetReferenceWasAdded: assign({
				referenceWasAdded: (_context, _event) => false,
			}),
			setReferenceUpdated: assign({
				referenceUpdated: (_context, _event) => true,
			}),
			unsetReferenceUpdated: assign({
				referenceUpdated: (_context, _event) => false,
			}),
			setUpdateButtonStateSuccess: assign({
				updateButtonState: (_context, _event) => 'success' as ActionButtonState,
			}),
			setUpdateButtonStateError: assign({
				updateButtonState: (_context, _event) => 'failure' as ActionButtonState,
			}),
			setUpdateButtonStateLoading: assign({
				updateButtonState: (_context, _event) => 'loading' as ActionButtonState,
			}),
			setRemoveReferenceButtonStateSuccess: assign({
				removeReferenceButtonState: (_context, _event) =>
					'success' as ActionButtonState,
			}),
			setRemoveReferenceButtonStateError: assign({
				removeReferenceButtonState: (_context, _event) =>
					'failure' as ActionButtonState,
			}),
			setRemoveReferenceButtonStateLoading: assign({
				removeReferenceButtonState: (_context, _event) =>
					'loading' as ActionButtonState,
			}),
			setAddReferenceButtonStateSuccess: assign({
				addReferenceButtonState: (_context, _event) =>
					'success' as ActionButtonState,
			}),
			setAddReferenceButtonStateError: assign({
				addReferenceButtonState: (_context, _event) =>
					'failure' as ActionButtonState,
			}),
			setAddReferenceButtonStateLoading: assign({
				addReferenceButtonState: (_context, _event) =>
					'loading' as ActionButtonState,
			}),
			setSelectedReferenceUpdatedCode: assign({
				selectedReferenceUpdatedCode: (_context, _event) => {
					const event = _event as any;
					return event.reference.newReferenceName || null;
				},
			}),
			unsetSelectedReferenceUpdatedCode: assign({
				selectedReferenceUpdatedCode: (_context, _event) => null,
			}),
			updateUrlWithUpdatedReferenceCode: () => {},
			redirectToReferenceList: () => {},
			setAddReferenceError: assign({
				addReferenceError: (_context, _event) => {
					const event = _event as any;

					return event.data;
				},
			}),
			unsetAddReferenceError: assign({
				addReferenceError: (_context, _event) => undefined,
			}),
			setEditReferenceError: assign({
				editReferenceError: (_context, _event) => {
					const event = _event as any;

					return event.data;
				},
			}),
			unsetEditReferenceError: assign({
				editReferenceError: (_context, _event) => undefined,
			}),
		},
		guards: {
			referenceWasAdded: (context) => context.referenceWasAdded === true,
			referenceWasUpdated: (context) => context.referenceUpdated === true,
		},
	},
);
export type ReferenceManagementMachineState = StateFrom<
	typeof referenceManagementMachine
>;
