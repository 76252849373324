import React, { useState } from 'react';
import { useRouter } from 'next/router';

import Img from 'components/Img';
import ListItem from 'components/ListItem';
import Popover from 'components/Popover';
import Price from 'components/Price';
import ProductFit from 'components/ProductFit';
import SelectButton from 'components/SelectButton';
import Text from 'components/Text';
import { StoreIdName } from 'contexts';
import type { QuestionSummary } from 'models/api';
import { ItemStockResponse } from 'models/api';
import type { Product } from 'models/product';
import type { ProductCard } from 'models/productCard';
import {
	checkSupercheap,
	getMainImageDetail,
	getPriceProps,
	getProductImageFormat,
} from 'utils/business-logic';
import { useI18n } from 'utils/i18n';

import StockInformation from './VariantPicker.StockInformation';

interface Props {
	fit3Summary?: QuestionSummary;
	onOpenCallback: () => void;
	product: Product;
	selectedStore: StoreIdName | undefined;
	stockData: ItemStockResponse[] | undefined;
	stockIsLoading: boolean;
	variants: ProductCard[] | undefined;
}

export default function VariantPicker({
	fit3Summary,
	onOpenCallback,
	product,
	selectedStore,
	stockData,
	stockIsLoading,
	variants,
}: Props) {
	const router = useRouter();
	const { t } = useI18n();
	const [isOpen, setIsOpen] = useState(false);

	if (!variants || variants.length < 2) {
		return null;
	}

	const onOpen = () => {
		onOpenCallback();
		setIsOpen(true);
	};
	const currentImg = getMainImageDetail(product);
	const isSize = Boolean(product.sizeGuideUrl);

	const buttonText = isSize
		? t('product_details_pick_size_button')
		: t('product_details_pick_variant_button');
	const popoverTitle = isSize
		? t('popover_choose_size_header')
		: t('popover_choose_variant_header');

	return (
		<div className="mt-8">
			<Text as="span" className="font-bold">
				{buttonText}
			</Text>
			<SelectButton
				label={buttonText}
				onClick={onOpen}
				hiddenLabel
				invertArrow={isOpen}
			>
				<Img
					src={currentImg?.src}
					useFallbackOnError
					className="mr-2 h-8 w-8"
					service="nextjs"
					width={40}
					height={40}
				/>
				<Text
					as="p"
					className="mr-8 overflow-hidden text-ellipsis whitespace-nowrap"
				>
					{product.variantText}
				</Text>
			</SelectButton>
			<Popover
				isOpen={isOpen}
				title={popoverTitle}
				onClose={() => {
					setIsOpen(false);
				}}
			>
				{isSize && (
					<ProductFit
						infoTextClassName="mb-6"
						fit3Summary={fit3Summary}
						showScale={false}
					/>
				)}
				<ul className="flex flex-col gap-y-2">
					{variants.map((variant) => {
						const image = getProductImageFormat(
							'Templated square transparent',
							variant?.assets,
						);
						const stock = stockData?.find(
							(stockItem) => stockItem.variantId === variant.id,
						);
						const price = variant.listPrice && (
							<Price
								{...getPriceProps(
									variant.listPrice,
									false,
									checkSupercheap(variant),
								)}
								size="microCompact"
							/>
						);
						return (
							<ListItem
								key={variant.id}
								as="li"
								title={variant.variantText || ''}
								startContent={
									<Img
										src={image?.location}
										className="h-10 w-10 shrink-0"
										service="nextjs"
										width={40}
										height={40}
									/>
								}
								endContent={price}
								isSelected={variant.id === product.id}
								onClick={() => {
									setIsOpen(false);
									router.push(variant.url, undefined, { scroll: false });
								}}
							>
								<StockInformation
									stock={stock}
									variant={variant}
									selectedStore={selectedStore}
									isLoading={stockIsLoading}
								/>
							</ListItem>
						);
					})}
				</ul>
			</Popover>
		</div>
	);
}
VariantPicker.displayName = 'ProductDetails_VariantPicker';
