/**
 * ListItem
 */

import React, { type MouseEventHandler, ReactNode } from 'react';
import clsx from 'clsx';

import Link from 'components/Link';
import type { HTMLTagName } from 'types';

export type ListItemAlignment = 'start' | 'center' | 'end';

interface Props {
	/** Container flex alignment. */
	alignment?: ListItemAlignment;

	/** Container tag. */
	as: HTMLTagName;

	/** Main content below the title. */
	children?: ReactNode;

	/** Additional container class names. */
	className?: string;

	/** Content to the right of the 'title content'. */
	endContent?: ReactNode;

	/** URL, will make the title a link. */
	href?: string;

	/** Give the item a thicker, green border. */
	isSelected?: boolean;

	/** Click handler, will make the title a button if there isn't a `href` set. */
	onClick?: MouseEventHandler<HTMLElement>;

	/** Content to the left of the 'title content'. */
	startContent?: ReactNode;

	/** Primary title, is the action text when button or link. */
	title: string;
}

const alignmentClasses: Record<ListItemAlignment, string> = {
	start: 'items-start',
	center: 'items-center',
	end: 'items-end',
};

/**
 * A bordered box with a title.
 *
 * Not necessarily an actual list item, the name comes from the design system
 * but ListItems are sometimes used outside of lists.
 */
export default function ListItem({
	alignment,
	as: Component,
	children,
	className,
	endContent,
	href,
	isSelected,
	onClick,
	startContent,
	title,
}: Props) {
	const TitleTag = href ? Link : onClick ? 'button' : 'span';
	const alignClass = alignment ? alignmentClasses[alignment] : '';

	return (
		<Component
			className={clsx(
				'relative flex gap-x-2 p-4 font-standard text-base',
				alignClass,
				className,
			)}
		>
			{startContent}
			<div className="grow">
				<TitleTag
					// The Link prop doesn't accept undefined but TitleTag won't be a
					// link if href is undefined so it's fine here.
					href={href!}
					onClick={onClick}
					type={TitleTag === 'button' ? 'button' : undefined}
					className={clsx(
						'block font-bold',
						children && 'mb-1',
						// Stretch the title over the entire box with a pseudo element
						// and set the border on that.
						'before:absolute before:inset-0 before:z-1 before:rounded-button before:border',
						!isSelected && 'before:border-greyDark',
						// Add a shadow for the thicker border instead of increasing the
						// border width. This prevents layout shifting when selecting.
						isSelected &&
							'before:border-success before:ring-1 before:ring-inset before:ring-success',
						(href || onClick) && [
							isSelected &&
								'hover:before:border-successDarker hover:before:ring-successDarker',
							!isSelected &&
								'hover:before:border-1 hover:before:border-greyDarker hover:before:ring-1 hover:before:ring-greyDarker',
						],
						!href && !onClick && 'before:pointer-events-none',
					)}
				>
					{title}
				</TitleTag>
				{children}
			</div>
			{endContent}
		</Component>
	);
}
ListItem.displayName = 'ListItem';
