import React from 'react';

import Placeholder from 'components/Placeholder';
import type { JulaComponentProps } from 'lib/component-props';
import { getEditorMargin } from 'utils/business-logic';

type Props = JulaComponentProps;

export default function MediaCaption({ params, rendering }: Props) {
	return (
		<div id={params?.anchor} className={getEditorMargin(params)}>
			<Placeholder name="jula-media-caption" rendering={rendering} />
		</div>
	);
}
MediaCaption.displayName = 'MediaCaption';
