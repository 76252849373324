import React from 'react';

import Button from 'components/Button';
import Img from 'components/Img';
import Text from 'components/Text';
import clsx from 'clsx';

interface Props {
	membershipCreationErrorHeading: string;
	membershipCreationErrorText: string;
	onTryAgain: () => void;
	membershipCreationErrorButtonText: string;
	className?: string;
}
export default function CustomerNotCreatedView({
	membershipCreationErrorHeading,
	membershipCreationErrorButtonText,
	membershipCreationErrorText,
	onTryAgain,
	className,
}: Props) {
	return (
		<div className={clsx(className, 'flex h-full flex-col content-between')}>
			<div>
				<Img
					src="/assets/images/graphic-congrats-julaclub.png"
					alt=""
					className="mx-auto mt-8 block h-20 w-20 object-cover"
				/>
				<Text as="h2" className="mt-8">
					{membershipCreationErrorHeading}
				</Text>
				<Text as="p" className="mt-4">
					{membershipCreationErrorText}
				</Text>
			</div>
			<div className="mt-8">
				<Button
					variant="primary"
					displayWidth="full"
					className="my-4"
					onClick={onTryAgain}
				>
					{membershipCreationErrorButtonText}
				</Button>
			</div>
		</div>
	);
}
CustomerNotCreatedView.displayName = 'CustomerNotCreatedView';
