/**
 * Skiplink
 */

import React from 'react';
import clsx from 'clsx';

import { ScrollLink } from 'components/Link';

interface Props {
	/** Extra class names */
	className?: string;

	/** Element ID to target */
	target: string;

	/** Link text */
	text: string;
}

/** Hidden block link that becomes visible on focus. */
export default function Skiplink({ className, target, text }: Props) {
	return (
		<ScrollLink
			anchor={target}
			className={clsx(
				className,
				'sr-only flex items-center justify-center bg-greyDarker text-center font-bold text-white',
				'focus:not-sr-only focus:min-h-[3.5rem] focus:w-full focus:p-3 focus:underline',
				'md:text-lg',
			)}
		>
			{text}
		</ScrollLink>
	);
}
Skiplink.displayName = 'Skiplink';
