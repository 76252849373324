import { Extends } from 'types';

import { StatusType } from './statusType';

export interface ProductDetailsStoreStock {
	inStock: boolean;
	productInStockAtStores: number;
	storeSelected: boolean;
	webStockInStock: boolean;
}

export function getProductDetailsStoreStockStatus({
	storeSelected,
	inStock,
	productInStockAtStores,
	webStockInStock,
}: ProductDetailsStoreStock): ProductDetailsStoreStockStatusType {
	switch (true) {
		case storeSelected && inStock:
			return 'Store-InStock';
		case storeSelected && !inStock && webStockInStock:
			return 'Store-OutOfStock-WebStock';
		case storeSelected && !inStock && !webStockInStock:
			return 'Store-OutOfStock-NoWebStock';
		case productInStockAtStores > 0:
			return 'NoStore-InStock';
		case productInStockAtStores === 0 && webStockInStock:
			return 'NoStore-OutOfStock-WebStock';
		case productInStockAtStores === 0 && !webStockInStock:
			return 'NoStore-OutOfStock-NoWebStock';
		default:
			return 'MatchFailed';
	}
}

export type ProductDetailsStoreStockStatusType = Extends<
	StatusType,
	| 'Store-InStock'
	| 'Store-OutOfStock-WebStock'
	| 'Store-OutOfStock-NoWebStock'
	| 'NoStore-InStock'
	| 'NoStore-OutOfStock-WebStock'
	| 'NoStore-OutOfStock-NoWebStock'
	| 'MatchFailed'
>;
