/**
 * VideoEmbed
 */

import React from 'react';

import { getVideoEmbedUrl } from './helpers';

interface Props {
	/** Descriptive title for accessibility. */
	iframeTitle?: string;

	/** Base video URL, the correct embed format will be handled automatically. */
	url: string | undefined;
}

/** A responsive, embedded video player. */
export default function VideoEmbed({ iframeTitle, url }: Props) {
	if (!url) {
		return null;
	}
	const embedUrl = getVideoEmbedUrl(url);
	if (!embedUrl) {
		return null;
	}

	// Use the padding technique until aspect-ratio support is a bit better.
	// 16:9 aspect ratio = 9/16 = 0.5625 = 56.25%.
	return (
		<div className="relative box-content h-px pb-[56.25%]">
			<iframe
				className="absolute left-0 top-0 h-full w-full"
				src={embedUrl}
				title={iframeTitle}
			/>
		</div>
	);
}
VideoEmbed.displayName = 'VideoEmbed';
