import React from 'react';
import clsx from 'clsx';

import Button from 'components/Button';
import Img from 'components/Img';
import InfoBox from 'components/InfoBox';
import Text from 'components/Text';

interface Props {
	membershipCreatedHeading: string;
	creditResult: 'Approved' | 'Denied' | 'Canceled' | 'Failed' | undefined;
	creditLimit: string | undefined;
	creditApplicationApprovedText: string;
	creditApplicationFailedOrDeniedText: string;
	creditApplicationCanceledText: string;
	membershipCreatedText: string;
	onAcceptMembershipAndContinue: () => void;
	membershipCreatedButtonText: string;
	className?: string;
}
export default function CustomerCreationDoneView({
	membershipCreatedHeading,
	membershipCreatedText,
	membershipCreatedButtonText,
	creditApplicationApprovedText,
	creditApplicationCanceledText,
	creditApplicationFailedOrDeniedText,
	onAcceptMembershipAndContinue,
	creditResult,
	creditLimit,
	className,
}: Props) {
	return (
		<div className={clsx(className, 'flex h-full flex-col content-between')}>
			<div>
				<Img
					src="/assets/images/graphic-congrats-julaclub.png"
					alt=""
					className="mx-auto mt-8 block h-20 w-20 object-cover"
				/>
				<Text as="h2" className="mb-2 mt-8">
					{membershipCreatedHeading}
				</Text>

				{creditResult === 'Approved' && creditLimit && (
					<>{creditApplicationApprovedText}</>
				)}
				{creditResult === 'Denied' && (
					<InfoBox
						icon="info"
						className="mb-8"
						message={creditApplicationFailedOrDeniedText}
					/>
				)}
				{creditResult === 'Failed' && (
					<InfoBox
						icon="info"
						className="mb-8"
						message={creditApplicationFailedOrDeniedText}
					/>
				)}
				{creditResult === 'Canceled' && (
					<InfoBox
						icon="info"
						className="mb-8"
						message={creditApplicationCanceledText}
					/>
				)}

				<Text as="p" className="mt-4">
					{membershipCreatedText}
				</Text>

				<div className="mt-8">
					<Button
						variant="primary"
						displayWidth="full"
						className="my-4"
						onClick={onAcceptMembershipAndContinue}
					>
						{membershipCreatedButtonText}
					</Button>
				</div>
			</div>
		</div>
	);
}
CustomerCreationDoneView.displayName = 'CustomerCreationDoneView';
