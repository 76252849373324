import React, { useState } from 'react';

import {
	StockStoreSelectorPopover,
	StoreStockBalanceDetailed,
} from 'components/Stock';
import { StoreIdName } from 'contexts';
import { ItemStockResponse, Stock, StockStore } from 'models/api';
import { Product } from 'models/product';
import { getProductDetailsStoreStockStatus } from 'utils/business-logic/stock';

interface Props {
	allStoresStock: Stock[] | undefined;
	currentProductStock: ItemStockResponse | undefined;
	currentStoreStock: Stock | undefined;
	isLoadingInitialStock: boolean;
	isLoadingNearbyStoresStock: boolean;
	isLoadingStock: boolean;
	nearbyStoresStock: Stock[] | undefined;
	onOpenCallBack: () => void;
	onSearch: (searchValue: string) => void;
	onTabChange: (tab: string) => void;
	onUpdateSelectedStore: (store: StockStore) => void;
	product: Product;
	selectedStore: StoreIdName | undefined;
}

export default function StoreStock({
	allStoresStock,
	currentProductStock,
	currentStoreStock,
	isLoadingInitialStock,
	isLoadingNearbyStoresStock,
	isLoadingStock,
	nearbyStoresStock,
	onOpenCallBack,
	onSearch,
	onTabChange,
	onUpdateSelectedStore,
	product,
	selectedStore,
}: Props) {
	const [storeSelectorOpen, setStoreSelectorOpen] = useState(false);
	const status = getProductDetailsStoreStockStatus({
		storeSelected: Boolean(selectedStore),
		inStock: currentStoreStock?.inStock ?? false,
		productInStockAtStores: currentProductStock?.storeStock?.inStockCount ?? 0,
		webStockInStock: currentProductStock?.webStock?.inStock ?? false,
	});
	return (
		<div>
			<StoreStockBalanceDetailed
				status={status}
				storeStock={currentStoreStock}
				productStock={currentProductStock}
				selectedStore={selectedStore}
				product={product}
				onOpenStoreSelector={() => {
					onOpenCallBack();
					setStoreSelectorOpen(true);
				}}
				isLoading={isLoadingInitialStock}
			/>
			<StockStoreSelectorPopover
				selectedStore={selectedStore}
				allStoresStock={allStoresStock}
				nearbyStoresStock={nearbyStoresStock}
				isOpen={storeSelectorOpen}
				onUpdateSelectedStore={onUpdateSelectedStore}
				onClose={() => setStoreSelectorOpen(false)}
				onTabChange={onTabChange}
				onSearch={onSearch}
				isLoading={isLoadingStock || isLoadingNearbyStoresStock}
			/>
		</div>
	);
}
StoreStock.displayName = 'ProductDetails_StockInformation_StoreStock';
