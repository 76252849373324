/**
 * BlockShortcutImages
 */

import React, { type ReactNode } from 'react';
import clsx from 'clsx';

import type { HTMLTagName } from 'types';

import type { ItemSize } from './types';

interface Props {
	as?: HTMLTagName;
	children: ReactNode;
	className?: string;
	size?: ItemSize;
}

/** Grid for the items. */
export default function ImagesGrid({
	as: Component = 'div',
	children,
	className,
	size = 'regular',
}: Props) {
	return (
		<Component
			className={clsx(
				'grid items-start gap-4 md:gap-6',
				size === 'regular' && 'grid-cols-2 sm:grid-cols-3 md:grid-cols-6',
				size === 'large' && 'grid-cols-1 sm:grid-cols-2 md:grid-cols-4',
				className,
			)}
		>
			{children}
		</Component>
	);
}
ImagesGrid.displayName = 'BlockShortcutImages_ImagesGrid';
