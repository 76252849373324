import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import clsx from 'clsx';

import ComponentPlaceholder from 'components/ComponentPlaceholder';
import DiscountBubble from 'components/DiscountBubble';
import Img from 'components/Img';
import { LayoutContainer, SM_GUTTER_MARGIN_CLASSES } from 'components/Layout';
import { useIsEditing } from 'hooks';
import { JulaComponentProps } from 'lib/component-props';
import { DigizuiteAssetList } from 'models/asset';
import { getEditorMargin } from 'utils/business-logic';
import { getAsset } from 'utils/helpers';

type Props = JulaComponentProps & {
	fields?: {
		campaignBubbleLabel?: Field<string>;
		campaignBubbleText?: Field<string>;
		landscapeImage: DigizuiteAssetList;
		wideImage: DigizuiteAssetList;
	};
};

export default function MediaBanner({ fields, params }: Props) {
	const isEditing = useIsEditing();

	if (!fields) {
		return null;
	}

	const largeImg = getAsset('Templated 4:1', fields.wideImage);
	const smallImg = getAsset('Templated 2:1', fields.landscapeImage);
	const campaignBubbleLabel = fields.campaignBubbleLabel?.value;
	const campaignBubbleText = fields.campaignBubbleText?.value;

	if (!isEditing && (!largeImg || !smallImg)) {
		return null;
	}

	if (isEditing && !largeImg) {
		return (
			<ComponentPlaceholder
				componentName="MediaBanner"
				className={getEditorMargin(params)}
			/>
		);
	}

	return (
		<LayoutContainer
			gutters={false}
			id={params?.anchor}
			outerClassName={clsx(getEditorMargin(params), SM_GUTTER_MARGIN_CLASSES)}
			className="relative"
		>
			<Img
				src={smallImg?.src}
				alt={smallImg?.alt}
				width={360}
				height={180}
				service="nextjs"
				className="w-full sm:hidden"
			/>
			<Img
				src={largeImg?.src}
				alt={largeImg?.alt}
				width={1644}
				height={411}
				service="nextjs"
				className="w-full max-sm:hidden"
			/>
			{campaignBubbleText && (
				<DiscountBubble
					label={campaignBubbleLabel}
					text={campaignBubbleText}
					isPositioned
				/>
			)}
		</LayoutContainer>
	);
}
MediaBanner.displayName = 'MediaBanner';
