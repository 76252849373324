import React from 'react';

import CampaignPackage from 'components/CampaignPackage';
import Slider from 'components/Slider';
import Text from 'components/Text';
import type { CampaignResponse } from 'models/product';
import type { ButtonStateWithId } from 'state-machines/cart';
import { useI18n } from 'utils/i18n';

import { CAMPAIGN_PACKAGE_DISCOUNT_TYPES } from '../helpers/constants';

interface BaseProps {
	className?: string;
	onAddToCartClick: ((campaign: CampaignResponse) => void) | undefined;
	purchaseButtonState: ButtonStateWithId;
}
interface Props extends BaseProps {
	campaigns: CampaignResponse[] | undefined;
}
interface ItemProps extends BaseProps {
	campaign: CampaignResponse;
}

function CampaignItem({
	campaign,
	className,
	onAddToCartClick,
	purchaseButtonState,
}: ItemProps) {
	const { t } = useI18n();

	const buttonId = `addCampaignToCart-${campaign.title}-${campaign.validTo}`;
	const buttonState =
		purchaseButtonState.buttonId === buttonId
			? purchaseButtonState.state
			: 'idle';

	const onlyAvailableInStore =
		!campaign.salesAttributes.isSellableOnWeb &&
		campaign.salesAttributes.isSellableInStore;
	const maybeAvailableInStore =
		!campaign.salesAttributes.isSellable &&
		campaign.salesAttributes.isSellableOnWeb;

	return (
		<CampaignPackage
			key={campaign.id}
			className={className}
			title={campaign.title}
			validTo={campaign.validTo}
			price={campaign.price}
			variants={campaign.variants}
			addToCartButtonDisabled={onlyAvailableInStore || maybeAvailableInStore}
			addToCartButtonDisabledText={
				maybeAvailableInStore
					? t('product_details_campaign_maybe_available_in_store_text')
					: t('product_details_campaign_only_available_in_store_text')
			}
			buttonState={buttonState}
			onAddToCartClick={() => onAddToCartClick?.(campaign)}
		/>
	);
}
CampaignItem.displayName = 'ProductDetailsCampaignPackages_CampaignItem';

/**
 * Campaigns where all items can be added to cart with a single button.
 */
export default function ProductDetailsCampaignPackages({
	campaigns,
	className,
	onAddToCartClick,
	purchaseButtonState,
}: Props) {
	const filteredCampaigns = campaigns?.filter(({ discountType }) =>
		CAMPAIGN_PACKAGE_DISCOUNT_TYPES.includes(discountType),
	);

	const { t } = useI18n();

	if (!filteredCampaigns?.length) {
		return null;
	}

	const hasMultipleCampaigns = filteredCampaigns.length > 1;

	return (
		<div className={className}>
			<Text
				as="h2"
				text={t('product_details_campaign_section_heading')}
				className="mb-4 md:mb-6"
			/>
			{hasMultipleCampaigns && (
				<Slider
					sizeClasses="w-5/6 sm:w-2/3 md:w-5/6 lg:w-1/2"
					items={filteredCampaigns.map((campaign) => (
						<CampaignItem
							key={campaign.id}
							onAddToCartClick={onAddToCartClick}
							purchaseButtonState={purchaseButtonState}
							campaign={campaign}
							className="h-full"
						/>
					))}
				/>
			)}
			{!hasMultipleCampaigns && filteredCampaigns[0] && (
				<CampaignItem
					onAddToCartClick={onAddToCartClick}
					purchaseButtonState={purchaseButtonState}
					campaign={filteredCampaigns[0]}
					className="w-full lg:w-8/12"
				/>
			)}
		</div>
	);
}
ProductDetailsCampaignPackages.displayName = 'ProductDetailsCampaignPackages';
