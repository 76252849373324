/**
 * Reviews
 */

import React, { useState } from 'react';
import clsx from 'clsx';

import Button from 'components/Button';
import Chip from 'components/Chip';
import { Select } from 'components/FormUi';
import Icon from 'components/Icon';
import Img from 'components/Img';
import Link from 'components/Link';
import LoadMoreList from 'components/LoadMoreList';
import ProductFit from 'components/ProductFit';
import Rating from 'components/Rating';
import { Skeleton, SkeletonItem } from 'components/Skeleton';
import Text from 'components/Text';
import type { ReviewImage, ReviewImageId } from 'hooks/product-details';
import type { QuestionSummary, Review as ReviewModel } from 'models/api';
import cn from 'utils/cn';
import { formatNumber } from 'utils/format';
import { is, range } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

import Review from './Review';

interface Props {
	/** Container class names. */
	className?: string;
	/** Has more reviews */
	hasMoreReviews: boolean;
	/** Are the reviews loading */
	isLoading?: boolean;
	/** Is review machine state = loading */
	isLoadingMoreReviews: boolean;
	/** Callback for selecting a single review grade (1–5) to display */
	onGradeClick: (score: number) => void;
	/** Callback for resetting grade selection */
	onGradeResetClick: () => void;
	/** Callback for 'load more' button */
	onLoadMoreClick: () => void;
	/** Callback for clicking on a review image */
	onReviewImageClick: (id: ReviewImageId) => void;
	/** Callback for viewing all review photos */
	onShowTopScoreReviewImages?: () => void;
	/** Callback for changing the current sort option */
	onSortOptionChange: (value: string | undefined) => void;
	/** Callback for clicking on a top score review image */
	onTopScoreReviewImageClick: (id: ReviewImageId) => void;
	/** questionSummary */
	questionsSummary: QuestionSummary[] | undefined;
	/** List of ratings for each number of stars */
	ratingsPerGrade: number[];
	/** Written user review objects */
	reviewItems?: ReviewModel[];
	/** URL to review policy page. */
	reviewPolicyUrl?: string;
	/** Total rating score */
	score?: number;
	/** Currently selected sort option */
	sortOption: string | undefined;
	/** Filtered reviews with images */
	topScoreReviewImages?: ReviewImage[];
	/** Total number of reviews */
	totalReviews: number;
}

/** The reviews component in the product details component. */
export default function Reviews({
	className,
	hasMoreReviews,
	isLoading,
	isLoadingMoreReviews,
	onGradeClick,
	onGradeResetClick,
	onLoadMoreClick,
	onReviewImageClick,
	onShowTopScoreReviewImages,
	onSortOptionChange,
	onTopScoreReviewImageClick,
	questionsSummary,
	ratingsPerGrade,
	reviewItems,
	reviewPolicyUrl,
	score = 0,
	sortOption,
	topScoreReviewImages,
	totalReviews,
}: Props) {
	const { t, tPlural } = useI18n();
	const [selectedGradeIndex, setSelectedGradeIndex] = useState<number>(-1);
	// A min-width value to ensure the number of reviews per grade are displayed
	// at the same size. Without it a '123' next to the 5-star count and a '7'
	// next to the 1-star will have different widths and things will look uneven.
	const ratingCountMinWidthClass = [
		'min-w-[2em]',
		'min-w-[2.5em]',
		'min-w-[3em]',
		'min-w-[3.5em]',
		'min-w-[4em]',
	][String(Math.max(...ratingsPerGrade)).length - 1];

	return (
		<div className={className}>
			<Text as="p" className="mb-8">
				{t('reviews_review_policy_text')}{' '}
				{reviewPolicyUrl && (
					<Link href={reviewPolicyUrl} className="underline hover:no-underline">
						{t('reviews_review_policy_link_text')}
					</Link>
				)}
			</Text>
			<div className="mb-8 mt-2 flex flex-col">
				<div className="flex items-center">
					<Text as="span" styleAs="h2" className="mr-4">
						{formatNumber(score)}
					</Text>
					<Rating score={score} size="large" reviewCountVariant="hidden" />
				</div>
				<Text as="pSmall" className="mt-2">
					{t('product_details_based_on_reviews_text', {
						reviewCount: totalReviews,
					})}
				</Text>
			</div>
			<div className="mb-12 flex flex-col gap-3 md:mb-14">
				{ratingsPerGrade.map((ratingCount, i) => {
					const ratingBarPercent =
						ratingCount === 0 || totalReviews === 0
							? 0
							: (ratingCount / totalReviews) * 100;
					return (
						<button
							// Will not be sorted and there is no meaningful key.
							// eslint-disable-next-line react/no-array-index-key
							key={i}
							type="button"
							onClick={() => {
								// Unset if currently selected
								const newSelectedGradeIndex = selectedGradeIndex === i ? -1 : i;
								if (newSelectedGradeIndex === -1) {
									onGradeResetClick();
								} else {
									onGradeClick(5 - i);
								}
								setSelectedGradeIndex(newSelectedGradeIndex);
							}}
							aria-label={`${t('product_review_stars_screen_reader_text', {
								starCount: 5 - i,
							})}, ${t('reviews_grade_count_text', { ratingCount })}`}
							aria-pressed={selectedGradeIndex === i}
							className={cn(
								'group/grade-button -mx-3 flex h-8 items-center justify-center rounded-full border border-transparent px-3',
								i === selectedGradeIndex && 'border-greyLight bg-greyLighter',
							)}
						>
							{/* A 1 is less wide than a 5, set min-width to align stars */}
							<span className="inline-block min-w-[0.75em] text-center font-bold">
								{5 - i}
							</span>
							<Icon icon="star" color="black" size={16} className="ml-1" />
							<div
								className={cn(
									'relative mx-6 h-4 grow overflow-hidden rounded-full border border-greyDark group-hover/grade-button:border-black group-hover/grade-button:ring-1 group-hover/grade-button:ring-black',
									i === selectedGradeIndex &&
										'border-2 border-black group-hover/grade-button:ring-2',
								)}
							>
								{Boolean(ratingBarPercent) && (
									<div
										className={clsx(
											'h-full bg-black',
											ratingBarPercent < 3 ? 'rounded-border' : 'rounded-full',
										)}
										style={{ width: `${ratingBarPercent}%` }}
									/>
								)}
							</div>
							<span
								className={clsx(
									'inline-block text-right underline',
									ratingCountMinWidthClass,
								)}
							>
								{t('reviews_grade_count_text', { ratingCount })}
							</span>
						</button>
					);
				})}
			</div>

			{is.arrayWithLength(topScoreReviewImages) && (
				<div className="mb-12 md:mb-14">
					<Text as="h4" text={t('reviews_images_heading')} />
					<div className="mt-4 flex flex-wrap gap-4">
						{topScoreReviewImages.slice(0, 3).map((reviewImage) => (
							<button
								key={reviewImage.image.icon}
								type="button"
								onClick={() => onTopScoreReviewImageClick(reviewImage.id)}
								aria-label={t('review_image_button')}
							>
								<Img
									src={reviewImage.image.icon}
									className="size-16"
									width={reviewImage.image.iconWidth}
									height={reviewImage.image.iconHeight}
								/>
							</button>
						))}
					</div>
					<Button
						className="mt-4"
						onClick={onShowTopScoreReviewImages}
						variant="text"
					>
						{tPlural(
							'reviews_show_all_images_button',
							topScoreReviewImages.length,
						)}
					</Button>
				</div>
			)}

			<ProductFit
				className="mb-12 md:mb-14"
				fit3Summary={questionsSummary?.find(({ name }) => name === 'fit3')}
			/>

			<Text as="h4" text={t('reviews_all_reviews_heading')} className="mb-4" />
			<div className="sm:flex">
				<Select
					className="sm:w-1/2"
					id="review-sort"
					label={t('reviews_sorting_label')}
					hiddenLabel
					value={sortOption ?? 'default'}
					onChange={(e) => {
						if (e.target.value === 'default') {
							onSortOptionChange(undefined);
						} else {
							onSortOptionChange(e.target.value);
						}
					}}
					options={[
						{
							value: 'default',
							label: t('reviews_sorting_option_default_label'),
						},
						{
							value: 'Relevance',
							label: t('reviews_sorting_option_relevance_label'),
						},
						{
							value: 'ScoreAsc',
							label: t('reviews_sorting_option_score_ascending_label'),
						},
						{
							value: 'ScoreDesc',
							label: t('reviews_sorting_option_score_descending_label'),
						},
						{
							value: 'DateAsc',
							label: t('reviews_sorting_option_date_ascending_label'),
						},
						{
							value: 'DateDesc',
							label: t('reviews_sorting_option_date_descending_label'),
						},
					]}
				/>
			</div>
			{selectedGradeIndex > -1 && (
				<Chip
					className="mt-6"
					color="red"
					isRemovable
					text={t('reviews_selected_grade_chip_text', {
						grade: 5 - selectedGradeIndex,
					})}
					onClick={() => {
						setSelectedGradeIndex(-1);
						onGradeResetClick();
					}}
				/>
			)}
			{isLoading && (
				<div className="mt-8 divide-y divide-greyDark">
					{range(2).map((index) => (
						<Skeleton key={index} className="py-8">
							{/* Flag and name */}
							<div className="mb-4 flex items-center gap-2">
								<SkeletonItem
									height="1.5rem"
									width="1.5rem"
									className="rounded-full"
								/>
								<SkeletonItem height="1rem" width="5rem" />
							</div>
							{/* date */}
							<SkeletonItem height="1rem" width="13rem" className="mt-1" />
							{/* Stars */}
							<SkeletonItem height="1.5rem" width="7.5rem" className="mt-4" />
							{/* Main text */}
							<SkeletonItem height="6rem" width="100%" className="my-4" />
							{/* Like/report buttons */}
							<SkeletonItem height="2rem" width="12rem" className="mt-6" />
						</Skeleton>
					))}
				</div>
			)}
			{!isLoading && (
				<LoadMoreList
					onLoadMoreClick={onLoadMoreClick}
					isLoading={isLoadingMoreReviews}
					buttonVariant="secondary"
					className="mt-8"
					listClassName="divide-y divide-greyDark"
					buttonAlignment="center"
					buttonClassName="min-w-[50%] mb-6 mt-10"
					itemCountScreenReaderText={tPlural(
						'reviews_item_count_text',
						reviewItems?.length ?? 0,
					)}
					buttonText={t('product_details_review_load_more_button')}
					hasLoadMoreButton={hasMoreReviews}
				>
					{reviewItems?.map((review) => (
						<Review
							className="py-8"
							key={`${review.author}-${review.date}-${review.score}`}
							onReviewImageClick={onReviewImageClick}
							review={review}
						/>
					))}
				</LoadMoreList>
			)}
		</div>
	);
}
Reviews.displayName = 'Reviews';
