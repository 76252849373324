/**
 * CategoryIntro
 */

import React from 'react';

import ExpandableDescription from 'components/ExpandableDescription';
import { LayoutContainer } from 'components/Layout';
import Text from 'components/Text';
import type { JulaComponentProps } from 'lib/component-props';
import { getEditorMargin } from 'utils/business-logic';

type Props = JulaComponentProps & {
	fields?: {
		title?: string;
		longText?: string;
	};
};

export default function CategoryIntro({ params, fields }: Props) {
	const { title, longText } = fields || {};

	if (!title && !longText) {
		return null;
	}

	return (
		<LayoutContainer
			id={params?.anchor}
			withGrid
			className={getEditorMargin(params)}
		>
			{title && (
				<Text className="col-span-full mb-2" as="h1">
					{title}
				</Text>
			)}
			{longText && (
				<ExpandableDescription
					description={longText}
					textLengthBreakPoint={400}
					className="col-span-full text-lg md:col-span-6"
					descriptionClassName="text-lg"
					buttonVariant="text"
					buttonPlacement="left"
					textHeight="short"
				/>
			)}
		</LayoutContainer>
	);
}
CategoryIntro.displayName = 'CategoryIntro';
