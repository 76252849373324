/**
 * BlockShortcutInspiration
 */

import React from 'react';

import { MicroContentMedia } from 'components/MicroContent';

import ImagesGrid from './ImagesGrid';
import type { ItemProps } from './types';

interface Props {
	className?: string;
	items: ItemProps[];
}

/** Linked images with an arrow icon and text below. */
export default function BlockShortcutInspiration({ className, items }: Props) {
	return (
		<ImagesGrid as="ul" className={className} size="large">
			{items.map((item) => (
				<li key={item.key || item.href} className="col-span-1">
					<MicroContentMedia {...item} imgWidth={393} imgHeight={197} />
				</li>
			))}
		</ImagesGrid>
	);
}
BlockShortcutInspiration.displayName = 'BlockShortcutInspiration';
