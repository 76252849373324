/**
 * StoreSelectorPopover
 */

import React, { useEffect, useState } from 'react';

import { StockListItem } from 'components/Stock';
import StoreListItem from 'components/StoreListItem';
import StorePopover from 'components/StorePopover';
import { useSelectedStore, useSelectedStoreInfo } from 'contexts';
import {
	useAllStores,
	useGlobalEvent,
	useNearbyStores,
	useProductStoreStocks,
} from 'hooks';
import { Stock } from 'models/api';
import type { Store } from 'models/store';

interface Props {
	isOpen: boolean;
	onClose: () => void;
}

/** Popover for the global 'home store' selector */
export default function GlobalStoreSelectorPopover({ isOpen, onClose }: Props) {
	const {
		isLoading: isLoadingSelectedStore,
		selectedStore,
		setSelectedStore,
	} = useSelectedStore();
	const { storeInfo } = useSelectedStoreInfo();
	const [storeDetails, setStoreDetails] = useState<Store | undefined>(
		undefined,
	);
	const [shouldFetchNearbyStores, setShouldFetchNearbyStores] = useState(false);
	const [shouldFetchAllStores, setShouldFetchAllStores] = useState(false);
	const [stockFetchProductId, setStockFetchProductId] = useState('');

	useGlobalEvent('open-stock-store-selector', (e) => {
		setStockFetchProductId(e.detail.productId);
	});

	const { isLoading: isLoadingAllStores, stores: allStores } =
		useAllStores(shouldFetchAllStores);
	const { isLoading: isLoadingNearbyStores, stores: nearbyStores } =
		useNearbyStores(selectedStore?.id, shouldFetchNearbyStores);
	const { isLoading: isLoadingProductStocks, stocks: productStocks } =
		useProductStoreStocks(stockFetchProductId);

	useEffect(() => {
		if (isOpen) {
			if (selectedStore) {
				setShouldFetchNearbyStores(true);
			} else {
				setShouldFetchAllStores(true);
			}
		}
	}, [isOpen, selectedStore]);
	useEffect(() => {
		if (storeInfo) setStoreDetails(storeInfo);
	}, [storeInfo]);

	if (isLoadingSelectedStore) {
		return null;
	}

	const renderStoreItem = (store: Store) => (
		<StoreListItem
			key={store.id}
			name={store.name}
			onClick={() => {
				setSelectedStore(store);
				setStoreDetails(store);
			}}
			isSelected={selectedStore?.id === store.id}
			openHours={store.todaysOpeningHours?.description || ''}
			isOpen={store.todaysOpeningHours?.state?.toUpperCase() === 'OPEN'}
			address={`${store.streetAddress}, ${store.postalCode}, ${store.city}`}
			storeArea={store.storeArea}
		/>
	);
	const renderStockItem = (stock: Stock) => (
		<StockListItem
			key={stock.store.id}
			availableStockLevel={stock.availableStockLevel}
			inStock={stock.inStock}
			isSelected={selectedStore?.id === stock.store.id}
			title={stock.store.name || '–'}
			onClick={() => {
				if (stock.store.id && stock.store.name) {
					setSelectedStore({ id: stock.store.id, name: stock.store.name });
				}
				onClose();
			}}
		/>
	);

	const isLoading =
		!storeDetails &&
		(isLoadingAllStores || isLoadingNearbyStores || isLoadingProductStocks);
	return (
		<StorePopover
			hasSelectedStore={Boolean(selectedStore)}
			isLoading={isLoading}
			isOpen={isOpen}
			onTabChange={(tab) => {
				if (tab.includes('nearby')) {
					setShouldFetchNearbyStores(true);
				}
				if (tab.includes('all')) {
					setShouldFetchAllStores(true);
				}
			}}
			onSearch={() => {
				setShouldFetchAllStores(true);
			}}
			onClose={() => {
				onClose();
				setStockFetchProductId('');
			}}
			allStores={
				stockFetchProductId
					? productStocks.map((stock) => ({
							filterKey: stock.store.name || '–',
							node: renderStockItem(stock),
						}))
					: allStores.map((store) => ({
							filterKey: store.name,
							node: renderStoreItem(store),
						}))
			}
			nearbyStores={nearbyStores.map((store) => ({
				filterKey: store.name,
				node: renderStoreItem(store),
			}))}
			storeDetails={storeDetails}
			onBackClick={() => {
				setStoreDetails(undefined);
			}}
		/>
	);
}
GlobalStoreSelectorPopover.displayName = 'GlobalStoreSelectorPopover';
