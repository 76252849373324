import React from 'react';

import ErrorBoundary from 'components/ErrorBoundary';
import { LayoutContainer } from 'components/Layout';
import MiniHeader from 'components/MiniHeader';
import Placeholder from 'components/Placeholder';
import type { CustomLayoutServiceData, UserInfo } from 'lib/page-props';
import { useI18n } from 'utils/i18n';

import BaseLayout from './BaseLayout';

interface Props {
	layoutData: CustomLayoutServiceData;
	userInfo?: UserInfo;
}

export default function LayoutOrderStatus({ layoutData, userInfo }: Props) {
	const { t } = useI18n();
	const { route } = layoutData.sitecore;

	return (
		<BaseLayout
			forceNoindexNofollow
			layoutData={layoutData}
			route={route}
			renderFooter={false}
			renderHeader={false}
			renderMain={false}
			userInfo={userInfo}
		>
			<MiniHeader linkText={t('order_status_back_button')} linkUrl="/" />

			{route && (
				<LayoutContainer
					maxWidth="extraNarrow"
					className="my-14 rounded-lg md:border md:p-12"
				>
					<Placeholder
						name="jula-main"
						rendering={route}
						render={(components) => (
							<ErrorBoundary isPageWidth>{components}</ErrorBoundary>
						)}
					/>
				</LayoutContainer>
			)}
		</BaseLayout>
	);
}
LayoutOrderStatus.displayName = 'LayoutOrderStatus';
