/**
 * UserInformationValidationPopover
 */

import React, { useState } from 'react';

import Button from 'components/Button';
import Img from 'components/Img';
import Popover from 'components/Popover';
import { Skeleton, SkeletonItem } from 'components/Skeleton';
import Text from 'components/Text';
import { useCustomerInformation, useGlobalEvent, useGlobalLinks } from 'hooks';
import { pushToGTM } from 'utils/GoogleTagManager';
import { useI18n } from 'utils/i18n';

/** A popover that displays user information to request verification */
export default function UserInformationValidationPopover() {
	const [isOpen, setIsOpen] = useState(false);
	useGlobalEvent('open-user-information-validation-popover', () => {
		setIsOpen(true);
		pushToGTM({ type: 'user_information_validation_popover_displayed' });
	});
	const { t } = useI18n();
	const { accountDetailsClub } = useGlobalLinks();
	const { customerInformation, isLoading, error } =
		useCustomerInformation(isOpen);
	const isLoadedOk = !isLoading && !error;

	return (
		<Popover
			title={t('user_information_validation_popover_title')}
			isOpen={isOpen}
			shouldCloseOnNavigation={false}
			onClose={() => {
				setIsOpen(false);
			}}
		>
			<Img
				src="/assets/images/graphic-signin-birthday.svg"
				width={80}
				height={80}
				className="mx-auto mt-8 block h-20 w-20 object-cover"
			/>
			<Text
				className="mt-6 text-center"
				as="h1"
				styleAs="h3"
				text={t('user_information_validation_popover_confirm_heading')}
			/>
			<Text
				as="p"
				text={t('user_information_validation_popover_confirm_text')}
				className="mb-8 mt-4 text-center"
			/>

			{isLoading && (
				<Skeleton>
					<SkeletonItem height="1.5rem" className="mb-1" />
					<SkeletonItem height="1.5rem" className="mb-1" />
					<SkeletonItem height="1.5rem" className="mb-1" />
					<SkeletonItem height="1.5rem" className="mb-1 mt-4" />
					<SkeletonItem height="1.5rem" className="mb-1" />
					<SkeletonItem height="1.5rem" />
				</Skeleton>
			)}
			{isLoadedOk && (
				<div className="text-center">
					<Text
						as="p"
						text={`${customerInformation?.firstName} ${customerInformation?.lastName}`}
					/>
					{customerInformation?.isNameAndAddressEditableByCustomer && (
						<>
							<Text as="p" text={customerInformation?.postalAddress.street} />
							<Text
								as="p"
								text={`${customerInformation?.postalAddress.postalCode} ${customerInformation?.postalAddress.city}`}
							/>
						</>
					)}
					<Text
						as="p"
						text={t(
							'user_information_validation_popover_contact_information_heading',
						)}
						className="mt-4 font-bold"
					/>
					<Text as="p" text={customerInformation?.emailAddress} />
					<Text
						as="p"
						text={`${t('user_information_validation_popover_tel_nr_label')} ${customerInformation?.cellPhoneNumberFormatted.default}`}
					/>
				</div>
			)}

			<Button
				variant="cta"
				displayWidth="full"
				onClick={() => {
					pushToGTM({ type: 'user_information_validation_popover_dismissed' });
					setIsOpen(false);
				}}
				className="mt-6"
			>
				{t('user_information_validation_popover_dismiss_button')}
			</Button>
			<Button
				variant="text"
				displayWidth="full"
				href={accountDetailsClub}
				onClick={() => {
					pushToGTM({
						type: 'user_information_validation_popover_go_to_verification',
					});
					setIsOpen(false);
				}}
				className="mt-6"
			>
				{t('user_information_validation_popover_go_to_verification_button')}
			</Button>
		</Popover>
	);
}
UserInformationValidationPopover.displayName =
	'UserInformationValidationPopover';
