import { publicRuntimeConfig } from 'config';
import { isTestmode } from 'hooks';
import type { PendingCustomerTicket, ResolvedCustomerTicket } from 'models/api';
import { getPostSigningRedirectUrl } from 'pages/api/sign/helpers';
import fetchData, { API_URL, ExtendedResponse } from 'utils/fetchData';

import type { TicketResponse } from './createJulaClubCustomer.types';

export const requestCustomerApi = (
	fallBackApiUrl: string | undefined,
	params: Record<string, unknown>,
) => {
	let apiPathString = `${API_URL}Customer/julaclub/${publicRuntimeConfig?.NEXT_PUBLIC_JULA_MARKET_CODE}`;
	if (fallBackApiUrl) {
		apiPathString = `${API_URL}${fallBackApiUrl}`;
	}
	const apiPath = new URL(apiPathString);
	if (isTestmode()) {
		apiPath.searchParams.append('testmode', 'true');
	}
	const body = JSON.stringify({
		...params,
		postSigningRedirectUrl: getPostSigningRedirectUrl(),
	});

	return new Promise<TicketResponse>((resolve, reject) => {
		fetchData<ExtendedResponse<PendingCustomerTicket | ResolvedCustomerTicket>>(
			apiPath.href,
			{ method: 'POST', body },
			true,
		)
			.then((data) => {
				if (data) {
					resolve({
						ticket: data.responseData,
						status: data.status,
					});
				} else {
					reject();
				}
			})
			.catch((error) => reject(error));
	});
};
export const pollCustomerRequest = () => {
	const apiPath = `${API_URL}Ticket/Customer`;
	return new Promise<TicketResponse>((resolve, reject) => {
		fetchData<ExtendedResponse<PendingCustomerTicket | ResolvedCustomerTicket>>(
			apiPath,
			{ method: 'GET' },
			true,
		)
			.then((data) => {
				if (data) {
					resolve({
						ticket: data.responseData,
						status: data.status,
					});
				} else {
					reject();
				}
			})
			.catch((error) => reject(error));
	});
};
// Clear the refreshToken by querying the Next API endpoint.
export async function signInNewCustomer() {
	return new Promise<any>(async (resolve, reject) => {
		try {
			const response = await fetch(
				'/api/signin/session/?forceRefresh=true',
				{},
			);
			if (response.ok) {
				resolve(response);
			} else {
				reject(response);
			}
		} catch (error) {
			reject(error);
		}
	});
}

export function tokenInfoRequest() {
	return new Promise<any>(async (resolve, reject) => {
		try {
			const response = await fetch('/api/token/introspection', {});
			if (response.ok) {
				const data = await response.json();
				resolve(data);
			} else {
				reject(response);
			}
		} catch (error) {
			reject(error);
		}
	});
}

export const openSignWindow = async (iframeSrc: string | undefined) =>
	new Promise<Window | null>((resolve, reject) => {
		if (!iframeSrc) {
			reject();
			return;
		}

		const authWindow = window.open(iframeSrc);
		if (authWindow) {
			resolve(authWindow);
		} else {
			reject();
		}
	});
