/**
 * IconMenuItem
 */

import React, { type MouseEventHandler, type ReactNode } from 'react';
import clsx from 'clsx';

import Count from 'components/Count';
import Icon, { type IconType } from 'components/Icon';
import LinkOrButton from 'components/LinkOrButton';

export interface Props {
	amount?: number;
	className?: string;
	dataCy?: string | undefined;
	href?: string;
	icon?: IconType;
	inlineSubText?: boolean;
	itemClassName?: string;
	onClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
	size?: 'small' | 'regular' | 'large';
	subText?: string;
	text: ReactNode;
	withBorder?: boolean;
}

/** A single item in an IconMenu. */
export default function IconMenuItem({
	amount,
	className,
	dataCy,
	href,
	icon,
	inlineSubText = false,
	itemClassName,
	onClick,
	size = 'regular',
	subText,
	text,
	withBorder = false,
}: Props) {
	return (
		<li className={clsx(className, withBorder && 'border-t last:border-b')}>
			<LinkOrButton
				href={href}
				onClick={onClick}
				className={clsx(
					'group flex w-full font-standard',
					withBorder ? 'py-4' : 'py-3',
					size === 'regular' && 'text-base',
					size === 'large' && 'text-lg',
					inlineSubText && 'items-center',
					!inlineSubText && 'flex-col',
					itemClassName,
				)}
				data-cy={dataCy}
			>
				<span className="flex min-h-[1.5rem] items-center">
					{icon && <Icon icon={icon} className="mr-2" />}
					<span
						className={clsx(
							'inline-flex items-center group-hover:underline',
							size !== 'small' && 'font-bold',
						)}
					>
						{text}
					</span>
					<Count amount={amount} variant="badge" color="green" />
				</span>
				{subText && (
					<span
						className={clsx(
							'text-sm',
							inlineSubText && 'ml-3',
							!inlineSubText && icon && 'ml-8',
						)}
					>
						{subText}
					</span>
				)}
			</LinkOrButton>
		</li>
	);
}
IconMenuItem.displayName = 'IconMenuItem';
