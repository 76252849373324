/**
 * DiscountBubble
 */

import React from 'react';
import clsx from 'clsx';

import Text from 'components/Text';

interface Props {
	className?: string;

	/** If the standard top right absolute position should be used. */
	isPositioned?: boolean;

	/** A small label above the main text. */
	label?: string;

	/** The large main text. */
	text: string;
}

/** A circle intended for short text. */
export default function DiscountBubble({
	className,
	isPositioned,
	label,
	text,
}: Props) {
	return (
		<div
			className={clsx(
				'inline-flex aspect-square min-h-24 min-w-24 flex-col items-center justify-center rounded-full bg-julaRed p-2 text-center text-white md:min-h-32 md:min-w-32',
				isPositioned && 'absolute right-4 top-4 md:right-6 md:top-6',
				className,
			)}
		>
			{/* Whitespace after for screen readers and before to keep it centered */}
			{label && <span className="-mb-1.5">&nbsp;{label}&nbsp;</span>}
			<Text as="span" styleAs="h1">
				{text}
			</Text>
		</div>
	);
}
DiscountBubble.displayName = 'DiscountBubble';
