/**
 * BonusCheque
 */

import React from 'react';

import { Checkbox } from 'components/FormUi';
import Text from 'components/Text';
import { formatDate } from 'utils/format';
import { useI18n } from 'utils/i18n';

interface Props {
	/** Description of this property */
	id: string;
	validThru: string;
	amount: string;
	addRemoveCheckCallback: (checked: boolean) => void;
	isUsed: boolean;
}

/** Main description for this component. */
function BonusCheque({
	validThru,
	id,
	amount,
	addRemoveCheckCallback,
	isUsed,
}: Props) {
	const { t } = useI18n();
	return (
		<div className="relative cursor-pointer rounded-border bg-julaRedDark">
			<div className="flex justify-between p-4 md:p-6">
				<div className="flex-grow flex-col">
					<Checkbox
						label={`${t('checkout_bonus_check_id_label')} ...${id.slice(
							Math.max(0, id.length - 3),
						)}`}
						id={id}
						checked={isUsed}
						className="font-bold text-white"
						color="white"
						inputClassName="mr-2"
						labelClassName="before:absolute before:inset-0 before:z-1"
						onChange={() => addRemoveCheckCallback(!isUsed)}
					/>
					<Text className="ml-10 mt-1  text-white" as="p">
						{t('checkout_bonus_check_valid_thru_label')} {formatDate(validThru)}
					</Text>
				</div>

				<div className="flex flex-col">
					<Text className="ml-auto mr-0 text-white" as="span" styleAs="pSmall">
						{t('checkout_bonus_check_balance_label')}
					</Text>

					<Text className="text-white" styleAs="h2" as="p">
						{amount}
					</Text>
				</div>
			</div>
		</div>
	);
}

export default BonusCheque;
BonusCheque.displayName = 'BonusCheque';
