import React from 'react';
import clsx from 'clsx';

import type { Price, PriceType } from 'models/price';
import type { HTMLAttributes } from 'types';
import { formatPriceText } from 'utils/format';
import { useI18n } from 'utils/i18n';
import { parseMarkdown } from 'utils/string';

import { removeCurrencySymbols } from './helpers';

// Price types that should NOT display the 'lowest recent price' text, instead
// defaulting to the regular base price text.
const BLOCKED_LOWEST_RECENT_PRICE_TYPES: PriceType[] = [
	'JulaClubsmart',
	'JulaPro',
	'Base',
	'Staff',
];

interface Props extends HTMLAttributes<HTMLParagraphElement> {
	basePrice: Price | undefined;
	basePriceKey?: string;
	displayedPriceType: PriceType | undefined;
	recentLowestPriceKey: string;
}

/**
 * Displays different texts describing the base price, depending on the price
 * type and the current market.
 *
 * - A 'lowest recent price' text may exist via the recentLowestPriceKey but
 *   the key can also contain the key itself as the text, in which case it
 *   should not be displayed.
 * - The 'lowest recent price' text should only be displayed for certain
 *   price types.
 * - A fallback 'base price' text may exist via the basePriceKey key.
 */
export default function BasePriceText({
	basePrice,
	basePriceKey,
	className,
	displayedPriceType,
	recentLowestPriceKey,
	...attrs
}: Props) {
	/* eslint-disable react/no-danger */

	const { t } = useI18n();

	if (!basePrice || !displayedPriceType) {
		return null;
	}

	const tParams = {
		basePrice: formatPriceText(
			basePrice.priceIncVat?.displayValue,
			basePrice.priceIncVat?.displaySymbol,
		),
	};

	// A markdown strikethrough results in a del element. If the text is fully
	// wrapped in del, it's likely just the base price without any context. Add
	// a screen reader label in this case.
	const getStrikethroughScreenReaderLabel = (priceText: string): string => {
		if (!priceText.startsWith('<del>') || !priceText.endsWith('</del>')) {
			return '';
		}
		const key = 'product_recent_lowest_price_screen_reader_label';
		const label = t(key, tParams);
		if (label === key) {
			return '';
		}
		return removeCurrencySymbols(label);
	};

	const lowestRecentText = t(recentLowestPriceKey, tParams);

	if (
		// Key is not set in every market, in which case the base text should be
		// displayed instead (depending on prop, see further down).
		lowestRecentText !== recentLowestPriceKey &&
		!BLOCKED_LOWEST_RECENT_PRICE_TYPES.includes(displayedPriceType)
	) {
		const parsedText = parseMarkdown(lowestRecentText, { inline: true });
		const srLabel = getStrikethroughScreenReaderLabel(parsedText);
		return (
			<>
				<p
					{...attrs}
					className={clsx('font-standard', className)}
					dangerouslySetInnerHTML={{ __html: parsedText }}
					aria-hidden={srLabel ? true : undefined}
				/>
				{srLabel && <span className="sr-only">{srLabel}</span>}
			</>
		);
	}

	if (basePriceKey) {
		const baseText = parseMarkdown(t(basePriceKey, tParams), { inline: true });
		const srLabel = getStrikethroughScreenReaderLabel(baseText);
		return (
			<>
				<p
					{...attrs}
					className={clsx('font-standard', className)}
					dangerouslySetInnerHTML={{ __html: baseText }}
					aria-hidden={srLabel ? true : undefined}
				/>
				{srLabel && <span className="sr-only">{srLabel}</span>}
			</>
		);
	}

	return null;
}
BasePriceText.displayName = 'BasePriceText';
