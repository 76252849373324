import React, { useEffect, useState } from 'react';
import { Field } from 'react-final-form';

interface OnChangeStateProps<ValueT> {
	children: (value: ValueT, previous: ValueT) => void;
	value: ValueT;
}

function OnChangeState<ValueT>({
	value,
	children,
}: OnChangeStateProps<ValueT>) {
	const [previous, setPrevious] = useState(value);
	useEffect(() => {
		if (value !== previous) {
			setPrevious(value);
			children(value, previous);
		}
	}, [value]);

	return null;
}
OnChangeState.displayName = 'FieldOnChange_OnChangeState';

interface Props<ValueT> {
	children: (value: ValueT, previous: ValueT) => void;
	name: string;
}

export default function FieldOnChange<ValueT extends unknown>({
	name,
	children,
}: Props<ValueT>) {
	return React.createElement(Field<ValueT>, {
		name,
		subscription: { value: true },
		allowNull: true,
		render: (renderProps) => (
			<OnChangeState value={renderProps.input.value}>{children}</OnChangeState>
		),
	});
}
FieldOnChange.displayName = 'FieldOnChange';
