import React, { type ChangeEventHandler, useRef, useState } from 'react';
import clsx from 'clsx';

import { useEffectOnce } from 'hooks';

import styles from './Range.module.css';

export interface Props {
	id: string;
	label: string;
	max: number;
	min: number;
	onChange: ChangeEventHandler<HTMLInputElement>;
	step?: number;
	steps?: (string | number)[];
	value?: number;
}

export default function Range({
	id,
	label,
	value,
	onChange,
	steps,
	min,
	max,
	step,
}: Props) {
	const getProgress = (val: number | undefined) =>
		val === undefined || Number.isNaN(val)
			? 0
			: Math.round(((val - min) / (max - min)) * 100);

	const [progress, setProgress] = useState<number>(getProgress(value));

	// Read initial value from the input to handle a missing value prop.
	const inputRef = useRef<HTMLInputElement>(null);
	useEffectOnce(() => {
		setProgress(getProgress(Number(inputRef.current?.value)));
	});

	return (
		<>
			<label className="mb-2 inline-block" htmlFor={id}>
				{label}
			</label>
			<input
				ref={inputRef}
				id={id}
				type="range"
				value={value}
				min={min}
				max={max}
				step={step}
				onChange={(e) => {
					setProgress(getProgress(Number(e.target.value)));
					onChange(e);
				}}
				style={{ '--progress': `${progress}%` }}
				className={clsx(
					'h-6 w-full appearance-none bg-transparent',

					// Chrome/Safari
					'[&::-webkit-slider-runnable-track]:h-2',
					'[&::-webkit-slider-runnable-track]:border-0',
					'[&::-webkit-slider-runnable-track]:rounded-full',
					'[&::-webkit-slider-runnable-track]:bg-julaRed',
					'[&::-webkit-slider-thumb]:appearance-none',
					'[&::-webkit-slider-thumb]:size-6',
					'[&::-webkit-slider-thumb]:-mt-2',
					'[&::-webkit-slider-thumb]:border-0',
					'[&::-webkit-slider-thumb]:rounded-full',
					'[&::-webkit-slider-thumb]:bg-julaRed',
					// Firefox has the -moz-range-progress selector for progress styling.
					styles['track-gradient'],

					// Firefox
					'[&::-moz-range-track]:w-full',
					'[&::-moz-range-track]:h-2',
					'[&::-moz-range-track]:border-0',
					'[&::-moz-range-track]:rounded-full',
					'[&::-moz-range-track]:bg-greyLight',
					'[&::-moz-range-progress]:h-2',
					'[&::-moz-range-progress]:rounded-full',
					'[&::-moz-range-progress]:bg-julaRed',
					'[&::-moz-range-thumb]:bg-julaRed',
					'[&::-moz-range-thumb]:size-6',
					'[&::-moz-range-thumb]:border-0',
					'[&::-moz-range-thumb]:rounded-full',
				)}
			/>
			{steps && (
				/* Set aria-hidden since the labels' context is all visual. */
				<p className="flex justify-between px-1" aria-hidden="true">
					{steps.map((stepLabel) => (
						<span key={stepLabel} className="min-w-[1.5em] text-center text-sm">
							{stepLabel}
						</span>
					))}
				</p>
			)}
		</>
	);
}
Range.displayName = 'FormUI_Range';
