import React from 'react';

import StorePopover from 'components/StorePopover';
import { StoreIdName, useSelectedStore } from 'contexts';
import { Stock, StockStore } from 'models/api';
import { pushToGTM } from 'utils/GoogleTagManager';
import { sendGlobalEvent } from 'utils/helpers';

import StockListItem from './StockListItem';

interface Props {
	allStoresStock: Stock[] | undefined;
	isLoading: boolean;
	isOpen: boolean;
	nearbyStoresStock: Stock[] | undefined;
	onClose: () => void;
	onSearch: (searchValue: string) => void;
	onTabChange: (tab: string) => void;
	onUpdateSelectedStore: (store: StockStore) => void;
	selectedStore: StoreIdName | undefined;
}

export default function StockStoreSelectorPopover({
	allStoresStock,
	isLoading,
	isOpen,
	nearbyStoresStock,
	onClose,
	onSearch,
	onTabChange,
	onUpdateSelectedStore,
	selectedStore,
}: Props) {
	const { isLoading: isLoadingSelectedStore } = useSelectedStore();
	// TODO: this is a temporary fix to an issue with having the correct
	// initial tab in <StorePopover/>
	if (isLoadingSelectedStore) {
		return null;
	}

	const renderStockListItem = (storeStock: Stock) => (
		<StockListItem
			key={storeStock.store?.id}
			title={storeStock.store?.name || ''}
			isSelected={selectedStore?.id === storeStock.store?.id}
			inStock={storeStock.inStock}
			availableStockLevel={storeStock.availableStockLevel}
			onClick={() => {
				onUpdateSelectedStore(storeStock.store);
				sendGlobalEvent('engagement', {
					type: 'checkAvailabilityInStore',
					data: {
						storeId: storeStock.store?.id,
					},
				});
				if (storeStock.store.name && storeStock.availableStockLevel) {
					pushToGTM({
						type: 'select_store_choice',
						payload: {
							storeName: storeStock.store.name,
							storeStock: storeStock.availableStockLevel,
						},
					});
				}

				onClose();
			}}
		/>
	);
	const allStores = allStoresStock?.map((storeStock) => ({
		filterKey: storeStock.store?.name || '',
		node: renderStockListItem(storeStock),
	}));
	const nearbyStores = nearbyStoresStock?.map((storeStock) => ({
		filterKey: storeStock.store?.name || '',
		node: renderStockListItem(storeStock),
	}));

	return (
		<StorePopover
			allStores={allStores}
			nearbyStores={nearbyStores}
			hasSelectedStore={Boolean(selectedStore)}
			onClose={onClose}
			onTabChange={onTabChange}
			onSearch={onSearch}
			isLoading={isLoading}
			isOpen={isOpen}
		/>
	);
}

StockStoreSelectorPopover.displayName = 'StockStoreSelectorPopover';
