import React, { TextareaHTMLAttributes } from 'react';
import clsx from 'clsx';

import FloatingLabel from './FloatingLabel';
import { getInputClassName, isLabelFloating } from './helpers';
import InputInfo from './InputInfo';

export interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
	invalid?: boolean;
	valid?: boolean;
	label: string;
	id: string;
	hiddenLabel?: boolean;
	errorMessage?: string | string[];
	helpText?: string;
	rows?: number;
}

export default function Textarea({
	className,
	defaultValue,
	disabled,
	errorMessage,
	helpText,
	hiddenLabel = false,
	id,
	invalid = false,
	label,
	required,
	rows = 3,
	valid = false,
	value,
	...rest
}: Props) {
	const errorMessageId = `${id}-error`;
	const helpTextId = `${id}-help`;

	return (
		<div className={className}>
			<div className={clsx('relative', disabled && 'opacity-50')}>
				<textarea
					{...rest}
					className={getInputClassName({
						disabled,
						extra: 'py-4',
						hideOutline: true,
						invalid,
						valid,
					})}
					aria-describedby={helpTextId}
					aria-errormessage={errorMessageId}
					aria-invalid={invalid}
					defaultValue={defaultValue}
					disabled={disabled}
					id={id}
					required={required}
					rows={rows}
					value={value}
				/>
				<FloatingLabel
					htmlFor={id}
					invalid={invalid}
					isFloating={isLabelFloating(value, defaultValue)}
					isHidden={hiddenLabel}
					required={required}
				>
					{label}
				</FloatingLabel>
			</div>
			<InputInfo
				errorMessage={errorMessage}
				errorMessageId={errorMessageId}
				helpText={helpText}
				helpTextId={helpTextId}
				invalid={invalid}
			/>
		</div>
	);
}
Textarea.displayName = 'FormUI_Textarea';
