import React from 'react';
import { useSelector } from '@xstate/react';

import BonusCheque from 'components/BonusCheque';
import InfoBox from 'components/InfoBox';
import Text from 'components/Text';
import { useCheckoutContext } from 'contexts';
import { selectGiftCardBonusActor } from 'state-machines/checkout';
import {
	selectAvailableBonusDiscounts,
	selectBonusDiscounts,
	selectErrorList,
} from 'state-machines/checkout/giftCardBonus';
import { findTheErrorTitle, isThereError } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

interface Props {
	className?: string;
}

export default function Bonus({ className }: Props) {
	const { checkoutService } = useCheckoutContext();
	const giftCardBonusActor = useSelector(
		checkoutService,
		selectGiftCardBonusActor,
	);
	const { send } = giftCardBonusActor;
	const { t } = useI18n();

	const availableBonusDiscounts = useSelector(
		giftCardBonusActor,
		selectAvailableBonusDiscounts,
	);
	const errorList = useSelector(giftCardBonusActor, selectErrorList);
	const bonusCheques = useSelector(giftCardBonusActor, selectBonusDiscounts);

	return (
		<div className={className}>
			{availableBonusDiscounts && availableBonusDiscounts.length > 0 && (
				<div>
					<Text as="p" className="mb-2 font-bold">
						{t('checkout_bonus_discounts_heading')}
					</Text>
					<div className="flex flex-col gap-4">
						{availableBonusDiscounts.map((bonusDiscount) => (
							<BonusCheque
								addRemoveCheckCallback={() => {
									send({
										type: 'ADD_REMOVE_BONUS_CHECK',
										bonusChequeId: bonusDiscount.checkNumber,
									});
								}}
								key={bonusDiscount.checkNumber}
								id={bonusDiscount.checkNumber}
								isUsed={bonusCheques.some(
									(bs) => bs.checkNumber === bonusDiscount.checkNumber,
								)}
								validThru={bonusDiscount.validTo}
								amount={bonusDiscount.amountWithCurrencySymbol}
							/>
						))}
						{isThereError('DiscountAmountInfo', errorList) && (
							<InfoBox
								icon="info"
								variant="information"
								message={findTheErrorTitle('DiscountAmountInfo', errorList)}
							/>
						)}
					</div>
				</div>
			)}
		</div>
	);
}
Bonus.displayName = 'Bonus';
