import React, { type MouseEventHandler, useEffect, useState } from 'react';

import Accordion from 'components/Accordion';
import InfoBox from 'components/InfoBox';
import LoadMoreList from 'components/LoadMoreList';
import ProductCard from 'components/ProductCard';
import { Skeleton, SkeletonItem } from 'components/Skeleton';
import Text from 'components/Text';
import { useCallbackOnce, useProductListGTMEvents } from 'hooks';
import type { ProductCard as ProductCardModel } from 'models/productCard';
import type { GTMItemListId, GTMItemListName } from 'utils/GoogleTagManager';
import { is, range } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

interface BaseProps {
	accordionId: string;
	afterListContent?: React.ReactNode;
	contentClassName?: string;
	gtmItemListId: GTMItemListId;
	gtmItemListName: GTMItemListName;
	hasNextPage: boolean;
	isLoadingInitial: boolean;
	isLoadingMore: boolean;
	onAddToCart?: () => void;
	onLoadMoreClick: () => void;
	products: ProductCardModel[] | undefined;
	title: string;
}

interface PropsWithState extends BaseProps {
	initialOpen?: never;
	isOpen: boolean;
	/** Trigger click handler to toggle the `isOpen` state. */
	onClick: MouseEventHandler<HTMLButtonElement>;
}

interface PropsWithoutState extends BaseProps {
	/** Initial open state when uncontrolled. */
	initialOpen?: boolean;
	isOpen?: never;
	onClick?: never;
}

type Props = PropsWithState | PropsWithoutState;

export default function RelatedProductsAccordion({
	accordionId,
	afterListContent,
	contentClassName,
	gtmItemListId,
	gtmItemListName,
	hasNextPage,
	initialOpen,
	isLoadingInitial,
	isLoadingMore,
	isOpen,
	onAddToCart,
	onClick,
	onLoadMoreClick,
	products,
	title,
}: Props) {
	const { t, tPlural } = useI18n();

	const hasProducts = is.arrayWithLength(products);
	const regularProducts = products?.filter((product) => !product.expired) ?? [];
	const expiredProducts = products?.filter((product) => product.expired) ?? [];

	// Use the first page count as page size.
	const [pageSize, setPageSize] = useState(products?.length ?? 0);
	useEffect(() => {
		if (!pageSize && products?.length) {
			setPageSize(products.length);
		}
	}, [products, pageSize]);

	const { sendViewItemListEvent } = useProductListGTMEvents(
		gtmItemListId,
		gtmItemListName,
	);
	const sendViewItemListEventOnce = useCallbackOnce(sendViewItemListEvent);

	// The readiness of the initial list depends on combinations of several
	// factors: initial or user triggered opening and if products are loading
	// or already available. Use a limited callback so the event isn't sent
	// for every close + open.
	useEffect(() => {
		if ((isOpen || initialOpen) && products?.length && pageSize) {
			sendViewItemListEventOnce(products, pageSize);
		}
	}, [isOpen, initialOpen, products, pageSize, sendViewItemListEventOnce]);

	return (
		// @ts-expect-error The initial open or isOpen and onClick case is handled
		// in the prop interface but TS doesn't see it.
		<Accordion
			id={accordionId}
			title={title}
			titleTag="h2"
			initialOpen={initialOpen}
			isOpen={isOpen}
			onClick={onClick}
		>
			<div className={`mb-5 mt-2 ${contentClassName}`}>
				{isLoadingInitial && (
					<Skeleton>
						{range(4).map((key) => (
							<div className="mb-4 flex" key={key}>
								<SkeletonItem height="6rem" width="6rem" className="mr-2" />
								<div className="flex flex-col gap-2">
									<SkeletonItem height="1.5rem" width="6rem" />
									<SkeletonItem height="1.5rem" width="20rem" />
									<SkeletonItem height="1.5rem" width="12rem" />
								</div>
							</div>
						))}
					</Skeleton>
				)}
				{!isLoadingInitial && (
					<>
						{hasProducts && (
							<>
								<LoadMoreList
									hasLoadMoreButton={hasNextPage}
									isLoading={isLoadingMore}
									onLoadMoreClick={onLoadMoreClick}
									onLoadMoreDone={() => {
										sendViewItemListEvent(products, pageSize);
									}}
									buttonText={t('load_more_products_button')}
									itemCountScreenReaderText={tPlural(
										'general_amount_of_products',
										products?.length ?? 0,
									)}
								>
									{regularProducts.map((product, i) => (
										<ProductCard
											key={product.id}
											orientation="row"
											product={product}
											productListIndex={i}
											gtmItemListId={gtmItemListId}
											gtmItemListName={gtmItemListName}
											buttonText={t('product_buy_button')}
											showAddToCartButton
											requestAdditionalSales
											onAddToCart={onAddToCart}
										/>
									))}
									{is.arrayWithLength(expiredProducts) && (
										<>
											<Text
												as="h3"
												className="mb-6 mt-4"
												text={t(
													'product_details_related_products_accordion_expired_products_title',
												)}
											/>
											{expiredProducts.map((product, i) => (
												<ProductCard
													key={product.id}
													orientation="row"
													product={product}
													productListIndex={i + regularProducts.length}
													gtmItemListId={gtmItemListId}
													gtmItemListName={gtmItemListName}
												/>
											))}
										</>
									)}
								</LoadMoreList>
								{afterListContent}
							</>
						)}
						{!hasProducts && (
							<InfoBox
								icon="info"
								message={t(
									'product_details_product_accordion_no_products_text',
								)}
								className="mt-4"
							/>
						)}
					</>
				)}
			</div>
		</Accordion>
	);
}
RelatedProductsAccordion.displayName = 'RelatedProductsAccordion';
