import { publicRuntimeConfig } from 'config';
import { isTestmode } from 'hooks';
import { PendingCustomerTicket, ResolvedCustomerTicket } from 'models/api';
import { getPostSigningRedirectUrl } from 'pages/api/sign/helpers';
import fetchData, { API_URL, ExtendedResponse } from 'utils/fetchData';

import { CreditRequestParams } from './credit.types';

export const locationOrigin =
	typeof window === 'undefined'
		? publicRuntimeConfig?.NEXT_PUBLIC_PUBLIC_URL
		: window.location.origin;

// A getter instead of just a variable for Storybook, isTestmode is initially
// undefined there for some reason.
const getQueryParams = () => (isTestmode() ? '?testmode=true' : '');
const postSigningRedirectUrl = getPostSigningRedirectUrl();

export async function requestJulaProCreditApi(params: CreditRequestParams) {
	const apiPath = `${API_URL}Customer/julapro/credit${getQueryParams()}`;
	const response = await fetchData<
		ExtendedResponse<PendingCustomerTicket | ResolvedCustomerTicket>
	>(
		apiPath,
		{
			method: 'POST',
			body: JSON.stringify({ ...params, postSigningRedirectUrl }),
		},
		true,
	).catch((error) => error);

	if (!response || response?.status < 200 || response?.status >= 300) {
		throw response;
	}
	return response;
}

export async function requestTokenRefresh() {
	return fetch('/api/signin/session/?forceRefresh=true');
}

export async function requestJulaClubApplyForCredit(
	params: CreditRequestParams,
) {
	const marketCode = publicRuntimeConfig?.NEXT_PUBLIC_JULA_MARKET_CODE;
	const apiPath = `${API_URL}Customer/julaclub/${marketCode}/credit${getQueryParams()}`;

	const response = await fetchData<
		ExtendedResponse<PendingCustomerTicket | ResolvedCustomerTicket>
	>(
		apiPath,
		{
			method: 'POST',
			body: JSON.stringify({ ...params, postSigningRedirectUrl }),
		},
		true,
	).catch((error) => error);

	if (!response || response?.status < 200 || response?.status >= 300) {
		throw response;
	}
	return response;
}

export async function requestJulaClubRaiseCredit(params: CreditRequestParams) {
	const apiPath = `${API_URL}Customer/julaclub/raiseCredit${getQueryParams()}`;

	const response = await fetchData<
		ExtendedResponse<PendingCustomerTicket | ResolvedCustomerTicket>
	>(
		apiPath,
		{
			method: 'POST',
			body: JSON.stringify({ ...params, postSigningRedirectUrl }),
		},
		true,
	).catch((error) => error);

	if (!response || response?.status < 200 || response?.status >= 300) {
		throw response;
	}
	return response;
}

export async function requestPollCredit() {
	const apiPath = `${API_URL}Ticket/Customer`;

	const response = await fetchData<
		ExtendedResponse<PendingCustomerTicket | ResolvedCustomerTicket>
	>(
		apiPath,
		{
			method: 'GET',
		},
		true,
	).catch((error) => error);

	if (!response || response?.status < 200 || response?.status >= 300) {
		throw response;
	}
	return response;
}

export const openSignWindow = async (iframeSrc: string | undefined) =>
	new Promise<Window | null>((resolve, reject) => {
		if (!iframeSrc || iframeSrc === '') {
			reject();
			return;
		}

		const authWindow = window.open(iframeSrc);

		if (authWindow) {
			resolve(authWindow);
		} else {
			reject();
		}
	});
