import React from 'react';

import { StoreIdName } from 'contexts';
import { ItemStockResponse, Stock } from 'models/api';
import { Product } from 'models/product';
import {
	getFormattedStockAmount,
	ProductDetailsStoreStockStatusType,
} from 'utils/business-logic/stock';
import { assertUnreachable } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

import StockBalanceDetailed from './StockBalanceDetailed';
import StockBalanceDetailedSkeleton from './StockBalanceDetailedSkeleton';

interface Props {
	isLoading: boolean;
	onOpenStoreSelector?: () => void;
	product: Product;
	productStock: ItemStockResponse | undefined;
	selectedStore: StoreIdName | undefined;
	status: ProductDetailsStoreStockStatusType;
	storeStock: Stock | undefined;
}

export default function StoreStockBalanceDetailed({
	isLoading,
	onOpenStoreSelector,
	product,
	productStock,
	selectedStore,
	status,
	storeStock,
}: Props) {
	const { t } = useI18n();

	if (isLoading) {
		return <StockBalanceDetailedSkeleton stockType="store" />;
	}

	switch (status) {
		case 'Store-InStock':
			return (
				<StockBalanceDetailed
					statusType={status}
					locationIcon="store"
					header={selectedStore?.name || ''}
					stockStatus={{
						inStock: true,
						statusText: t('stock_general_amount_exact', {
							amount: getFormattedStockAmount(storeStock?.availableStockLevel),
						}),
					}}
					storeInfo={{
						articleInfo: {
							department: product.category1Name,
							shelf: storeStock?.primaryLocation,
							artno: product.id,
							color: product.category1Color,
						},
						openingHours:
							storeStock?.store?.todaysOpeningHours?.description || '',
					}}
					buttonText={t('product_details_store_accordion_change_store_button')}
					onClick={onOpenStoreSelector}
				/>
			);
		case 'Store-OutOfStock-WebStock':
			return (
				<StockBalanceDetailed
					statusType={status}
					locationIcon="store"
					header={selectedStore?.name || ''}
					stockStatus={{
						inStock: false,
						statusText: t('stock_general_out_of_stock'),
					}}
					onClick={onOpenStoreSelector}
					buttonText={t('product_details_store_accordion_change_store_button')}
					infoText={t('product_details_store_accordion_online_only_text')}
				/>
			);
		case 'Store-OutOfStock-NoWebStock':
			return (
				<StockBalanceDetailed
					statusType={status}
					locationIcon="store"
					header={selectedStore?.name || ''}
					stockStatus={{
						inStock: false,
						statusText: t('stock_general_out_of_stock'),
					}}
					onClick={onOpenStoreSelector}
					buttonText={t('product_details_store_accordion_change_store_button')}
				/>
			);
		case 'NoStore-InStock':
			return (
				<StockBalanceDetailed
					statusType={status}
					locationIcon="store"
					header={t('product_details_store_accordion_heading')}
					onClick={onOpenStoreSelector}
					buttonText={t('product_details_store_accordion_select_store_button')}
					stockStatus={{
						inStock: true,
						statusText: t('stock_stores_amount_exact', {
							amount: productStock?.storeStock?.inStockCount,
						}),
					}}
				/>
			);
		case 'NoStore-OutOfStock-WebStock':
			return (
				<StockBalanceDetailed
					statusType={status}
					locationIcon="store"
					header={t('product_details_store_accordion_heading')}
					stockStatus={{
						inStock: false,
						statusText: t('stock_stores_amount_exact', {
							amount: productStock?.storeStock?.inStockCount,
						}),
					}}
					infoText={t('product_details_store_accordion_online_only_text')}
					onClick={onOpenStoreSelector}
					buttonText={t('product_details_store_accordion_select_store_button')}
				/>
			);
		case 'NoStore-OutOfStock-NoWebStock':
			return (
				<StockBalanceDetailed
					statusType={status}
					locationIcon="store"
					header={t('product_details_store_accordion_heading')}
					stockStatus={{
						inStock: false,
						statusText: t('stock_stores_amount_exact', {
							amount: productStock?.storeStock?.inStockCount,
						}),
					}}
					onClick={onOpenStoreSelector}
					buttonText={t('product_details_store_accordion_select_store_button')}
				/>
			);

		case 'MatchFailed':
			return null;
		default:
			assertUnreachable(status);
	}
}

StoreStockBalanceDetailed.displayName = 'StoreStockBalanceDetailed';
