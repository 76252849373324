import React, { useRef } from 'react';
import clsx from 'clsx';

import ActionButton, { ActionButtonState } from 'components/ActionButton';
import Button from 'components/Button';
import { InputInfo } from 'components/FormUi';
import Icon from 'components/Icon';
import InfoBox from 'components/InfoBox';
import Link from 'components/Link';
import Price from 'components/Price';
import Text from 'components/Text';
import { StoredFileResponse } from 'models/api/userFileStorage';
import { Price as PriceType } from 'models/price';
import { getPriceProps } from 'utils/business-logic';
import { filterTruthy } from 'utils/collection';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

interface IconTextButtonProps {
	buttonText: string | undefined;
	className?: string;
	iconText: string | undefined;
	onClick: () => void;
	url: string;
}

function IconTextButton({
	buttonText,
	className,
	iconText,
	onClick,
	url,
}: IconTextButtonProps) {
	return (
		<div className={clsx('flex items-center justify-between', className)}>
			<Link href={url} underline className="flex items-center" target="_blank">
				<Icon icon="file" className="mr-2" />
				<Text as="p" text={iconText} />
			</Link>
			<Button variant="text" onClick={onClick}>
				{buttonText}
			</Button>
		</div>
	);
}

IconTextButton.displayName = 'ProductPrintPopover_IconTextButton';

interface Props {
	abortButtonText: string | undefined;
	addPrintButtonText: string | undefined;
	addPrintPlacementButtonState: ActionButtonState;
	description: string | undefined;
	fileUploadButtonState: ActionButtonState;
	heading: string | undefined;
	infoText: string | undefined;
	onAbortClick: () => void;
	onAddPrintPlacementClick: () => void;
	onRemovePrintClick: (id: string) => void;
	onReusePrintClick: (id: string) => void;
	onUpLoadPrint: (file: File) => void;
	price: PriceType | undefined;
	print: StoredFileResponse | undefined;
	printPriceLabel: string | undefined;
	printUploadErrors?: string[] | undefined;
	removeUploadedPrintButtonText: string | undefined;
	reusePrintButtonText: string | undefined;
	reuseUploadedPrintText: string | undefined;
	storedPrints: StoredFileResponse[] | undefined;
	uploadPrintButtonText: string | undefined;
}

export default function PrintUploadView({
	abortButtonText,
	addPrintButtonText,
	addPrintPlacementButtonState,
	description,
	fileUploadButtonState,
	heading,
	infoText,
	onAbortClick,
	onAddPrintPlacementClick,
	onRemovePrintClick,
	onReusePrintClick,
	onUpLoadPrint,
	price,
	print,
	printPriceLabel,
	printUploadErrors,
	removeUploadedPrintButtonText,
	reusePrintButtonText,
	reuseUploadedPrintText,
	storedPrints,
	uploadPrintButtonText,
}: Props) {
	const { t } = useI18n();
	const inputRef = useRef<HTMLInputElement>(null);
	const errorMessageId = `product-print-upload-error`;
	const helpTextId = 'print-upload-help-text';
	const printOriginalFileName = print?.originalFileName;
	const printFileName = print?.fileName;
	const printUrl = print?.url;
	const filteredStoredPrints = filterTruthy(
		storedPrints,
		'originalFileName',
		'fileName',
		'url',
	);
	return (
		<div>
			<Text as="h3" text={heading} />
			<Text className="mt-6" as="p" text={description} />
			<InfoBox
				className="mt-6"
				icon="info"
				variant="information"
				message={infoText}
			/>
			<div className="mt-6">
				{!print && (
					<>
						{/* TODO: we should make this into a fileInput component to be
						used here and in generic form */}
						<input
							id="print-upload-file-input"
							type="file"
							aria-errormessage={errorMessageId}
							className="hidden"
							ref={inputRef}
							onChange={(event) => {
								const file = event.target?.files?.[0];
								if (!file) {
									return;
								}
								onUpLoadPrint(file);
							}}
						/>
						<ActionButton
							onClick={() => {
								inputRef.current?.click();
							}}
							customState={fileUploadButtonState}
							variant="primary"
						>
							{uploadPrintButtonText}
						</ActionButton>
						<InputInfo
							className="ml-0"
							errorMessage={printUploadErrors}
							invalid={Boolean(printUploadErrors)}
							errorMessageId={errorMessageId}
							helpText={t('product_print_file_input_file_size_limit_text')}
							helpTextId={helpTextId}
						/>
					</>
				)}
				{printOriginalFileName && printFileName && printUrl && (
					<IconTextButton
						buttonText={removeUploadedPrintButtonText}
						iconText={printOriginalFileName}
						onClick={() => onRemovePrintClick(printFileName)}
						url={printUrl}
					/>
				)}
			</div>
			{!print && is.arrayWithLength(filteredStoredPrints) && (
				<div className="mt-8">
					<Text as="p" className="font-bold" text={reuseUploadedPrintText} />
					<ul className="flex flex-col gap-2">
						{filteredStoredPrints.map((storedPrint) => (
							<li key={storedPrint.fileName}>
								<IconTextButton
									buttonText={reusePrintButtonText}
									iconText={storedPrint.originalFileName}
									onClick={() => onReusePrintClick(storedPrint.fileName)}
									url={storedPrint.url}
								/>
							</li>
						))}
					</ul>
				</div>
			)}
			<div className="mt-6 flex justify-between">
				<Text as="p" text={printPriceLabel} className="self-end font-bold" />
				<Price {...getPriceProps(price)} size="small" />
			</div>
			<ActionButton
				onClick={onAddPrintPlacementClick}
				disabled={!print}
				variant="cta"
				displayWidth="full"
				className="mt-6"
				customState={addPrintPlacementButtonState}
			>
				{addPrintButtonText}
			</ActionButton>
			<Button
				className="mt-6"
				displayWidth="full"
				variant="text"
				onClick={onAbortClick}
			>
				{abortButtonText}
			</Button>
		</div>
	);
}

PrintUploadView.displayName = 'ProductPrintPopover_PrintUploadView';
