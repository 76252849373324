import React from 'react';

import { LayoutContainer } from 'components/Layout';
import { Column } from 'models/sitecore';

import PageFooterInfo from './PageFooterInfo';
import PageFooterMenus from './PageFooterMenus';

interface Props {
	columns: Column[] | undefined;
	infoText?: string;
}

/** Primary footer navigation. */
export default function PageFooterNav({ columns, infoText }: Props) {
	return (
		<LayoutContainer
			gutterType="padding"
			outerClassName="md:py-10"
			className="lg:grid lg:grid-cols-12 lg:gap-6"
		>
			<PageFooterInfo text={infoText} className="max-lg:hidden lg:col-span-3" />
			<PageFooterMenus
				columns={columns}
				className="lg:col-span-8 lg:col-start-5"
			/>
		</LayoutContainer>
	);
}
PageFooterNav.displayName = 'PageFooterNav';
