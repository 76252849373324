import React from 'react';
import clsx from 'clsx';

import Button from 'components/Button';
import Img from 'components/Img';
import Text from 'components/Text';

interface Props {
	alreadyMemberButtonText: string;
	alreadyMemberHeading: string;
	alreadyMemberText: string;
	className?: string;
	onContinueShopping: () => void;
}
export default function AlreadyMemberView({
	alreadyMemberText,
	alreadyMemberHeading,
	alreadyMemberButtonText,
	className,
	onContinueShopping,
}: Props) {
	return (
		<div className={clsx(className, 'flex h-full flex-col content-between')}>
			<div>
				<Img
					src="/assets/images/graphic-congrats-julaclub.png"
					alt=""
					width={80}
					height={80}
					className="mx-auto mt-8 block h-20 w-20 object-cover"
				/>
				<Text as="h2" className="mt-8">
					{alreadyMemberHeading}
				</Text>
				<Text as="p" className="mt-6">
					{alreadyMemberText}
				</Text>
				<div className="mt-8">
					<Button
						variant="primary"
						displayWidth="full"
						className="my-4"
						onClick={onContinueShopping}
					>
						{alreadyMemberButtonText}
					</Button>
				</div>
			</div>
		</div>
	);
}
AlreadyMemberView.displayName = 'AlreadyMemberView';
