/**
 * Step
 */

import React from 'react';
import clsx from 'clsx';

import Icon from 'components/Icon';
import Text from 'components/Text';

interface Props {
	/** React children */
	children?: React.ReactNode;

	/** The label under the circle of each step */
	label?: string;

	/** The number in the circle */
	number?: number;

	/** Pass optional classnames for the html */
	className?: string;

	/** The status of the steps in the stepper */
	status: 'done' | 'active' | 'pending' | 'highlight';
}

/** The step component in the stepper component */
function Step({ label, number, status, children, className }: Props) {
	return (
		<div
			className={clsx('relative flex flex-col items-center', label && 'w-6%')}
		>
			<div
				className={clsx(
					className,
					'mb-1 flex h-10 w-10 items-center justify-center rounded-full border-2 border-white',
					status === 'done' || status === 'active' || status === 'highlight'
						? 'bg-julaRed'
						: 'bg-greyLight',
				)}
			>
				<Text
					as="p"
					className={clsx(
						className,
						'm-0',
						'font-bold',
						status === 'done' ? 'hidden' : '',
						status === 'active' ? 'text-white' : 'text-grey',
					)}
				>
					{children || number}
				</Text>
				<Icon
					icon="check"
					color="white"
					className={clsx(
						className,
						'',
						status === 'done' ? 'block' : 'hidden',
					)}
					aria-hidden
				/>
			</div>
			<Text
				as="pSmall"
				className={clsx(
					className,
					'm-0',
					'w-max',
					'text-center',
					'max-w-[7rem]',
					status === 'active' ? 'font-bold' : '',
				)}
			>
				{label}
			</Text>
		</div>
	);
}

export default Step;
Step.displayName = 'Step';
