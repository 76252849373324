import React from 'react';

import Accordion from 'components/Accordion';
import Img from 'components/Img';
import {
	getDocuments,
	RelatedProductsAccordion,
} from 'components/ProductDetails';
import RichText from 'components/RichText';
import TechnicalSpecification from 'components/TechnicalSpecification';
import Text from 'components/Text';
import { ProductResponse } from 'models/api/variant';
import { ProductCard } from 'models/productCard';
import {
	getBrandLogoImage,
	getVerticalEnergyLabel,
} from 'utils/business-logic';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

import ProductPopoverImage from './ProductPopoverImage';
import ProductPopoverTitle from './ProductPopoverTitle';

interface Props {
	onSparePartsLoadMore: () => void;
	product: ProductResponse | undefined;
	spareParts: ProductCard[] | undefined;
	sparePartsHasNextPage: boolean;
	sparePartsIsLoading: boolean;
	sparePartsIsLoadingMore: boolean;
}

export default function ProductArchiveSearchContent({
	onSparePartsLoadMore,
	product,
	spareParts,
	sparePartsHasNextPage,
	sparePartsIsLoading,
	sparePartsIsLoadingMore,
}: Props) {
	const { t } = useI18n();

	if (!product) return null;

	const { packageSize, technicalAttributes } = product;
	const documents = getDocuments(product);
	const verticalEnergyLabel = getVerticalEnergyLabel(product);
	const brandLogoSrc = getBrandLogoImage(product);

	return (
		<>
			<ProductPopoverTitle
				brandTitle={product.brand?.title}
				model={product.model}
				subTitle={product.subTitle}
				title={product.title}
				titleExclModel={product.titleExclModel}
			/>
			<ProductPopoverImage product={product} />

			<Accordion
				id="product-archive-search-item-popover-description"
				title={t('product_details_about_product_section_heading')}
				className="mt-6"
				titleTag="h2"
			>
				<Img
					src={brandLogoSrc}
					alt={product.brand?.title}
					useFallbackOnError
					className="mb-4 max-h-16 max-w-[10rem]"
				/>
				<Text as="p">
					<span className="font-bold">
						{t('product_details_article_number_label')}:
					</span>{' '}
					{product.id}
				</Text>
				<RichText className="mt-6" html={product.webTextFormatted} />
			</Accordion>
			{(is.arrayWithLength(technicalAttributes?.categories) ||
				is.arrayWithLength(documents) ||
				packageSize) && (
				<Accordion
					id="product-archive-search-item-popover-tech-spec"
					title={t('product_details_technical_specification_heading')}
					titleTag="h2"
				>
					<TechnicalSpecification
						className="my-4"
						categories={technicalAttributes?.categories}
						packageSize={packageSize}
						energyLabelSrc={verticalEnergyLabel?.url.location}
						documents={documents}
					/>
				</Accordion>
			)}
			<RelatedProductsAccordion
				accordionId="spare-parts-search-item-popover-spare-parts"
				hasNextPage={sparePartsHasNextPage}
				initialOpen
				isLoadingInitial={sparePartsIsLoading}
				isLoadingMore={sparePartsIsLoadingMore}
				onLoadMoreClick={onSparePartsLoadMore}
				products={spareParts}
				gtmItemListId="pdp_spare_parts"
				gtmItemListName={`List: Spare parts ${product.id}`}
				title={t('product_details_spare_parts_heading')}
			/>
		</>
	);
}

ProductArchiveSearchContent.displayName = 'ProductArchiveSearchContent';
