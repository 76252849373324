import React from 'react';

import InfoBox from 'components/InfoBox';
import { getWeightedPercentage } from 'utils/math';

interface Point {
	count: number;
}
export interface InfoText {
	isActive: (percentage: number) => boolean;
	srOnly?: boolean;
	text: string;
}
interface Props {
	className?: string;
	points: Point[];
	texts: InfoText[];
}

/**
 * Displays info text boxes for percentage thresholds.
 */
export default function WeightedAverageInfoText({
	className,
	points,
	texts,
}: Props) {
	const percentage = getWeightedPercentage(...points.map((p) => p.count));
	const infoText = texts.find((info) => info.isActive(percentage));

	if (!infoText) {
		return null;
	}

	return (
		<>
			{infoText.srOnly && <p className="sr-only">{infoText.text}</p>}
			{!infoText.srOnly && (
				<InfoBox icon="info" className={className} message={infoText.text} />
			)}
		</>
	);
}
WeightedAverageInfoText.displayName = 'WeightedAverageInfoText';
