import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import { useRouter } from 'next/router';

import BlogDetails from 'components/BlogDetails';
import ComponentPlaceholder from 'components/ComponentPlaceholder';
import { LayoutContainer } from 'components/Layout';
import LoadMoreList from 'components/LoadMoreList';
import Meter from 'components/Meter';
import Text from 'components/Text';
import { useIsEditing, usePagination } from 'hooks';
import type { JulaComponentProps } from 'lib/component-props';
import { DigizuiteAsset, DigizuiteAssetList } from 'models/asset';
import { getEditorMargin } from 'utils/business-logic';
import { asArray, is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

const OFFSET_QUERY_VAR = 'bo-offset';
const PAGE_SIZE_QUERY_VAR = 'bo-pageSize';
const TAG_QUERY_VAR = 'bo-tag';

interface Tag {
	id: string;
	name: string;
}

interface BlogItemType {
	displayName: string;
	fields: {
		content: Field<string>;
		heading: Field<string>;
		images: {
			value: { assets: DigizuiteAsset[][] };
		};
		landscapeImage: DigizuiteAssetList;
		portraitImage: DigizuiteAssetList;
		published: Field<string>;
		squareImage: DigizuiteAssetList;
	};
	id: string;
	name: string;
	url: string;
}

interface Fields {
	description: Field<string>;
	hasNextPage: boolean;
	heading: Field<string>;
	items: BlogItemType[];
	nextPageOffset: number;
	pageSize: Field<number>;
	tags: Tag[];
	total: number;
}

type Props = JulaComponentProps & {
	fields: Fields;
};

export default function BlogOverview({ params, fields: initialFields }: Props) {
	const { t, tPlural } = useI18n();
	const isEditing = useIsEditing();
	const router = useRouter();

	const urlTags = asArray(router.query[TAG_QUERY_VAR]).filter(is.truthy);

	const {
		component: blogOverviewFields,
		isLoading: isLoadingMoreItems,
		items,
		loadMore: loadMoreItems,
		queryVarItems,
		updateQueryVars,
	} = usePagination<BlogItemType, Fields>({
		initialComponent: initialFields,
		initialItems: initialFields.items,
		initialNextPageOffset: initialFields.nextPageOffset,
		initialQueryVars: urlTags.map((tag) => [TAG_QUERY_VAR, tag]),
		itemsKey: 'items',
		offsetQueryVarName: OFFSET_QUERY_VAR,
		pageSizeQueryVarName: PAGE_SIZE_QUERY_VAR,
		placeholderComponentName: 'BlogOverview',
	});

	const visibleItemsCount = items.length;
	const totalItemsCount = blogOverviewFields?.total ?? 0;

	if (isEditing && visibleItemsCount === 0 && !initialFields.heading?.value) {
		return (
			<ComponentPlaceholder
				className={getEditorMargin(params)}
				componentName="BlogOverview"
				description="Requires a heading"
			/>
		);
	}

	return (
		<LayoutContainer
			withGrid
			id={params?.anchor}
			className={getEditorMargin(params)}
		>
			<div className="col-span-4 flex flex-col md:col-span-6 md:col-start-4">
				<Text as={params?.heading || 'h1'} field={initialFields.heading} />
				<Text as="pLarge" field={initialFields.description} />
				<LoadMoreList
					isLoading={isLoadingMoreItems}
					onLoadMoreClick={loadMoreItems}
					hasLoadMoreButton={blogOverviewFields?.hasNextPage}
					buttonAlignment="center"
					buttonClassName="mt-4 max-sm:w-full sm:min-w-72"
					itemCountScreenReaderText={tPlural(
						'blog_item_count_text',
						visibleItemsCount,
					)}
					buttonText={t('load_more_inspiration_items_button')}
					listTag="ul"
					listClassName="mt-8 divide-y divide-grey border-y border-grey"
					afterListContent={
						<Meter
							alignment="center"
							className="mt-14"
							current={visibleItemsCount}
							max={totalItemsCount}
							labelHasProgress
							label={t('inspiration_overview_page_indicator_text', {
								numShown: visibleItemsCount,
								numTotal: totalItemsCount,
							})}
						/>
					}
				>
					{items.map((item) => (
						<li key={item.id}>
							<BlogDetails
								className="py-8"
								content={item.fields.content.value}
								heading={item.fields.heading.value}
								images={item.fields.images.value.assets}
								published={item.fields.published?.value}
								url={item.url}
							/>
						</li>
					))}
				</LoadMoreList>
			</div>
		</LayoutContainer>
	);
}
BlogOverview.displayName = 'BlogOverview';
