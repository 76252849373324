import React from 'react';

import Button from 'components/Button';
import LoadingSpinner from 'components/LoadingSpinner';
import Text from 'components/Text';

interface Props {
	loadingText: string;
	className?: string;
	hasErrorOpeningSignWindow: boolean;
	errorOpeningSignWindowText: string;
	onRetryOpenSignWindow: () => void;
	onRetryOpenSignWindowButtonText: string;
}
export default function LoadingView({
	loadingText,
	hasErrorOpeningSignWindow,
	onRetryOpenSignWindow,
	onRetryOpenSignWindowButtonText,
	errorOpeningSignWindowText,
	className,
}: Props) {
	return (
		<div className={className}>
			<Text as="h2" className="mb-2 mt-14 text-center">
				{loadingText}
			</Text>
			<div className="relative flex flex-col items-center justify-center">
				<LoadingSpinner
					variant="dashing"
					spinnerColor="julaRed"
					trackColor="transparent"
					size="medium"
				/>
				{hasErrorOpeningSignWindow && (
					<>
						<p className="mt-8 font-bold">{errorOpeningSignWindowText}</p>
						<Button
							onClick={onRetryOpenSignWindow}
							className="mt-4"
							variant="primary"
						>
							{onRetryOpenSignWindowButtonText}
						</Button>
					</>
				)}
			</div>
		</div>
	);
}
LoadingView.displayName = 'LoadingView';
