/**
 * AccountPurchases
 */

import React, { useEffect, useState } from 'react';

import InfoBox from 'components/InfoBox';
import { AccountLayoutContainer } from 'components/Layout';
import LoadingSpinner from 'components/LoadingSpinner';
import { OrderList } from 'components/Order/';
import Placeholder from 'components/Placeholder';
import { useIsEditing, useOrderHistory } from 'hooks';
import type { JulaComponentProps } from 'lib/component-props';
import { pushToGTM } from 'utils/GoogleTagManager';
import { useI18n } from 'utils/i18n';

type Props = JulaComponentProps;

export default function AccountPurchases({ rendering }: Props) {
	const { t } = useI18n();

	// Per https://jula.atlassian.net/browse/DP-5478 they want to find out if
	// any customers look at the end of their order history and if they should
	// supply more history and perhaps search. Hopefully this related code can be
	// deleted in time.
	const {
		items: orders,
		isLoading,
		isLoadingMore,
		error,
		loadMore,
		hasNextPage,
	} = useOrderHistory();
	const [hasLoadedMore, setHasLoadedMore] = useState(false);
	const isEditing = useIsEditing();

	useEffect(() => {
		if (hasLoadedMore && !hasNextPage) {
			pushToGTM({
				type: 'order_history_end',
				payload: { date: orders?.at(-1)?.purchaseDate || 'date missing' },
			});
		}
	}, [orders, hasNextPage, hasLoadedMore]);
	const handleLoadMoreClick = () => {
		setHasLoadedMore(true);
		loadMore();
	};
	const hasOrders = orders && orders.length > 0;

	if (isEditing) {
		return (
			<AccountLayoutContainer
				heading={`${t('account_purchases_heading')}: Exp editor placeholder`}
				rendering={rendering}
			>
				<Placeholder
					rendering={rendering}
					name="jula-account-details"
					isInNestedPlaceholder
				/>
			</AccountLayoutContainer>
		);
	}

	return (
		<AccountLayoutContainer
			rendering={rendering}
			heading={t('account_purchases_heading')}
			headingClassName="print:hidden"
		>
			{isLoading && !error && (
				<div className="flex items-center justify-center">
					<LoadingSpinner />
				</div>
			)}
			{!isLoading && !error && (
				<>
					{hasOrders && (
						<OrderList
							rendering={rendering}
							hasLoadMoreButton={hasNextPage}
							isLoadingMore={isLoadingMore}
							onLoadMoreClick={handleLoadMoreClick}
							orders={orders}
						/>
					)}
					{!hasOrders && (
						<InfoBox
							icon="info"
							message={t('jula_pro_orders_no_orders_text')}
						/>
					)}
				</>
			)}
			{error && (
				<InfoBox
					icon="error"
					variant="error"
					className="mt-3"
					message={t('account_generic_fetch_error_text')}
				/>
			)}
		</AccountLayoutContainer>
	);
}
AccountPurchases.displayName = 'AccountPurchases';
