import React from 'react';
import dynamic from 'next/dynamic';

import LoadingSpinner from 'components/LoadingSpinner';

import type { Props } from './GenericForm';

const GenericFormSuspense = dynamic(() => import('./GenericForm'), {
	loading: () => <LoadingSpinner />,
});

export default function DynamicGenericForm(props: Props) {
	return <GenericFormSuspense {...props} />;
}
DynamicGenericForm.displayName = 'DynamicGenericForm';
