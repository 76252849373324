import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { useInterpret, useSelector } from '@xstate/react';
import { waitFor } from 'xstate/lib/waitFor';

import AccountDetailsCreditInformation from 'components/AccountDetailsCreditInformation';
import ActionButton from 'components/ActionButton';
import TextInput from 'components/FinalForm/TextInput';
import InfoBox from 'components/InfoBox';
import { AccountLayoutContainer } from 'components/Layout';
import { Skeleton, SkeletonItem } from 'components/Skeleton';
import Text from 'components/Text';
import { useFeatureToggle, useGlobalStateContext } from 'contexts';
import {
	useAvailableCreditBalance,
	useContactInformation,
	useCustomerInformation,
	useEffectOnce,
	useGlobalLinks,
	useRevalidateContactInformation,
	useSitecoreContext,
} from 'hooks';
import { JulaComponentProps } from 'lib/component-props';
import {
	accountMachine,
	selectSubmitButtonState,
} from 'state-machines/account';
import {
	selectContactRole,
	selectCustomerContactId,
} from 'state-machines/authentication';
import { useI18n } from 'utils/i18n';

type Props = JulaComponentProps & {};

export default function AccountJulaProDetails({ rendering }: Props) {
	const { t } = useI18n();

	const { userService, globalPopoverService } = useGlobalStateContext();
	const accountService = useInterpret(accountMachine, {
		devTools: true,
	});
	const { send } = accountService;

	const { sitecoreContext } = useSitecoreContext();
	const { globalLinks } = sitecoreContext;

	const customerContactId = useSelector(userService, selectCustomerContactId);
	const contactRole = useSelector(userService, selectContactRole);
	const isEcomBuyer = contactRole === 'EcomBuyer';
	const { creditFunctionsEnabled } = useFeatureToggle();
	const { julaProApplyForCredit, julaProRaiseCredit } = useGlobalLinks();

	const {
		customerInformation,
		isLoading: isLoadingCustomerInformation,
		error: errorCustomerInformation,
	} = useCustomerInformation();
	const {
		isCreditCustomer,
		creditLimit,
		isValidCreditCustomer,
		invoiceAddress,
		fullName,
		identificationNumber,
	} = customerInformation || {};
	const {
		contactInformation,
		isLoading: isLoadingContactInformation,
		error: errorContactInformation,
	} = useContactInformation(customerContactId || '');
	const revalidate = useRevalidateContactInformation(customerContactId || '');

	const {
		availableCreditBalance,
		isLoading: isLoadingAvailableCreditBalance,
		error: errorAvailableCreditBalance,
	} = useAvailableCreditBalance(
		Boolean(creditFunctionsEnabled && customerInformation?.isCreditCustomer),
	);

	const submitButtonState = useSelector(
		accountService,
		selectSubmitButtonState,
	);
	const [showAcceptInviteInfo, setShowAcceptInviteInfo] = useState(false);

	useEffectOnce(() => {
		const { href } = window.location;
		const url = new URL(href);
		const params = url.searchParams;
		if (params.get('accepted_invite') !== null) {
			setShowAcceptInviteInfo(true);
		}
	});

	const isLoadingCreditInformation =
		(isCreditCustomer && isLoadingAvailableCreditBalance) ||
		isLoadingCustomerInformation;
	const errorCreditInformation =
		errorCustomerInformation || errorAvailableCreditBalance;

	const loadingCustomerInformationOk =
		!isLoadingCustomerInformation && !errorCustomerInformation;
	const loadingContactInformationOk =
		!isLoadingContactInformation && !errorContactInformation;

	const onSubmit = async (values) => {
		send({
			type: 'UPDATE_CONTACT_INFORMATION',
			updateContactInformationData: {
				email: values.email,
				mobileNumber: values.mobileNumber,
				allowMarketingCommunication: values.allowMarketingCommunication,
			},
		});

		const state = await waitFor(
			accountService,
			(currentState) => currentState.hasTag('submitDone'),
			{
				timeout: 120_000,
			},
		);
		if ('data' in state.event) {
			if (state?.event?.data?.fieldValidationErrors) {
				return state?.event?.data?.fieldValidationErrors;
			}
			revalidate();
		}
	};

	// replace all hyphens and spaces in a string
	const replaceHyphensAndSpaces = (str?: string) => {
		if (!str) return undefined;
		return str.replaceAll(/-| /g, '');
	};

	const userInfoNotEditableText = t(
		'account_details_pro_userinfo_not_editable_description',
	);
	const hastUnserInfoNotEditableTranslation =
		userInfoNotEditableText !==
		'account_details_pro_userinfo_not_editable_description';

	return (
		<AccountLayoutContainer
			rendering={rendering}
			heading={t('jula_pro_org_details_heading')}
			mainContentSize="thin"
		>
			{showAcceptInviteInfo && (
				<InfoBox
					heading={t('julapro_org_check_information_heading')}
					variant="information"
					message={t('julapro_org_check_information_text')}
					className="mb-6"
				/>
			)}
			<div>
				{creditFunctionsEnabled && !isEcomBuyer && (
					<>
						<Text as="h2" text={t('jula_pro_heading')} className="mb-4" />
						<AccountDetailsCreditInformation
							isCreditCustomer={isCreditCustomer}
							isValidCreditCustomer={
								isValidCreditCustomer && contactRole === 'Admin'
							}
							availableCreditHeading={t('account_details_credit_space_text')}
							applyForCreditInfoHeading={t('account_details_invoice_heading')}
							creditSpace={creditLimit}
							creditInformationText={t(
								'julapro_account_credit_information_text',
							)}
							availableCreditBalance={availableCreditBalance}
							descriptiveText={t(
								'account_details_linear_gauge_descriptive_text',
							)}
							applyForCreditButtonOnClick={() => {
								if (julaProApplyForCredit) {
									globalPopoverService.send({
										type: 'OPEN',
										target: julaProApplyForCredit,
										heading: t('account_apply_for_credit_button'),
									});
								}
							}}
							raiseCreditButtonOnClick={() => {
								if (julaProRaiseCredit) {
									globalPopoverService.send({
										type: 'OPEN',
										target: julaProRaiseCredit,
										heading: t('account_raise_credit_button'),
									});
								}
							}}
							raiseCreditButtonText={t('account_raise_credit_button')}
							loading={isLoadingCreditInformation}
							hasError={errorCreditInformation}
							applyForCreditButtonText={t('account_apply_for_credit_button')}
							lowerOrRemoveCreditText={
								t('account_lower_or_remove_credit_text') ===
								'account_lower_or_remove_credit_text'
									? undefined
									: t('account_lower_or_remove_credit_text')
							}
						/>
					</>
				)}
				<Text as="h2" className="mb-4 mt-10">
					{t('julapro_org_your_org_heading')}
				</Text>
				{loadingCustomerInformationOk && (
					<>
						<Text as="p">{fullName}</Text>
						<Text as="p" className="mb-8">
							{identificationNumber}
						</Text>
						<Text as="h2" className="mb-4">
							{t('julapro_org_invoice_address_heading')}
						</Text>
						<Text as="p">{invoiceAddress?.street}</Text>
						<Text as="p" className="mb-8">
							{invoiceAddress?.postalCode}, {invoiceAddress?.city}
						</Text>
					</>
				)}
				{isLoadingCustomerInformation && (
					<Skeleton className="w-[21rem]">
						<SkeletonItem height="2rem" className="mt-2" />
						<SkeletonItem height="1rem" className="mt-4" />
						<SkeletonItem height="1rem" className="mb-8 mt-2" />
						<SkeletonItem height="2rem" className="mt-2" />
						<SkeletonItem height="1rem" className="mt-4" />
						<SkeletonItem height="1rem" className="mb-8 mt-2" />
					</Skeleton>
				)}
				{errorCustomerInformation && (
					<InfoBox
						icon="error"
						variant="error"
						message={t('account_generic_fetch_error_text')}
					/>
				)}

				<Text as="h2" className="my-4">
					{t('julapro_org_your_info_heading')}
				</Text>
				{loadingContactInformationOk && (
					<>
						<Text as="p" className="mb-6">
							{contactInformation?.firstName} {contactInformation?.lastName}
						</Text>
						{hastUnserInfoNotEditableTranslation && (
							<InfoBox icon="info" variant="information" className="mb-6">
								<Text as="pSmall">{userInfoNotEditableText}</Text>
							</InfoBox>
						)}
						<Form
							onSubmit={onSubmit}
							initialValues={{
								email: contactInformation?.email,
								mobileNumber: replaceHyphensAndSpaces(
									contactInformation?.phoneNumber.default,
								),
								allowMarketingCommunication:
									contactInformation?.acceptsMarketing,
							}}
							initialValuesEqual={() => true}
							render={(renderProps) => {
								const { handleSubmit } = renderProps;
								return (
									<form onSubmit={handleSubmit}>
										<TextInput
											type="email"
											id="email"
											label={t('account_details_email_label')}
											inputMode="email"
											name="email"
											className="mb-6"
										/>
										<TextInput
											id="mobileNumber"
											name="mobileNumber"
											label={t('account_details_phone_label')}
											inputMode="tel"
											className="mb-8"
										/>
										{/* 											<div className="my-6">
												<Checkbox
													bgColor="red"
													type="checkbox"
													name="allowMarketingCommunication"
													label={t(
														'julapro_stepper_step_three_admin_mail_checkbox_text'
													)}
													id="allowMarketingCommunication"
												/>
											</div> */}
										<div>
											<ActionButton
												color="white"
												variant="cta"
												displayWidth="full"
												type="submit"
												customState={submitButtonState}
											>
												{t('account_details_save_details_button')}
											</ActionButton>
										</div>
									</form>
								);
							}}
						/>
					</>
				)}
				{isLoadingContactInformation && (
					<Skeleton className="">
						<SkeletonItem height="2rem" className="mt-2" />
						<SkeletonItem height="1rem" className="mt-4" />
						<SkeletonItem height="3.5rem" className="mt-6" />
						<SkeletonItem height="3.5rem" className="mt-6" />
						<SkeletonItem height="1.5rem" className="my-6" />
						<SkeletonItem height="3.5rem" className="mt-2" />
					</Skeleton>
				)}
				{errorContactInformation && (
					<InfoBox
						icon="error"
						variant="error"
						message={t('account_generic_fetch_error_text')}
					/>
				)}
			</div>
		</AccountLayoutContainer>
	);
}
AccountJulaProDetails.displayName = 'AccountJulaProDetails';
