import React from 'react';
import { Field } from 'react-final-form';
import clsx from 'clsx';

import InfoBox from 'components/InfoBox';
import { FormInfo } from 'models/sitecore';

function getInfoBox(content: FormInfo, inputSpace?: string) {
	switch (content?.type) {
		case 'InfoField':
			return (
				<div key={content.id} className={inputSpace}>
					<div
						className="text-sm"
						dangerouslySetInnerHTML={{ __html: content.text }}
					/>
				</div>
			);
		case 'HighlightField':
			return (
				<div className={clsx('text-sm', inputSpace)} key={content.id}>
					<InfoBox icon={content?.icon?.value?.[0]?.src} variant="information">
						<div dangerouslySetInnerHTML={{ __html: content.text }} />
					</InfoBox>
				</div>
			);
		default:
			return null;
	}
}

export default function renderNonInputContent(
	content: FormInfo,
	inputSpace?: string,
) {
	if (content.requires) {
		return (
			<Field
				key={content.id}
				name={content.requires}
				render={({ input }) =>
					input.value ? getInfoBox(content, inputSpace) : null
				}
			/>
		);
	}
	return getInfoBox(content, inputSpace);
}
