import React from 'react';

import ExpandableDescription from 'components/ExpandableDescription';
import Img from 'components/Img';
import { LayoutContainer } from 'components/Layout';
import StoreInfo from 'components/StoreInfo';
import { JulaComponentProps } from 'lib/component-props';
import { Store } from 'models/store';
import { getAsset } from 'utils/helpers';

type Props = JulaComponentProps & {
	fields: { store: Store };
};

export default function StoreIntro({ fields }: Props) {
	if (!fields) {
		return null;
	}
	const {
		city,
		information,
		latitude,
		longitude,
		name,
		postalCode,
		regularOpeningHours,
		specialOpeningHours,
		storeArea,
		streetAddress,
		todaysOpeningHours,
	} = fields.store;

	const { description, header, storeHeaderImage } = information ?? {};
	const storeImg =
		storeHeaderImage && getAsset('Templated 2:1', storeHeaderImage);

	return (
		<LayoutContainer>
			<div className="grid grid-cols-4 gap-4 sm:grid-flow-col sm:grid-cols-8 sm:gap-6 lg:grid-cols-12">
				<div className="col-span-4 md:col-span-3">
					<StoreInfo
						name={name}
						todaysOpeningHours={todaysOpeningHours}
						storeArea={storeArea}
						streetAddress={streetAddress}
						postalCode={postalCode}
						city={city}
						latitude={latitude}
						longitude={longitude}
						regularOpeningHours={regularOpeningHours}
						specialOpeningHours={specialOpeningHours}
					/>
				</div>

				<div className="col-span-4 sm:col-span-5 sm:col-start-4 lg:col-span-7 lg:col-start-6">
					{storeImg && (
						<div className="mt-8 max-sm:-mx-4 sm:mt-14">
							<Img
								src={storeImg.src}
								alt={storeImg.alt}
								width={949}
								height={475}
								service="nextjs"
							/>
						</div>
					)}
					{(header?.value || description?.value) && (
						<ExpandableDescription
							className="mt-4"
							heading={header?.value}
							showHeading
							description={description?.value}
							textLengthBreakPoint={600}
						/>
					)}
				</div>
			</div>
		</LayoutContainer>
	);
}
StoreIntro.displayName = 'StoreIntro';
