/**
 * OptionalContainer
 */

import React, { type ReactNode } from 'react';

import type { HTMLAttributes, HTMLTagName } from 'types';

interface Props extends HTMLAttributes<HTMLDivElement> {
	/** Container tag to use. */
	as: HTMLTagName;

	/** Container children, or the only output if container isn't active. */
	children: ReactNode;

	/** If the container should be rendered. */
	hasContainer: boolean;
}

/** Render a container for the children, or just the children themselves. */
export default function OptionalContainer({
	as: Tag,
	children,
	hasContainer,
	...containerProps
}: Props) {
	if (!hasContainer) {
		return children;
	}
	return <Tag {...containerProps}>{children}</Tag>;
}
OptionalContainer.displayName = 'OptionalContainer';
