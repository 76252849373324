/**
 * PageHeaderSearch
 */

import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useRouter } from 'next/router';

import Button from 'components/Button';
import { ComboboxOption, ComboboxOptionGroup } from 'components/Combobox';
import HighlightedText from 'components/HighlightedText';
import Icon from 'components/Icon';
import Link from 'components/Link';
import ProductLine from 'components/ProductLine';
import SearchCombobox, {
	type SearchComboboxChildProps,
} from 'components/SearchCombobox';
import { Skeleton, SkeletonItem } from 'components/Skeleton';
import Text from 'components/Text';
import {
	fetchSearchResults,
	useMaxWidth,
	usePopularProducts,
	usePopularSearchTerms,
	useProductListGTMEvents,
	useSearch,
	useSearchProductsPageSize,
	useSearchTermsPageSize,
	useVirtualKeyboardSize,
} from 'hooks';
import type { ProductCard } from 'models/productCard';
import { filterTruthy } from 'utils/collection';
import { type GTMHelperInput, pushToGTM } from 'utils/GoogleTagManager';
import {
	getTestDataAttrFrom,
	ignorePromiseRejection,
	range,
} from 'utils/helpers';
import { useI18n } from 'utils/i18n';
import { createUrl, getQueryParam } from 'utils/url';

function getSearchPageUrl(
	searchQuery: string,
	extraParams: Record<string, string> = {},
) {
	return createUrl(
		'/search/',
		{ query: searchQuery, ...extraParams },
		// Keep query first
		{ sortParams: false },
	);
}

interface Props {
	/** Container class name */
	className?: string;

	/** Field id */
	id: string;
}

interface TermItem {
	hasIcon: boolean;
	key: string;
	screenReaderPrefix?: string;
	selectGTMEvent?: GTMHelperInput;
	suffix?: string;
	text: string;
	url: string;
}

/** Cancel button on smaller screens. */
function PageHeaderSearchCancel({
	closeCombobox,
	focusAfterSearch,
	isOpen,
}: SearchComboboxChildProps) {
	const { t } = useI18n();

	if (!isOpen) {
		return null;
	}

	return (
		<Button
			variant="text"
			size="small"
			underline={false}
			onClick={() => {
				closeCombobox();
				focusAfterSearch();
			}}
			className="ml-4 hover:underline headerRow:hidden"
		>
			{t('search_cancel_search_button')}
		</Button>
	);
}
PageHeaderSearchCancel.displayName = 'PageHeaderSearchCancel';

/** Search results dropdown, run the actual search. */
function PageHeaderSearchResults({
	baseId,
	blurInput,
	closeCombobox,
	hasSearchQuery,
	hasOpened,
	listboxProps,
	isOpen,
	rawInputValue,
	searchQuery,
	selectedOptionId,
	setInputValue,
	useFormSubmit,
}: SearchComboboxChildProps) {
	const router = useRouter();
	const { t } = useI18n();
	const isMaxMd = useMaxWidth('md');
	const keyboardSize = useVirtualKeyboardSize();

	const {
		searchTerms: searchResultTerms,
		categories: searchResultCategories,
		brands: searchResultBrands,
		products: searchResultProducts,
		spellingSuggestions: searchResultSpellingSuggestions,
		isLoading: isLoadingResults,
		totalProductCount,
		// Checking hasOpened instead of isOpen to have the results render even
		// if closed, when they have been loaded. Then they can be focused when
		// pressing down arrow from a closed state.
	} = useSearch(hasOpened ? searchQuery : '');

	const hasSearchTerms = searchResultTerms.length > 0;
	const hasCategories = searchResultCategories.length > 0;
	const hasBrands = searchResultBrands.length > 0;
	const hasProducts = searchResultProducts.length > 0;
	const hasSpellingSuggestions = searchResultSpellingSuggestions.length > 0;
	const hasResults =
		hasSearchQuery &&
		(hasSearchTerms || hasCategories || hasBrands || hasProducts);
	const showNoResults = hasSearchQuery && !isLoadingResults && !hasResults;
	const showPopular = !hasSearchQuery || showNoResults;
	const showResults = hasSearchQuery && !isLoadingResults && hasResults;
	const showItems = showPopular || showResults;

	// Checking hasOpened for the same reason as for useSearch, see above.
	// The search query check is to avoid unnecessary fetching when the popular
	// items won't be displayed, since there is a search result to show instead.
	const isPopularHookIdle = !hasOpened || (hasSearchQuery && !showNoResults);
	const productsPageSize = useSearchProductsPageSize();
	const termsPageSize = useSearchTermsPageSize();
	const { products: popularProducts, isLoading: isLoadingPopularProducts } =
		usePopularProducts({
			isIdle: isPopularHookIdle,
			itemLimit: productsPageSize,
		});
	const { terms: popularTerms, isLoading: isLoadingPopularTerms } =
		usePopularSearchTerms({
			isIdle: isPopularHookIdle,
			itemLimit: termsPageSize,
		});

	// Map search/spelling suggestions, categories and brands to a common format
	// for easier rendering.
	const termItems = [
		// Spelling suggestions
		...(hasSpellingSuggestions ? searchResultSpellingSuggestions : []).map(
			(term): TermItem => ({
				hasIcon: true,
				key: `spelling-${term}`,
				screenReaderPrefix: t('search_suggestion_label'),
				text: term,
				url: getSearchPageUrl(term),
			}),
		),
		// Search suggestions
		...(hasSearchQuery && hasResults ? searchResultTerms : popularTerms).map(
			(term): TermItem => ({
				hasIcon: true,
				key: `suggestion-${term}`,
				screenReaderPrefix: t('search_suggestion_label'),
				text: term,
				url: getSearchPageUrl(term),
			}),
		),
		// Categories
		...(showResults ? searchResultCategories : []).map(
			(cat): TermItem => ({
				hasIcon: false,
				key: `category-${cat.name}-${cat.parentName}`,
				text: cat.name,
				screenReaderPrefix: t('search_category_suggestion_label'),
				selectGTMEvent: { type: 'search_term_category_select' },
				suffix: cat.parentName ? `(${cat.parentName})` : '',
				url: cat.url,
			}),
		),
		// Brands
		...(showResults ? searchResultBrands : []).map(
			(brand): TermItem => ({
				hasIcon: false,
				key: `brand-${brand.name}`,
				text: brand.name,
				selectGTMEvent: { type: 'search_term_brand_select' },
				suffix: `(${t('search_brand_suggestion_label')})`,
				url: brand.url,
			}),
		),
	];
	const productItems = hasResults ? searchResultProducts : popularProducts;

	const { sendViewItemListEvent, sendSelectItemEvent } =
		useProductListGTMEvents(
			'search_autocomplete',
			'Search result autocomplete',
		);
	const resultProductIds = searchResultProducts.map(({ id }) => id).join(',');
	useEffect(() => {
		if (searchResultProducts.length > 0) {
			sendViewItemListEvent(searchResultProducts, searchResultProducts.length);
		}
		// Only check IDs in case the results array changes but it still contains
		// the same products.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [resultProductIds]);

	// Add overlay to page if search is active
	useEffect(() => {
		if (isOpen) {
			document.body.classList.add('search-overlay');
		}
		return () => {
			document.body.classList.remove('search-overlay');
		};
	}, [isOpen]);

	// Sync the search field with changes to the URL
	useEffect(() => {
		const handleRouteChange = (url: string) => {
			setInputValue(getQueryParam(url, 'query'));
			closeCombobox();
		};
		router.events.on('routeChangeStart', handleRouteChange);

		return () => {
			router.events.off('routeChangeStart', handleRouteChange);
		};
	}, [closeCombobox, router, setInputValue]);

	useFormSubmit(async () => {
		if (rawInputValue) {
			let singleProduct: ProductCard | undefined;
			// Quickly entering a product ID and pressing enter (to trigger submit)
			// will send the user to an empty results page since there will be no
			// `searchResultProducts` yet. Instead of some complex queue system, run
			// an extra request right away if it looks like the input is a product ID.
			if (!hasProducts && /\d{5,}/.test(rawInputValue)) {
				const results = await fetchSearchResults(rawInputValue);
				const products = filterTruthy(
					results?.productSearchResponse?.products ?? [],
					'url',
				);
				if (products.length === 1) {
					singleProduct = products[0];
				}
			} else if (searchResultProducts.length === 1) {
				singleProduct = searchResultProducts[0];
			}

			// If the result is a single product, go directly to it.
			const targetUrl = singleProduct
				? singleProduct.url
				: getSearchPageUrl(rawInputValue);
			ignorePromiseRejection(router.push(targetUrl));

			blurInput();
			closeCombobox();
		}
	});

	const ITEM_SPACING_CLASSES = 'mt-6 md:mt-4';
	const resultTermsGroupId = `${baseId}-result-terms`;
	const resultProductsGroupId = `${baseId}-result-products`;
	const resultTermsIdPrefix = `${baseId}-term-item-`;
	const resultProductsIdPrefix = `${baseId}-product-item-`;
	const isGroupTitleHidden =
		!hasSpellingSuggestions &&
		(showNoResults || hasResults || isLoadingResults);
	const resultTermsGroupHeading = t(
		hasSpellingSuggestions
			? 'search_results_spelling_suggestion_text'
			: hasResults
				? 'search_results_screen_reader_text'
				: 'search_popular_search_terms_text',
	);

	return (
		<div
			className="mt-2 p-2 headerRow:mt-4 headerRow:p-4"
			style={
				isMaxMd && keyboardSize
					? // Add 8px for the p-2 that gets overwritten.
						{ paddingBottom: `${keyboardSize + 8}px` }
					: undefined
			}
			data-cy={getTestDataAttrFrom('search-results')}
		>
			{isLoadingResults && (
				<Skeleton className="max-headerRow:mt-4">
					<div className="grid gap-6 md:grid-cols-2 md:gap-4">
						<div className="col-span-1 space-y-6 md:space-y-4">
							<SkeletonItem height="1.5rem" />
							<SkeletonItem height="1.5rem" />
						</div>
						<div className="col-span-1 space-y-6 md:space-y-4">
							<SkeletonItem height="2.5rem" />
							<SkeletonItem height="2.5rem" />
						</div>
					</div>
				</Skeleton>
			)}

			{/* Live regions should stay in the DOM and have their content
			    changed rather than being added and removed. Be assertive
			    about no results since that's important to know. */}
			<Text as="p" aria-live="assertive" aria-atomic="true">
				{showNoResults && (
					<>
						{t('search_no_search_results_text')}
						<strong className="[quotes:auto] before:content-[open-quote] after:content-[close-quote]">
							{searchQuery}
						</strong>
						…
					</>
				)}
			</Text>
			{showNoResults && (
				<Text as="h3" className="mt-12">
					{t('search_other_search_term_recommendations_text')}
				</Text>
			)}

			<div
				{...listboxProps}
				className={clsx(
					'headerRow:grid headerRow:grid-cols-2',
					hasResults && '-mt-4',
				)}
			>
				<ComboboxOptionGroup
					titleId={resultTermsGroupId}
					groupTitle={
						<Text
							as="p"
							styleAs="h3"
							className={isGroupTitleHidden ? 'sr-only' : undefined}
						>
							{resultTermsGroupHeading}
						</Text>
					}
				>
					{isLoadingPopularTerms && showPopular && (
						<li role="presentation">
							<Skeleton>
								{range(termsPageSize).map((i) => (
									<SkeletonItem
										key={i}
										width={`${50 + (7 % (i + 1)) * 7}px`}
										height="24px"
										className={ITEM_SPACING_CLASSES}
									/>
								))}
							</Skeleton>
						</li>
					)}
					{showItems &&
						termItems.map((item, i) => (
							<ComboboxOption
								key={item.key}
								id={`${resultTermsIdPrefix}${i + 1}`}
								selectedId={selectedOptionId}
								className={`w-fit pr-1 ${ITEM_SPACING_CLASSES}`}
							>
								<Link
									href={item.url}
									className="group inline-flex items-center align-top font-standard text-base"
									tabIndex={-1}
									onClick={() => {
										if (item.selectGTMEvent) {
											pushToGTM(item.selectGTMEvent);
										}
									}}
									aria-label={
										item.screenReaderPrefix
											? `${item.screenReaderPrefix}: ${item.text} ${item.suffix || ''}`
											: undefined
									}
								>
									{item.hasIcon && <Icon icon="search" className="mr-2" />}
									<span className="group-hover:underline">
										<HighlightedText
											text={item.text}
											highlight={hasResults ? searchQuery : undefined}
										/>
										{item.suffix && <> {item.suffix}</>}
									</span>
								</Link>
							</ComboboxOption>
						))}
				</ComboboxOptionGroup>
				<ComboboxOptionGroup
					titleId={resultProductsGroupId}
					groupTitle={
						<Text
							as="p"
							styleAs="h3"
							className={isGroupTitleHidden ? 'sr-only' : undefined}
						>
							{/* No-break space to let the heading occupy its natural
							    height since the actual text is always hidden. */}
							<span className="max-headerRow:hidden">&nbsp;</span>
							<span className="sr-only">
								{t(
									hasResults
										? 'search_products_screen_reader_text'
										: 'search_popular_products_text',
								)}
							</span>
						</Text>
					}
				>
					{isLoadingPopularProducts && showPopular && (
						<li role="presentation">
							<Skeleton>
								{range(productsPageSize).map((i) => (
									<SkeletonItem
										key={i}
										height="2.5rem"
										className={ITEM_SPACING_CLASSES}
									/>
								))}
							</Skeleton>
						</li>
					)}
					{showItems &&
						productItems.map((product, i) => (
							<ComboboxOption
								key={product.id}
								id={`${resultProductsIdPrefix}${product.id}`}
								selectedId={selectedOptionId}
								className={ITEM_SPACING_CLASSES}
							>
								<ProductLine
									highlight={hasResults ? searchQuery : undefined}
									product={product}
									tabIndex={-1}
									onClick={() => {
										sendSelectItemEvent(product, i);
									}}
								/>
							</ComboboxOption>
						))}
				</ComboboxOptionGroup>
			</div>

			{showItems && totalProductCount > productsPageSize && (
				<div className="mt-8 flex justify-center">
					<Button
						href={getSearchPageUrl(searchQuery)}
						className="max-sm:w-full sm:min-w-[20rem]"
					>
						{t('search_show_all_button')}{' '}
						<span className="ml-1 font-normal">({totalProductCount})</span>
					</Button>
				</div>
			)}
		</div>
	);
}
PageHeaderSearchResults.displayName = 'PageHeaderSearchResults';

/** Search field with results dropdown. */
export default function PageHeaderSearch({ className, id }: Props) {
	const { t } = useI18n();

	return (
		<SearchCombobox
			className={className}
			id={id}
			inputLabel={t('screenreader_text_site_main_search')}
			inputPlaceholder={t('search_global_search_placeholder_text')}
			submitButtonLabel={t('search_show_all_button')}
			afterSubmitComponent={PageHeaderSearchCancel}
			resultsComponent={PageHeaderSearchResults}
			formClassName="max-headerRow:static"
			controlsContainerClassName="max-headerRow:mx-4 z-searchField"
			openClassName={clsx(
				'max-headerRow:fixed',
				'max-headerRow:inset-0',
				'max-headerRow:z-99',
				'max-headerRow:overflow-y-scroll',
				'max-headerRow:overscroll-contain',
				'max-headerRow:pt-4',
				'max-headerRow:h-[calc(100%+1px)]',
			)}
			resultsContainerClassName={clsx(
				'z-searchResults',
				'max-headerRow:inset-0',
				// Change top padding to border to avoid having scrolling content
				// going behind and above the search input.
				'max-headerRow:pt-0',
				'max-headerRow:border-t-[5rem]',
				'max-headerRow:border-t-white',
				'max-headerRow:rounded-none',
				'max-headerRow:shadow-none',
			)}
		/>
	);
}
PageHeaderSearch.displayName = 'PageHeaderSearch';
