import React from 'react';
import dynamic from 'next/dynamic';

import LoadingSpinner from 'components/LoadingSpinner';

import type { Props } from './Map';

const MapSuspense = dynamic(() => import('./Map'), {
	loading: () => <LoadingSpinner />,
});

export default function DynamicMap(props: Props) {
	return <MapSuspense {...props} />;
}
DynamicMap.displayName = 'DynamicMap';
