import React from 'react';

import ArrowLink from 'components/ArrowLink';
import { FaqArticle, FaqCategoryArticle } from 'models/sitecore';

interface Props {
	articles: FaqArticle[];
	className?: string;
	items: FaqCategoryArticle[];
}

export default function FAQAccordionArticles({
	articles,
	className,
	items,
}: Props) {
	return (
		<ul className={className}>
			{items.map(({ articleId, shortDesc }) => {
				const articleData = articles.find(
					(article) => article.fields?.id?.value === articleId,
				);

				if (!articleData) return null;

				return (
					<li key={articleId} className="mb-4 last:mb-0">
						<ArrowLink text={shortDesc} href={articleData.url} />
					</li>
				);
			})}
		</ul>
	);
}

FAQAccordionArticles.displayName = 'FAQAccordionArticles';
