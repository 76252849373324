/**
 * CountryList
 */

import React from 'react';

interface Props {
	/** React children */
	children?: React.ReactNode;
}

/** The "list" of the countries for the maintenance items. */
export default function CountryList({ children }: Props) {
	return (
		<div className="sm:mt-48">
			<div className="mx-auto grid max-w-pageStandard grid-cols-4 gap-4 md:grid-cols-8 lg:grid-cols-12">
				{children}
			</div>
		</div>
	);
}
CountryList.displayName = 'CountryList';
