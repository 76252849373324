import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import clsx from 'clsx';

import ArrowLink from 'components/ArrowLink';
import ComponentPlaceholder from 'components/ComponentPlaceholder';
import DiscountBubble from 'components/DiscountBubble';
import Img from 'components/Img';
import { LayoutContainer } from 'components/Layout';
import Link from 'components/Link';
import Text from 'components/Text';
import { useIsEditing } from 'hooks';
import type { JulaComponentProps } from 'lib/component-props';
import type { DigizuiteAssetList } from 'models/asset';
import type { SitecoreLink } from 'models/sitecore';
import { getEditorMargin } from 'utils/business-logic';
import { filterTruthy } from 'utils/collection';
import { getAsset } from 'utils/helpers';

interface TeaserItem {
	campaignBubbleLabel?: Field<string>;
	campaignBubbleText?: Field<string>;
	heading: Field<string>;
	landscapeImage: DigizuiteAssetList;
	link: Field<SitecoreLink>;
	video: DigizuiteAssetList;
}

type Props = JulaComponentProps & {
	fields?: {
		heading: Field<string>;
		items: TeaserItem[];
		small: Field<boolean>;
	};
};

function mapTeaserItems(items: TeaserItem[]) {
	const teasers = filterTruthy(
		items?.map((item) => ({
			campaignBubbleLabel: item.campaignBubbleLabel?.value,
			campaignBubbleText: item.campaignBubbleText?.value,
			heading: item?.heading?.value,
			href: item?.link?.value.href,
			image: getAsset('Templated 2:1', item?.landscapeImage),
			linkTarget: item?.link?.value.target,
			linkText: item?.link?.value.text,
		})),
		'heading',
		'href',
		'image',
		'linkText',
	);
	return teasers.length === 2 ? teasers : undefined;
}

export default function DoubleTeaser({ fields, params }: Props) {
	const isEditing = useIsEditing();
	if (!fields) {
		return null;
	}

	const teasers = mapTeaserItems(fields.items);
	if (!teasers && isEditing) {
		return (
			<ComponentPlaceholder
				componentName="DoubleTeaser"
				className={getEditorMargin(params)}
				description="This component requires two teaser items with heading, link, link text and image to function."
			/>
		);
	}
	if (!teasers) {
		return null;
	}
	const isSmall = Boolean(fields.small.value);

	return (
		<LayoutContainer
			outerClassName={getEditorMargin(params)}
			id={params?.anchor}
			className="grid grid-cols-4 gap-4 md:grid-cols-12 md:gap-6"
		>
			<Text
				as={params?.heading ?? 'h2'}
				field={fields.heading}
				className={clsx(
					'col-span-full',
					isSmall && 'md:col-span-6 md:col-start-4',
				)}
			/>
			{teasers.map(
				(
					{
						campaignBubbleLabel,
						campaignBubbleText,
						heading: teaserHeading,
						href,
						image,
						linkTarget,
						linkText,
					},
					index,
				) => (
					<div
						key={href + linkText + teaserHeading}
						className={clsx(
							'relative col-span-full',
							isSmall ? 'md:col-span-3' : 'md:col-span-6',
							isSmall && index === 0 && 'md:col-start-4',
						)}
					>
						<Link href={href} isScreenReaderHidden>
							<Img
								src={image.src}
								alt={image.alt}
								width={isSmall ? 393 : 810}
								height={isSmall ? 196 : 404}
								service="nextjs"
								className="h-auto w-full object-cover"
							/>
							{campaignBubbleText && (
								<DiscountBubble
									label={campaignBubbleLabel}
									text={campaignBubbleText}
									isPositioned
								/>
							)}
						</Link>
						<Text as="h3" text={teaserHeading} className="mt-4 md:mt-6" />
						<ArrowLink
							className="mt-4"
							href={href}
							text={linkText}
							target={linkTarget}
						/>
					</div>
				),
			)}
		</LayoutContainer>
	);
}
DoubleTeaser.displayName = 'DoubleTeaser';
