import React, { useEffect } from 'react';
import { useMachine, useSelector } from '@xstate/react';

import Button from 'components/Button';
import Icon from 'components/Icon';
import InfoBox from 'components/InfoBox';
import { AccountLayoutContainer } from 'components/Layout';
import ListItem from 'components/ListItem';
import { Skeleton, SkeletonItem } from 'components/Skeleton';
import Text from 'components/Text';
import { useGlobalStateContext } from 'contexts';
import { useGlobalLinks, useSitecoreContext } from 'hooks';
import type { JulaComponentProps } from 'lib/component-props';
import type { JulaProCustomerContact } from 'models/api';
import { selectIsClosing } from 'state-machines/global-popover.machine';
import {
	selectStateIsLoadingUserList,
	selectUserList,
	userManagementMachine,
} from 'state-machines/userManagement';
import { useI18n } from 'utils/i18n';

type Props = JulaComponentProps;

export default function AccountJulaProUsers({ rendering }: Props) {
	const { t } = useI18n();
	const { globalPopoverService } = useGlobalStateContext();
	const { addUserJulaPro } = useGlobalLinks();

	const [, send, userManagementService] = useMachine(userManagementMachine, {
		devTools: true,
	});
	const { sitecoreContext } = useSitecoreContext();
	const { itemPath } = sitecoreContext;

	const userList = useSelector(userManagementService, selectUserList);
	const isLoadingUserList = useSelector(
		userManagementService,
		selectStateIsLoadingUserList,
	);
	const isLoadingInitialUserList = isLoadingUserList && !userList;
	// TODO: fix this, the error state did not exist in the machine anymore
	// so we did not select anything
	const errorGettingUserList = false;

	const getTextForRole = (user: JulaProCustomerContact) => {
		switch (user.role) {
			case 'Admin':
				return t('jula_pro_myusers_role_admin');
			case 'Authorized':
				return t('jula_pro_myusers_role_authorized');
			case 'EcomBuyer':
				return t('jula_pro_myusers_role_ecombuyer');
			default:
				return '';
		}
	};

	const addUserPopoverCLosing = useSelector(
		globalPopoverService,
		selectIsClosing,
	);
	useEffect(() => {
		send('GET_USER_LIST');
	}, [addUserPopoverCLosing, send]);

	return (
		<AccountLayoutContainer
			rendering={rendering}
			heading={t('julapro_myusers_heading')}
		>
			{isLoadingInitialUserList && (
				<Skeleton>
					<SkeletonItem height="6rem" className="my-4" />
					<SkeletonItem height="6rem" className="my-4" />
					<SkeletonItem height="6rem" className="my-4" />
				</Skeleton>
			)}
			{errorGettingUserList && (
				<InfoBox
					icon="error"
					variant="error"
					message={t('account_generic_fetch_error_text')}
				/>
			)}
			{userList && (
				<ul className="flex flex-col gap-2">
					{userList.map((user) => (
						<ListItem
							as="li"
							href={`${itemPath}${user.customerContactId}/`}
							title={user.name}
							key={user.customerContactId}
							endContent={<Icon icon="arrow" className="self-center" />}
						>
							<Text as="pSmall">
								{getTextForRole(user)}
								{user.invitePendingForRole && (
									<>
										<br />
										{t('jula_pro_myusers_invitation_has_been_sent')}
									</>
								)}
							</Text>
						</ListItem>
					))}
				</ul>
			)}
			{!errorGettingUserList && !isLoadingInitialUserList && (
				<Button
					variant="primary"
					onClick={() => {
						if (addUserJulaPro) {
							globalPopoverService.send({
								type: 'OPEN',
								target: addUserJulaPro,
								heading: t('julapro_myusers_open_add_user_button_text'),
							});
						}
					}}
					className="mt-6"
				>
					{t('julapro_myusers_open_add_user_button_text')}
				</Button>
			)}
		</AccountLayoutContainer>
	);
}
AccountJulaProUsers.displayName = 'AccountJulaProUsers';
