import { stringify } from 'query-string';

import fetchData, { API_URL } from 'utils/fetchData';

import type {
	JulaClubCustomerInformationUpdateRequestParams,
	JulaProContactInformationUpdateRequestParams,
} from './account.types';

export const requestJulaProAccountApiUpdateContactInformation = (
	params: JulaProContactInformationUpdateRequestParams,
): Promise<void> => {
	const apiPath = `${API_URL}Customer/julapro/contact/?${stringify(params)}`;
	return new Promise<void>((resolve, reject) => {
		fetchData(apiPath, {
			method: 'PUT',
		})
			.then(() => {
				resolve();
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const requestJulaClubAccountApiUpdateCustomerInformation = (
	params: JulaClubCustomerInformationUpdateRequestParams,
): Promise<void> => {
	const apiPath = `${API_URL}Customer/julaclub`;
	return new Promise<void>((resolve, reject) => {
		fetchData(apiPath, {
			method: 'PUT',
			body: JSON.stringify(params),
		})
			.then(() => {
				resolve();
			})
			.catch((error) => {
				reject(error);
			});
	});
};
