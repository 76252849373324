import React from 'react';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import Button from 'components/Button';
import { LayoutContainer } from 'components/Layout';
import Map from 'components/Map';
import StoreListItem from 'components/StoreListItem';
import Text from 'components/Text';
import type { JulaComponentProps } from 'lib/component-props';
import type { Store, StoreMarkerModel } from 'models/store';
import { getEditorMargin } from 'utils/business-logic';
import { useI18n } from 'utils/i18n';

type Props = JulaComponentProps & {
	fields?: {
		heading: Field<string>;
		storeListUrl: string;
		stores: Store[];
	};
};

export default function NearbyStores({ fields, params }: Props) {
	const { t } = useI18n();
	if (!fields) {
		return null;
	}

	const { storeListUrl, stores } = fields;

	const storeMarkers: StoreMarkerModel[] = stores?.map((store: Store) => ({
		id: store?.id,
		name: store?.name,
		openHours: store.todaysOpeningHours?.description || '',
		isOpen: store?.todaysOpeningHours?.state?.toUpperCase() === 'OPEN',
		address: `${store?.streetAddress}, ${store?.postalCode}, ${store?.city}`,
		storeArea: store?.storeArea,
		url: store?.url,
		latitude: Number(store?.latitude),
		longitude: Number(store?.longitude),
	}));

	return (
		<LayoutContainer
			id={params?.anchor}
			withGrid
			className={getEditorMargin(params)}
		>
			<Text
				as="h2"
				text={t('stores_nearby_stores_heading')}
				className="col-span-full mb-4 md:mb-6"
			/>
			<div className="col-span-4 md:col-span-6">
				{stores && (
					<ul className="flex flex-col gap-y-2">
						{storeMarkers?.map((store) => (
							<StoreListItem
								key={store.id}
								name={store.name}
								url={store.url}
								openHours={store.openHours}
								isOpen={store.isOpen}
								address={store.address}
								storeArea={store.storeArea}
							/>
						))}
					</ul>
				)}
			</div>
			<div className="hidden md:col-span-6 md:block">
				<Map
					stores={storeMarkers}
					className="h-[500px] max-h-[calc(100vh-10rem)]"
				/>
			</div>
			<div className="col-span-full mt-6">
				<Button variant="primary" href={storeListUrl}>
					{t('stores_all_stores_button')}
				</Button>
			</div>
		</LayoutContainer>
	);
}
NearbyStores.displayName = 'NearbyStores';
