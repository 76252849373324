/**
 * BlockContentHeading
 */

import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import Text from 'components/Text';
import { HeadingLevel } from 'lib/component-props';

interface Props {
	heading: Field<string>;
	headingLevel?: HeadingLevel;
	description: Field<string>;
	className?: string;
}

export default function BlockContentHeading({
	heading,
	headingLevel = 'h2',
	description,
	className,
}: Props) {
	return (
		<div className={className}>
			<Text as={headingLevel} field={heading} />
			<Text as="pLarge" field={description} />
		</div>
	);
}
BlockContentHeading.displayName = 'BlockContentHeading';
