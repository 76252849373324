import { ProductCard } from 'models/productCard';
import { Extends } from 'types';

import { StatusType } from './statusType';

export type WebStock = Pick<
	ProductCard,
	'isSellable' | 'isSellableOnWeb' | 'productReStockWeb'
>;

export function getProductCardWebStockStatus({
	isSellable,
	isSellableOnWeb,
	productReStockWeb,
}: WebStock): ProductCardWebStockStatusType {
	switch (true) {
		case isSellable && isSellableOnWeb:
			return 'InStock';
		case !isSellable && isSellableOnWeb && !productReStockWeb:
			return 'OutOfStock';
		case !isSellable && isSellableOnWeb && productReStockWeb:
			return 'ReStock-NotWatchable';
		case !isSellableOnWeb:
			return 'InStoreOnly';
		default:
			return 'MatchFailed';
	}
}

export type ProductCardWebStockStatusType = Extends<
	StatusType,
	| 'InStock'
	| 'OutOfStock'
	| 'ReStock-NotWatchable'
	| 'InStoreOnly'
	| 'MatchFailed'
>;
