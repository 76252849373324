import React from 'react';

import BlockOpeningHours from 'components/BlockOpeningHours';
import ComponentPlaceholder from 'components/ComponentPlaceholder';
import { LayoutContainer } from 'components/Layout';
import type { JulaComponentProps } from 'lib/component-props';
import type { OpeningHourSection } from 'models/store';
import { getEditorMargin } from 'utils/business-logic/getEditorMargin';

type Props = JulaComponentProps & {
	fields?: {
		special?: OpeningHourSection;
		sections?: OpeningHourSection[];
	};
};

export default function OpeningHours({ params, fields }: Props) {
	if (!fields) {
		return null;
	}

	if (!fields.sections?.length) {
		return (
			<ComponentPlaceholder
				componentName="OpeningHours"
				description="Requires at least one regular opening hours"
				className={getEditorMargin(params)}
			/>
		);
	}

	return (
		<LayoutContainer outerClassName={getEditorMargin(params)}>
			<BlockOpeningHours sections={fields.sections} special={fields.special} />
		</LayoutContainer>
	);
}
OpeningHours.displayName = 'OpeningHours';
