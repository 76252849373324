import React, { useState } from 'react';

import Button from 'components/Button';
import Icon from 'components/Icon';
import LoadMoreList from 'components/LoadMoreList';
import Text from 'components/Text';
import { Invoice } from 'models/api';
import { ensureFreshToken } from 'utils/fetchData';
import { formatDate } from 'utils/format';
import { empty, ignorePromiseRejection, raceTimeout } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

interface Props {
	hasLoadMoreButton?: boolean;
	invoices: Invoice[] | undefined;
	isLoadingMore?: boolean;
	onLoadMoreClick?: () => void;
}

export default function InvoiceList({
	hasLoadMoreButton = false,
	invoices,
	isLoadingMore,
	onLoadMoreClick,
}: Props) {
	const { t, tPlural } = useI18n();
	const [loadingUrl, setLoadingUrl] = useState('');

	if (!invoices || invoices.length === 0) {
		return null;
	}

	const openInvoice = async (invoiceUrl: string) => {
		await raceTimeout(ensureFreshToken(), 100, () => {
			setLoadingUrl(invoiceUrl);
		});
		setLoadingUrl('');

		window.open(invoiceUrl, '_blank');
	};

	return (
		<LoadMoreList
			buttonAlignment="center"
			buttonClassName="min-w-60"
			buttonText={t('load_more_invoices_button')}
			itemCountScreenReaderText={tPlural(
				'invoice_item_count_text',
				invoices.length,
			)}
			buttonVariant="secondary"
			hasLoadMoreButton={hasLoadMoreButton}
			isLoading={isLoadingMore}
			listTag="ul"
			onLoadMoreClick={onLoadMoreClick ?? empty.func}
		>
			{invoices.map((invoice) => (
				<li key={invoice.id}>
					<article className="mb-2 flex items-center justify-between rounded border border-grey p-4 text-sm">
						<div>
							<Text
								as="h2"
								styleAs="h6"
								// Add # as pseudo element to keep it outside text selection
								className="mb-2 before:content-['#']"
								text={invoice.id}
							/>
							<time className="mb-2 block">{formatDate(invoice.date)}</time>
							{invoice.amountInclVat && (
								<p>
									{invoice.amountInclVat.displayValue}
									{invoice.amountInclVat.displaySymbol}
								</p>
							)}
						</div>
						<Button
							variant="text"
							className="group"
							onClick={() => {
								ignorePromiseRejection(openInvoice(invoice.url));
							}}
							underline={false}
							disabled={loadingUrl !== ''}
							size="small"
						>
							{loadingUrl === invoice.url ? (
								<Icon icon="spinner" className="animate-spinFast" />
							) : (
								<Icon icon="openNewWindow" />
							)}
							<span className="underline group-hover:no-underline">
								{t('jula_pro_invoices_view_invoice_link_text')}
							</span>
						</Button>
					</article>
				</li>
			))}
		</LoadMoreList>
	);
}
InvoiceList.displayName = 'AccountJulaProInvoices_InvoiceList';
