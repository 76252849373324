/**
 * ComponentPlaceholder
 */

import React from 'react';
import clsx from 'clsx';

import Text from 'components/Text';
import { useIsEditing } from 'hooks';

interface Props {
	className?: string;
	componentName: string;
	description?: string;
	isDeprecated?: boolean;
}

export function ComponentPlaceholderBox({
	className,
	componentName,
	description,
	isDeprecated,
}: Props) {
	return (
		<div
			className={clsx(
				className,
				'flex min-h-[10rem] w-full flex-col justify-center p-8',
				isDeprecated ? 'bg-error text-white' : 'bg-greyLighter',
			)}
		>
			<Text as="h2" styleAs="h1" className="text-center">
				{componentName}
			</Text>
			{!isDeprecated && description && (
				<Text as="p" className="mt-1 text-center">
					{description}
				</Text>
			)}
			{isDeprecated && (
				<Text as="p" className="mt-1 text-center font-bold">
					This component is deprecated and should be removed from the page.
				</Text>
			)}
		</div>
	);
}
ComponentPlaceholderBox.displayName = 'ComponentPlaceholder';

/** Box to display in experience editor when required data for a component is missing. Renders nothing on the site. */
export default function ComponentPlaceholder(props: Props) {
	const isEditing = useIsEditing();

	if (!isEditing) {
		return null;
	}

	return <ComponentPlaceholderBox {...props} />;
}
ComponentPlaceholder.displayName = 'ComponentPlaceholder';
