import React, { useEffect } from 'react';
import { useSelector } from '@xstate/react';

import { useGlobalStateContext, useSelectedStore } from 'contexts';
import { useIsEditing } from 'hooks';
import type { CustomLayoutServiceData, UserInfo } from 'lib/page-props';
import {
	selectIsInitialLoading,
	selectWishlistHasVariants,
} from 'state-machines/wishlist';

import BaseLayout from './BaseLayout';

interface Props {
	layoutData: CustomLayoutServiceData;
	userInfo?: UserInfo;
}

export default function LayoutWishlist({ layoutData, userInfo }: Props) {
	const { route } = layoutData.sitecore;
	const { wishlistService } = useGlobalStateContext();
	const isEditing = useIsEditing();

	const { isLoading: isLoadingSelectedStore, selectedStore } =
		useSelectedStore();
	useEffect(() => {
		if (!isLoadingSelectedStore) {
			wishlistService.send({
				type: 'FETCH_WISHLIST',
				storeId: selectedStore?.id,
			});
		}
	}, [selectedStore, isLoadingSelectedStore, wishlistService]);
	const hasVariants = useSelector(wishlistService, selectWishlistHasVariants);

	const initialLoading = useSelector(wishlistService, selectIsInitialLoading);
	return (
		<BaseLayout
			forceNoindexNofollow
			layoutData={layoutData}
			route={route}
			userInfo={userInfo}
			renderMain={isEditing || (!initialLoading && hasVariants)}
			renderEmpty={isEditing || (!initialLoading && !hasVariants)}
			renderLoading={!isEditing && initialLoading}
		/>
	);
}
LayoutWishlist.displayName = 'LayoutWishlist';
