import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import ComponentPlaceholder from 'components/ComponentPlaceholder';
import InfoBox from 'components/InfoBox';
import Text from 'components/Text';
import { useIsEditing } from 'hooks';
import type { JulaComponentProps } from 'lib/component-props';
import { getEditorMargin } from 'utils/business-logic';

type Props = JulaComponentProps & {
	fields?: {
		heading: Field<string>;
	};
};

export default function HiddenHeading({ params, fields }: Props) {
	const isEditing = useIsEditing();

	if (!fields) {
		return null;
	}

	if (!fields.heading) {
		return (
			<ComponentPlaceholder
				className={getEditorMargin(params)}
				componentName="HiddenHeading"
				description="Requires a heading"
			/>
		);
	}

	if (isEditing) {
		return (
			<>
				<InfoBox
					icon="info"
					message="This heading is only visible in the experience editor"
				/>
				<Text as={params?.heading ?? 'h2'} field={fields.heading} />
			</>
		);
	}

	return (
		<Text
			as={params?.heading ?? 'h2'}
			field={fields.heading}
			className="sr-only"
		/>
	);
}
HiddenHeading.displayName = 'HiddenHeading';
