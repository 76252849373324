/**
 * Tabs
 */

import React, { type ReactNode, useRef } from 'react';

import { useValueChangeEffect } from 'hooks';
import { HTMLAttributes } from 'types';
import { findNewIndex } from 'utils/collection';
import { is } from 'utils/helpers';

import Tab from './Tab';
import TabList, { type NavigationDirection } from './TabList';
import TabPanel from './TabPanel';

interface TabItem {
	content: ReactNode;
	contentClassName?: string;
	id: string;
	title: string;
}

interface BaseProps<T> extends HTMLAttributes<HTMLDivElement> {
	/** ID of the currently active tab, one of the IDs in the `items` array. */
	activeTabId: string;
	/** Data for each tab. Allows falsy values to make conditional tabs easier. */
	items: (TabItem | null | undefined | false)[];
	/** Handler to set the new tab ID to be active. */
	onTabChange: (newTabId: T) => void;
	/** To style the tablist layout */
	tabListClassName?: string | undefined;
}

interface PropsWithLabel<T> extends BaseProps<T> {
	tabListLabel: string;
	tabListLabelledBy?: never;
}

interface PropsWithLabelledBy<T> extends BaseProps<T> {
	tabListLabel?: never;
	tabListLabelledBy: string;
}

type Props<T> = PropsWithLabel<T> | PropsWithLabelledBy<T>;

/**
 * Implements the tabs pattern.
 *
 * Requires the `tabListLabel` OR `tabListLabelledBy` props, which sets
 * aria-label or aria-labelledby respectively on the tablist element.
 * Use labelledby if there is a visible text that describes what the
 * tabs are used for (e.g. a nearby heading), otherwise use a label.
 *
 * https://www.w3.org/WAI/ARIA/apg/patterns/tabs/
 */
export default function Tabs<T extends string = string>({
	activeTabId,
	onTabChange,
	tabListClassName,
	tabListLabel,
	tabListLabelledBy,
	items,
	...attrs
}: Props<T>) {
	const tabsRef = useRef<Record<string, HTMLButtonElement | null>>({});
	const visibleItems = items.filter(is.truthy);
	const activeTabIndex = visibleItems.findIndex(({ id }) => id === activeTabId);

	// Move focus when changing active tab. Needed for the arrow key controls.
	useValueChangeEffect(activeTabId, () => {
		tabsRef.current[activeTabId]?.focus();
	});

	// Just render the panel content if there is a single tab.
	if (visibleItems.length < 2) {
		return (
			<div {...attrs}>
				<div className={visibleItems[0]?.contentClassName}>
					{visibleItems[0]?.content}
				</div>
			</div>
		);
	}

	function findTabId(direction: NavigationDirection) {
		const newIndex = findNewIndex(
			visibleItems,
			activeTabIndex,
			direction === 'back' ? 'prev' : 'next',
		);
		// Above length check ensures visibleItems[0] is always set here.
		return (visibleItems[newIndex]?.id || visibleItems[0]!.id) as T;
	}

	return (
		<div {...attrs}>
			<TabList
				className={tabListClassName}
				tabCount={visibleItems.length}
				activeTabIndex={activeTabIndex}
				onArrowKey={(direction) => {
					onTabChange(findTabId(direction));
				}}
				aria-label={tabListLabel}
				aria-labelledby={tabListLabelledBy}
			>
				{visibleItems.map(({ id, title }) => (
					<Tab
						key={id}
						ref={(el) => {
							tabsRef.current[id] = el;
						}}
						tabId={id}
						isSelected={id === activeTabId}
						title={title}
						// @ts-expect-error: Generic types and forwardRef is a yikes and not worth it
						// when we know that the tab id will be correct in here
						onClick={onTabChange}
					/>
				))}
			</TabList>
			{visibleItems.map(({ content, contentClassName, id }) => (
				<TabPanel
					key={id}
					className={contentClassName}
					tabId={id}
					isSelected={id === activeTabId}
				>
					{content}
				</TabPanel>
			))}
		</div>
	);
}
Tabs.displayName = 'Tabs';
