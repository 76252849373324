import React from 'react';
import clsx from 'clsx';

import ProductTag from 'components/ProductTag';
import type { ProductTag as ProductTagModel } from 'models/product';

interface Props {
	className?: string;
	tags: ProductTagModel[];
}

export default function ProductCardTags({ className, tags }: Props) {
	const productTag = tags.find(({ section }) => section === 'Product');
	const priceTag = tags.find(({ section }) => section === 'Price');

	if (!productTag && !priceTag) {
		return null;
	}

	return (
		<div className={clsx('flex flex-wrap gap-2', className)}>
			{productTag && (
				<ProductTag
					type={productTag.type}
					text={productTag.text}
					size="small"
				/>
			)}
			{priceTag && (
				<ProductTag type={priceTag.type} text={priceTag.text} size="small" />
			)}
		</div>
	);
}
ProductCardTags.displayName = 'ProductCardTags';
