import type { InputHTMLAttributes } from 'react';

import cn from 'utils/cn';

export function isLabelFloating(
	value: InputHTMLAttributes<HTMLInputElement>['value'],
	defaultValue: InputHTMLAttributes<HTMLInputElement>['defaultValue'],
) {
	return Boolean(value || value === 0 || defaultValue || defaultValue === 0);
}

export function getInputClassName({
	disabled,
	extra,
	hideOutline,
	invalid,
	valid,
}: {
	disabled?: boolean;
	extra?: string;
	hideOutline?: boolean;
	invalid: boolean;
	valid: boolean;
}): string {
	return cn(
		'peer',
		'w-full rounded-border border bg-white px-4 align-middle',
		// Prevent iOS from zooming on focus by having the text at 16px+.
		'text-[1rem]',
		hideOutline && 'focus:outline-none',

		// Border color
		'border-greyDark',
		!disabled &&
			'hover:border-greyDarker hover:ring-1 hover:ring-greyDarker focus:ring-1 focus:ring-greyDarker',
		valid && '!border-success !ring-success',
		invalid && '!border-julaRed !ring-julaRed',

		extra,
	);
}

export type RadioCheckboxColor = 'red' | 'white' | 'green';

export function getCheckboxRadioClassName({
	color,
	extra,
}: {
	color?: RadioCheckboxColor;
	extra?: string;
}): string {
	return cn(
		'size-6 shrink-0 md:mt-[0.0625rem] md:size-5',
		color === 'white' && 'accent-white hover:accent-greyLight',
		color === 'red' && 'accent-julaRed hover:accent-julaRedDark',
		color === 'green' && 'accent-success hover:accent-successDarker',
		extra,
	);
}
