import React, { type ReactNode } from 'react';

import { useI18n } from 'utils/i18n';

interface Props {
	children: ReactNode;
	required?: boolean;
}

/** Label text, with an asterisk if required. */
export default function LabelText({ children, required }: Props) {
	const { t } = useI18n();

	return required ? (
		<>
			{children}{' '}
			<span
				className="cursor-help no-underline"
				// Screen readers announce required/aria-required attributes, the
				// asterisk should not be needed.
				aria-hidden="true"
				title={t('FieldIsRequired')}
			>
				*
			</span>
		</>
	) : (
		children
	);
}
LabelText.displayName = 'LabelText';
