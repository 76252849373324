import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import BlockMedia from 'components/BlockMedia';
import ComponentPlaceholder from 'components/ComponentPlaceholder';
import { LayoutContainer } from 'components/Layout';
import type { ColorField, JulaComponentProps } from 'lib/component-props';
import type { DigizuiteAssetList } from 'models/asset';
import type { ProductCard } from 'models/productCard';
import { getEditorMargin } from 'utils/business-logic';
import { getAsset, is } from 'utils/helpers';

interface Item {
	landscapeImage: DigizuiteAssetList;
	portraitImage: DigizuiteAssetList;
	portraitVideo?: DigizuiteAssetList;
	preventAutoplay?: Field<boolean>;
	products: ProductCard[];
	video: DigizuiteAssetList;
}

type Props = JulaComponentProps & {
	fields?: {
		backgroundColor?: ColorField;
		datasourceName?: string;
		items?: Item[];
	};
};

export default function MediaDoubleWide({ fields, params }: Props) {
	if (!fields) {
		return null;
	}

	const items = fields.items?.filter((item) => {
		const hasMobileContent = item.portraitImage || item.portraitVideo;
		const hasDesktopContent = item.landscapeImage || item.video;
		return hasMobileContent && hasDesktopContent;
	});

	if (!is.arrayWithLength(items) || items.length < 2) {
		return (
			<ComponentPlaceholder
				componentName="MediaDoubleWide"
				description="Requires two items with conent (image or video) for both mobile and desktop to function"
				className={getEditorMargin(params)}
			/>
		);
	}

	return (
		<LayoutContainer
			id={params?.anchor}
			outerClassName={getEditorMargin(params)}
			withGrid
			backgroundColor={fields.backgroundColor?.fields?.colorHex?.value}
		>
			{items.map(
				({
					video,
					portraitVideo,
					portraitImage,
					landscapeImage,
					products,
					preventAutoplay,
				}) => {
					const landscapeImg = getAsset('Templated 2:1', landscapeImage);
					const portraitImg = getAsset('Templated 3:4', portraitImage);
					const videoAsset = getAsset('Source Copy', video);
					const portraitVideoAsset = getAsset('Source Copy', portraitVideo);

					return (
						<>
							<BlockMedia
								className="col-span-6 max-md:hidden"
								variant="landscapeWide"
								imgSrc={landscapeImg?.src}
								imgAlt={landscapeImg?.alt}
								preventAutoplay={preventAutoplay?.value}
								video={videoAsset}
								products={products}
								productsDisplayType="shopByMedia"
								gtmItemListId="block"
								gtmItemListName={`Block: ${fields.datasourceName ?? 'MediaDoubleWideUnknown'}`}
							/>
							<BlockMedia
								className="col-span-2 md:hidden"
								variant="portrait"
								imgSrc={portraitImg?.src}
								imgAlt={portraitImg?.alt}
								video={portraitVideoAsset}
								preventAutoplay={preventAutoplay?.value}
								products={products}
								productsDisplayType="shopByMedia"
								gtmItemListId="block"
								gtmItemListName={`Block: ${fields.datasourceName ?? 'MediaDoubleWideUnknown'}`}
							/>
						</>
					);
				},
			)}
		</LayoutContainer>
	);
}
MediaDoubleWide.displayName = 'MediaDoubleWide';
