import React from 'react';
import { Field } from 'react-final-form';

import {
	Checkbox as StyledCheckbox,
	CheckboxProps as StyledCheckboxProps,
} from 'components/FormUi';

interface Props extends StyledCheckboxProps {
	name: string;
}

export default function Checkbox({ name, ...rest }: Props) {
	return (
		<Field
			name={name}
			type="checkbox"
			render={({ input }) => <StyledCheckbox {...input} {...rest} />}
		/>
	);
}
Checkbox.displayName = 'FinalForm_Checkbox';
