/**
 * BonusChequeQrCode
 */

import React from 'react';

import ChunkedText from 'components/ChunkedText';
import Img from 'components/Img';
import JulaLogo from 'components/JulaLogo';
import Text from 'components/Text';
import { formatDate } from 'utils/format';
import { useI18n } from 'utils/i18n';

interface Props {
	/** Check amount, with currency symbol */
	amount: string;

	/** Check owner's full name */
	customerFullName?: string;

	/** Customer number */
	customerNo: string;

	/** The check number */
	number: string;

	/** QR code URL */
	qrCodeSrc?: string;

	/** If the check number should be truncated to only show the last digits */
	truncateNumber?: boolean;

	/** Last valid date, ISO format */
	validThru: string;
}

/** Bonus check information, optionally with a QR code. */
export default function BonusChequeQrCode({
	amount,
	customerFullName,
	customerNo,
	number,
	qrCodeSrc,
	truncateNumber = true,
	validThru,
}: Props) {
	const { t } = useI18n();
	return (
		<div className="max-w-[400px] rounded-2xl bg-julaRedDark px-6 py-8 text-white">
			{qrCodeSrc && (
				<div className="mb-8 flex justify-center">
					<Img src={qrCodeSrc} />
				</div>
			)}

			<div className="flex justify-between">
				<div>
					<Text as="pSmall">{t('checkout_bonus_check_balance_label')}</Text>
					<Text styleAs="h2" as="p">
						{amount}
					</Text>
				</div>

				<Text className="ml-4" as="pSmall">
					{t('checkout_bonus_check_valid_thru_label')} {formatDate(validThru)}
				</Text>
			</div>

			<div className="mt-4 flex items-end justify-between">
				<div>
					<Text className="font-bold" as="p">
						{customerFullName}
					</Text>
					<Text as="pSmall">
						{t('checkout_bonus_check_id_label')}{' '}
						{truncateNumber ? (
							`…${number.slice(-3)}`
						) : (
							<ChunkedText text={number} chunkSize={4} />
						)}
					</Text>
					<Text as="pSmall">
						{t('account_details_customer_number_text')}
						{': '}
						<ChunkedText text={customerNo} chunkSize={3} />
					</Text>
				</div>

				<div className="ml-4">
					<JulaLogo className="h-6 w-auto" />
				</div>
			</div>
		</div>
	);
}
BonusChequeQrCode.displayName = 'BonusChequeQrCode';
