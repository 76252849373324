import React from 'react';

import { LayoutContainer } from 'components/Layout';
import type { FooterLinkField, IconLinkField } from 'models/sitecore';
import { useI18n } from 'utils/i18n';

import { flattenFooterLinks } from './helpers';
import PageFooterLinks from './PageFooterLinks';
import PageFooterSocial from './PageFooterSocial';

interface Props {
	links: FooterLinkField[];
	socialMediaLinks: IconLinkField[];
}

/** Secondary area with socials and other links. */
export default function PageFooterSecondary({
	links,
	socialMediaLinks,
}: Props) {
	const { t } = useI18n();
	const linkData = flattenFooterLinks(links);

	return (
		<LayoutContainer
			gutterType="padding"
			outerClassName="bg-greyDarker py-1 min-h-[3.5rem] flex items-center"
			className="w-full md:flex md:items-center"
		>
			<div className="flex shrink-0 items-center justify-between max-md:min-h-[3rem]">
				<p>
					© {new Date().getFullYear()} {t('page_footer_company_name')}
				</p>
				<PageFooterSocial links={socialMediaLinks} className="md:hidden" />
			</div>
			<PageFooterLinks
				links={linkData}
				className="flex grow flex-wrap gap-x-6 gap-y-2 pb-3 md:justify-center md:px-8 md:py-2"
			/>
			<PageFooterSocial links={socialMediaLinks} className="max-md:hidden" />
		</LayoutContainer>
	);
}
PageFooterSecondary.displayName = 'PageFooterSecondary';
