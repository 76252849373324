import type { CreateJulaClubCustomerMachineState } from 'state-machines/createJulaClubCustomer';
import type { CreditMachineState } from 'state-machines/credit';

export const selectIsOpen = (state: CreditMachineState) =>
	state.context?.signFrameOpen;

export const selectRequestCreditButtonState = (state: CreditMachineState) =>
	state.context.requestCreditButtonState;

export const selectIframeSrc = (state: CreditMachineState) =>
	state.context.bankIdSignUrl;

export const selectCreditRequestDone = (state: CreditMachineState) =>
	state.hasTag('doneCreatingCredit');

export const selectRequestingCredit = (state: CreditMachineState) =>
	state.hasTag('loading');

export const selectCreditNotCreatedState = (state: CreditMachineState) =>
	state.matches('creditNotCreated');

export const selectSigningCanceledState = (state: CreditMachineState) =>
	state.matches('signingCanceled');

export const selectErrorOpeningSignWindow = (
	state: CreateJulaClubCustomerMachineState,
) => state.context.errorOpeningSignWindow;
