import React, { InputHTMLAttributes } from 'react';
import clsx from 'clsx';

import FloatingLabel from './FloatingLabel';
import { getInputClassName, isLabelFloating } from './helpers';
import InputInfo from './InputInfo';

// React has HTMLInputTypeAttribute but several of those (like 'button' and
// 'checkbox') are not relevant here since this is specifically a text field.
export type InputType =
	| 'date'
	| 'datetime-local'
	| 'email'
	| 'month'
	| 'number'
	| 'password'
	| 'search'
	| 'tel'
	| 'text'
	| 'time'
	| 'url'
	| 'week';

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
	type?: InputType;
	errorMessage?: string | string[];
	helpText?: string;
	hiddenLabel?: boolean;
	id: string;
	inputClassName?: string;
	invalid?: boolean;
	label: string;
	valid?: boolean;
}

export default function Input({
	className,
	defaultValue,
	disabled,
	errorMessage,
	helpText,
	hiddenLabel = false,
	id,
	inputClassName,
	inputMode,
	invalid = false,
	label,
	required,
	type = 'text',
	valid = false,
	value,
	...rest
}: Props) {
	const errorMessageId = `${id}-error`;
	const helpTextId = `${id}-help`;

	let inputType = type;
	let mode = inputMode;
	let pattern: string | undefined;
	if (type === 'number') {
		inputType = 'text';
		pattern = '\\d*';
		mode = 'numeric';
	}

	return (
		<div className={className}>
			<div className={clsx('relative', disabled && 'opacity-50')}>
				<input
					{...rest}
					className={getInputClassName({
						disabled,
						extra: clsx('h-14 py-0', inputClassName),
						hideOutline: true,
						invalid,
						valid,
					})}
					aria-describedby={[errorMessageId, helpTextId].join(' ')}
					// support isn't good enough for now so stick with above solution
					// aria-errormessage={errorMessageId}
					aria-invalid={invalid}
					defaultValue={defaultValue}
					disabled={disabled}
					id={id}
					inputMode={mode}
					pattern={pattern}
					required={required}
					type={inputType}
					value={value}
				/>
				<FloatingLabel
					htmlFor={id}
					invalid={invalid}
					valid={valid}
					isFloating={isLabelFloating(value, defaultValue)}
					isHidden={hiddenLabel}
					required={required}
				>
					{label}
				</FloatingLabel>
			</div>
			<InputInfo
				errorMessage={errorMessage}
				errorMessageId={errorMessageId}
				helpText={helpText}
				helpTextId={helpTextId}
				invalid={invalid}
			/>
		</div>
	);
}
Input.displayName = 'FormUI_Input';
