/**
 * ProductList
 */

import React from 'react';

import Placeholder from 'components/Placeholder';
import ProductListComponent from 'components/ProductList';
import Text from 'components/Text';
import { withRequiredProps } from 'hoc';
import { useIsEditing } from 'hooks';
import type { JulaComponentProps } from 'lib/component-props';
import type { ProductList as ProductListModel } from 'models/sitecore';

type Props = JulaComponentProps & {
	fields: ProductListModel;
};

function ProductList({ fields, params, rendering }: Props) {
	const { brand, category, datasourceName, heading } = fields;
	const isEditing = useIsEditing();
	const categoryName = category?.title?.value;

	return (
		<ProductListComponent
			// This is deliberately a fallback stack rather than a template string
			// with every value. Having them all at the same time can result in the
			// list resetting when certain facet items are selected.
			key={category?.id.value || brand?.id.value || heading?.value}
			editorMargin={params?.margin}
			id={params?.anchor}
			fields={fields}
			gtmItemListId={categoryName || brand ? 'category' : 'block'}
			gtmItemListName={
				categoryName
					? `Category: ${categoryName}`
					: brand
						? `Category: ${brand.title.value}`
						: `Block: ${datasourceName}`
			}
			headingLevel={params?.heading}
			isEditing={isEditing}
			placeholderComponentName="ProductList"
			layoutArea1Content={
				<>
					{rendering.placeholders && (
						<Placeholder
							name="breadcrumbs"
							rendering={rendering}
							render={(components) => <div className="mb-8">{components}</div>}
						/>
					)}
					<Text
						as="h1"
						className="text-left"
						text={heading?.value || categoryName}
					/>
				</>
			}
		/>
	);
}
ProductList.displayName = 'ProductList_JSS';

export default withRequiredProps(ProductList, 'fields');
