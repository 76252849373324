import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import Button from 'components/Button';
import ComponentPlaceholder from 'components/ComponentPlaceholder';
import RichText from 'components/RichText';
import { Skeleton, SkeletonItem } from 'components/Skeleton';
import Status from 'components/Status';
import { useGlobalStateContext } from 'contexts';
import { useChat } from 'hooks';
import type { JulaComponentProps } from 'lib/component-props';
import { getEditorMargin } from 'utils/business-logic';

type Props = JulaComponentProps & {
	fields?: {
		chatBusyMessage: Field<string>;
		chatClosedMessage: Field<string>;
		heading: Field<string>;
	};
};

export default function ChatButton({ fields, params }: Props) {
	const { globalPopoverService } = useGlobalStateContext();
	const {
		chatAvailabilityIsLoading,
		chatIsAvailable,
		chatIsOpen,
		setHasOpenedMessenger,
		zE,
		zendeskIsLoading,
	} = useChat(true);
	const isLoading = chatAvailabilityIsLoading || zendeskIsLoading;

	if (!fields) {
		return null;
	}

	const heading = fields.heading?.value;
	const chatBusyMessage = fields.chatBusyMessage?.value;
	const chatClosedMessage = fields.chatClosedMessage?.value;

	if (!heading || !chatBusyMessage || !chatClosedMessage) {
		return (
			<ComponentPlaceholder
				className={getEditorMargin(params)}
				componentName="ChatButton"
				description="Requires a heading"
			/>
		);
	}

	return (
		<div className={getEditorMargin(params)}>
			{isLoading && (
				<Skeleton>
					<SkeletonItem height="3rem" />
				</Skeleton>
			)}
			{!isLoading && (
				<>
					{chatIsOpen && chatIsAvailable && (
						<Button
							variant="cta"
							displayWidth="full"
							onClick={() => {
								if (zE) {
									globalPopoverService.send('CLOSE');
									setHasOpenedMessenger();
									setTimeout(() => {
										zE('messenger', 'open');
									}, 100);
								}
							}}
						>
							{heading}
						</Button>
					)}
					{chatIsOpen && !chatIsAvailable && (
						<Status color="red" text={<RichText html={chatBusyMessage} />} />
					)}
					{!chatIsOpen && (
						<Status color="red" text={<RichText html={chatClosedMessage} />} />
					)}
				</>
			)}
		</div>
	);
}
ChatButton.displayName = 'ChatButton';
