import React, {
	type ButtonHTMLAttributes,
	type KeyboardEventHandler,
} from 'react';

import Icon from 'components/Icon';
import { useDisclosureWidgetClose, useForwardedRef } from 'hooks';
import type { InspirationLink, MainLinkFields } from 'models/sitecore';
import { slugify } from 'utils/string';

import PageHeaderDropdown from './PageHeaderDropdown';
import type { DropdownLinkClickHandler } from './PageHeaderLinkListItem';

export type CloseHandler = (linkText: string) => void;
export type OpenHandler = (linkText: string) => void;

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
	className?: string;
	dropdownLinks: MainLinkFields[];
	inspirationLink: InspirationLink | undefined;
	isLargeScreen: boolean | undefined;
	isOpen?: boolean;
	onClose: CloseHandler;
	onLargeScreenMenuLastLinkTab: KeyboardEventHandler<HTMLAnchorElement>;
	onLinkClick: DropdownLinkClickHandler;
	onOpen: OpenHandler;
	onToggledMenuLastLinkTab: KeyboardEventHandler<HTMLAnchorElement>;
	selectedDropdownLinkId: string;
	text: string;
}

/** A top level main menu item that has a dropdown menu. */
const PageHeaderDropdownToggle = React.forwardRef<HTMLButtonElement, Props>(
	(
		{
			className,
			dropdownLinks,
			inspirationLink,
			isLargeScreen,
			isOpen = false,
			onClose,
			onLargeScreenMenuLastLinkTab,
			onLinkClick,
			onOpen,
			onToggledMenuLastLinkTab,
			selectedDropdownLinkId,
			text,
			...props
		},
		ref,
	) => {
		const triggerRef = useForwardedRef<HTMLButtonElement>(ref);
		const dropdownId = `header-dropdown-${slugify(text)}`;

		const handleClose = () => {
			onClose(text);
		};

		const {
			onTriggerClick,
			onTriggerMouseDown,
			widgetRef: dropdownRef,
		} = useDisclosureWidgetClose<HTMLButtonElement, HTMLDivElement>(
			// Not active when the menu is in its small screen toggled form.
			Boolean(isOpen && isLargeScreen),
			handleClose,
			{ triggerRef },
		);

		const handleTriggerClick = () => {
			onTriggerClick();
			if (isOpen) {
				onClose(text);
			} else {
				onOpen(text);
			}
		};

		return (
			<>
				<button
					ref={triggerRef}
					type="button"
					aria-controls={dropdownId}
					aria-expanded={isOpen}
					className={className}
					onClick={handleTriggerClick}
					onMouseDown={onTriggerMouseDown}
					{...props}
				>
					{text}
					<Icon
						icon="arrow"
						className="ml-2 transition-transform"
						direction={
							// Default to up/down when the screen size isn't yet determined
							// (undefined). The arrow is visible on page load on large screens
							// while it's hidden behind a menu toggle on small screens. Having
							// it mobile first (right by default) means it will point right
							// and then rotate down on large screens when loading.
							isLargeScreen === false ? 'right' : isOpen ? 'up' : 'down'
						}
					/>
				</button>
				<PageHeaderDropdown
					ref={dropdownRef}
					id={dropdownId}
					inspirationLink={inspirationLink}
					isLargeScreen={isLargeScreen}
					isOpen={isOpen}
					links={dropdownLinks}
					onLinkClick={onLinkClick}
					onLargeScreenMenuLastLinkTab={onLargeScreenMenuLastLinkTab}
					onToggledMenuLastLinkTab={onToggledMenuLastLinkTab}
					selectedLinkId={selectedDropdownLinkId}
					title={text}
				/>
			</>
		);
	},
);
PageHeaderDropdownToggle.displayName = 'PageHeaderDropdownToggle';

export default PageHeaderDropdownToggle;
