/**
 * Confirmation
 */

import React from 'react';

import Link from 'components/Link';
import Text from 'components/Text';
import { DiscountCode, GiftCard, Summary, Variant } from 'models/api';
import { getProductImageFormat } from 'utils/business-logic';
import { is } from 'utils/helpers';

import PriceSummaries from './PriceSummaries';
import SummaryProduct from './SummaryProduct';

interface Props {
	/** All bonus discount codes added to the cart, used by the summary component */
	bonusDiscounts: DiscountCode[] | undefined;

	/** All gift cards added to the cart, used by the summary component */
	giftCards: GiftCard[] | undefined;

	/** Heading text of the confirmation summary component */
	heading?: string;
	/** The heading of the link text in the header */
	headingLinkText?: string;
	/** Total savings, if any, used by the summary component */
	savingTotalSumSummary: Summary | undefined;
	/** Summaries for the cart, used by the summary component */
	summaries: Summary[] | undefined;
	/** The cart variants */
	variants: Variant[] | undefined;
}

/** The confirmation summary component used in the cart page. */
export default function ConfirmationSummary({
	bonusDiscounts,
	giftCards,
	heading,
	headingLinkText,
	savingTotalSumSummary,
	summaries,
	variants,
}: Props) {
	return (
		<div className="rounded border-greyLight md:border md:px-6 md:py-8">
			<div className="flex items-baseline justify-between">
				<Text as="h3" styleAs="h5" className="font-bold text-greyDarker">
					{heading}
				</Text>
				{headingLinkText && (
					<Link href="/varukorg">
						<Text
							as="pSmall"
							className="text-greyDarker underline hover:no-underline"
						>
							{headingLinkText}
						</Text>
					</Link>
				)}
			</div>

			{variants?.map((variant) => (
				<SummaryProduct
					key={variant.id}
					link={variant.url}
					heading={variant.title}
					placements={
						variant.customization
							? variant.customization.placements
									?.map((placement) => placement.title)
									.filter(is.defined)
							: undefined
					}
					imageSrc={
						getProductImageFormat(
							'Templated square transparent',
							variant.listImageFormats,
						)?.location || '/assets/images/placeholder-image.png'
					}
					articleNumber={variant.id}
					rowSum={variant.rowSum}
					amount={variant.qty}
					discount={
						is.defined(variant.price.saveIncVat)
							? variant.price.saveIncVat.value > 0
							: false
					}
				/>
			))}
			<PriceSummaries
				className="mt-4"
				summaries={summaries}
				bonusDiscounts={bonusDiscounts}
				savingTotalSumSummary={savingTotalSumSummary}
				giftCards={giftCards}
				summaryLargeTitle
			/>
		</div>
	);
}
ConfirmationSummary.displayName = 'ConfirmationSummary';
