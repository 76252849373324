import React from 'react';
import type { TextField } from '@sitecore-jss/sitecore-jss-react';

import { LayoutContainer } from 'components/Layout';
import Text from 'components/Text';
import type { JulaComponentProps } from 'lib/component-props';
import { getEditorMargin } from 'utils/business-logic';

type Props = JulaComponentProps & {
	fields?: {
		author: TextField;
		quote: TextField;
	};
};

export default function Quote({ fields, params }: Props) {
	if (!fields) {
		return null;
	}

	return (
		<LayoutContainer
			id={params?.anchor}
			outerClassName={getEditorMargin(params)}
			className="grid grid-cols-4 gap-4 md:grid-cols-12 md:gap-6"
		>
			<div className="col-span-4 md:col-span-6 md:col-start-4">
				<blockquote>
					<Text
						as="p"
						styleAs="h1"
						field={fields?.quote}
						className="mb-2 text-left"
					/>
				</blockquote>

				<Text as="p" className="text-left font-bold" field={fields?.author} />
			</div>
		</LayoutContainer>
	);
}
Quote.displayName = 'Quote';
