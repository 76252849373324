import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import BlogDetailsBlock from 'components/BlogDetails';
import ComponentPlaceholder from 'components/ComponentPlaceholder';
import { LayoutContainer } from 'components/Layout';
import type { JulaComponentProps } from 'lib/component-props';
import { DigizuiteAsset, DigizuiteAssetList } from 'models/asset';
import { getEditorMargin } from 'utils/business-logic';
import { useI18n } from 'utils/i18n';

type Props = JulaComponentProps & {
	fields?: {
		blogRootUrl: string;
		content: Field<string>;
		heading: Field<string>;
		images: {
			value: {
				assets: DigizuiteAsset[][];
			};
		};
		landscapeImage: DigizuiteAssetList;
		portraitImage: DigizuiteAssetList;
		published: Field<string>;
		squareImage: DigizuiteAssetList;
	};
};

export default function BlogDetails({ params, fields }: Props) {
	const { t } = useI18n();

	if (!fields) {
		return null;
	}

	// if (!fields.heading) {
	// 	return (
	// 		<ComponentPlaceholder
	// 			className={getEditorMargin(params)}
	// 			componentName="BlogDetails"
	// 			description="Requires a heading"
	// 		/>
	// 	);
	// }

	return (
		<LayoutContainer withGrid className={getEditorMargin(params)}>
			<BlogDetailsBlock
				viewType="details"
				className="col-span-4 flex flex-col md:col-span-6 md:col-start-4"
				content={fields.content.value}
				heading={fields.heading.value}
				images={fields.images.value.assets}
				published={fields.published?.value}
				overviewLinkHref={fields.blogRootUrl}
				overviewLinkText={t('blog_details_return_to_overview_text')}
			/>
		</LayoutContainer>
	);
}
BlogDetails.displayName = 'BlogDetails';
