import { stringify } from 'query-string';

import type { PendingCustomerTicket, ResolvedCustomerTicket } from 'models/api';
import { getPostSigningRedirectUrl } from 'pages/api/sign/helpers';
import fetchData, { API_URL, type ExtendedResponse } from 'utils/fetchData';

import type {
	CustomerInfo,
	JulaProAuthRequestParams,
	JulaProOrganizationSearchParams,
	JulaProOrganizationSelectParams,
	JulaProRegisterCustomerParams,
	Organization,
	Worksite,
} from './createJulaProCustomer.types';

export const requestJulaProApiAuth = (
	params: JulaProAuthRequestParams,
): Promise<CustomerInfo> => {
	const apiPath = `${API_URL}Customer/julapro/auth`;
	const body = JSON.stringify(params);

	return new Promise<CustomerInfo>((resolve, reject) => {
		fetchData<ExtendedResponse<CustomerInfo>>(
			apiPath,
			{ method: 'POST', body },
			true,
		)
			.then((data) => {
				if (data) {
					resolve(data.responseData);
				} else {
					reject();
				}
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const requestJulaProApiOrganizationSearch = (
	params: JulaProOrganizationSearchParams,
): Promise<Organization[]> => {
	const apiPath = `${API_URL}Customer/julapro/search/company?${stringify(
		params,
	)}`;

	return new Promise<Organization[]>((resolve, reject) => {
		fetchData<ExtendedResponse<Organization[]>>(
			apiPath,
			{ method: 'GET' },
			true,
		)
			.then((data) => {
				if (data) {
					resolve(data.responseData);
				} else {
					reject();
				}
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const requestJulaProApiOrganizationSelect = (
	params: JulaProOrganizationSelectParams,
): Promise<Worksite> => {
	const { worksiteId, ...otherParams } = params;
	const apiPath = `${API_URL}Customer/julapro/worksite/${worksiteId}?${stringify(
		otherParams,
	)}`;

	return new Promise<Worksite>((resolve, reject) => {
		fetchData<ExtendedResponse<Worksite>>(apiPath, { method: 'GET' }, true)
			.then((data) => {
				if (data) {
					resolve(data.responseData);
				} else {
					reject();
				}
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const requestJulaProApiRegisterCustomer = ({
	customerData,
}: JulaProRegisterCustomerParams): Promise<PendingCustomerTicket> => {
	const apiPath = `${API_URL}Customer/julapro?termsAndConditionsAccepted=${customerData.termsAndConditionsAccepted}`;
	const postSigningRedirectUrl = getPostSigningRedirectUrl();
	const body = JSON.stringify({ postSigningRedirectUrl, ...customerData });

	return new Promise<PendingCustomerTicket>((resolve, reject) => {
		fetchData<ExtendedResponse<PendingCustomerTicket>>(
			apiPath,
			{ method: 'POST', body },
			true,
		)
			.then((data) => {
				if (data) {
					resolve(data.responseData);
				} else {
					reject();
				}
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const requestPollCustomerRegistrationStatus = () => {
	const apiPath = `${API_URL}Ticket/Customer`;
	return new Promise<PendingCustomerTicket | ResolvedCustomerTicket>(
		(resolve, reject) => {
			fetchData<
				ExtendedResponse<PendingCustomerTicket | ResolvedCustomerTicket>
			>(apiPath, { method: 'GET' }, true)
				.then((data) => {
					if (data) {
						resolve(data.responseData);
					} else {
						reject();
					}
				})
				.catch((error) => reject(error));
		},
	);
};
export const requestCancelSigning = () => {
	const apiPath = `${API_URL}Ticket/Customer/cancel`;
	return new Promise<PendingCustomerTicket | ResolvedCustomerTicket>(
		(resolve, reject) => {
			fetchData<
				ExtendedResponse<PendingCustomerTicket | ResolvedCustomerTicket>
			>(apiPath, { method: 'GET' }, true)
				.then((data) => {
					if (data) {
						resolve(data.responseData);
					} else {
						reject();
					}
				})
				.catch((error) => reject(error));
		},
	);
};

export const bankIdSignInInNewTab = async (iframeSrc?: string) =>
	new Promise<Window>((resolve, reject) => {
		if (!iframeSrc) {
			reject();
			return;
		}

		const authWindow = window.open(iframeSrc);
		if (authWindow) {
			resolve(authWindow);
		} else {
			reject();
		}
	});

export const signInNewCustomer = async () =>
	new Promise<any>(async (resolve, reject) => {
		try {
			const response = await fetch(
				'/api/signin/session/?forceRefresh=true',
				{},
			);
			resolve(response);
		} catch (error) {
			reject(error);
		}
	});
