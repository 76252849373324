import React, { useState } from 'react';

import { Select, SelectOption } from 'components/FormUi';
import LoadMoreList from 'components/LoadMoreList';
import Meter from 'components/Meter';
import ScreenReaderAnnouncementText from 'components/ScreenReaderAnnouncementText';
import { Skeleton, SkeletonItem } from 'components/Skeleton';
import Text from 'components/Text';
import { useProductListGTMEvents, useValueChangeEffect } from 'hooks';
import { range } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

import ProductArchiveSearchForm from './ProductArchiveSearchForm';
import ProductArchiveSearchItem from './ProductArchiveSearchItem';

export const PRODUCT_ARCHIVE_SEARCH_LIST_ID = 'product_archive_search_list';
export const EMPTY_SELECT_VALUE = '';

interface ListItem {
	brand: string;
	id: string;
	imgSrc: string;
	title: string;
}

export interface Props {
	brandFacetOption: string;
	brandFacetOptions: SelectOption[];
	brandFacetSelectLabel: string;
	categoryFacetOption: string;
	categoryFacetOptions: SelectOption[];
	categoryFacetSelectLabel: string;
	categoryOption: string | undefined;
	categoryOptions: SelectOption[];
	categorySelectDisabled: boolean;
	categorySelectLabel: string;
	collectionOption: string | undefined;
	collectionOptions: SelectOption[];
	collectionSelectLabel: string;
	hasNextPage: boolean;
	isLoadingMoreProducts: boolean;
	isLoadingProducts: boolean;
	onBrandFacetOptionChange: (value: string) => void;
	onCategoryFacetOptionChange: (value: string) => void;
	onCategoryOptionChange: (value: string) => void;
	onCollectionOptionChange: (value: string) => void;
	onLoadMoreProductsClick: () => void;
	onProductClick: (id: string) => void;
	onSearchSubmit: ({ search }: { search: string }) => void;
	onSortingOptionChange: (value: string) => void;
	onSubCategoryOptionChange: (value: string) => void;
	products: ListItem[] | undefined;
	query: string;
	selectedCategoryName?: string;
	sortingOption: string;
	sortingOptions: SelectOption[];
	subCategoryOption: string | undefined;
	subCategoryOptions: SelectOption[];
	subCategorySelectDisabled: boolean;
	subCategorySelectLabel: string;
	toResultsLinkVisible: boolean;
	totalProductsCount: number | undefined;
	visibleProductsCount: number | undefined;
}

export default function ProductArchiveSearch({
	brandFacetOption,
	brandFacetOptions,
	brandFacetSelectLabel,
	categoryFacetOption,
	categoryFacetOptions,
	categoryFacetSelectLabel,
	categoryOption,
	categoryOptions,
	categorySelectDisabled,
	categorySelectLabel,
	collectionOption,
	collectionOptions,
	collectionSelectLabel,
	hasNextPage,
	isLoadingMoreProducts,
	isLoadingProducts,
	onBrandFacetOptionChange,
	onCategoryFacetOptionChange,
	onCategoryOptionChange,
	onCollectionOptionChange,
	onLoadMoreProductsClick,
	onProductClick,
	onSearchSubmit,
	onSortingOptionChange,
	onSubCategoryOptionChange,
	products,
	query,
	selectedCategoryName,
	sortingOption,
	sortingOptions,
	subCategoryOption,
	subCategoryOptions,
	subCategorySelectDisabled,
	subCategorySelectLabel,
	toResultsLinkVisible,
	totalProductsCount,
	visibleProductsCount,
}: Props) {
	const { t } = useI18n();
	const productCountText = `${totalProductsCount} ${t(
		'product_list_products_label',
	)}`;
	const hasQuery = Boolean(query);
	const hasSearch = hasQuery || subCategoryOption !== EMPTY_SELECT_VALUE;
	const isEmptySearch = hasSearch && products?.length === 0;

	const { sendViewItemListEvent, sendSelectItemEvent } =
		useProductListGTMEvents('product_archive', 'Product archive results');

	// Send view event and set page size when a new set of products have loaded.
	const [pageSize, setPageSize] = useState(products?.length ?? 0);
	useValueChangeEffect(isLoadingProducts, () => {
		if (!isLoadingProducts && hasSearch && products?.length) {
			sendViewItemListEvent(products, products.length);
			setPageSize(products.length);
		}
	});

	return (
		<div>
			<ProductArchiveSearchForm
				categoryOption={categoryOption}
				categoryOptions={categoryOptions}
				categorySelectDisabled={categorySelectDisabled}
				collectionOption={collectionOption}
				collectionOptions={collectionOptions}
				onCategoryOptionChange={onCategoryOptionChange}
				onCollectionOptionChange={onCollectionOptionChange}
				onSubCategoryOptionChange={onSubCategoryOptionChange}
				subCategoryOption={subCategoryOption}
				subCategoryOptions={subCategoryOptions}
				subCategorySelectDisabled={subCategorySelectDisabled}
				categorySelectLabel={categorySelectLabel}
				collectionSelectLabel={collectionSelectLabel}
				subCategorySelectLabel={subCategorySelectLabel}
				onSparePartSearchSubmit={onSearchSubmit}
				toResultsLinkVisible={toResultsLinkVisible}
			/>
			{hasSearch && (
				<>
					<div className="mt-14 ">
						{!isLoadingProducts && (
							<div className="flex max-md:flex-col max-md:gap-y-4 md:gap-x-4">
								<Select
									id="category-facet"
									label={categoryFacetSelectLabel}
									hiddenLabel
									className="w-48 shrink-0 font-bold"
									options={categoryFacetOptions}
									value={categoryFacetOption}
									onChange={(e) => onCategoryFacetOptionChange(e.target.value)}
								/>
								<Select
									id="brand-facet"
									hiddenLabel
									label={brandFacetSelectLabel}
									className="w-48 shrink-0 font-bold"
									value={brandFacetOption}
									options={brandFacetOptions}
									onChange={(e) => onBrandFacetOptionChange(e.target.value)}
								/>
								<div className="flex items-center gap-2">
									{t('product_archive_sorting_label')}
									<Select
										id="sorting-select"
										hiddenLabel
										label={t('product_list_sorting_facet_heading')}
										className="w-48 shrink-0 font-bold"
										value={sortingOption}
										options={sortingOptions}
										onChange={(e) => onSortingOptionChange(e.target.value)}
									/>
								</div>
							</div>
						)}
						{isLoadingProducts && (
							<Skeleton>
								<div className="flex max-md:flex-col max-md:gap-y-4 md:gap-x-4">
									<SkeletonItem height="3.5rem" width="12rem" />
									<SkeletonItem height="3.5rem" width="12rem" />
									<SkeletonItem height="3.5rem" width="12rem" />
								</div>
							</Skeleton>
						)}
					</div>
					<Text as="h2" className="mt-14">
						<>
							{t(
								hasQuery ? 'search_query_label' : 'products_in_category_label',
							)}{' '}
							<span className="[quotes:auto] before:content-[open-quote] after:content-[close-quote]">
								{hasQuery ? query : selectedCategoryName}
							</span>
						</>
					</Text>
					<ScreenReaderAnnouncementText
						as="p"
						atomic
						text={productCountText}
						className="mt-8"
					/>
					<div id={PRODUCT_ARCHIVE_SEARCH_LIST_ID}>
						{!isEmptySearch && (
							<div className="mt-14 grid-cols-12 border-b-1 border-greyDark pb-4 font-bold max-md:hidden md:grid">
								<span className="col-span-6">
									{t('product_archive_search_list_product_name_heading')}
								</span>
								<span className="col-span-2">
									{t('product_archive_search_list_brand_heading')}
								</span>
								<span className="col-span-4 hidden">
									{t('product_archive_search_list_year_of_sale_heading')}
								</span>
							</div>
						)}
						{isLoadingProducts && (
							<div className="flex flex-col divide-y-1 divide-greyDark">
								{range(4).map((index) => (
									<Skeleton key={index} className="grid-cols-12 py-4 md:grid">
										<div className="col-span-6 flex items-center">
											<SkeletonItem
												height="4rem"
												width="4rem"
												className="mr-6"
											/>
											<div>
												<SkeletonItem height="1rem" width="13rem" />
												<SkeletonItem
													height="1rem"
													width="5rem"
													className="mt-2"
												/>
												<SkeletonItem
													height="1.5rem"
													width="7.5rem"
													className="mt-2 md:hidden"
												/>
											</div>
										</div>
										<SkeletonItem
											height="1.5rem"
											width="7.5rem"
											className="col-span-2 mt-2 max-md:hidden"
										/>
									</Skeleton>
								))}
							</div>
						)}
						{!isLoadingProducts && (
							<>
								{!isEmptySearch && (
									<LoadMoreList
										onLoadMoreClick={onLoadMoreProductsClick}
										onLoadMoreDone={() => {
											if (products) {
												sendViewItemListEvent(products, pageSize);
											}
										}}
										isLoading={isLoadingMoreProducts}
										hasLoadMoreButton={hasNextPage}
										listClassName="mt-4 flex flex-col divide-y-1 divide-greyDark"
										buttonClassName="mt-4 max-sm:w-full sm:min-w-72"
										buttonAlignment="center"
										buttonText={t('load_more_products_button')}
										afterListContent={
											<Meter
												alignment="center"
												className="mt-14"
												current={visibleProductsCount ?? 1}
												max={totalProductsCount ?? 1}
												labelHasProgress
												label={t('product_list_page_indicator_text', {
													numShown: visibleProductsCount,
													numTotal: totalProductsCount,
												})}
											/>
										}
									>
										{products?.map((product, i) => (
											<ProductArchiveSearchItem
												key={product.id}
												title={product.title}
												articleNr={product.id}
												brand={product.brand}
												imageSrc={product.imgSrc}
												onProductClick={() => {
													onProductClick(product.id);
													sendSelectItemEvent(product, i);
												}}
											/>
										))}
									</LoadMoreList>
								)}
								{isEmptySearch && (
									<Text
										as="p"
										className="mt-6"
										text={t('search_page_no_search_results_text')}
									/>
								)}
							</>
						)}
					</div>
				</>
			)}
		</div>
	);
}
ProductArchiveSearch.displayName = 'ProductArchiveSearch_Component';
