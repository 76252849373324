import { useEffect, useMemo, useState } from 'react';

import { useFilteredReviews } from 'hooks';
import type { Review, ReviewImage as Image } from 'models/api';
import { filterTruthy } from 'utils/collection';
import { is } from 'utils/helpers';

export type ReviewImageId = `${string}-${number}`;

/** A review image with a reference to the review it belongs to. */
export interface ReviewImage {
	id: ReviewImageId;
	image: Image;
	review: Review;
}

export function getReviewImageId(review: Review, image: Image): ReviewImageId {
	const imageIndex =
		review.images?.findIndex((img) => img.icon === image.icon) ?? -1;
	return `${review.id}-${imageIndex}`;
}

export default function useReviewImage({
	productId,
	reviewScore,
}: {
	productId: string;
	reviewScore?: 1 | 2 | 3 | 4 | 5;
}) {
	const [selectedReviewImageId, setSelectedReviewImageId] = useState<
		ReviewImageId | undefined
	>();

	const { error, isLoading, reviewResponse } = useFilteredReviews({
		productId,
		filter: 'HasImage',
		reviewScore,
	});

	const reviewImages: ReviewImage[] = useMemo(
		() =>
			filterTruthy(reviewResponse?.reviews, 'images').flatMap((review) =>
				review?.images.map((image) => ({
					image,
					review,
					id: getReviewImageId(review, image),
				})),
			),
		[reviewResponse],
	);

	useEffect(() => {
		if (is.arrayWithLength(reviewImages)) {
			setSelectedReviewImageId(reviewImages[0]?.id);
		}
	}, [reviewImages]);

	return {
		reviewImages,
		selectedReviewImageId,
		setSelectedReviewImageId,
		isLoading,
		error,
	};
}
