/**
 * Portal
 */

import { type ReactNode, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

interface Props {
	/** Portal content. */
	children: ReactNode;

	/** Rendering key. */
	portalKey?: string;

	/** CSS selector for the DOM node to render the portal in. */
	selector: string;
}

/**
 * A React portal that only renders on the client.
 *
 * https://github.com/vercel/next.js/tree/canary/examples/with-portals
 */
export default function Portal({ children, portalKey, selector }: Props) {
	const ref = useRef<HTMLElement | null>(null);
	const [isMounted, setIsMounted] = useState(false);

	useEffect(() => {
		ref.current = document.querySelector(selector);
		setIsMounted(true);
	}, [selector]);

	return isMounted && ref.current
		? createPortal(children, ref.current, portalKey)
		: null;
}
Portal.displayName = 'Portal';
