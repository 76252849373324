import React from 'react';

import Button from 'components/Button';
import Img from 'components/Img';
import Text from 'components/Text';

interface Props {
	// image
	image?: string;
	// Header
	header?: string;
	// Description
	creditInfo?: string;
	infoOne?: string;
	infoTwo?: string;

	// Button Text
	buttonText?: string;
	// onclkick callback
	onClick: () => void;
}

export default function JulaProInfo({
	header,
	creditInfo,
	infoOne,
	infoTwo,
	onClick,
	buttonText,
	image,
}: Props) {
	return (
		<div className="relative flex h-full flex-col items-center">
			<Img src={image} className="my-8" />
			<div className="mb-2 flex h-full w-full flex-col justify-between">
				<div>
					<Text as="h2" text={header} className="mb-2" />
					<Text className="mt-6" as="p" text={creditInfo} />
					<Text className="mt-6" as="p" text={infoOne} />
					<Text className="mt-6" as="p" text={infoTwo} />
				</div>
				<Button variant="primary" displayWidth="full" onClick={onClick}>
					{buttonText}
				</Button>
			</div>
		</div>
	);
}
JulaProInfo.displayName = 'AccountJulaProSignupForm_JulaProInfo';
