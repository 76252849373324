/**
 * ProductFlag
 */

import React from 'react';

import { Product } from 'models/product';
import { ProductCard, RelatedProductCard } from 'models/productCard';
import cn from 'utils/cn';
import { useI18n } from 'utils/i18n';
import { isUppercase, upperFirst } from 'utils/string';

export type ProductFlagSize = 'small' | 'medium' | 'large';
export type ProductFlagVariant = 'news' | 'outlet' | 'sale';

export function getFlagVariant(
	product: Product | ProductCard | RelatedProductCard,
): ProductFlagVariant | undefined {
	if ('salesAttributes' in product) {
		return product.salesAttributes?.isNews
			? 'news'
			: product.salesAttributes?.isOutlet
				? 'outlet'
				: product.salesAttributes?.isSale
					? 'sale'
					: undefined;
	}
	if ('isNews' in product) {
		return product.isNews
			? 'news'
			: product.isOutlet
				? 'outlet'
				: product.isSale
					? 'sale'
					: undefined;
	}
	return undefined;
}

interface Props {
	className?: string;
	size: ProductFlagSize;
	text?: string;
	variant: ProductFlagVariant;
}

/** Flag with a short label. */
export default function ProductFlag({ className, size, text, variant }: Props) {
	const { t } = useI18n();

	const defaultTexts: Record<ProductFlagVariant, string> = {
		news: t('product_list_new_product_label'),
		outlet: t('product_list_outlet_product_label'),
		sale: t('product_list_sale_product_label'),
	};
	const displayText = text || defaultTexts[variant];

	return (
		<span
			className={cn(
				'relative inline-block rounded-bl-none',
				'after:absolute after:left-0 after:box-content after:border-transparent',

				size === 'small' &&
					'rounded-sm px-1 py-px text-sm font-bold after:-bottom-[5px] after:border-[5px]',
				size === 'medium' &&
					'rounded-border px-2 py-1 text-lg font-bold after:-bottom-[7px] after:border-[7px]',
				size === 'large' &&
					'rounded px-3 py-1 font-alt text-h3-small after:-bottom-[10px] after:border-[10px] md:text-h3-large',

				variant === 'news' &&
					'bg-news text-informationDarker after:border-l-news',
				variant === 'outlet' &&
					'bg-campaign text-greyDarker after:border-l-campaign',
				variant === 'sale' &&
					'bg-campaign text-julaRedDark after:border-l-campaign',

				// Keep uppercase appearance but make the actual text sentence case
				// to improve the experience for screen readers — uppercase is often
				// spelled out letter by letter.
				isUppercase(displayText) && 'uppercase',

				className,
			)}
		>
			{upperFirst(displayText.toLowerCase())}
		</span>
	);
}
ProductFlag.displayName = 'ProductFlag';
