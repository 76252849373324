import React, { useState } from 'react';
import { Form } from 'react-final-form';

import ActionButton, { type ActionButtonState } from 'components/ActionButton';
import Button from 'components/Button';
import { TextInput } from 'components/FinalForm';
import GdprAccordion from 'components/GdprAccordion';
import { composeValidators, required } from 'components/GenericForm';
import InfoBox from 'components/InfoBox';
import fetchData, { API_URL } from 'utils/fetchData';
import { pushToGTM } from 'utils/GoogleTagManager';
import { sendGlobalEvent } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

interface Props {
	onClose: () => void;
	userEmail?: string;
	variantId: string;
}

function WatchableSignup({ userEmail = '', variantId, onClose }: Props) {
	const { t } = useI18n();
	const [submitButtonState, setSubmitButtonState] =
		useState<ActionButtonState>('idle');

	const onWatchableSignup = async ({ email }: { email: string }) => {
		setSubmitButtonState('loading');

		if (email && variantId) {
			const response = await fetchData(
				`${API_URL}ProductStock/watch?variantId=${variantId}&email=${email}`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					credentials: 'include',
				},
			).catch((error) => error);

			if (response?.fieldValidationErrors) {
				setSubmitButtonState('failure');
				return response.fieldValidationErrors;
			}
			setSubmitButtonState('success');
			pushToGTM({
				type: 'notify_in_stock',
				payload: {
					productId: variantId,
				},
			});
		} else {
			setSubmitButtonState('failure');
		}
		sendGlobalEvent('engagement', {
			type: 'registeredForBackInStock',
		});
	};

	return (
		<div>
			{submitButtonState === 'success' ? (
				<>
					<InfoBox
						icon="info"
						message={t('product_watchable_popover_success_text')}
						variant="success"
						type="default"
						className="mb-4"
					/>
					<Button
						variant="secondary"
						displayWidth="full"
						size="large"
						onClick={onClose}
					>
						{t('popover_close_label')}
					</Button>
				</>
			) : (
				<>
					{submitButtonState === 'failure' && (
						<InfoBox
							icon="error"
							message={t('product_watchable_popover_error_text')}
							variant="error"
							type="default"
							className="mb-4"
						/>
					)}
					<p>{t('product_watchable_popover_text')}</p>
					<GdprAccordion />
					<Form
						onSubmit={onWatchableSignup}
						initialValues={{
							email: userEmail,
						}}
						render={(renderProps) => {
							const { handleSubmit } = renderProps;
							return (
								<form className="mt-4" onSubmit={handleSubmit}>
									<div>
										<TextInput
											type="email"
											id="email"
											className="mb-4"
											label={t('account_details_email_label')}
											inputMode="email"
											name="email"
											validate={composeValidators(
												required(t('FieldIsRequired')),
											)}
										/>
									</div>
									<ActionButton
										variant="cta"
										displayWidth="full"
										size="xl"
										customState={submitButtonState}
										minimunLoadingTime={0}
										type="submit"
									>
										{t('product_watchable_popover_button')}
									</ActionButton>
								</form>
							);
						}}
					/>
				</>
			)}
		</div>
	);
}

WatchableSignup.displayName = 'WatchableSignup';

export default WatchableSignup;
