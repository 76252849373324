import React, { type FieldsetHTMLAttributes } from 'react';
import clsx from 'clsx';

import type { WithOptional, WithRequired } from 'types';
import cn from 'utils/cn';
import { slugify } from 'utils/string';

import type { RadioCheckboxColor } from './helpers';
import InputInfo from './InputInfo';
import LabelText from './LabelText';
import Radio, { type Props as RadioProps } from './Radio';

export interface Props
	extends Omit<FieldsetHTMLAttributes<HTMLFieldSetElement>, 'onChange'> {
	className?: string;
	color?: RadioCheckboxColor;
	disabled?: boolean;
	errorMessage?: string | string[];
	helpText?: string;
	hiddenLegend?: boolean;
	id: string;
	invalid?: boolean;
	legend: string;
	legendClassName?: string;
	name: string;
	onChange: RadioProps['onChange'];
	// Omit name since it's set on the group, make id optional since it can be
	// generated by the group and make value required so the group's value can
	// be based on something.
	options: Omit<
		WithOptional<WithRequired<RadioProps, 'value'>, 'id'>,
		'name'
	>[];
	required?: boolean;
	value: string;
}

export default function RadioGroup({
	className,
	color,
	disabled = false,
	errorMessage,
	helpText,
	hiddenLegend,
	id,
	invalid = false,
	legend,
	legendClassName,
	name,
	onChange,
	options,
	required,
	value,
}: Props) {
	const errorMessageId = `${id}-error`;
	const helpTextId = `${id}-help`;

	return (
		<fieldset
			className={className}
			// https://adrianroselli.com/2022/02/support-for-marking-radio-buttons-required-invalid.html
			// False positive, radiogroup is allowed on fieldset.
			// eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
			role="radiogroup"
			aria-required={required}
			aria-invalid={invalid}
			aria-describedby={helpTextId}
		>
			<legend
				className={cn(
					'font-bold',
					disabled && 'opacity-50',
					hiddenLegend && 'sr-only',
					legendClassName,
				)}
			>
				<LabelText required={required}>{legend}</LabelText>
			</legend>
			{options.map(
				({ id: inputId, className: radioClassName, label, ...radioProps }) => {
					const radioId = inputId || slugify(`${name}-${label}`);
					return (
						<Radio
							key={radioId}
							checked={value === radioProps.value}
							className={cn('mt-4', radioClassName)}
							color={color}
							disabled={disabled}
							id={radioId}
							label={label}
							name={name}
							onChange={onChange}
							{...radioProps}
						/>
					);
				},
			)}
			<InputInfo
				invalid={invalid}
				errorMessage={errorMessage}
				errorMessageId={errorMessageId}
				helpText={helpText}
				helpTextId={helpTextId}
				className="ml-8 md:ml-7"
			/>
		</fieldset>
	);
}
RadioGroup.displayName = 'FormUi_RadioGroup';
