import { FormField } from 'models/sitecore';

export const required = (error) => (value) => (value ? undefined : error);

export const pattern =
	({
		errorMessage,
		patternString,
		required,
	}: {
		errorMessage: string;
		patternString: string;
		required: boolean;
	}) =>
	(value: string) => {
		const pattern = new RegExp(patternString);
		if (required) {
			return pattern.test(value) ? undefined : errorMessage;
		}
		if (value) {
			return pattern.test(value) ? undefined : errorMessage;
		}
		return undefined;
	};

export const composeValidators =
	(...validators) =>
	(value) =>
		validators.reduce(
			(error, validator) => error || validator(value),
			undefined,
		);

export const getValidationForField = (field: FormField) => {
	const validation: any[] = [];
	// same error for required and pattern, empty string if no error message sent to field to make it validate still
	// since empty string/undefined skips validation
	if (field.required) {
		validation.push(required(field.error ? field.error : ' '));
	}
	if (field.validation) {
		validation.push(
			pattern({
				patternString: field.validation,
				required: field.required,
				errorMessage: field.error ? field.error : ' ',
			}),
		);
	}
	return validation;
};
