/**
 * ProductDetailsImage
 */

import React, { useState } from 'react';

import ImageSlider from 'components/ImageSlider';
import Img from 'components/Img';
import ProductFlag, { getFlagVariant } from 'components/ProductFlag';
import ThumbnailList from 'components/ThumbnailList';
import { useMinWidth } from 'hooks';
import type { ReviewImage, ReviewImageId } from 'hooks/product-details';
import type { Product } from 'models/product';
import { getAllImages, getMainImageDetail } from 'utils/business-logic';
import { useI18n } from 'utils/i18n';

import AllReviewImages from './AllReviewImages';
import ProductAndSelectedImages, {
	ProductAndSelectedImagesTabs,
} from './ProductAndSelectedImages';

interface Props {
	activeTabId: 'product-images' | 'review-images';
	allReviewImages: ReviewImage[] | undefined;
	allReviewImagesIsOpen: boolean;
	onAllReviewImagesSlideChange: (slideIndex: number) => void;
	onCloseAllReviewImages: () => void;
	onCloseProductAndSelectedImages: () => void;
	onOpenProductAndSelectedImages: () => void;
	onSelectedReviewImagesSlideChange: (slideIndex: number) => void;
	onTabChange: (newTabId: ProductAndSelectedImagesTabs) => void;
	product: Product;
	productAndSelectedImagesIsOpen: boolean;
	selectedAllReviewImageId?: ReviewImageId | undefined;
	selectedTopScoreReviewImageId: ReviewImageId | undefined;
	topScoreReviewImages: ReviewImage[] | undefined;
}

const PLACEHOLDER_SRC = '/assets/images/placeholder-image.png';

/** Image component for Product Details. */
export default function ProductDetailsImage({
	activeTabId,
	allReviewImages,
	allReviewImagesIsOpen,
	onAllReviewImagesSlideChange,
	onCloseAllReviewImages,
	onCloseProductAndSelectedImages,
	onOpenProductAndSelectedImages,
	onSelectedReviewImagesSlideChange,
	onTabChange,
	product,
	productAndSelectedImagesIsOpen,
	selectedAllReviewImageId,
	selectedTopScoreReviewImageId,
	topScoreReviewImages,
}: Props) {
	const { t } = useI18n();
	const isMediumOrLarger = useMinWidth('md');

	const [carouselIndex, setCarouselIndex] = useState(0);

	const allProductImages = getAllImages(product) ?? [];
	const hasProductImages = allProductImages.length > 0;

	const [selectedImageId, setSelectedImageId] = useState(
		getMainImageDetail(product)?.id ?? 1,
	);
	const selectedImage = allProductImages.find(
		(image) => image.id === selectedImageId,
	);

	const flagVariant = getFlagVariant(product);
	const flag = flagVariant ? (
		<ProductFlag
			variant={flagVariant}
			size={isMediumOrLarger ? 'large' : 'medium'}
			className="absolute left-0 top-0 z-1"
		/>
	) : null;

	const mainImageSrc = selectedImage?.src || PLACEHOLDER_SRC;
	const mainImage = (
		<Img
			className="mt-6 max-w-[24rem]"
			src={mainImageSrc}
			alt={selectedImage?.alt}
			service="nextjs"
			priority
			width={384}
			height={384}
		/>
	);

	return (
		<>
			<ProductAndSelectedImages
				activeTabId={activeTabId}
				carouselIndex={carouselIndex}
				images={allProductImages}
				isOpen={productAndSelectedImagesIsOpen}
				onClose={onCloseProductAndSelectedImages}
				onReviewImagesSlideChange={onSelectedReviewImagesSlideChange}
				onTabChange={onTabChange}
				onThumbnailClick={setSelectedImageId}
				onThumbnailSlideChange={setCarouselIndex}
				reviewImages={topScoreReviewImages}
				selectedImageId={selectedImageId}
				selectedReviewImageId={selectedTopScoreReviewImageId}
			/>
			{allReviewImages && (
				<AllReviewImages
					images={allReviewImages}
					isOpen={allReviewImagesIsOpen}
					onClose={onCloseAllReviewImages}
					onSlideChange={onAllReviewImagesSlideChange}
					selectedAllReviewImageId={selectedAllReviewImageId}
				/>
			)}

			<div className="relative md:hidden">
				{flag}
				{hasProductImages && (
					<ImageSlider
						images={allProductImages}
						sliderButtonLabel={t('product_details_show_large_image_label')}
						onSlideChange={(imageId) => {
							if (imageId !== undefined) {
								setSelectedImageId(imageId);
							}
						}}
						onSliderClick={onOpenProductAndSelectedImages}
					/>
				)}
				{!hasProductImages && (
					<Img
						src={PLACEHOLDER_SRC}
						width={128}
						height={128}
						className="mx-auto size-32"
					/>
				)}
			</div>

			<div className="grid grid-cols-8 gap-6 max-md:hidden">
				{allProductImages.length > 1 && (
					<ThumbnailList
						images={allProductImages}
						selectedImageId={selectedImageId}
						onImageClick={(imageId) => setSelectedImageId(imageId)}
						carouselIndex={carouselIndex}
						onSlideChange={(index) => setCarouselIndex(index)}
						className="col-span-1 mb-4"
					/>
				)}
				<div className="relative col-span-5 col-start-3">
					{flag}
					{mainImageSrc === PLACEHOLDER_SRC && mainImage}
					{mainImageSrc !== PLACEHOLDER_SRC && (
						<button
							type="button"
							aria-label={t('product_details_show_large_image_label')}
							className="min-h-24 min-w-24"
							onClick={onOpenProductAndSelectedImages}
						>
							{mainImage}
						</button>
					)}
				</div>
			</div>
		</>
	);
}
ProductDetailsImage.displayName = 'ProductDetailsImage';
