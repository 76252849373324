import { Extends } from 'types';

import { StatusType } from './statusType';

export interface StoreStock {
	inStock: boolean;
	productInStockAtStores: number;
	storeSelected: boolean;
}

export function getProductCardStoreStockStatus({
	storeSelected,
	inStock,
	productInStockAtStores,
}: StoreStock): ProductCardStoreStockStatusType {
	switch (true) {
		case storeSelected && inStock:
			return 'Store-InStock';
		case storeSelected && !inStock:
			return 'Store-OutOfStock';
		case productInStockAtStores > 0:
			return 'NoStore-InStock';
		case productInStockAtStores === 0:
			return 'NoStore-OutOfStock';
		default:
			return 'MatchFailed';
	}
}

export type ProductCardStoreStockStatusType = Extends<
	StatusType,
	| 'Store-InStock'
	| 'Store-OutOfStock'
	| 'NoStore-InStock'
	| 'NoStore-OutOfStock'
	| 'MatchFailed'
>;
