import React from 'react';

import Icon from 'components/Icon';
import JulaLogo from 'components/JulaLogo';
import Link from 'components/Link';
import Text from 'components/Text';

interface Props {
	linkText?: string;
	linkUrl?: string;
}

export default function MiniHeader({ linkText, linkUrl }: Props) {
	return (
		<div className="flex h-14 w-full items-center bg-julaRed px-3 text-white md:grid md:h-24 md:grid-cols-3 md:px-5">
			<div>
				{linkUrl && linkText && (
					<Link
						href={linkUrl}
						className="group inline-flex items-center py-2 pr-2"
					>
						<Icon icon="arrow" direction="left" className="-ml-1" />
						<Text as="span" className="font-bold group-hover:underline">
							{linkText}
						</Text>
					</Link>
				)}
			</div>
			{/* Right margin to get the same visual spacing as the link's arrow */}
			<JulaLogo className="justify-self-end max-md:ml-auto max-md:mr-1 md:w-[120px] md:justify-self-center" />
		</div>
	);
}
MiniHeader.displayName = 'MiniHeader';
