import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import ArrowLink from 'components/ArrowLink';
import ComponentPlaceholder from 'components/ComponentPlaceholder';
import { LayoutContainer } from 'components/Layout';
import type { JulaComponentProps } from 'lib/component-props';
import type { SitecoreLink } from 'models/sitecore';
import { getEditorMargin } from 'utils/business-logic';

type Props = JulaComponentProps & {
	fields?: {
		link?: Field<SitecoreLink>;
	};
};

export default function ContentLink({ fields, params }: Props) {
	if (!fields) {
		return null;
	}
	const { link } = fields;
	if (!link?.value?.text || !link.value.href) {
		return (
			<ComponentPlaceholder
				componentName="ContentLink"
				description="Requires a link with text"
				className={getEditorMargin(params)}
			/>
		);
	}

	return (
		<LayoutContainer
			outerClassName={getEditorMargin(params)}
			id={params?.anchor}
			className="grid grid-cols-4 gap-4 md:grid-cols-12 md:gap-6"
		>
			<div className="col-span-4 md:col-span-6 md:col-start-4">
				<ArrowLink
					href={link.value.href}
					text={link.value.text}
					target={link.value.target}
				/>
			</div>
		</LayoutContainer>
	);
}
ContentLink.displayName = 'ContentLink';
