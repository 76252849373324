import React from 'react';

import Popover from 'components/Popover';
import { WatchableSignup } from 'components/WatchableSignup';
import { useI18n } from 'utils/i18n';

interface Props {
	isOpen: boolean;
	onClose: () => void;
	productId: string;
}

export default function WatchableVariantPopover({
	productId,
	isOpen,
	onClose,
}: Props) {
	const { t } = useI18n();
	return (
		<Popover
			isOpen={isOpen}
			title={t('product_watchable_popover_heading')}
			onClose={onClose}
		>
			<WatchableSignup onClose={onClose} variantId={productId} />
		</Popover>
	);
}

WatchableVariantPopover.displayName =
	'ProductDetails_StockInformation_WatchableVariantPopover';
