import type { DiscountType } from 'models/product';

export const PRODUCT_ACCESSORIES_ID = 'accessories';
export const PRODUCT_IMPORTANT_INFO_ID = 'important-information';
export const PRODUCT_REVIEWS_ID = 'product-reviews';
export const PRODUCT_SIMILAR_PRODUCTS_ID = 'similar-products';
export const PRODUCT_TECHNICAL_ATTRIBUTES_ID = 'technical-attributes';
export const PRODUCT_SPARE_PARTS_ID = 'spare-parts';
export const PRODUCT_BELONGS_TO_ID = 'belongs-to';

/** Campaign discount types that are displayed in the 'packages section' */
export const CAMPAIGN_PACKAGE_DISCOUNT_TYPES: DiscountType[] = [
	'BuyAllFixedPrice',
	'BuyAllCheapestFree',
];
