/**
 * PortalContainers
 */

import React from 'react';

import { MODALS_CONTAINER_ID, POPOVERS_CONTAINER_ID } from 'constants/ids';

export default function PortalContainers() {
	return (
		<>
			<div id={POPOVERS_CONTAINER_ID} />
			<div id={MODALS_CONTAINER_ID} />
		</>
	);
}
PortalContainers.displayName = 'PortalContainers';
