/**
 * MicroContent - Theme
 */

import React from 'react';

import Chip from 'components/Chip';
import Img from 'components/Img';
import ThemedBlockContainer from 'components/ThemedBlockContainer';
import { SimpleLink } from 'models/sitecore';

interface Props {
	centerImageAlt?: string;
	centerImageSrc?: string;
	className?: string;
	color: string;
	link: SimpleLink;
	topLeftImageSrc?: string;
}

/** Corner theme image, center image and link */
export default function MicroContentTheme({
	centerImageAlt,
	centerImageSrc,
	className,
	color,
	link,
	topLeftImageSrc,
}: Props) {
	return (
		<ThemedBlockContainer
			color={color}
			topLeftImageSrc={topLeftImageSrc}
			className={className}
			contentClassName="flex flex-col items-center"
		>
			{centerImageSrc && (
				<>
					<Img
						src={centerImageSrc}
						alt={centerImageAlt}
						width={360}
						height={180}
						service="nextjs"
						jpgOptimized={false}
						className="mx-auto mb-6 md:hidden"
					/>
					<Img
						src={centerImageSrc}
						alt={centerImageAlt}
						width={810}
						height={405}
						service="nextjs"
						jpgOptimized={false}
						className="max-md:hidden"
					/>
				</>
			)}
			<div className="flex items-center justify-center">
				{link && (
					<Chip
						key={link.href}
						href={link.href}
						text={link.text}
						color="grey"
					/>
				)}
			</div>
		</ThemedBlockContainer>
	);
}
MicroContentTheme.displayName = 'MicroContentTheme';
