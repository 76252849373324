import React from 'react';
import clsx from 'clsx';

import Button from 'components/Button';
import CreditSimulationPopover from 'components/CreditSimulation';
import IconList from 'components/IconList';
import Img from 'components/Img';
import InfoBox from 'components/InfoBox';
import { OptionalLink, ScrollLink } from 'components/Link';
import Price from 'components/Price';
import {
	MIN_ANSWER_COUNT,
	TOO_LARGE_PERCENTAGE,
	TOO_SMALL_PERCENTAGE,
} from 'components/ProductFit';
import ProductTag from 'components/ProductTag';
import Rating from 'components/Rating';
import { Skeleton, SkeletonItem } from 'components/Skeleton';
import Staffling from 'components/Staffling';
import Text from 'components/Text';
import { StoreIdName } from 'contexts';
import type { QuestionSummary } from 'models/api';
import { ItemStockResponse, Stock, StockStore } from 'models/api';
import {
	CreateCustomizationRequestItem,
	CustomizationPlacement,
	Placement,
} from 'models/api/ProductCustomization';
import { StoredFileResponse } from 'models/api/userFileStorage';
import type { CampaignResponse, Product } from 'models/product';
import type { ProductCard } from 'models/productCard';
import { ActionButtonState } from 'state-machines/ActionButton.machine';
import type { ButtonStateWithId } from 'state-machines/cart';
import { getPriceProps } from 'utils/business-logic';
import { FormattedValidationErrors } from 'utils/fetchData';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';
import { getWeightedPercentage } from 'utils/math';

import {
	PRODUCT_REVIEWS_ID,
	PRODUCT_TECHNICAL_ATTRIBUTES_ID,
} from './helpers/constants';
import ButtonPanel from './ButtonPanel';
import Prices from './Prices';
import { ProductDetailsCampaignProductsPopover } from './ProductDetailsCampaign';
import ProductPrint from './ProductPrint';
import StockInformation from './StockInformation';
import VariantPicker from './VariantPicker';

interface BrandTitleProps {
	className: string;
	href: string | undefined;
	model: string | undefined;
	title: string | undefined;
}

function BrandTitle({ className, href, model, title }: BrandTitleProps) {
	if (!title) {
		return null;
	}
	if (model) {
		return (
			<div className={className}>
				<OptionalLink underline href={href} fallbackTag="span">
					{title}
				</OptionalLink>
				{' | '}
				{model}
			</div>
		);
	}
	return (
		<OptionalLink underline href={href} className={className} fallbackTag="p">
			{title}
		</OptionalLink>
	);
}

BrandTitle.displayName = 'ProductDetailsSidebar_BrandTitle';

export interface Props {
	addPrintPlacementButtonState: ActionButtonState;
	addPrintPlacementErrors: FormattedValidationErrors | undefined;
	allStoresStock: Stock[] | undefined;
	className?: string;
	creditSimulationPopoverIsOpen: boolean;
	currentProductStock: ItemStockResponse | undefined;
	currentStoreStock: Stock | undefined;
	existingPrintPlacements: CustomizationPlacement[] | undefined;
	fileUploadButtonState: ActionButtonState;
	fit3Summary?: QuestionSummary;
	isInitialLoadingProductPrint: boolean;
	isLoadingFit?: boolean;
	isLoadingInitialStock: boolean;
	isLoadingNearbyStoresStock: boolean;
	isLoadingStock: boolean;
	isLoadingVariantsStock: boolean;
	isPurchasable: boolean;
	nearbyStoresStock: Stock[] | undefined;
	onAddPrintPlacementClick: (newPrint: CreateCustomizationRequestItem) => void;
	onCampaignPopoverProductAddToCartClick: (
		variant: ProductCard,
		campaign: CampaignResponse,
	) => void;
	onCreditSimulationClick: () => void;
	onCreditSimulationPopoverClose: () => void;
	onEnergyLabelClick: () => void;
	onMainPurchaseButtonClick: () => void;
	onRatingClick: () => void;
	onRemovePrintClick: (id: string) => void;
	onRemovePrintPlacementClick: (placementId: string) => void;
	onReusePrintClick: (id: string) => void;
	onSizeFitClick: () => void;
	onSizeGuideClick: () => void;
	onStockInformationOpen: () => void;
	onStockInformationSearch: (searchValue: string) => void;
	onStockInformationTabChange: (tab: string) => void;
	onUpdateSelectedStore: (store: StockStore) => void;
	onUpLoadPrint: (file: File) => void;
	onVariantPickerOpen: () => void;
	onWishlistButtonClick: () => void;
	placements: Placement[] | undefined;
	print: StoredFileResponse | undefined;
	printPlacements: CustomizationPlacement[] | undefined;
	printUploadErrors?: string[] | undefined;
	product: Product;
	productPrintHasError: boolean;
	productPrintTotalCost: string | undefined;
	purchaseButtonState: ButtonStateWithId;
	selectedStore: StoreIdName | undefined;
	storedPrints: StoredFileResponse[] | undefined;
	variantsStock: ItemStockResponse[] | undefined;
	wishlistButtonState: ButtonStateWithId;
}

export default function ProductDetailsSidebar({
	addPrintPlacementButtonState,
	addPrintPlacementErrors,
	allStoresStock,
	className,
	creditSimulationPopoverIsOpen,
	currentProductStock,
	currentStoreStock,
	existingPrintPlacements,
	fileUploadButtonState,
	fit3Summary,
	isInitialLoadingProductPrint,
	isLoadingFit = false,
	isLoadingInitialStock,
	isLoadingNearbyStoresStock,
	isLoadingStock,
	isLoadingVariantsStock,
	isPurchasable,
	nearbyStoresStock,
	onAddPrintPlacementClick,
	onCampaignPopoverProductAddToCartClick,
	onCreditSimulationClick,
	onCreditSimulationPopoverClose,
	onEnergyLabelClick,
	onMainPurchaseButtonClick,
	onRatingClick,
	onRemovePrintClick,
	onRemovePrintPlacementClick,
	onReusePrintClick,
	onSizeFitClick,
	onSizeGuideClick,
	onStockInformationOpen,
	onStockInformationSearch,
	onStockInformationTabChange,
	onUpdateSelectedStore,
	onUpLoadPrint,
	onVariantPickerOpen,
	onWishlistButtonClick,
	placements,
	print,
	printPlacements,
	printUploadErrors,
	product,
	productPrintHasError,
	productPrintTotalCost,
	purchaseButtonState,
	selectedStore,
	storedPrints,
	variantsStock,
	wishlistButtonState,
}: Props) {
	const { t } = useI18n();
	const {
		brand,
		customization,
		energyInformation,
		productSheetLink,
		reviewScore,
		salesAttributes,
		shippingUsp,
		showCreditSimulationLink,
		sizeGuideUrl,
		staffPrice,
		variants,
		volumePrices,
	} = product;

	const productTags = product.productTags?.filter(
		({ section }) => section === 'Product',
	);
	const energySymbolSrc = energyInformation?.symbol?.versions?.find(
		(x) => x.subType === 'EfficiencySymbol',
	)?.formats?.[0]?.url?.location;

	const perceivedFitPercentage =
		fit3Summary?.options?.length && fit3Summary.count >= MIN_ANSWER_COUNT
			? getWeightedPercentage(...fit3Summary.options.map((opt) => opt.count))
			: undefined;
	const perceivedFitText =
		// Explicitly check undefined to not exclude zero.
		perceivedFitPercentage === undefined
			? undefined
			: perceivedFitPercentage < TOO_SMALL_PERCENTAGE
				? t('question_summary_fit_is_small_title')
				: perceivedFitPercentage > TOO_LARGE_PERCENTAGE
					? t('question_summary_fit_is_large_title')
					: t('question_summary_fit_is_perfect_title');

	return (
		<div className={clsx('flex flex-col', className)}>
			<Text
				as="h1"
				styleAs="h2"
				className="mb-2 max-sm:mt-1"
				text={
					product.titleExclModel && product.subTitle
						? `${product.titleExclModel} ${product.subTitle}`
						: (product.titleExclModel ?? product.title)
				}
			/>
			<BrandTitle
				className="mb-2"
				model={product.variantModel || product.productModel}
				href={brand?.relativeUrl}
				title={brand?.title}
			/>
			<div className="mb-4 mt-2 sm:mb-6">
				{reviewScore?.score && reviewScore.score > 0 ? (
					<Rating
						reviewCountVariant="fullText"
						href={`#${PRODUCT_REVIEWS_ID}`}
						onClick={onRatingClick}
						score={reviewScore.score}
						reviewCount={reviewScore.count}
					/>
				) : (
					<div className="h-6" />
				)}
			</div>
			{is.arrayWithLength(productTags) && (
				<div className="mb-4 flex flex-wrap gap-2 sm:mb-6">
					{productTags.map((tag) => (
						<ProductTag
							key={tag.text}
							type={tag.type}
							text={tag.text}
							size="large"
						/>
					))}
				</div>
			)}
			{!salesAttributes?.expired && (
				<div>
					<Prices
						displayPrice={product.displayPrice}
						basePrice={product.basePrice}
						salesAttributes={product.salesAttributes}
					/>

					{Boolean(energySymbolSrc || productSheetLink) && (
						<div className="my-8 flex flex-grow items-center">
							{energySymbolSrc && (
								<ScrollLink
									anchor={PRODUCT_TECHNICAL_ATTRIBUTES_ID}
									onClick={onEnergyLabelClick}
									className="mr-2 hover:opacity-80"
								>
									<Img
										src={energySymbolSrc}
										alt={t('product_details_technical_specification_heading')}
										className="h-12 object-contain"
									/>
								</ScrollLink>
							)}
							{productSheetLink && (
								<Text as="pSmall">
									<a
										href={productSheetLink}
										target="_blank"
										rel="nofollow noopener noreferrer"
										className="underline hover:no-underline"
									>
										{product.productSheetLinkDescription ||
											t('product_details_product_sheet_button')}
									</a>
								</Text>
							)}
						</div>
					)}

					{staffPrice && (
						<div className="mb-3 sm:mb-6">
							<InfoBox
								heading={t('product_details_staff_price_heading')}
								icon="info"
								className="!mt-8"
							>
								<div className="mb-4 mt-2 text-sm">
									{t('product_details_staff_price_text')}
								</div>
								<Price {...getPriceProps(staffPrice, true)} size="mini" />
							</InfoBox>
						</div>
					)}

					{is.arrayWithLength(volumePrices) && (
						<div className="space-between mt-6 flex flex-col gap-4 md:mt-8">
							{volumePrices.map((price, index) => (
								<Staffling key={index} volumePrice={price} />
							))}
						</div>
					)}
					<ProductDetailsCampaignProductsPopover
						campaigns={product.campaigns}
						purchaseButtonState={purchaseButtonState}
						onAddToCartClick={onCampaignPopoverProductAddToCartClick}
					/>

					{showCreditSimulationLink && (
						<>
							<CreditSimulationPopover
								isOpen={creditSimulationPopoverIsOpen}
								onClose={onCreditSimulationPopoverClose}
								price={product.displayPrice?.priceIncVat?.value}
							/>
							<Button
								variant="text"
								className="mt-6"
								onClick={onCreditSimulationClick}
							>
								{t('product_details_credit_simulation_link_button')}
							</Button>
						</>
					)}
					<VariantPicker
						variants={variants}
						stockData={variantsStock}
						product={product}
						fit3Summary={fit3Summary}
						selectedStore={selectedStore}
						stockIsLoading={isLoadingVariantsStock}
						onOpenCallback={onVariantPickerOpen}
					/>

					{sizeGuideUrl && variants.length > 1 && (
						<div className="flex">
							{isLoadingFit && (
								<Skeleton>
									<SkeletonItem
										height="1.5rem"
										className="my-1 mr-2 w-[7rem] sm:max-md:w-[11rem] lg:w-[11rem]"
									/>
								</Skeleton>
							)}
							{!isLoadingFit && perceivedFitText && (
								<ScrollLink
									anchor={PRODUCT_REVIEWS_ID}
									onClick={onSizeFitClick}
									// Match size guide button height
									className="group mr-4 inline-flex min-h-[2rem] flex-wrap items-center gap-x-1 py-1"
								>
									{`${t('product_details_size_fit')}: `}
									<span className="underline group-hover:no-underline">
										{perceivedFitText}
									</span>
								</ScrollLink>
							)}
							<Button
								variant="text"
								onClick={onSizeGuideClick}
								className="ml-auto text-right"
							>
								{t('product_details_sizeguide_button')}
							</Button>
						</div>
					)}

					{isPurchasable && (
						<ProductPrint
							className="mt-2"
							// Print availability depends on stock status
							isLoading={isInitialLoadingProductPrint || isLoadingInitialStock}
							hasError={productPrintHasError}
							addPrintPlacementButtonState={addPrintPlacementButtonState}
							fileUploadButtonState={fileUploadButtonState}
							existingPrintPlacements={existingPrintPlacements}
							onAddPrintPlacementClick={onAddPrintPlacementClick}
							onRemovePrintPlacementClick={onRemovePrintPlacementClick}
							onReusePrintClick={onReusePrintClick}
							onSelectExistingPrintPlacementClick={onAddPrintPlacementClick}
							onUpLoadPrint={onUpLoadPrint}
							placements={placements}
							print={print}
							productId={product.id}
							printPlacements={printPlacements}
							storedPrints={storedPrints}
							total={productPrintTotalCost}
							printOnClothes={customization}
							onRemovePrintClick={onRemovePrintClick}
							printUploadErrors={printUploadErrors}
							addPrintPlacementErrors={addPrintPlacementErrors}
						/>
					)}

					<ButtonPanel
						onPurchaseButtonClick={onMainPurchaseButtonClick}
						onWishlistButtonClick={onWishlistButtonClick}
						purchaseButtonDisabled={!isPurchasable}
						purchaseButtonState={
							purchaseButtonState.buttonId === product.id
								? purchaseButtonState.state
								: 'idle'
						}
						wishlistButtonDisabled={Boolean(printPlacements?.length)}
						wishlistButtonState={
							wishlistButtonState.buttonId === product.id
								? wishlistButtonState.state
								: 'idle'
						}
					/>

					<IconList
						className="mt-6"
						iconName="check"
						items={[
							shippingUsp,
							t('product_details_payment_usp_text'),
							t('product_details_return_usp_text'),
							t('product_details_delivery_usp_text'),
						].filter(Boolean)}
						size="regular"
					/>

					<StockInformation
						className="my-8 sm:mb-20"
						onOpenCallBack={onStockInformationOpen}
						onTabChange={onStockInformationTabChange}
						onSearch={onStockInformationSearch}
						onUpdateSelectedStore={onUpdateSelectedStore}
						product={product}
						currentStoreStock={currentStoreStock}
						allStoresStock={allStoresStock}
						nearbyStoresStock={nearbyStoresStock}
						currentProductStock={currentProductStock}
						selectedStore={selectedStore}
						isLoadingStock={isLoadingStock}
						isLoadingInitialStock={isLoadingInitialStock}
						isLoadingNearbyStoresStock={isLoadingNearbyStoresStock}
					/>
				</div>
			)}
		</div>
	);
}
ProductDetailsSidebar.displayName = 'ProductDetailsSidebar';
