import {
	ActionLink,
	Column,
	FooterLinkField,
	IconLinkField,
	ImageColumn,
	SitecoreLink,
} from 'models/sitecore';
import { WithRequired } from 'types';
import { filterTruthy } from 'utils/collection';
import { is } from 'utils/helpers';

type VisibleSitecoreLink = WithRequired<SitecoreLink, 'href' | 'text'>;
type VisibleActionLink = WithRequired<ActionLink, 'text' | 'type'>;
export type VisibleLink = VisibleSitecoreLink | VisibleActionLink;

export function flattenColumns(columns: Column[] | undefined) {
	return filterTruthy(
		columns?.map(({ fields }) => ({
			title: fields.title,
			// Action links don't need a href.
			links: fields.links.filter((link) =>
				'type' in link ? link.type && link.text : link.href && link.text,
			) as VisibleLink[],
		})) || [],
		'title',
		'links',
	).filter((item) => item.links.length > 0);
}

export function flattenImageColumns(columns: ImageColumn[] | undefined) {
	return filterTruthy(
		columns?.map(({ fields }) => ({
			title: fields.title,
			images: filterTruthy(
				fields.links.map((link) => {
					// Exclude the image field since it's flattened to the link object.
					const { image, ...linkFields } = link;
					return {
						...linkFields,
						...link.image?.value?.find((img) =>
							img.contentType.includes('svg'),
						),
					};
				}),
				'src',
			),
		})) || [],
		'title',
		'images',
	).filter((item) => item.images.length > 0);
}

export function flattenIconLinks(links: IconLinkField[]) {
	return filterTruthy(
		links.map(({ fields }) => ({ iconName: fields.iconName, ...fields.link })),
		'href',
		'iconName',
		'text',
	);
}

export function flattenFooterLinks(links: FooterLinkField[]): VisibleLink[] {
	return filterTruthy(
		links
			.map((link) => link.fields.link)
			.filter(is.truthy)
			// Action links don't need a href.
			.filter((link) => ('type' in link ? link.type : link.href)),
		'text',
	);
}
