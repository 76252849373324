/**
 * WarningSymbols
 */

import React from 'react';

import Img from 'components/Img';
import Text from 'components/Text';
import { is } from 'utils/helpers';

interface Props {
	images?: {
		src: string;
		alt?: string;
	}[];
	heading?: string;
	text?: string;
}

/** Warning symbols and text. */
export default function WarningSymbols({ images, heading, text }: Props) {
	return (
		<>
			{is.arrayWithLength(images) && (
				<div className="mb-4 flex">
					{images.map((image) => (
						<Img
							key={image.src}
							src={image.src}
							alt={image.alt}
							className="mr-3 h-14 object-contain last:mr-0"
						/>
					))}
				</div>
			)}
			<Text as="h3" styleAs="h6" className="mb-2">
				{heading}
			</Text>
			<Text as="p">{text}</Text>
		</>
	);
}
WarningSymbols.displayName = 'WarningSymbols';
