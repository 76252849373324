import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import InfoBox from 'components/InfoBox';
import Price from 'components/Price';
import Text from 'components/Text';
import type { JulaComponentProps } from 'lib/component-props';
import type { PriceSize } from 'models/price';

type Props = JulaComponentProps & {
	fields?: {
		currency: Field<string>;
		heading: Field<string>;
		icon: Field<string>;
		price: Field<string | number>;
		priceSize: Field<string>;
		staffMessage: Field<string>;
	};
};

export default function StaffPrice({ fields, params }: Props) {
	if (!fields) {
		return null;
	}

	return (
		<InfoBox icon={fields?.icon.value}>
			<Text
				as={params?.heading || 'h2'}
				styleAs="p"
				className="mt-0.5 font-bold"
			>
				{fields?.heading.value}
			</Text>
			<Text as="p" className="mt-2">
				{fields?.staffMessage.value}
			</Text>
			<Price
				className="mt-4"
				price={fields?.price.value as string}
				currency={fields?.currency.value}
				size={fields?.priceSize.value as PriceSize}
			/>
		</InfoBox>
	);
}
StaffPrice.displayName = 'StaffPrice';
