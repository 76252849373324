import React from 'react';

import Img from 'components/Img';
import Text from 'components/Text';
import { useI18n } from 'utils/i18n';

export interface Props {
	articleNr: string;
	brand: string;
	imageSrc: string;
	onProductClick: () => void;
	title: string;
	// yearsOfSale: string[];
}

export default function ProductArchiveSearchItem({
	title,
	articleNr,
	brand,
	imageSrc,
	onProductClick,
}: Props) {
	const { t } = useI18n();
	const ariaLabel = `${t('product_archive_search_item_button')} ${title}`;
	return (
		<div className="grid-cols-12 py-4 md:grid">
			<div className="col-span-6 flex">
				<button
					tabIndex={-1}
					aria-hidden="true"
					type="button"
					onClick={onProductClick}
				>
					<Img
						src={imageSrc}
						width={64}
						height={64}
						service="nextjs"
						className="size-16"
					/>
				</button>
				<div className="ml-6 mt-2">
					<button
						className="font-bold hover:underline"
						type="button"
						onClick={onProductClick}
						aria-label={ariaLabel}
					>
						{title}
					</button>
					<Text as="p">
						{t('cart_article_number_label')} {articleNr}
					</Text>
					<Text as="p" className="md:hidden">
						{brand}
					</Text>
				</div>
			</div>
			<div className="col-span-2 mt-2 max-md:hidden">{brand}</div>
			{/* <div className="col-span-4 flex justify-end max-md:flex-col md:items-center">
				 <Text as="p">
					{yearsOfSale.map((year, index) => (
						<React.Fragment key={year}>
							{index > 0 && ', '}
							{year}
						</React.Fragment>
					))}
				</Text>
			</div> */}
		</div>
	);
}

ProductArchiveSearchItem.displayName = 'ProductArchiveSearchItem';
