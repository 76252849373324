import React from 'react';

import BlockMedia from 'components/BlockMedia/';
import ComponentPlaceholder from 'components/ComponentPlaceholder';
import type { JulaComponentProps } from 'lib/component-props';
import type { DigizuiteAssetList } from 'models/asset';
import type { ProductCard as ProductCardType } from 'models/productCard';
import { getEditorMargin } from 'utils/business-logic';
import { getAsset } from 'utils/helpers';

type Props = JulaComponentProps & {
	fields?: {
		datasourceName?: string;
		products?: ProductCardType[];
		squareImage?: DigizuiteAssetList;
	};
};

export default function MediaSquare({ fields, params }: Props) {
	if (!fields) {
		return null;
	}

	const { datasourceName, products, squareImage } = fields;
	const squareImg = getAsset('Templated 1:1', squareImage);

	if (!squareImg) {
		return (
			<ComponentPlaceholder
				componentName="ShortcutImages"
				description="Requires an image"
				className={getEditorMargin(params)}
			/>
		);
	}

	return (
		<div id={params?.anchor} className={getEditorMargin(params)}>
			<BlockMedia
				variant="square"
				imgSrc={squareImg?.src}
				imgAlt={squareImg?.alt}
				products={products}
				productsDisplayType="shopByMedia"
				gtmItemListId="block"
				gtmItemListName={`Block: ${datasourceName ?? 'MediaSquareUnknown'}`}
			/>
		</div>
	);
}
MediaSquare.displayName = 'MediaSquare';
