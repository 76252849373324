import React from 'react';
import clsx from 'clsx';

import Price, { BasePriceText } from 'components/Price';
import Text from 'components/Text';
import { Price as PriceModel } from 'models/price';
import { SalesAttributes } from 'models/product';
import { getPriceProps } from 'utils/business-logic';
import { formatDate, formatPriceText } from 'utils/format';
import { useI18n } from 'utils/i18n';

interface Props {
	basePrice: PriceModel | undefined;
	displayPrice: PriceModel | undefined;
	salesAttributes?: SalesAttributes;
}

export default function Prices({
	basePrice,
	displayPrice,
	salesAttributes,
}: Props) {
	const { t } = useI18n();

	if (!displayPrice) {
		return null;
	}
	const hasReducedPrice = [
		'Campaign',
		'Clearance',
		'JulaClub',
		'JulaPro',
		'Sale',
	].includes(displayPrice.priceType);

	return (
		<>
			<div>
				<Price
					{...getPriceProps(displayPrice, false, salesAttributes?.isHero)}
					size="large"
				/>

				{displayPrice.priceExcVat && (
					<Text
						as="pSmall"
						className={clsx(
							hasReducedPrice && 'text-julaRed',
							(displayPrice.priceType !== 'Base' || salesAttributes?.isHero) &&
								'mt-2',
						)}
					>
						{t('product_details_price_vat_excluded_text', {
							priceExclVat: formatPriceText(
								displayPrice.priceExcVat.displayValue,
								displayPrice.priceExcVat.displaySymbol,
							),
						})}
					</Text>
				)}
			</div>

			<div className="mt-6 empty:hidden">
				{displayPrice.maxPurchaseQuantity &&
					displayPrice.maxPurchaseQuantity > 0 &&
					hasReducedPrice && (
						<Text as="pSmall" className="mb-2 font-bold text-julaRed">
							{t('product_details_maximum_purchase_quantity_text', {
								amount: displayPrice.maxPurchaseQuantity,
							})}
						</Text>
					)}
				{displayPrice.validTo && hasReducedPrice && (
					<Text as="pSmall" className="text-julaRed">
						{t('product_details_valid_to_text', {
							validTo: formatDate(displayPrice.validTo),
						})}
					</Text>
				)}
				{displayPrice.comparisonPrice?.title &&
					displayPrice.comparisonPrice.price &&
					hasReducedPrice && (
						<Text
							as="pSmall"
							text={displayPrice.comparisonPrice.title}
							className="text-julaRed"
						>
							{`${displayPrice.comparisonPrice.title} `}
							{formatPriceText(
								displayPrice.comparisonPrice.price,
								displayPrice.comparisonPrice.priceSymbol,
							)}
						</Text>
					)}

				{displayPrice.priceType !== 'Base' && basePrice && (
					<BasePriceText
						basePrice={basePrice}
						displayedPriceType={displayPrice.priceType}
						recentLowestPriceKey="product_details_recent_lowest_price_text"
						basePriceKey="product_details_base_price_text"
						className="text-sm font-bold"
					/>
				)}
				{basePrice?.comparisonPrice?.title &&
					basePrice.comparisonPrice.price && (
						<Text as="pSmall">
							{`${basePrice.comparisonPrice.title} `}
							{formatPriceText(
								basePrice.comparisonPrice.price,
								basePrice.comparisonPrice.priceSymbol,
							)}
						</Text>
					)}
			</div>
		</>
	);
}
Prices.displayName = 'Prices';
