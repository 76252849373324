/**
 * Accordion
 */

import React, {
	type MouseEventHandler,
	type ReactNode,
	useId,
	useState,
} from 'react';
import clsx from 'clsx';

import Icon from 'components/Icon';
import { FONT_CLASSES } from 'components/Text';
import type { HTMLAttributes, HTMLTagName } from 'types';
import cn from 'utils/cn';

export type AccordionColor = 'white-red' | 'white' | 'red';
export type AccordionSize = 'small' | 'medium' | 'large' | 'heading';

interface BaseProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onClick'> {
	/** Content to show/hide. */
	children: ReactNode;

	/** Additional classes for the outermost container. */
	className?: string;

	/** Color scheme. */
	color?: AccordionColor;

	/** Additional classes for the content container. */
	contentClassName?: string;

	/** A unique ID, will be set on the trigger button. */
	id?: string;

	/** Set to false to disable all accordion behavior. */
	isActive?: boolean;

	/** Size of text and padding. */
	size?: AccordionSize;

	/** Trigger button text. */
	title: string;

	/** Additional classes for the trigger button container. */
	titleClassName?: string;

	/** Tag for the trigger button container. */
	titleTag?: HTMLTagName;
}

interface PropsWithState extends BaseProps {
	initialOpen?: never;
	isOpen: boolean;
	/** Trigger click handler to toggle the `isOpen` state. */
	onClick: MouseEventHandler<HTMLButtonElement>;
}

interface PropsWithoutState extends BaseProps {
	/** Initial open state when uncontrolled. */
	initialOpen?: boolean;
	isOpen?: never;
	onClick?: never;
}

type Props = PropsWithState | PropsWithoutState;

/**
 * Toggle showing/hiding content.
 *
 * Follows the accordion pattern.
 * https://www.w3.org/WAI/ARIA/apg/patterns/accordion/
 */
export default function Accordion({
	children,
	className,
	color = 'white-red',
	contentClassName,
	id,
	initialOpen = false,
	isActive = true,
	isOpen: isOpenProp,
	onClick,
	size = 'heading',
	title,
	titleClassName,
	titleTag: TitleComponent = 'h3',
	...attrs
}: Props) {
	const [internalIsOpen, setInternalIsOpen] = useState<boolean>(initialOpen);
	// Behave like inputs where it can be controlled or uncontrolled.
	const isOpen = onClick ? isOpenProp : internalIsOpen;
	const fallbackId = useId();
	const triggerId = id || `accordion-${fallbackId}`;
	const contentId = `${triggerId}-content`;

	return (
		<div
			{...attrs}
			className={clsx(
				isActive && [
					color === 'red' && 'bg-julaRed text-white',
					size !== 'small' && [
						'border-y border-solid',
						// Remove double border for consecutive accordions.
						'accordion peer peer-[.accordion]:border-t-0',
						color === 'red' && 'border-julaRedDark',
						color === 'white-red' && 'border-greyDark',
						color === 'white' && 'border-greyLight',
					],
				],
				className,
			)}
		>
			<TitleComponent
				className={cn(
					size === 'small' && 'text-sm',
					size === 'medium' && 'text-base font-bold',
					size === 'large' && 'text-lg font-bold',
					size !== 'heading' && 'font-standard',
					size === 'heading' && FONT_CLASSES.h3,
					titleClassName,
				)}
			>
				<span hidden={isActive}>{title}</span>
				<button
					type="button"
					id={triggerId}
					aria-controls={contentId}
					aria-expanded={isOpen}
					hidden={!isActive}
					onClick={(e) => {
						if (onClick) {
							onClick(e);
						} else {
							setInternalIsOpen((current) => !current);
						}
					}}
					className={clsx(
						'group/accordion flex w-full items-center justify-between text-left',
						size === 'small' && ['py-2', color === 'red' && 'px-2'],
						size === 'medium' && ['py-3', color === 'red' && 'px-3'],
						(size === 'large' || size === 'heading') && [
							'py-4',
							color === 'red' && 'px-4',
						],
					)}
				>
					{title}
					<span
						className={clsx(
							'ml-4 inline-flex size-8 shrink-0 items-center justify-center rounded-full',
							color === 'white' && 'group-hover/accordion:bg-greyLight',
							color === 'white-red' && [
								!isOpen && 'bg-julaRed group-hover/accordion:bg-julaRedDark',
								isOpen && 'bg-greyDark group-hover/accordion:bg-greyDarker',
							],
							color === 'red' &&
								'bg-julaRedDark group-hover/accordion:bg-julaRedDarker',
						)}
					>
						<Icon
							icon="arrow"
							className="transition-transform"
							color={color === 'white' ? undefined : 'white'}
							direction={isOpen ? 'up' : 'down'}
						/>
					</span>
				</button>
			</TitleComponent>
			<div
				id={contentId}
				role={isActive ? 'region' : undefined}
				aria-labelledby={isActive ? triggerId : undefined}
				hidden={isActive && !isOpen}
				className={clsx(
					isActive && [
						size === 'small' && color === 'red' && 'px-2 pb-2',
						size === 'medium' && ['pb-3', color === 'red' && 'px-3'],
						(size === 'large' || size === 'heading') && [
							'pb-4',
							color === 'red' && 'px-4',
						],
					],
					contentClassName,
				)}
			>
				{children}
			</div>
		</div>
	);
}
Accordion.displayName = 'Accordion';
