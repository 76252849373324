import React from 'react';

import { ProductCardStoreStockStatusType } from 'utils/business-logic/stock';
import { assertUnreachable } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

import StockBalanceConcise from './StockBalanceConcise';

interface BaseProps {
	productInStockAtStores: number;
	status: ProductCardStoreStockStatusType;
	storeName: string;
}

interface WithClickHandlerProps extends BaseProps {
	buttonText: string | undefined;
	onOpenStoreSelector: () => void | undefined;
}
interface WithoutClickHandlerProps extends BaseProps {
	buttonText?: never;
	onOpenStoreSelector?: never;
}

type Props = WithClickHandlerProps | WithoutClickHandlerProps;

export default function StoreStockBalanceConcise({
	buttonText,
	onOpenStoreSelector,
	productInStockAtStores,
	status,
	storeName,
}: Props) {
	const { t } = useI18n();

	switch (status) {
		case 'Store-InStock':
			return (
				<StockBalanceConcise
					text={t('stock_store_in_stock_general', {
						store: storeName,
					})}
					inStock
				/>
			);

		case 'Store-OutOfStock':
			return (
				<StockBalanceConcise
					inStock={false}
					text={t('stock_store_out_of_stock_general', {
						store: storeName,
					})}
				/>
			);
		case 'NoStore-InStock':
			return (
				<StockBalanceConcise
					inStock
					text={t('stock_stores_amount_exact', {
						amount: productInStockAtStores,
					})}
					buttonText={buttonText}
					onStatusClick={onOpenStoreSelector}
				/>
			);
		case 'NoStore-OutOfStock':
			return (
				<StockBalanceConcise
					inStock={false}
					text={t('stock_stores_amount_exact', {
						amount: productInStockAtStores,
					})}
					buttonText={buttonText}
					onStatusClick={onOpenStoreSelector}
				/>
			);
		case 'MatchFailed':
			return null;
		default:
			assertUnreachable(status);
	}
}
StoreStockBalanceConcise.displayName = 'StoreStockBalanceConcise';
