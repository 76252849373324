import type { DigizuiteAsset } from 'models/asset';
import type { MicroContent, SimpleLink } from 'models/sitecore';
import { filterTruthy, withTruthyProps } from 'utils/collection';
import type { GTMPromotionId, GTMPromotionName } from 'utils/GoogleTagManager';
import { getAsset } from 'utils/helpers';

interface BaseFields {
	id: GTMPromotionId;
	name: GTMPromotionName;
}

interface ChipLinksItem extends BaseFields {
	type: 'ChipLinks';
	links: SimpleLink[];
	title: string;
}

interface MediaItem extends BaseFields {
	type: 'Media';
	image: DigizuiteAsset;
	link: SimpleLink;
}

interface TextItem extends BaseFields {
	type: 'Text';
	text: string;
	title: string;
}

interface ThemeItem extends BaseFields {
	type: 'Theme';
	color: string;
	image: DigizuiteAsset;
	link: SimpleLink;
	topLeftImage?: DigizuiteAsset;
}

export type FilteredMicroContent =
	| ChipLinksItem
	| MediaItem
	| TextItem
	| ThemeItem;

export function refineMicroContent(
	rawItem: MicroContent | undefined,
): FilteredMicroContent | undefined {
	const fields = rawItem?.fields;
	if (!fields) {
		return undefined;
	}

	const baseData: BaseFields = {
		id: 'micro_content',
		name: `Micro Content: ${rawItem.name} - ${rawItem.id}`,
	};

	if ('links' in fields) {
		const links = filterTruthy(fields.links, 'href', 'text');
		const title = fields.heading?.value;
		if (links && title) {
			return { ...baseData, type: 'ChipLinks', links, title };
		}
	}

	// Inspiration article, just a media block with a different source data shape.
	if ('portraitImage' in fields && 'navigationTitle' in fields) {
		const image = getAsset('Templated 3:4', fields.portraitImage);
		const href = rawItem.url;
		const text = fields.navigationTitle?.value;
		if (image && href && text) {
			return { ...baseData, type: 'Media', image, link: { href, text } };
		}
	}

	if ('portraitImage' in fields && 'link' in fields) {
		const image = getAsset('Templated 3:4', fields.portraitImage);
		const link = withTruthyProps(fields.link?.value, 'href', 'text');
		if (image && link) {
			return { ...baseData, type: 'Media', image, link };
		}
	}

	if ('description' in fields || 'content' in fields) {
		const title = fields.heading?.value;
		const text = fields.content?.value || fields.description?.value;
		if (title && text) {
			return { ...baseData, type: 'Text', title, text };
		}
	}

	if ('theme' in fields) {
		const image = getAsset('Source Copy', fields.squareImage);
		const link = withTruthyProps(fields.link?.value, 'href', 'text');
		const color = fields.theme?.backgroundColor;
		const topLeftImage = getAsset(
			'Source Copy',
			fields.theme?.topLeftImageSquare,
		);

		if (image && link && color) {
			return { ...baseData, type: 'Theme', image, link, color, topLeftImage };
		}
	}

	return undefined;
}
