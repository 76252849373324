import React from 'react';

import InfoBox from 'components/InfoBox';
import Text from 'components/Text';
import { BusinessLogicError } from 'models/api';
import { filterTruthy } from 'utils/collection';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

export function ErrorMessageInfoBox({
	errors,
	className,
}: {
	className?: string;
	errors: BusinessLogicError[] | undefined;
}) {
	const { t } = useI18n();
	const visibleErrors = filterTruthy(errors, 'text');
	if (!is.arrayWithLength(visibleErrors)) return null;
	return (
		<InfoBox icon="error" variant="error" className={className}>
			<div className="space-y-2">
				{visibleErrors.map((error) => (
					<Text as="p" key={error.key}>
						{t(error.text)}
					</Text>
				))}
			</div>
		</InfoBox>
	);
}

ErrorMessageInfoBox.displayName = 'ErrorMessageInfoBox';
