import type {
	JulaProCustomerContact,
	JulaProReference,
	JulaProReferenceListItem,
	JulaProUpdatedReference,
} from 'models/api';
import fetchData, { API_URL } from 'utils/fetchData';

/** Gets the reference list */
export const requestGetReferences = (): Promise<JulaProReferenceListItem[]> => {
	const apiPath = `${API_URL}Customer/julapro/reference`;
	return new Promise<JulaProReferenceListItem[]>((resolve, reject) => {
		fetchData(apiPath)
			.then((data) => {
				const referenceList = data as unknown as JulaProReferenceListItem[];
				resolve(referenceList);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

/** Gets a single reference by id */
export const requestGetReference = (
	code: string,
): Promise<JulaProReference> => {
	const apiPath = `${API_URL}Customer/julapro/reference/${encodeURIComponent(
		code,
	)}`;
	return new Promise<JulaProReference>((resolve, reject) => {
		fetchData(apiPath)
			.then((data) => {
				const reference = data as unknown as JulaProReference;
				resolve(reference);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

/** Updates a reference */
export const requestUpdateReference = (
	code: string,
	reference: JulaProUpdatedReference,
): Promise<void> => {
	const apiPath = `${API_URL}Customer/julapro/reference/${encodeURIComponent(
		code,
	)}`;
	const body = JSON.stringify(reference);
	return new Promise((resolve, reject) => {
		fetchData(apiPath, { method: 'PUT', body }, true)
			.then(() => {
				resolve();
			})
			.catch((error) => {
				reject(error);
			});
	});
};

/** Adds a new reference */
export const requestAddReference = (
	value: JulaProUpdatedReference,
): Promise<void> => {
	const apiPath = `${API_URL}Customer/julapro/reference?referenceName=${encodeURIComponent(
		value.newReferenceName,
	)}`;
	return new Promise<void>((resolve, reject) => {
		fetchData(apiPath, {
			method: 'POST',
			body: JSON.stringify(value.contactPermissions),
		})
			.then((data) => {
				resolve(data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

/** Remove reference */
export const requestRemoveReference = (code: string): Promise<void> => {
	const apiPath = `${API_URL}Customer/julapro/reference/${encodeURIComponent(
		code,
	)}`;
	return new Promise((resolve, reject) => {
		fetchData(apiPath, { method: 'DELETE' })
			.then(() => {
				resolve();
			})
			.catch((error) => {
				reject(error);
			});
	});
};

/** Gets the contact list */
export const requestGetContacts = (): Promise<JulaProCustomerContact[]> => {
	const apiPath = `${API_URL}Customer/julapro/contact`;
	return new Promise<JulaProCustomerContact[]>((resolve, reject) => {
		fetchData(apiPath)
			.then((data) => {
				const userList = data as unknown as JulaProCustomerContact[];
				resolve(userList);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
