import React from 'react';
import { Field } from 'react-final-form';
import { FieldValidator } from 'final-form';

import {
	Textarea as StyledTextarea,
	type TextareaProps,
} from 'components/FormUi';

interface Props extends TextareaProps {
	name: string;
	validate?: FieldValidator<any>;
}

export default function Textarea({ name, validate, ...rest }: Props) {
	return (
		<Field
			name={name}
			validate={validate}
			render={({ input, meta }) => {
				let invalid = meta.touched && meta.invalid;
				let valid = meta.touched && meta.valid;
				if (meta.dirtySinceLastSubmit && meta.submitError) {
					invalid = false;
					valid = false;
				}
				const errorMessage = meta.touched && (meta.error || meta.submitError);
				const { type, ...fieldInputProps } = input;
				return (
					<StyledTextarea
						invalid={invalid}
						valid={valid}
						errorMessage={errorMessage}
						{...fieldInputProps}
						{...rest}
					/>
				);
			}}
		/>
	);
}
Textarea.displayName = 'FinalForm_Textarea';
