/**
 * StockBalanceDetailedSkeleton
 */

import React from 'react';

import { Skeleton, SkeletonItem } from 'components/Skeleton';

interface Props {
	stockType: 'web' | 'store';
}

/** Loading skeleton for detailed stock balance */
export default function StockBalanceDetailedSkeleton({ stockType }: Props) {
	return (
		<div className="p-4">
			<Skeleton>
				{/* Icon and title. */}
				<div className="mb-4 flex items-center">
					<SkeletonItem height="1.5rem" width="1.5rem" className="mr-2" />
					<SkeletonItem height="text" width="6rem" />
				</div>
				<div className="pl-8">
					{/* Stock amount. */}
					<SkeletonItem width="7rem" height="text" />
					{/* Store selector button.
					Skipping selected store info since it loads async and would cause an
					additional layout shift from the skeleton before everything is loaded. */}
					{stockType === 'store' && (
						<SkeletonItem width="5.5rem" height="2rem" className="mt-4" />
					)}
				</div>
			</Skeleton>
		</div>
	);
}
StockBalanceDetailedSkeleton.displayName = 'StockBalanceDetailedSkeleton';
