/**
 * PageOverlay
 */

import React from 'react';

export default function PageOverlay() {
	return <div className="page-overlay" />;
}
PageOverlay.displayName = 'PageOverlay';
