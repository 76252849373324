/**
 * IndexedList
 */

import React, { type ReactNode } from 'react';
import clsx from 'clsx';

import Text from 'components/Text';

interface Props {
	children: ReactNode;
	className?: string;
	heading: string;
}

export default function IndexedList({ className, heading, children }: Props) {
	return (
		<section className={clsx('sm:flex', className)}>
			<Text as="h2" className="mb-8 !leading-none sm:w-[15%]">
				{heading}
			</Text>
			<ul className="grid grid-cols-1 gap-y-6 sm:w-[85%] sm:grid-cols-3 sm:gap-x-8 lg:grid-cols-5">
				{children}
			</ul>
		</section>
	);
}
IndexedList.displayName = 'IndexedList';
