/**
 * ImageSlider
 */

import React, { useState } from 'react';
import clsx from 'clsx';
import { useKeenSlider } from 'keen-slider/react';

import Bullets from 'components/Bullets';
import Image from 'components/Img';
import { useDebouncedCallback, useEffectOnce } from 'hooks';
import type { Thumbnail } from 'utils/business-logic';
import { useI18n } from 'utils/i18n';

interface Props {
	images: Thumbnail[];
	onSlideChange: (activeImageId: number | undefined) => void;
	onSliderClick: () => void;
	sliderButtonLabel: string;
}

/** Horizontal image slider with bullets */
export default function ImageSlider({
	images,
	onSlideChange,
	onSliderClick,
	sliderButtonLabel,
}: Props) {
	const { t } = useI18n();
	const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
	// Keen runs slideChanged for every slide, e.g. even when moving from
	// index 1 to index 5 with a single call. Debounce to only run callback
	// for the slide landed on.
	const [debouncedOnSlideChange] = useDebouncedCallback(onSlideChange, 100, [
		onSlideChange,
	]);
	const [sliderRef, instanceRef] = useKeenSlider({
		initial: 0,
		slides: { perView: 1 },
		slideChanged(slider) {
			const currentIndex = slider.track.details.rel;
			debouncedOnSlideChange(images[currentIndex]?.id);
			setCurrentSlideIndex(currentIndex);
		},
	});

	// Default to scrolling container and enable slider on initial render.
	const [shouldEnableSlider, setShouldEnableSlider] = useState(false);
	useEffectOnce(() => {
		setShouldEnableSlider(true);
	});

	return (
		<div>
			<div
				ref={shouldEnableSlider ? sliderRef : undefined}
				className={clsx(
					'flex overflow-x-auto',
					shouldEnableSlider && 'keen-slider',
				)}
			>
				{images.map((image, i) => (
					<button
						key={image.src}
						type="button"
						className={clsx(
							'relative w-full shrink-0',
							shouldEnableSlider && 'keen-slider__slide',
							[
								// The slide hides overflow which means no outline is visible,
								// use a pseudo element as replacement.
								'focus-visible:after:absolute',
								'focus-visible:after:inset-0',
								'focus-visible:after:z-5',
								'focus-visible:after:pointer-events-none',
								// TODO: Replace with a common outline style
								'focus-visible:after:border',
								'focus-visible:after:border-2',
								'focus-visible:after:border-black',
							],
						)}
						aria-label={
							image.alt
								? `${sliderButtonLabel}: ${image.alt}`
								: sliderButtonLabel
						}
						tabIndex={i === currentSlideIndex ? undefined : -1}
						onClick={onSliderClick}
					>
						<Image
							src={image.src}
							alt={image.alt}
							service="nextjs"
							priority={i === 0}
							width={384}
							height={384}
							className="mx-auto"
						/>
					</button>
				))}
			</div>
			{images.length > 1 && (
				<div
					className={clsx(
						'mt-1 flex justify-center',
						!shouldEnableSlider && 'invisible',
					)}
				>
					<Bullets
						buttons={images.map((thumb, i) => ({
							...thumb,
							label: t('product_details_show_image_number_label', {
								num: i + 1,
							}),
						}))}
						activeId={currentSlideIndex + 1}
						onClick={(thumb) => {
							instanceRef.current?.moveToIdx(thumb.id - 1);
						}}
					/>
				</div>
			)}
		</div>
	);
}
ImageSlider.displayName = 'ImageSlider';
