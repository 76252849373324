/**
 * HighlightedText
 */

import React from 'react';
import clsx from 'clsx';

import { escapeRegExp } from 'utils/helpers';

interface Props {
	/** Container class name. */
	className?: string;

	/** Part of the text to highlight. */
	highlight: string | undefined;

	/** Which part to visually mark. */
	markPart?: 'match' | 'rest';

	/** The full text. */
	text: string;
}

/** Render `text` while highlighting any matching `highlight` parts. */
export default function HighlightedText({
	className,
	highlight = '',
	markPart = 'rest',
	text,
}: Props) {
	if (!highlight.trim()) {
		return <span className={className}>{text}</span>;
	}
	const matchRegexp = new RegExp(`(${escapeRegExp(highlight)})`, 'gi');
	const parts = text.split(matchRegexp).filter(Boolean);
	return (
		<span className={className}>
			{parts.map((part, i) =>
				matchRegexp.test(part) ? (
					<mark
						key={i}
						className={clsx(
							'bg-transparent text-inherit',
							markPart === 'match' && 'font-bold',
							markPart !== 'match' && 'font-normal',
						)}
					>
						{part}
					</mark>
				) : (
					<span
						key={i}
						className={clsx(
							markPart === 'rest' && 'font-bold',
							markPart !== 'rest' && 'font-normal',
						)}
					>
						{part}
					</span>
				),
			)}
		</span>
	);
}
HighlightedText.displayName = 'HighlightedText';
