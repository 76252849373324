import React, { useEffect } from 'react';
import { useSelector } from '@xstate/react';
import { useRouter } from 'next/router';

import Wishlist from 'components/Wishlist';
import { useGlobalStateContext, useSelectedStore } from 'contexts';
import { selectSharedWishlist } from 'state-machines/wishlist';
import { formatDateAndTime } from 'utils/format';
import { useI18n } from 'utils/i18n';

export default function SharedWishlist() {
	const { t } = useI18n();
	const router = useRouter();
	const path = router?.asPath.replace(/\/$/, '');
	const id = path.split('/wishlist/').filter(Boolean).pop();
	const { wishlistService } = useGlobalStateContext();
	const wishlistData = useSelector(wishlistService, selectSharedWishlist);

	const { selectedStore, isLoading: isLoadingSelectedStore } =
		useSelectedStore();
	const shareDate = wishlistData?.dateCreated;

	let sharedText: string | undefined;

	if (shareDate) {
		sharedText = `${t('wishlist_shared_date_label', {
			date: formatDateAndTime(shareDate),
		})}`;
	}

	useEffect(() => {
		if (id && !isLoadingSelectedStore) {
			wishlistService.send({
				type: 'FETCH_WISHLIST_BY_ID',
				id,
				storeId: selectedStore?.id,
			});
		}
	}, [id, wishlistService, selectedStore?.id, isLoadingSelectedStore]);

	return (
		<Wishlist
			heading={t('wishlist_shared_wishlist_heading')}
			wishlist={wishlistData}
			sharedText={sharedText}
			moveVariantToCartButtonText={t('wishlist_add_product_to_cart_button')}
			moveAllVariantsToCartButtonText={t(
				'wishlist_add_all_products_to_cart_button',
			)}
		/>
	);
}

SharedWishlist.displayName = 'SharedWishlist';
