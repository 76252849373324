import React from 'react';

import FAQSearchComponent from 'components/FAQSearch';
import { LayoutContainer } from 'components/Layout';
import type { JulaComponentProps } from 'lib/component-props';
import { getEditorMargin } from 'utils/business-logic';

type Props = JulaComponentProps & {};

export default function FAQSearch({ params }: Props) {
	return (
		<LayoutContainer
			id={params?.anchor}
			outerClassName={getEditorMargin(params)}
			withGrid
		>
			<div className="col-span-4 md:col-span-6">
				<FAQSearchComponent />
			</div>
		</LayoutContainer>
	);
}
FAQSearch.displayName = 'FAQSearch';
