/**
 * ChunkedText
 */

import React from 'react';

import { chunkArray } from 'utils/collection';

interface Props {
	/** Number of characters per chunk */
	chunkSize: number;

	/** Text to chunk */
	text: string;
}

/** Text chunked into visually spaced parts */
export default function ChunkedText({ chunkSize, text }: Props) {
	if (!text || chunkSize < 1) {
		return null;
	}
	return (
		<>
			{chunkArray([...text], chunkSize).map((chars, i) => (
				// The text could contain repeating chunks so it can't be used as key.
				// eslint-disable-next-line react/no-array-index-key
				<span key={i} className={i === 0 ? undefined : 'ml-1'}>
					{chars.join('')}
				</span>
			))}
		</>
	);
}
ChunkedText.displayName = 'ChunkedText';
