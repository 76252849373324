/**
 * ErrorPageHeader
 */

import React from 'react';

interface Props {
	imgSrc: string;
	imgAlt?: string;
}

export default function ErrorPageHeader({ imgSrc, imgAlt = '' }: Props) {
	return (
		<div className="sticky top-0">
			<div className="relative flex max-h-24 items-center justify-center bg-julaRed p-6 sm:h-14 sm:min-h-full sm:p-2 md:h-14 md:min-h-full md:p-2">
				<img src={imgSrc} alt={imgAlt} className="w-30 sm:w-100 md:w-100" />
			</div>
		</div>
	);
}
ErrorPageHeader.displayName = 'ErrorPageHeader';
