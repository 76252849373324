/**
 * IndexedListItem
 */

import React from 'react';

import Link from 'components/Link';

interface Props {
	href: string;
	text: string;
}

export default function IndexedListItem({ href, text }: Props) {
	return (
		<li>
			<Link
				href={href}
				className="break-words font-standard text-lg hover:underline"
			>
				{text}
			</Link>
		</li>
	);
}
IndexedListItem.displayName = 'IndexedListItem';
