import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import ComponentPlaceholder from 'components/ComponentPlaceholder';
import Img from 'components/Img';
import InfoBox from 'components/InfoBox';
import { LayoutContainer } from 'components/Layout';
import Link from 'components/Link';
import type { JulaComponentProps } from 'lib/component-props';
import { DigizuiteAssetList } from 'models/asset';
import { SitecoreLink } from 'models/sitecore';
import { getEditorMargin } from 'utils/business-logic';
import { getAsset } from 'utils/helpers';

type Props = JulaComponentProps & {
	fields?: {
		assetIcon?: Field<DigizuiteAssetList>;
		bottomImage?: Field<DigizuiteAssetList>;
		error?: Field<boolean>;
		heading?: Field<string>;
		libraryIcon?: Field<string>;
		link?: Field<SitecoreLink>;
		text?: Field<string>;
	};
};

export default function InformationNotice({
	fields,
	isInNestedPlaceholder,
	params,
}: Props) {
	if (!fields) {
		return null;
	}

	const heading = fields.heading?.value;
	const icon =
		fields.libraryIcon?.value ||
		getAsset('Source Copy', fields.assetIcon?.value)?.src;
	const img = getAsset('Source Copy', fields.bottomImage?.value);
	const isError = fields.error?.value;
	const link = fields.link?.value.href;
	const linkText = fields.link?.value.text;
	const text = fields.text?.value;

	if (!text || !icon) {
		return (
			<ComponentPlaceholder
				className={getEditorMargin(params)}
				componentName="InformationNotice"
				description="The component requires an icon and a text"
			/>
		);
	}

	return (
		<LayoutContainer
			withPopoverLayout
			gutters={!isInNestedPlaceholder}
			maxWidth={isInNestedPlaceholder ? 'none' : undefined}
			outerClassName={getEditorMargin(params)}
		>
			<InfoBox
				className="max-w-[33rem] text-sm"
				icon={icon}
				heading={heading}
				variant={isError ? 'error' : 'information'}
			>
				<p>{text}</p>
				{img && <Img src={img.src} className="mt-4 max-h-6" />}
				{link && linkText && (
					<Link className="mt-4 block" href={link} underline>
						{linkText}
					</Link>
				)}
			</InfoBox>
		</LayoutContainer>
	);
}
InformationNotice.displayName = 'InformationNotice';
