import { publicRuntimeConfig } from 'config';
import type { GuiType } from 'models/connect/oauth';
import { createUrl } from 'utils/url';

export const POST_SIGN_EVENT = 'SIGN_DONE';
export type PostSignEventType = `${typeof POST_SIGN_EVENT}`;
export interface SignQuery {
	guiType: GuiType;
}

export function isGuiIframe() {
	return publicRuntimeConfig?.NEXT_PUBLIC_JULA_MARKET_CODE === 'SE';
}

export function getPostSigningRedirectUrl() {
	const locationOrigin = publicRuntimeConfig?.NEXT_PUBLIC_PUBLIC_URL;
	const guiType: GuiType = isGuiIframe() ? 'gui_iframe' : 'gui_full';
	return createUrl(`${locationOrigin}/api/sign/sign/`, {
		guiType,
	});
}
