/**
 * PageHeader
 */

import React, { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';

import ErrorBoundary from 'components/ErrorBoundary';
import { GlobalStoreSelectorPopover } from 'components/GlobalStoreSelector';
import JulaLogo from 'components/JulaLogo';
import { useDisclosureWidgetClose, useGlobalEvent } from 'hooks';
import type { Link as LinkType, MainLink, Review, USP } from 'models/sitecore';

import PageHeaderMain from './PageHeaderMain';
import PageHeaderNav from './PageHeaderNav';
import PageHeaderTopBar from './PageHeaderTopBar';

interface Props {
	/** Number of products in the cart */
	cartQuantity?: number;
	/** If the search field should be hidden */
	hideSearchBar?: boolean;
	/** If the user is logged in */
	isAuthenticatedUser?: boolean;
	/** If the user is being initialised */
	isInitialisingUser?: undefined | boolean;
	/** Main menu links */
	mainMenu?: MainLink[];
	/** Click handler for logged in account button */
	onAccountClick: () => void;
	/** Click handler for login button */
	onLoginClick: () => void;
	/** Menu links for the top bar */
	primaryTopLinks?: LinkType[];
	review?: Review | undefined;
	/** Secondary links for the menu row */
	secondaryTopLinks?: LinkType[];
	/** USP texts */
	uspLinks?: USP[];
	/** Wishlist product quantity */
	wishlistQuantity?: number;
}

/** Site page header */
export default function PageHeader({
	cartQuantity,
	hideSearchBar,
	isAuthenticatedUser,
	isInitialisingUser,
	mainMenu,
	onAccountClick,
	onLoginClick,
	primaryTopLinks,
	review,
	secondaryTopLinks,
	uspLinks,
	wishlistQuantity,
}: Props) {
	const router = useRouter();
	const toggledMenuCloseButtonRef = useRef<HTMLButtonElement>(null);
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [isStoreSelectorOpen, setIsStoreSelectorOpen] = useState(false);

	useGlobalEvent('open-store-selector', () => {
		setIsStoreSelectorOpen(true);
	});
	useGlobalEvent('open-stock-store-selector', () => {
		setIsStoreSelectorOpen(true);
	});

	// Close the menu on navigation.
	useEffect(() => {
		const handleRouteChange = () => {
			setIsMenuOpen(false);
		};
		router.events.on('routeChangeStart', handleRouteChange);
		return () => {
			router.events.off('routeChangeStart', handleRouteChange);
		};
	}, [router.events]);

	/* ---------- Toggled menu for smaller screens ---------- */
	const handleToggledMenuClose = () => {
		setIsMenuOpen(false);
	};
	const {
		onTriggerClick,
		onTriggerMouseDown,
		triggerRef: menuToggleRef,
		widgetRef: toggledMenuContainerRef,
	} = useDisclosureWidgetClose<HTMLButtonElement, HTMLDivElement>(
		isMenuOpen,
		handleToggledMenuClose,
	);
	const handleMenuToggleClick = () => {
		setIsMenuOpen((current) => !current);
		onTriggerClick();
		// Must wait for the menu to be displayed before focus can be moved.
		// Safari is especially fussy and requires a longer timeout than others.
		setTimeout(() => {
			toggledMenuCloseButtonRef.current?.focus();
		}, 150);
	};
	const handleToggledMenuCloseClick = () => {
		setIsMenuOpen(false);
		menuToggleRef.current?.focus();
	};

	// Use `display: contents` to allow the sticky part (PageHeaderMain) to stick
	// around for the entire page.
	// For a long time this has been bad for a11y, removing the node completely
	// from the accessibility tree. As of Mars 2023, the header element doesn't
	// seem to have any problems in Safari 16.3, Chrome 111 or Firefox 110 on
	// macOS when testing with VoiceOver.
	return (
		<>
			{/* Print only logo */}
			<div className="mb-4 hidden print:block">
				<JulaLogo />
			</div>
			<header data-testid="PageHeader" className="contents print:hidden">
				<ErrorBoundary isPageWidth>
					<PageHeaderTopBar
						uspLinks={uspLinks}
						menuLinks={primaryTopLinks}
						review={review}
					/>
				</ErrorBoundary>

				<ErrorBoundary isPageWidth>
					<PageHeaderMain
						cartQuantity={cartQuantity}
						hideSearchBar={hideSearchBar}
						isInitialisingUser={isInitialisingUser}
						isAuthenticatedUser={isAuthenticatedUser}
						isToggledMenuOpen={isMenuOpen}
						menuToggleRef={menuToggleRef}
						onAccountClick={onAccountClick}
						onLoginClick={onLoginClick}
						onMenuToggleClick={handleMenuToggleClick}
						onMenuToggleMouseDown={onTriggerMouseDown}
						wishlistQuantity={wishlistQuantity}
						onStoreSelectorClick={() => setIsStoreSelectorOpen(true)}
					/>
				</ErrorBoundary>

				<ErrorBoundary isPageWidth>
					<PageHeaderNav
						isToggledMenuOpen={isMenuOpen}
						mainMenu={mainMenu}
						onToggledMenuCloseClick={handleToggledMenuCloseClick}
						secondaryMenu={secondaryTopLinks}
						smallScreenSecondaryMenuExtra={primaryTopLinks}
						toggledMenuContainerRef={toggledMenuContainerRef}
						toggledMenuCloseButtonRef={toggledMenuCloseButtonRef}
						onStoreSelectorClick={() => setIsStoreSelectorOpen(true)}
					/>
				</ErrorBoundary>
			</header>
			<GlobalStoreSelectorPopover
				isOpen={isStoreSelectorOpen}
				onClose={() => setIsStoreSelectorOpen(false)}
			/>
		</>
	);
}
PageHeader.displayName = 'PageHeader';
