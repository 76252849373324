import React from 'react';

import Button from 'components/Button';
import Text from 'components/Text';
import {
	CreateCustomizationRequestItem,
	CustomizationPlacement,
} from 'models/api/ProductCustomization';

import PrintPlacement from './PrintPlacement';

interface ExistingPrintPlacementsViewProps {
	addNewPrintPlacementButtonText: string | undefined;
	description: string | undefined;
	heading: string | undefined;
	onCreateNewPrintPlacementClick: () => void;
	onSelectExistingPrintPlacementClick: (
		newPrint: CreateCustomizationRequestItem,
	) => void;
	printPlacements: CustomizationPlacement[] | undefined;
	printPlacementsLabel: string | undefined;
	selectExistingPrintPlacementButtonText: string | undefined;
}

export default function ExistingPrintPlacementsView({
	addNewPrintPlacementButtonText,
	description,
	heading,
	onCreateNewPrintPlacementClick,
	onSelectExistingPrintPlacementClick,
	printPlacements,
	printPlacementsLabel,
	selectExistingPrintPlacementButtonText,
}: ExistingPrintPlacementsViewProps) {
	return (
		<div>
			<Text as="h3" text={heading} />

			<Text as="p" text={printPlacementsLabel} className="mt-8 font-bold" />
			<Text as="p" text={description} />

			<ul className="mt-6 flex flex-col gap-2">
				{printPlacements?.map((printPlacement) => (
					<PrintPlacement
						key={printPlacement.id}
						placement={printPlacement.title || ''}
						logotypeName={printPlacement.printImageFilename?.originalFileName}
						size={printPlacement.option?.text}
						price={printPlacement.option?.price?.priceIncVat}
						onClick={() => {
							onSelectExistingPrintPlacementClick({
								placementId: printPlacement.id,
								printImageFilename: printPlacement.printImageFilename?.fileName,
								optionKey: printPlacement.option?.key,
							});
						}}
						buttonText={selectExistingPrintPlacementButtonText}
					/>
				))}
			</ul>
			<Button
				onClick={onCreateNewPrintPlacementClick}
				variant="cta"
				displayWidth="full"
				className="mt-6"
			>
				{addNewPrintPlacementButtonText}
			</Button>
		</div>
	);
}

ExistingPrintPlacementsView.displayName =
	'ProductPrintPopover_ExistingPrintPlacementsView';
