import React from 'react';

import { WebStock } from 'models/api';
import { Product } from 'models/product';
import {
	getFormattedStockAmount,
	ProductDetailsWebStockStatusType,
} from 'utils/business-logic/stock';
import { useI18n } from 'utils/i18n';

import StockBalanceDetailed from './StockBalanceDetailed';
import StockBalanceDetailedSkeleton from './StockBalanceDetailedSkeleton';

interface Props {
	isLoading: boolean;
	onWatchableVariantStockClick?: () => void;
	product: Product;
	status: ProductDetailsWebStockStatusType;
	webStock: WebStock | undefined;
}

export default function WebStockBalanceDetailed({
	isLoading,
	onWatchableVariantStockClick,
	product,
	status,
	webStock,
}: Props) {
	const { t } = useI18n();
	const { salesAttributes } = product;
	if (!salesAttributes) {
		return null;
	}

	if (isLoading) {
		return <StockBalanceDetailedSkeleton stockType="web" />;
	}

	switch (status) {
		case 'InStock':
			return (
				<StockBalanceDetailed
					statusType={status}
					locationIcon="parcel"
					header={t('product_details_online_accordion_available_heading')}
					stockStatus={{
						inStock: true,
						statusText: t('stock_general_amount_exact', {
							amount: getFormattedStockAmount(webStock?.availableStockLevel),
						}),
					}}
				/>
			);
		case 'ClickAndCollect':
			return (
				<StockBalanceDetailed
					statusType={status}
					locationIcon="parcel"
					header={t('product_details_online_accordion_available_heading')}
					stockStatus={{
						inStock: true,
						statusText: t('stock_general_amount_exact', {
							amount: getFormattedStockAmount(webStock?.availableStockLevel),
						}),
					}}
					infoText={t(
						'product_details_online_accordion_click_and_collect_text',
					)}
				/>
			);
		case 'OutOfStock':
			return (
				<StockBalanceDetailed
					statusType={status}
					locationIcon="parcel"
					header={t('product_details_online_accordion_available_heading')}
					stockStatus={{
						inStock: false,
						statusText: t('stock_general_out_of_stock'),
						statusInfo: t('product_details_online_accordion_out_of_stock_text'),
					}}
				/>
			);
		case 'ReStock-Watchable':
			return (
				<div>
					<StockBalanceDetailed
						statusType={status}
						locationIcon="parcel"
						header={t('product_details_online_accordion_available_heading')}
						stockStatus={{
							inStock: false,
							statusText: t('stock_general_re_stock'),
							statusInfo: t(
								'product_details_online_accordion_re_stock_watchable_text',
								{ month: webStock?.nextDeliveryInMonth },
							),
						}}
						onClick={onWatchableVariantStockClick}
						buttonText={t('product_watchable_popover_button')}
					/>
				</div>
			);
		case 'ReStock-Watchable-Delayed':
			return (
				<div>
					<StockBalanceDetailed
						statusType={status}
						locationIcon="parcel"
						header={t('product_details_online_accordion_available_heading')}
						stockStatus={{
							inStock: false,
							statusText: t('stock_general_re_stock'),
							statusInfo: t('product_details_store_re_stock_delayed_text'),
						}}
						onClick={onWatchableVariantStockClick}
						buttonText={t('product_watchable_popover_button')}
					/>
				</div>
			);
		case 'ReStock-NotWatchable':
			return (
				<StockBalanceDetailed
					statusType={status}
					locationIcon="parcel"
					header={t('product_details_online_accordion_available_heading')}
					stockStatus={{
						inStock: false,
						statusText: t('stock_general_re_stock'),
						statusInfo: t('product_details_online_accordion_re_stock_text', {
							month: webStock?.nextDeliveryInMonth,
						}),
					}}
				/>
			);
		case 'ReStock-NotWatchable-Delayed':
			return (
				<StockBalanceDetailed
					statusType={status}
					locationIcon="parcel"
					header={t('product_details_online_accordion_available_heading')}
					stockStatus={{
						inStock: false,
						statusText: t('stock_general_re_stock'),
						statusInfo: t('product_details_store_re_stock_delayed_text'),
					}}
				/>
			);
		case 'InStoreOnly':
			return (
				<StockBalanceDetailed
					statusType={status}
					locationIcon="parcel"
					header={t('product_details_online_accordion_available_heading')}
					stockStatus={{
						inStock: false,
						statusText: t('stock_online_store_only'),
					}}
				/>
			);
		default:
			return null;
	}
}
WebStockBalanceDetailed.displayName = 'WebStockBalanceDetailed';
