/**
 * OpeningHoursTable
 */

import React from 'react';
import clsx from 'clsx';

export interface Row {
	key: string;
	title: string;
	description: string;
}

interface Props {
	className?: string;
	caption?: string;
	rows: Row[];
}

export default function OpeningHoursTable({ className, caption, rows }: Props) {
	return (
		<table className={clsx('w-full text-sm', className)}>
			{caption && (
				<caption className="mb-2 text-left font-bold">{caption}</caption>
			)}
			<tbody>
				{rows.map((row) => (
					<tr key={row.key}>
						<th scope="row" className="text-left font-normal">
							{row.title}
						</th>
						<td className="text-right">{row.description}</td>
					</tr>
				))}
			</tbody>
		</table>
	);
}
OpeningHoursTable.displayName = 'OpeningHoursTable';
