import React from 'react';
import clsx from 'clsx';

import Text from 'components/Text';
import cn from 'utils/cn';
import { asArray, is } from 'utils/helpers';

const endsWithPunctuationRegExp = /[!.?]$/;

interface Props {
	className?: string;
	errorMessage: string | string[] | undefined;
	errorMessageId: string;
	helpText: string | undefined;
	helpTextId: string;
	invalid: boolean;
}

/** Help text and error messages. */
export default function InputInfo({
	className,
	errorMessage,
	errorMessageId,
	helpText,
	helpTextId,
	invalid,
}: Props) {
	const errors = asArray(errorMessage);
	const noEmptyStringErrors = errors.filter((error) => error !== ' ');
	const showErrors = invalid && is.arrayWithLength(noEmptyStringErrors);

	return (
		<div
			className={cn(
				// Add an additional pixel via border for alignment with the form field.
				// The border is not rem based like the padding.
				'ml-4 border-l border-l-transparent',
				(showErrors || helpText) && 'mt-2',
				className,
			)}
		>
			<Text renderEmpty as="pSmall" className="text-greyDark" id={helpTextId}>
				{helpText}
			</Text>
			<Text
				renderEmpty
				as="pSmall"
				className={clsx('text-julaRed', helpText && 'mt-1')}
				id={errorMessageId}
			>
				{showErrors &&
					noEmptyStringErrors.map((message) => (
						<React.Fragment key={message}>
							{message}
							{message && !endsWithPunctuationRegExp.test(message) && (
								<span className="sr-only">.</span>
							)}
							<br />
						</React.Fragment>
					))}
			</Text>
		</div>
	);
}
InputInfo.displayName = 'FormUI_InputInfo';
