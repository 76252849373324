/**
 * AccountDetailsCreditInformation
 */

import React from 'react';

import Button from 'components/Button';
import InfoBox from 'components/InfoBox';
import LinearGauge from 'components/LinearGauge';
import { Skeleton, SkeletonItem } from 'components/Skeleton';
import Text from 'components/Text';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

interface Props {
	isCreditCustomer: boolean | undefined;
	isValidCreditCustomer: boolean | undefined;
	availableCreditHeading: string;
	applyForCreditInfoHeading: string;
	creditSpace: number | undefined;
	creditInformationText: string;
	availableCreditBalance: number | undefined;
	descriptiveText: string;
	raiseCreditButtonOnClick: () => void;
	applyForCreditButtonOnClick: () => void;
	raiseCreditButtonText: string;
	applyForCreditButtonText: string;
	loading: boolean;
	hasError: boolean;
	lowerOrRemoveCreditText: string | undefined;
}

/** Displays info about jula credit for a potential credit customer or credit info for a credit user. */
function AccountDetailsCreditInformation({
	isCreditCustomer,
	isValidCreditCustomer,
	availableCreditHeading,
	applyForCreditInfoHeading,
	creditInformationText,
	raiseCreditButtonText,
	applyForCreditButtonText,
	creditSpace,
	availableCreditBalance,
	descriptiveText,
	applyForCreditButtonOnClick,
	raiseCreditButtonOnClick,
	loading,
	hasError,
	lowerOrRemoveCreditText,
}: Props) {
	const { t } = useI18n();
	const loadedAndOk = !loading && !hasError;
	return (
		<>
			{loadedAndOk && (
				<>
					<Text
						as="p"
						text={`${
							isCreditCustomer
								? availableCreditHeading
								: applyForCreditInfoHeading
						}`}
						className="font-bold"
					/>
					<Text
						as="p"
						text={`${
							isCreditCustomer ? `${creditSpace}.-` : creditInformationText
						}`}
						className="mb-6"
					/>
					{isCreditCustomer &&
						is.defined(availableCreditBalance) &&
						is.defined(creditSpace) && (
							<LinearGauge
								descriptiveText={descriptiveText}
								unitCount={availableCreditBalance}
								unitMax={creditSpace}
							/>
						)}
					{isCreditCustomer && isValidCreditCustomer && (
						<Button
							variant="primary"
							className="mt-6"
							onClick={raiseCreditButtonOnClick}
						>
							{raiseCreditButtonText}
						</Button>
					)}
					{!isCreditCustomer && (
						<Button
							variant="primary"
							className="mt-6"
							onClick={applyForCreditButtonOnClick}
						>
							{applyForCreditButtonText}
						</Button>
					)}
					{lowerOrRemoveCreditText && (
						<div className="mt-6">
							<InfoBox icon="info" variant="information">
								<Text as="pSmall">{lowerOrRemoveCreditText}</Text>
							</InfoBox>
						</div>
					)}
				</>
			)}

			{loading && (
				<Skeleton>
					<SkeletonItem height="1rem" className="mt-4" />
					<SkeletonItem height="1rem" className="mb-8 mt-2" />
					<SkeletonItem height="1rem" className="mt-4" />
					<SkeletonItem height="1rem" className="mb-8 mt-2" />
					<SkeletonItem height="3.5rem" className="mb-8 mt-2" />
				</Skeleton>
			)}
			{hasError && (
				<InfoBox
					icon="error"
					variant="error"
					message={t('account_generic_fetch_error_text')}
				/>
			)}
		</>
	);
}

export default AccountDetailsCreditInformation;
AccountDetailsCreditInformation.displayName = 'AccountDetailsCreditInformation';
