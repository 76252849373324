import React from 'react';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import { LayoutContainer } from 'components/Layout';
import VideoEmbed from 'components/VideoEmbed';
import { JulaComponentProps } from 'lib/component-props';
import { getEditorMargin } from 'utils/business-logic';

type Props = JulaComponentProps & {
	fields?: {
		youTubeLink: Field<string>;
	};
};

export default function YouTubeVideo({ params, fields }: Props) {
	if (!fields) {
		return null;
	}
	return (
		<LayoutContainer
			id={params?.anchor}
			outerClassName={getEditorMargin(params)}
			withGrid
		>
			<div className="col-span-4 md:col-span-6 md:col-start-4">
				<VideoEmbed url={fields.youTubeLink.value} />
			</div>
		</LayoutContainer>
	);
}
YouTubeVideo.displayName = 'YouTubeVideo';
