import React, { type ReactNode } from 'react';

import { LoginMethod } from 'models/connect/oauth';
import { getLoginOptions } from 'utils/business-logic';
import { useI18n } from 'utils/i18n';

import LoginOptions, { LoginOption } from './LoginOptions';

interface Props {
	errorMessage?: string | null;
	importantInformation?: ReactNode;
	introHeading?: string;
	introText?: string;
	loginOptions?: LoginOption[];
	onBecomeMemberClick?: () => void;
	onSelectLoginMethod: (loginMethod: LoginMethod) => void;
}

export default function ProLogin({
	errorMessage,
	importantInformation,
	introHeading,
	introText,
	loginOptions,
	onBecomeMemberClick,
	onSelectLoginMethod,
}: Props) {
	const { t } = useI18n();

	return (
		<LoginOptions
			introHeading={introHeading || t('account_pro_login_heading')}
			introText={introText || t('account_pro_login_intro_text')}
			errorMessage={errorMessage}
			importantInformation={importantInformation}
			memberType="pro"
			loginOptions={getLoginOptions('Pro', loginOptions)}
			onSelectLoginMethod={onSelectLoginMethod}
			becomeMemberButtonText={
				onBecomeMemberClick
					? t('account_julapro_become_member_button')
					: undefined
			}
			onBecomeMemberClick={onBecomeMemberClick}
		/>
	);
}
ProLogin.displayName = 'ProLogin';
