import type { FormField, FormFields } from 'models/sitecore';
import { filterObject } from 'utils/collection';
import { is } from 'utils/helpers';

// A helper that checks the values submitted from the generic form
// and makes sure everything is in order
// for now it checks that fields that have dependencies are correct, that the dependent field has a value
// so that we don't send something just because it's in the forms state
// it can also be passed an allow list to filter the values so that only those
// that should be passed on to the api are sent
export const getValuesForSubmit = (
	form: FormFields,
	values: Record<string, unknown>,
	allowList?: string[],
): Record<string, unknown> => {
	const inputFields = form.filter(
		(field) => field.type !== 'InfoField',
	) as FormField[];
	const currentValues = filterObject(values, (_val, key, obj) => {
		const field = inputFields.find(({ name }) => name === key);
		if (!field || (field.requires && !obj[field.requires])) {
			return false;
		}
		return true;
	});

	if (is.arrayWithLength(allowList)) {
		return filterObject(currentValues, (_val, key) => allowList.includes(key));
	}
	return filterObject(currentValues, (_val, key) =>
		Boolean(inputFields.find((field) => field.name === key)?.useData),
	);
};
