import LayoutWishlist from 'layouts/LayoutWishlist';

import Layout from './Layout';
import LayoutAccount from './LayoutAccount';
import LayoutCart from './LayoutCart';
import LayoutCheckout from './LayoutCheckout';
import LayoutLeftWide from './LayoutLeftWide';
import LayoutOrderStatus from './LayoutOrderStatus';

export type TemplateNames =
	| 'Account Root Route'
	| 'Account Route'
	| 'Brand Root Route'
	| 'Brand Information Route'
	| 'Brand List Route'
	| 'Cart Route'
	| 'Catalog Root Route'
	| 'Category List Route'
	| 'Checkout Route'
	| 'FAQ Root Route'
	| 'FAQ Route'
	| 'Generic Page Route'
	| 'Home Route'
	| 'Identification Route'
	| 'Inspiration Root Route'
	| 'Inspiration Route'
	| 'Legal Page Route'
	| 'My Order Route'
	| 'Order Confirmation Route'
	| 'Partial Route'
	| 'Product Route'
	| 'Search Route'
	| 'Size Guide Route'
	| 'Store Information Route'
	| 'Store Root Route'
	| 'Wishlist Route'
	| 'Spare Parts Route';

export const getLayout = (templateName: TemplateNames) => {
	switch (templateName) {
		case 'Account Root Route':
		case 'Account Route':
			return LayoutAccount;
		case 'Cart Route':
			return LayoutCart;
		case 'Checkout Route':
		case 'Identification Route':
			return LayoutCheckout;
		case 'My Order Route':
			return LayoutOrderStatus;
		case 'FAQ Root Route':
		case 'FAQ Route':
		case 'Spare Parts Route':
			return LayoutLeftWide;
		case 'Wishlist Route':
			return LayoutWishlist;
		default:
			return Layout;
	}
};
