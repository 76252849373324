import React, { useCallback, useEffect } from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import ComponentPlaceholder from 'components/ComponentPlaceholder';
import { LayoutContainer } from 'components/Layout';
import ProductCard from 'components/ProductCard';
import Slider from 'components/Slider';
import Text from 'components/Text';
import {
	useIsEditing,
	useMinWidth,
	useProductListGTMEvents,
	useRouteFields,
} from 'hooks';
import type { JulaComponentProps } from 'lib/component-props';
import type { ProductCard as ProductCardModel } from 'models/productCard';
import { getEditorMargin } from 'utils/business-logic';
import { is } from 'utils/helpers';

type Props = JulaComponentProps & {
	fields: {
		allowQuickBuy?: Field<boolean>;
		datasourceName?: string;
		heading: Field<string>;
		products: ProductCardModel[];
	};
};

export default function ProductCarousel({ fields, params }: Props) {
	const isMediumOrLarger = useMinWidth('md');
	const isLargeOrLarger = useMinWidth('lg');
	const isEditing = useIsEditing();
	const routeFields = useRouteFields();
	const hasPageTheme = Boolean(routeFields?.pageTheme?.value);

	const { allowQuickBuy, datasourceName, heading, products } = fields;
	const hasProducts = products.length > 0;
	const pageSize = isLargeOrLarger ? 5 : isMediumOrLarger ? 4 : 2;

	const { gtmItemListId, gtmItemListName, sendViewItemListEvent } =
		useProductListGTMEvents(
			'block',
			`Block: ${datasourceName ?? 'ProductCarouselUnknown'}`,
		);

	const sendViewToGTM = useCallback(
		(nextIndex: number) => {
			if (hasProducts) {
				sendViewItemListEvent(
					products.slice(0, nextIndex + pageSize),
					pageSize,
				);
			}
		},
		[hasProducts, sendViewItemListEvent, products, pageSize],
	);

	useEffect(() => {
		if (is.defined(isLargeOrLarger) && is.defined(isMediumOrLarger)) {
			sendViewToGTM(0);
		}
	}, [sendViewToGTM, isLargeOrLarger, isMediumOrLarger]);

	if (
		(isEditing && !heading?.value && !hasProducts) ||
		(!isEditing && !hasProducts)
	) {
		return (
			<ComponentPlaceholder
				componentName="ProductCarousel"
				description="Requires products"
				className={getEditorMargin(params)}
			/>
		);
	}

	const carousel = (
		<Slider
			onNext={(nextIndex) => sendViewToGTM(nextIndex)}
			hasOuterGutter={hasPageTheme}
			items={products.map((product, i) => (
				<ProductCard
					key={product.id}
					product={product}
					productListIndex={i}
					gtmItemListId={gtmItemListId}
					gtmItemListName={gtmItemListName}
					showAddToCartButton={
						allowQuickBuy?.value &&
						product.isSellable &&
						!product.hasMultipleVariants
					}
					className={hasPageTheme ? 'py-4' : ''}
				/>
			))}
		/>
	);

	return (
		<LayoutContainer
			id={params?.anchor}
			outerClassName={getEditorMargin(params)}
		>
			<Text as={params?.heading ?? 'h2'} field={heading} className="mb-8" />
			{hasPageTheme && (
				<div className="-mr-4 rounded-l-lg bg-white text-greyDarker md:mr-0 md:rounded-lg md:pb-6">
					{carousel}
				</div>
			)}
			{!hasPageTheme && carousel}
		</LayoutContainer>
	);
}
ProductCarousel.displayName = 'ProductCarousel_JSS';
