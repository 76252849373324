import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import { BlockBrandHeading } from 'components/BlockBrandHeading';
import { LayoutContainer } from 'components/Layout';
import type { JulaComponentProps } from 'lib/component-props';
import type { Asset, DigizuiteAssetList } from 'models/asset';
import { getEditorMargin } from 'utils/business-logic';
import { getAsset } from 'utils/helpers';

type Props = JulaComponentProps & {
	fields?: {
		brandAssetsJson?: Asset[];
		brandImage: DigizuiteAssetList;
		descriptionFormatted?: Field<string>;
		longTitle?: Field<string>;
		title?: Field<string>;
	};
};

export default function BrandHeading({ fields, params }: Props) {
	if (!fields) {
		return null;
	}
	const { brandAssetsJson, descriptionFormatted, longTitle } = fields;
	const img = getAsset('Templated 2:1', fields.brandImage);

	const primaryLogo = brandAssetsJson
		?.find((item) => item.type === 'Logotype')
		?.versions?.find((logo) => logo.subType === 'PrimaryLogo')
		?.formats?.find(
			(formats) => formats.type === 'ODL 300px height transparent',
		);

	return (
		<LayoutContainer
			id={params?.anchor}
			outerClassName={getEditorMargin(params)}
		>
			<BlockBrandHeading
				title={longTitle?.value}
				logo={primaryLogo?.url?.location || ''}
				description={descriptionFormatted?.value}
				image={img?.src || ''}
				imageAlt={img?.alt}
			/>
		</LayoutContainer>
	);
}
BrandHeading.displayName = 'BrandHeading';
