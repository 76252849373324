import React from 'react';
import clsx from 'clsx';

import Text from 'components/Text';

interface Props {
	heading: string;
	sectionColor: string | undefined;
	shelf: string;
	className?: string;
}

export default function StoreLocation({
	heading,
	className,
	sectionColor,
	shelf,
}: Props) {
	return (
		<div className={clsx('flex items-center justify-end', className)}>
			<Text as="span">
				<strong>{heading} </strong>
			</Text>
			<Text
				style={{
					background: sectionColor || '#fff',
				}}
				as="span"
				className={clsx(
					'ml-2 rounded-border p-2 font-bold md:px-4 md:py-3',
					sectionColor && 'text-white',
				)}
			>
				{shelf}
			</Text>
		</div>
	);
}
StoreLocation.displayName = 'StoreLocation';
