import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-react';

import BlockPromoStartPage from 'components/BlockPromoStartPage';
import ComponentPlaceholder from 'components/ComponentPlaceholder';
import { LayoutContainer } from 'components/Layout';
import { useEffectOnce, useProductListGTMEvents } from 'hooks';
import type { ColorField, JulaComponentProps } from 'lib/component-props';
import type { DigizuiteAssetList } from 'models/asset';
import type { ProductCard } from 'models/productCard';
import type { SitecoreLink } from 'models/sitecore';
import { getEditorMargin } from 'utils/business-logic';
import { filterTruthy, withTruthyProps } from 'utils/collection';
import { getAsset, is } from 'utils/helpers';

interface Category {
	fields: {
		icon?: DigizuiteAssetList;
		title: Field<string>;
	};
	id: string;
	url: string;
}

interface SupportItem {
	displayName: string;
	fields: {
		campaignBubbleLabel?: Field<string>;
		campaignBubbleText?: Field<string>;
		landscapeImage?: DigizuiteAssetList;
		link?: Field<SitecoreLink>;
	};
	id: string;
}

type Props = JulaComponentProps & {
	fields: {
		campaignBubbleLabel?: Field<string>;
		campaignBubbleText?: Field<string>;
		categories?: Category[];
		datasourceName?: string;
		heading?: Field<string>;
		largeImage?: DigizuiteAssetList;
		preventAutoplay?: Field<boolean>;
		primaryLink?: Field<SitecoreLink>;
		products?: ProductCard[];
		promoSupportItems?: SupportItem[];
		secondaryLink?: Field<SitecoreLink>;
		smallImage?: DigizuiteAssetList;
		tertiaryLink?: Field<SitecoreLink>;
		textBackgroundColor?: ColorField;
		video?: DigizuiteAssetList;
	};
};

/**
 * Media grid and category links.
 *
 * - One large primary image/video area with product animation and links.
 * - Two smaller secondary image/video areas with a link.
 * - List of level one categories.
 *
 * Order of secondary items and category links is different for desktop/mobile.
 */
export default function PromoStartPage({ fields, params }: Props) {
	const {
		categories,
		datasourceName,
		primaryLink,
		products,
		secondaryLink,
		tertiaryLink,
	} = fields;
	// TODO: separate largeVideo and smallVideo for better mobile performance.
	const primaryLargeVideo = getAsset('Source Copy', fields.video);
	const primarySmallVideo = getAsset('Source Copy', fields.video);
	const primaryLargeImage = getAsset('Templated 2:1', fields.largeImage);
	const primarySmallImage = getAsset('Templated 4:3', fields.smallImage);

	const primaryLinks = filterTruthy(
		[primaryLink, secondaryLink, tertiaryLink]
			.filter(is.truthy)
			.map((link) => link.value),
		'href',
		'text',
	);

	const supportItems = (fields.promoSupportItems ?? [])
		.map((item) => {
			const image = getAsset('Templated 2:1', item.fields.landscapeImage);
			return {
				campaignBubbleLabel: item.fields.campaignBubbleLabel?.value,
				campaignBubbleText: item.fields.campaignBubbleText?.value,
				id: item.id,
				imageSrc: image?.src,
				imageAlt: image?.alt,
				link: withTruthyProps(item.fields.link?.value, 'href', 'text'),
				// Currently no video support for support items.
				video: undefined,
			};
		})
		.filter((item) => item.imageSrc || item.video)
		.slice(0, 2);

	const categoryIcons = filterTruthy(
		(categories ?? []).map((cat) => ({
			iconUrl: getAsset('Source Copy', cat.fields.icon)?.src,
			title: cat.fields.title.value,
			url: cat.url,
		})),
		'title',
		'url',
	);

	const hasPrimaryImage =
		is.truthy(primarySmallImage) && is.truthy(primaryLargeImage);
	const hasPrimaryVideo =
		is.truthy(primarySmallVideo) && is.truthy(primaryLargeVideo);
	const hasRequiredData =
		supportItems.length === 2 && (hasPrimaryImage || hasPrimaryVideo);

	const { gtmItemListId, gtmItemListName, sendViewItemListEvent } =
		useProductListGTMEvents(
			'block',
			`Block: ${datasourceName ?? 'PromoStartPageUnknown'}`,
		);
	useEffectOnce(() => {
		if (products?.length) {
			sendViewItemListEvent(products, products.length);
		}
	});

	if (!hasRequiredData) {
		return (
			<ComponentPlaceholder
				componentName="PromoStartPage"
				description="Requires a primary image/video and two support items"
				className={getEditorMargin(params)}
			/>
		);
	}

	return (
		<LayoutContainer id={params?.anchor} className={getEditorMargin(params)}>
			<BlockPromoStartPage
				basePriceTextBackground={
					fields.textBackgroundColor?.fields?.colorHex?.value
				}
				campaignBubbleLabel={fields.campaignBubbleLabel?.value}
				campaignBubbleText={fields.campaignBubbleText?.value}
				categoryIcons={categoryIcons}
				gtmItemListId={gtmItemListId}
				gtmItemListName={gtmItemListName}
				heading={fields.heading}
				headingLevel={params?.heading}
				preventAutoplay={fields.preventAutoplay?.value}
				primaryLargeImageAlt={primaryLargeImage?.alt}
				primaryLargeImageSrc={primaryLargeImage?.src}
				primaryLargeVideo={primaryLargeVideo}
				primaryLinkHref={primaryLink?.value.href}
				primaryLinks={primaryLinks}
				primarySmallImageAlt={primarySmallImage?.alt}
				primarySmallImageSrc={primarySmallImage?.src}
				primarySmallVideo={primarySmallVideo}
				products={products}
				supportItems={supportItems}
			/>
		</LayoutContainer>
	);
}
PromoStartPage.displayName = 'PromoStartPage';
