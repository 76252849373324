import React from 'react';

import type { CustomLayoutServiceData, UserInfo } from 'lib/page-props';

import BaseLayout from './BaseLayout';

interface Props {
	layoutData: CustomLayoutServiceData;
	userInfo?: UserInfo;
}

export default function LayoutLeftWide({ layoutData, userInfo }: Props) {
	const { route } = layoutData.sitecore;

	return (
		<BaseLayout
			layoutData={layoutData}
			route={route}
			userInfo={userInfo}
			isLeftWide
		/>
	);
}
LayoutLeftWide.displayName = 'LayoutLeftWide';
