/**
 * BlockThemedProductCarousel
 */

import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import Chip from 'components/Chip';
import Img from 'components/Img';
import { LayoutContainer } from 'components/Layout';
import ProductCard from 'components/ProductCard';
import Slider from 'components/Slider';
import Text from 'components/Text';
import ThemedBlockContainer from 'components/ThemedBlockContainer';
import type { HeadingLevel } from 'lib/component-props';
import type { ProductCard as ProductCardType } from 'models/productCard';
import type { SimpleLink } from 'models/sitecore';
import type { GTMItemListId, GTMItemListName } from 'utils/GoogleTagManager';

interface Props {
	centerImageAlt: string | undefined;
	centerImageLargeSrc: string | undefined;
	centerImageSmallSrc: string | undefined;
	className?: string;
	color: string;
	gtmItemListId: GTMItemListId;
	gtmItemListName: GTMItemListName;
	heading?: Field<string>;
	headingLevel?: HeadingLevel;
	id?: string;
	link?: SimpleLink;
	products: ProductCardType[];
	text?: Field<string>;
	topLeftImageSrc: string | undefined;
}

export default function BlockThemedProductCarousel({
	centerImageAlt,
	centerImageLargeSrc,
	centerImageSmallSrc,
	className,
	color,
	gtmItemListId,
	gtmItemListName,
	heading,
	headingLevel = 'h2',
	id,
	link,
	products,
	text,
	topLeftImageSrc,
}: Props) {
	return (
		<LayoutContainer className={className} id={id}>
			<Text
				as={headingLevel}
				field={heading}
				className={text ? 'mb-2' : 'mb-4'}
			/>
			<Text as="p" field={text} className="mb-6" />

			<ThemedBlockContainer
				color={color}
				topLeftImageSrc={topLeftImageSrc}
				className="max-md:-mx-4"
				contentClassName="py-4 md:grid md:grid-cols-12 md:items-center md:gap-x-6 md:py-12"
			>
				<div className="relative self-stretch text-center max-md:mb-6 md:col-span-4 md:flex md:items-center">
					{centerImageSmallSrc && centerImageLargeSrc && (
						<>
							<Img
								src={centerImageSmallSrc}
								alt={centerImageAlt}
								width={360}
								height={180}
								service="nextjs"
								jpgOptimized={false}
								className="mx-auto md:hidden"
							/>
							<Img
								src={centerImageLargeSrc}
								alt={centerImageAlt}
								width={532}
								height={532}
								service="nextjs"
								jpgOptimized={false}
								className="mx-auto max-md:hidden"
							/>
						</>
					)}
					{link && (
						<Chip
							href={link.href}
							text={link.text}
							color="grey"
							className="mt-2 md:absolute md:bottom-0 md:left-1/2 md:-translate-x-1/2"
						/>
					)}
				</div>

				<div className="rounded-l-lg bg-white pt-4 text-greyDarker max-md:ml-4 md:col-span-8 md:self-stretch md:pb-6">
					<Slider
						sizeClasses="w-2/3 sm:w-[29%] lg:w-[23%]"
						hasOuterGutter
						sliderClassName="max-md:pb-6"
						items={products.map((product, i) => (
							<ProductCard
								key={product.id}
								product={product}
								productListIndex={i}
								gtmItemListId={gtmItemListId}
								gtmItemListName={gtmItemListName}
							/>
						))}
					/>
				</div>
			</ThemedBlockContainer>
		</LayoutContainer>
	);
}
BlockThemedProductCarousel.displayName = 'BlockThemedProductCarousel';
