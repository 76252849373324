import React from 'react';
import clsx from 'clsx';

import { StoreIdName, useFeatureToggle } from 'contexts';
import { ItemStockResponse, Stock, StockStore } from 'models/api';
import { Product } from 'models/product';

import StoreStock from './StoreStock';
import WebStock from './WebStock';

interface Props {
	allStoresStock: Stock[] | undefined;
	className?: string;
	currentProductStock: ItemStockResponse | undefined;
	currentStoreStock: Stock | undefined;
	isLoadingInitialStock: boolean;
	isLoadingNearbyStoresStock: boolean;
	isLoadingStock: boolean;
	nearbyStoresStock: Stock[] | undefined;
	onOpenCallBack: () => void;
	onSearch: (searchValue: string) => void;
	onTabChange: (tab: string) => void;
	onUpdateSelectedStore: (store: StockStore) => void;
	product: Product;
	selectedStore: StoreIdName | undefined;
}

export default function StockInformation({
	allStoresStock,
	className,
	currentProductStock,
	currentStoreStock,
	isLoadingInitialStock,
	isLoadingNearbyStoresStock,
	isLoadingStock,
	nearbyStoresStock,
	onOpenCallBack,
	onSearch,
	onTabChange,
	onUpdateSelectedStore,
	product,
	selectedStore,
}: Props) {
	const { storesEnabled } = useFeatureToggle();
	return (
		<div
			className={clsx(
				className,
				'divide-y-1 divide-grey rounded-border border-1 border-grey',
			)}
		>
			<WebStock
				product={product}
				webStock={currentProductStock?.webStock}
				isLoadingInitialStock={isLoadingInitialStock}
			/>
			{storesEnabled && (
				<StoreStock
					currentProductStock={currentProductStock}
					currentStoreStock={currentStoreStock}
					allStoresStock={allStoresStock}
					nearbyStoresStock={nearbyStoresStock}
					onOpenCallBack={onOpenCallBack}
					onUpdateSelectedStore={onUpdateSelectedStore}
					product={product}
					selectedStore={selectedStore}
					isLoadingInitialStock={isLoadingInitialStock}
					isLoadingStock={isLoadingStock}
					isLoadingNearbyStoresStock={isLoadingNearbyStoresStock}
					onTabChange={onTabChange}
					onSearch={onSearch}
				/>
			)}
		</div>
	);
}
StockInformation.displayName = 'ProductDetails_StockInformation';
