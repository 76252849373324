import React from 'react';

import { is } from 'utils/helpers';

/**
 * Create a component where the specified props are marked as required. If any
 * of those props are undefined or null, the component doesn't render.
 */
export function withRequiredProps<
	T extends object,
	K extends keyof T,
	FilteredT extends T & Required<Pick<T, K>>,
>(
	Component: React.FunctionComponent<T>,
	...propKeys: [K, ...K[]]
): React.FunctionComponent<FilteredT> {
	function Required(newProps: T) {
		if (!propKeys.every((propKey) => is.defined(newProps[propKey]))) {
			return null;
		}
		return <Component {...newProps} />;
	}
	Required.displayName = `${Component.displayName}WithRequiredFields`;
	return Required;
}
