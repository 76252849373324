/**
 * ProductDetailsAdditionalSales
 */

import React from 'react';

import { ActionButtonState } from 'components/ActionButton';
import InfoBox from 'components/InfoBox';
import Popover from 'components/Popover';
import ProductCard from 'components/ProductCard';
import { useProductListGTMEvents } from 'hooks';
import { RelatedProductCard } from 'models/productCard';
import { ButtonStateWithId } from 'state-machines/cart';
import type { GTMItemListId, GTMItemListName } from 'utils/GoogleTagManager';
import { useI18n } from 'utils/i18n';

interface AdditionalSalesPopoverProps {
	buttonState: { buttonId?: string; state: ActionButtonState };
	buttonText?: string;
	gtmItemListId: GTMItemListId;
	gtmItemListName: GTMItemListName;
	id: string;
	isOpen: boolean;
	onAddItem: (product: RelatedProductCard) => void;
	onClose: () => void;
	products: RelatedProductCard[] | undefined;
	title: string;
	toastLink: string;
	toastLinkText: string;
	toastText: string;
}

function AdditionalSalesPopover({
	buttonState,
	buttonText,
	gtmItemListId,
	gtmItemListName,
	id,
	isOpen,
	onAddItem,
	onClose,
	products,
	title,
	toastLink,
	toastLinkText,
	toastText,
}: AdditionalSalesPopoverProps) {
	const { sendViewItemListEvent } = useProductListGTMEvents(
		gtmItemListId,
		gtmItemListName,
	);

	return (
		<Popover
			isOpen={isOpen}
			id={id}
			beforeHeader={
				<InfoBox
					variant="success"
					icon="info"
					heading={toastText}
					link={toastLink}
					linkText={toastLinkText}
				/>
			}
			title={title}
			onOpen={() => {
				if (products?.length) {
					sendViewItemListEvent(products, products.length);
				}
			}}
			onClose={onClose}
		>
			{products?.map((product, i) => (
				<ProductCard
					orientation="row"
					key={product.id}
					onPurchaseButtonClick={() => onAddItem(product)}
					product={product}
					productListIndex={i}
					gtmItemListId={gtmItemListId}
					gtmItemListName={gtmItemListName}
					showAddToCartButton
					actionButtonState={
						product.id === buttonState.buttonId ? buttonState.state : undefined
					}
					buttonText={buttonText}
				/>
			))}
		</Popover>
	);
}

AdditionalSalesPopover.displayName = 'AdditionalSalesPopover';

interface Props {
	additionalSalesGTMItemListId: GTMItemListId;
	additionalSalesGTMItemListName: GTMItemListName;
	additionalSalesIsOpen: boolean;
	additionalSalesProducts: RelatedProductCard[] | undefined;
	additionalSalesToastText: string;
	onAdditionalSalesButtonClick: (product: RelatedProductCard) => void;
	onAdditionalSalesClose: () => void;
	onWishlistAdditionalSalesButtonClick: (product: RelatedProductCard) => void;
	onWishlistAdditionalSalesClose: () => void;
	productId: string;
	purchaseButtonState: ButtonStateWithId;
	wishlistAdditionalSalesGTMItemListId: GTMItemListId;
	wishlistAdditionalSalesGTMItemListName: GTMItemListName;
	wishlistAdditionalSalesIsOpen: boolean;
	wishlistAdditionalSalesProducts: RelatedProductCard[] | undefined;
	wishlistAdditionalSalesToastText: string | undefined;
	wishlistButtonState: ButtonStateWithId;
}

/** Additional sales popover panel for product detail. */
export default function ProductDetailsAdditionalSales({
	additionalSalesGTMItemListId,
	additionalSalesGTMItemListName,
	additionalSalesIsOpen,
	additionalSalesProducts,
	additionalSalesToastText,
	onAdditionalSalesButtonClick,
	onAdditionalSalesClose,
	onWishlistAdditionalSalesButtonClick,
	onWishlistAdditionalSalesClose,
	productId,
	purchaseButtonState,
	wishlistAdditionalSalesGTMItemListId,
	wishlistAdditionalSalesGTMItemListName,
	wishlistAdditionalSalesIsOpen,
	wishlistAdditionalSalesProducts,
	wishlistAdditionalSalesToastText,
	wishlistButtonState,
}: Props) {
	const { t } = useI18n();

	return (
		<>
			<AdditionalSalesPopover
				buttonState={purchaseButtonState}
				gtmItemListId={additionalSalesGTMItemListId}
				gtmItemListName={additionalSalesGTMItemListName}
				id={`additional-sales-${productId}`}
				isOpen={additionalSalesIsOpen}
				onAddItem={onAdditionalSalesButtonClick}
				onClose={onAdditionalSalesClose}
				products={additionalSalesProducts}
				title={t('product_details_additional_sales_popover_heading')}
				toastLink="/cart/"
				toastLinkText={t('continue_to_cart')}
				toastText={additionalSalesToastText}
			/>
			<AdditionalSalesPopover
				buttonState={wishlistButtonState}
				buttonText={t(
					'product_details_wishlist_additional_sales_popover_button',
				)}
				gtmItemListId={wishlistAdditionalSalesGTMItemListId}
				gtmItemListName={wishlistAdditionalSalesGTMItemListName}
				id={`additional-sales-wishlist-${productId}`}
				isOpen={wishlistAdditionalSalesIsOpen}
				onAddItem={onWishlistAdditionalSalesButtonClick}
				onClose={onWishlistAdditionalSalesClose}
				products={wishlistAdditionalSalesProducts}
				title={t('product_details_wishlist_additional_sales_popover_heading')}
				toastLink="/wishlist/"
				toastLinkText={t('continue_to_wishlist')}
				toastText={wishlistAdditionalSalesToastText || ''}
			/>
		</>
	);
}
ProductDetailsAdditionalSales.displayName = 'ProductDetailsAdditionalSales';
