import React, { type MouseEventHandler } from 'react';
import clsx from 'clsx';
import { useRouter } from 'next/router';

import IndexedList, { IndexedListItem } from 'components/IndexedList';
import { LayoutContainer } from 'components/Layout';
import ScreenReaderAnnouncementText from 'components/ScreenReaderAnnouncementText';
import SwipeWrapper from 'components/SwipeWrapper';
import { JulaComponentProps } from 'lib/component-props';
import { getEditorMargin } from 'utils/business-logic';
import { groupBy } from 'utils/collection';
import { ignorePromiseRejection, is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

interface FilterItem {
	id: string;
	name: string;
	section: string;
	url: string;
}

type Props = JulaComponentProps & {
	fields?: {
		items?: FilterItem[];
	};
};

const filterWidthClass = {
	26: 'max-w-[60rem]',
	27: 'max-w-[62rem]',
	28: 'max-w-[64rem]',
	29: 'max-w-[66rem]',
	30: 'max-w-[68rem]',
	31: 'max-w-[70rem]',
	32: 'max-w-[72rem]',
};

export default function BrandFilterList({ params, fields }: Props) {
	const { t, tPlural } = useI18n();
	const router = useRouter();

	if (!fields?.items) {
		return null;
	}

	const allBrandsLabel = t('brands_brand_filter_list_show_all_button');
	const sectionData = groupBy(fields.items, 'section');
	const filterButtonLabels = [allBrandsLabel, ...sectionData.keys()];

	const urlBrand = router.query.brand?.toString() || '';
	const selectedSection =
		urlBrand && filterButtonLabels.includes(urlBrand)
			? urlBrand
			: allBrandsLabel;

	const sections =
		selectedSection === allBrandsLabel
			? [...sectionData.entries()]
			: [...sectionData.entries()].filter(
					([section]) => section === selectedSection,
				);

	const hasFiltered = selectedSection !== allBrandsLabel;
	const filteredSectionBrandAmount = sections?.[0]?.[1]?.length ?? 0;

	const handleFilterClick: MouseEventHandler<HTMLButtonElement> = (e) => {
		const btn = e.currentTarget;
		if (is.element(btn) && btn.dataset.section) {
			const newSection = btn.dataset.section;
			if (newSection === selectedSection) {
				return;
			}
			const newUrl = new URL(window.location?.href);
			if (newSection === allBrandsLabel) {
				newUrl.searchParams.delete('brand');
			} else {
				newUrl.searchParams.set('brand', newSection);
			}
			ignorePromiseRejection(
				router.replace(newUrl.toString(), undefined, {
					scroll: false,
					shallow: true,
				}),
			);
		}
	};

	return (
		<>
			<LayoutContainer
				outerClassName={getEditorMargin(params, 'None')}
				className="my-8 md:mb-12 md:mt-16"
			>
				<SwipeWrapper
					spacing={1}
					activeClassName="sm:hidden"
					inactiveClassName={clsx(
						'mx-auto flex flex-wrap justify-center gap-4 max-sm:hidden md:gap-6',
						filterWidthClass[filterButtonLabels.length] || filterWidthClass[26],
					)}
					pullGutters
				>
					{filterButtonLabels.map((label) => (
						<li key={label} className="shrink-0">
							<button
								type="button"
								className={clsx(
									'h-11 min-w-[2.75rem] rounded-full border border-greyLight font-standard text-base',
									label !== selectedSection &&
										'bg-white hover:bg-julaRed hover:text-white',
									label === selectedSection && 'bg-julaRed text-white',
									label.length > 1 && 'px-4',
								)}
								onClick={handleFilterClick}
								// Used in click handler
								data-section={label}
							>
								{label}
							</button>
						</li>
					))}
				</SwipeWrapper>
			</LayoutContainer>
			<ScreenReaderAnnouncementText
				as="span"
				hidden
				atomic
				text={
					hasFiltered
						? tPlural(
								'brands_brand_filter_list_filtered_items_count_text',
								filteredSectionBrandAmount,
							)
						: ''
				}
			/>
			{sections.map(([section, items]) => (
				<LayoutContainer
					key={section}
					gutterType="padding"
					outerClassName="sm:border-t sm:border-t-greyLight"
				>
					<IndexedList heading={section} className="max-sm:mb-12 sm:py-12">
						{items.map((item) => (
							<IndexedListItem
								key={item.url}
								href={item.url}
								text={item.name}
							/>
						))}
					</IndexedList>
				</LayoutContainer>
			))}
		</>
	);
}
BrandFilterList.displayName = 'BrandFilterList';
