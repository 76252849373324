/**
 * ProductListFacetAccordions
 */

import React, { type ChangeEventHandler } from 'react';

import Accordion from 'components/Accordion';
import Count from 'components/Count';
import ExpandableItems from 'components/ExpandableItems';
import { Checkbox } from 'components/FormUi';
import { FacetResponse } from 'models/api';
import { filterTruthy } from 'utils/collection';
import { is } from 'utils/helpers';
import { addPunctuationBreak, slugify } from 'utils/string';

interface Props {
	activeFacets: FacetResponse[];
	disabled?: boolean;
	facets: FacetResponse[];
	onChange: ChangeEventHandler<HTMLInputElement>;
}

/** Accordions with checkboxes for each facet. */
export default function ProductListFacetAccordions({
	activeFacets,
	disabled,
	facets,
	onChange,
}: Props) {
	if (facets.length === 0) {
		return null;
	}

	const activePairs = activeFacets
		.flatMap((facet) =>
			facet.facetItems?.map((item): [string, string] => [facet.id, item.item]),
		)
		.filter(is.truthy);

	const isItemActive = (facetId: string, itemText: string) =>
		activePairs.some(([id, text]) => id === facetId && text === itemText);

	return (
		<>
			{facets.map((facet) => (
				<Accordion
					key={facet.id}
					id={`facet-${facet.id}`}
					title={facet.title}
					titleTag="h2"
					size="medium"
					color="white"
					initialOpen
				>
					<ExpandableItems
						items={filterTruthy(facet.facetItems, 'item').map((item) => (
							<Checkbox
								key={`${facet.id}-${item.item}`}
								id={slugify(`${facet.id}-${item.item}`)}
								name={facet.id}
								value={item.item}
								checked={isItemActive(facet.id, item.item)}
								disabled={disabled}
								onChange={onChange}
								className="mb-2"
								labelClassName="grow flex"
								label={
									<>
										{addPunctuationBreak(item.item)}
										<Count
											variant="text"
											amount={`(${item.count})`}
											className="ml-auto px-2"
										/>
									</>
								}
							/>
						))}
					/>
				</Accordion>
			))}
		</>
	);
}
ProductListFacetAccordions.displayName = 'ProductListFacetAccordions';
