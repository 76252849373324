import React, { useCallback, useEffect, useRef } from 'react';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';

import Img from 'components/Img';
import { ImagePopover } from 'components/Popover';
import Tabs from 'components/Tabs';
import ThumbnailList from 'components/ThumbnailList';
import type { ReviewImage, ReviewImageId } from 'hooks/product-details';
import type { Thumbnail } from 'utils/business-logic';
import { afterNextPaint, is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

import ReviewImagesView from './ReviewImagesView';

export type ProductAndSelectedImagesTabs = 'product-images' | 'review-images';

interface Props {
	activeTabId: 'product-images' | 'review-images';
	carouselIndex: number;
	/** An array of product images */
	images: Thumbnail[];
	/** Whether the layover is open or not */
	isOpen: boolean;
	/** Function to call when the layover should be closed */
	onClose: () => void;
	onReviewImagesSlideChange: (slideIndex: number) => void;
	/** Function to call when the layover tab should be changed */
	onTabChange: (newTabId: ProductAndSelectedImagesTabs) => void;
	onThumbnailClick: (activeImageId: number) => void;
	onThumbnailSlideChange: (index: number) => void;
	/** An array of images with related review */
	reviewImages: ReviewImage[] | undefined;
	/** ID of the image that should be selected and scrolled to */
	selectedImageId: number;
	/** currently selected review image */
	selectedReviewImageId: ReviewImageId | undefined;
}

export default function ProductAndSelectedImages({
	activeTabId,
	carouselIndex,
	images,
	isOpen,
	onClose: onCloseProp,
	onReviewImagesSlideChange,
	onTabChange: onTabChangeProp,
	onThumbnailClick,
	onThumbnailSlideChange,
	reviewImages,
	selectedImageId,
	selectedReviewImageId,
}: Props) {
	const { t } = useI18n();
	const productImagesScrollRef = useRef<HTMLDivElement>(null);
	const reviewImagesScrollRef = useRef<HTMLDivElement>(null);
	const largeImages = useRef<(HTMLDivElement | null)[]>([]);

	const scrollToImage = useCallback(
		(imageId: number) => {
			const img = largeImages.current[imageId];
			if (img && isOpen) {
				afterNextPaint(() => {
					img.scrollIntoView({
						behavior: 'smooth',
						block: 'start',
					});
				});
				setTimeout(() => {
					img.focus();
				}, 250);
			}
		},
		[isOpen],
	);

	const onOpen = useCallback(() => {
		if (activeTabId === 'product-images') {
			if (productImagesScrollRef.current) {
				disableBodyScroll(productImagesScrollRef.current, {
					reserveScrollBarGap: true,
				});
			}
			scrollToImage(selectedImageId);
		}
		if (activeTabId === 'review-images' && reviewImagesScrollRef.current) {
			disableBodyScroll(reviewImagesScrollRef.current, {
				reserveScrollBarGap: true,
			});
		}
	}, [activeTabId, scrollToImage, selectedImageId]);

	const onClose = useCallback(() => {
		onCloseProp();
		clearAllBodyScrollLocks();
	}, [onCloseProp]);

	const onTabChange = (tab: ProductAndSelectedImagesTabs) => {
		if (tab === 'review-images' && reviewImagesScrollRef.current) {
			disableBodyScroll(reviewImagesScrollRef.current, {
				reserveScrollBarGap: true,
			});
		}
		if (tab === 'product-images' && productImagesScrollRef.current) {
			disableBodyScroll(productImagesScrollRef.current, {
				reserveScrollBarGap: true,
			});
		}
		onTabChangeProp(tab);
	};

	useEffect(() => {
		scrollToImage(selectedImageId);
	}, [scrollToImage, selectedImageId]);

	return (
		<ImagePopover
			label={t('product_details_image_layover_title')}
			isOpen={isOpen}
			onOpen={onOpen}
			onClose={onClose}
		>
			<Tabs<ProductAndSelectedImagesTabs>
				tabListLabel={t('product_details_images_tab_list_label')}
				className="flex h-full flex-col"
				tabListClassName="sm:self-start mx-4 sm:min-w-[42rem]"
				activeTabId={activeTabId}
				onTabChange={onTabChange}
				items={[
					{
						id: 'product-images',
						title: t('product_details_images_tab_title'),
						contentClassName: 'flex justify-center h-full overflow-hidden',
						content: (
							<>
								{images.length > 0 && (
									<div className="ml-6 flex flex-col justify-center justify-self-start py-4 max-sm:hidden">
										<ThumbnailList
											images={images}
											selectedImageId={selectedImageId}
											onImageClick={onThumbnailClick}
											carouselIndex={carouselIndex}
											onSlideChange={onThumbnailSlideChange}
										/>
									</div>
								)}

								<div className="relative flex h-full grow">
									<div
										ref={productImagesScrollRef}
										className="flex grow flex-col items-center overflow-y-auto"
									>
										{images.map((image) => (
											<div
												key={image.src}
												tabIndex={-1}
												ref={(element) => {
													if (element) {
														largeImages.current[image.id] = element;
													}
												}}
											>
												<Img
													src={image.src}
													alt={image.alt}
													service="nextjs"
													width={792}
													height={792}
												/>
											</div>
										))}
									</div>
									<div className="absolute bottom-0 h-24 w-full bg-gradient-to-t from-white" />
								</div>
							</>
						),
					},
					is.arrayWithLength(reviewImages) && {
						id: 'review-images',
						title: t('product_details_review_images_tab_title'),
						contentClassName: 'h-full min-h-0 min-w-0',
						content: (
							<ReviewImagesView
								ref={reviewImagesScrollRef}
								images={reviewImages}
								isVisible={activeTabId === 'review-images' && isOpen}
								onSlideChange={onReviewImagesSlideChange}
								selectedReviewImageId={selectedReviewImageId}
							/>
						),
					},
				]}
			/>
		</ImagePopover>
	);
}
ProductAndSelectedImages.displayName = 'ProductAndSelectedImages';
