import React from 'react';
import clsx from 'clsx';

import Accordion from 'components/Accordion';
import { useMaxWidth } from 'hooks';
import { Column } from 'models/sitecore';
import { slugify } from 'utils/string';

import { flattenColumns } from './helpers';
import PageFooterLinks from './PageFooterLinks';

interface Props {
	className?: string;
	columns: Column[] | undefined;
}

/** Footer menu columns */
export default function PageFooterMenus({ className, columns }: Props) {
	const visibleColumns = flattenColumns(columns);
	const isSmallOrSmaller = useMaxWidth('md');

	if (visibleColumns.length === 0) {
		return null;
	}

	return (
		<div className={clsx('max-md:-mx-4 md:flex md:gap-6', className)}>
			{visibleColumns.map((col) => (
				<Accordion
					key={col.title}
					id={`footer-menu-${slugify(col.title)}`}
					title={col.title}
					size="large"
					color="red"
					isActive={isSmallOrSmaller}
					className="md:flex-1"
					titleClassName="md:mb-5"
				>
					<PageFooterLinks links={col.links} itemClassName="mt-1" />
				</Accordion>
			))}
		</div>
	);
}
PageFooterMenus.displayName = 'PageFooterMenus';
