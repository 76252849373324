import React from 'react';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import ArrowLink from 'components/ArrowLink';
import { LayoutContainer } from 'components/Layout/';
import RichText from 'components/RichText';
import Text from 'components/Text';
import type { JulaComponentProps } from 'lib/component-props';
import { getEditorMargin } from 'utils/business-logic';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

interface RelatedArticle {
	summary: string;
	url: string;
}

type Props = JulaComponentProps & {
	fields?: {
		content: Field<string>;
		id: Field<number>;
		relatedArticles: Field<RelatedArticle[]>;
		summary: Field<string>;
		title: Field<string>;
	};
};

export default function FAQArticle({ fields, params }: Props) {
	const { t } = useI18n();
	if (!fields) {
		return null;
	}
	const { content, relatedArticles, title } = fields;
	return (
		<LayoutContainer
			className={getEditorMargin(params)}
			id={params?.anchor}
			withGrid
		>
			<div className="col-span-full md:col-span-6">
				<Text field={title} className="mb-4" as="h1" />

				<RichText className="richtext--faq" field={content} />
				{is.arrayWithLength(relatedArticles.value) && (
					<>
						<Text
							as="h2"
							text={t('faq_article_related_articles_heading')}
							className="col-span-full mt-8"
						/>
						<ul className="col-span-full mt-4 flex flex-col gap-4">
							{relatedArticles.value.map(({ summary, url }) => (
								<li key={url}>
									<ArrowLink text={summary} href={url} />
								</li>
							))}
						</ul>
					</>
				)}
			</div>
		</LayoutContainer>
	);
}
FAQArticle.displayName = 'FAQArticle';
