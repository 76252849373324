/**
 * MicroContent - Media
 */

import React, { type AnchorHTMLAttributes } from 'react';
import clsx from 'clsx';

import Icon from 'components/Icon';
import Img from 'components/Img';
import Link from 'components/Link';

interface Props extends AnchorHTMLAttributes<HTMLAnchorElement> {
	className?: string;
	href: string;
	imgHeight: number;
	imgSrc: string;
	imgWidth: number;
	orientation?: 'landscape' | 'portrait';
	text: string;
}

/** Linked image with arrow icon. */
export default function MicroContentMedia({
	className,
	href,
	imgHeight,
	imgSrc,
	imgWidth,
	orientation = 'landscape',
	text,
	...attrs
}: Props) {
	return (
		<Link
			{...attrs}
			href={href}
			className={clsx(
				'group/micro-media inline-block overflow-hidden rounded-border bg-julaRed align-top text-base text-white hover:bg-julaRedDark',
				className,
			)}
		>
			{/* Skip alt text on purpose since the link has a proper text below. */}
			<Img
				src={imgSrc}
				width={imgWidth}
				height={imgHeight}
				service="nextjs"
				className="w-full"
			/>
			<span
				className={clsx(
					'flex',
					orientation === 'landscape' &&
						'min-h-16 items-center gap-4 px-4 py-2',
					orientation === 'portrait' && 'flex-col gap-3 p-4',
				)}
			>
				<span className="grow group-hover/micro-media:underline">{text}</span>
				<Icon
					icon="arrow"
					color="black"
					backgroundColor="white"
					hasBackgroundPadding
				/>
			</span>
		</Link>
	);
}
MicroContentMedia.displayName = 'MicroContentMedia';
