import React from 'react';
import { useSelector } from '@xstate/react';

import IconLink from 'components/IconLink';
import InfoBox from 'components/InfoBox';
import RichText from 'components/RichText';
import { SelectBox } from 'components/SelectBox';
import { useCheckoutContext } from 'contexts';
import { useResursBankPriceSignage } from 'hooks';
import { CartError, PaymentMethodLink, PaymentProvider } from 'models/api';
import { selectAmountToPayValue } from 'state-machines/checkout';
import { filterTruthy } from 'utils/collection';
import { getTestDataAttrFrom, is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

interface Props {
	adyenComponent: React.ReactNode;
	description?: string;
	errors: CartError[] | undefined;
	images?: string[];
	isSelected: boolean;
	isValid: boolean;
	links?: PaymentMethodLink[];
	longDescriptionHTML?: string;
	name?: string;
	onPaymentMethodChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	paymentProvider: PaymentProvider;
	paymentProviderMethodId: string | undefined;
	paymentType: string;
}

export default function PaymentMethod({
	adyenComponent,
	description,
	errors,
	images,
	isSelected,
	isValid,
	links,
	longDescriptionHTML,
	name,
	onPaymentMethodChange,
	paymentProvider,
	paymentProviderMethodId,
	paymentType,
}: Props) {
	const { checkoutService } = useCheckoutContext();
	const { t } = useI18n();
	const amountToPay = useSelector(checkoutService, selectAmountToPayValue);
	const filteredLinks = filterTruthy(links, 'link', 'title');
	const hasLinks = filteredLinks.length > 0;
	const { data: priceSignage } = useResursBankPriceSignage(
		paymentProviderMethodId,
		amountToPay,
		paymentProvider === 'ResursBank',
	);
	return (
		<SelectBox
			key={paymentType}
			id={paymentType}
			name="paymentMethod"
			primaryImages={images?.filter(is.truthy)}
			value={paymentType}
			heading={name || ''}
			isSelected={isSelected}
			description={description}
			onChange={onPaymentMethodChange}
			disabled={!isValid}
			selectedDescription={
				// No content should result in null - even an empty fragment
				// will cause a wrapper to be rendered in SelectBox which
				// leads to layout shifting.
				longDescriptionHTML || hasLinks || priceSignage ? (
					<>
						{priceSignage && (
							<p className="mb-2 font-bold">
								{t('checkout_resurs_bank_price_signage_text', {
									monthlyCost: priceSignage.monthlyCost,
									durationMonths: priceSignage.durationMonths,
								})}
							</p>
						)}
						<RichText html={longDescriptionHTML} inheritStyle />
						{hasLinks && (
							<ul className={longDescriptionHTML ? 'mt-2' : undefined}>
								{filteredLinks.map((link) => (
									<li key={link.link}>
										<IconLink
											icon="openNewWindow"
											iconSize={16}
											iconPosition="right"
											className="relative z-5 py-1"
											target="_blank"
											href={link.link}
											text={link.title}
										/>
									</li>
								))}
							</ul>
						)}
					</>
				) : null
			}
			dataCy={getTestDataAttrFrom(paymentType)}
		>
			{errors?.map((error) => (
				<div className="mt-4" key={error.key}>
					<InfoBox icon="error" variant="information">
						<RichText
							className="text-sm"
							onlyInternalMargin
							markdown={error.description?.replaceAll('\\', '')}
						/>
					</InfoBox>
				</div>
			))}
			{adyenComponent}
		</SelectBox>
	);
}
PaymentMethod.displayName = 'PaymentMethod';
