import { assign, createMachine, type StateFrom } from 'xstate';

import type { ActionButtonState } from 'components/ActionButton';
import { isTestmode } from 'hooks';
import type { PendingCustomerTicket, ResolvedCustomerTicket } from 'models/api';
import { isGuiIframe, POST_SIGN_EVENT } from 'pages/api/sign/helpers';

import {
	openSignWindow,
	requestJulaClubApplyForCredit,
	requestJulaClubRaiseCredit,
	requestJulaProCreditApi,
	requestPollCredit,
	requestTokenRefresh,
} from './credit.services';
import type {
	CreditMachineContext,
	CreditMachineEvents,
	CreditMachineServices,
} from './credit.types';

export const creditMachine = createMachine(
	{
		id: 'creditMachine',
		schema: {
			context: {} as CreditMachineContext,
			events: {} as CreditMachineEvents,
			services: {} as CreditMachineServices,
		},
		tsTypes: {} as import('./credit.machine.typegen').Typegen0,
		context: {
			signFrameOpen: false,
			requestCreditButtonState: 'idle',
			pollingNumberFailed: 0,
			signWindow: null,
			errorOpeningSignWindow: false,
		},
		initial: 'creditRequest',
		states: {
			creditRequest: {
				id: 'creditRequest',
				entry: ['resetValidationErrors'],
				exit: 'removeSignIframeModal',
				invoke: {
					src: 'listenForIframeMessage',
				},
				initial: 'waitingForCreditRequest',
				states: {
					waitingForCreditRequest: {
						on: {
							JULA_CLUB_APPLY_FOR_CREDIT: {
								target: 'requestNew',
							},
							JULA_CLUB_RAISE_CREDIT: {
								target: 'requestRaise',
							},
							JULA_PRO_REQUEST_CREDIT: {
								target: 'julaProCreditRequest',
							},
						},
					},
					requestRaise: {
						id: 'requestRaise',
						entry: 'setRequestCreditButtonStateLoading',
						invoke: {
							id: 'requestRaiseCreditService',
							src: 'requestRaiseCreditService',
							onDone: [
								{
									cond: 'shouldSignBankId',
									target: 'displayBankIdSignIn',
									actions: [
										'setBankIdSignUrl',
										'setRequestCreditButtonStateSuccess',
									],
								},
								{
									cond: 'shouldPollForCreditRequest',
									target: 'waitingForCreditToBeCreated',
									actions: [
										'setPendingCustomerTicket',
										'setRequestCreditButtonStateSuccess',
									],
								},
							],
							onError: [
								{
									cond: 'eventHasValidationErrors',
									target: 'validationError',
									actions: ['setValidationErrors'],
								},
								{
									cond: 'eventCreditNotCreated',
									target: '#creditNotCreated',
								},
								{
									target: '#unknownError',
								},
							],
						},
					},
					requestNew: {
						id: 'requestNew',
						entry: 'setRequestCreditButtonStateLoading',
						invoke: {
							id: 'requestNewCreditService',
							src: 'requestNewCreditService',
							onDone: [
								{
									cond: 'shouldSignBankId',
									target: 'displayBankIdSignIn',
									actions: [
										'setBankIdSignUrl',
										'setRequestCreditButtonStateSuccess',
									],
								},
								{
									cond: 'shouldPollForCreditRequest',
									target: 'waitingForCreditToBeCreated',
									actions: [
										'setPendingCustomerTicket',
										'setRequestCreditButtonStateSuccess',
									],
								},
							],
							onError: [
								{
									cond: 'eventHasValidationErrors',
									target: 'validationError',
									actions: ['setValidationErrors'],
								},
								{
									cond: 'eventCreditNotCreated',
									target: '#creditNotCreated',
								},
								{
									target: '#unknownError',
								},
							],
						},
					},
					julaProCreditRequest: {
						id: 'julaProCreditRequest',
						entry: 'setRequestCreditButtonStateLoading',
						invoke: {
							id: 'applyOrRaiseCreditJulaProService',
							src: 'applyOrRaiseCreditJulaPro',
							onDone: [
								{
									cond: 'shouldSignBankId',
									target: 'displayBankIdSignIn',
									actions: [
										'setBankIdSignUrl',
										'setRequestCreditButtonStateSuccess',
									],
								},
								{
									cond: 'shouldPollForCreditRequest',
									target: 'waitingForCreditToBeCreated',
									actions: [
										'setPendingCustomerTicket',
										'setRequestCreditButtonStateSuccess',
									],
								},
							],
							onError: [
								{
									cond: 'eventHasValidationErrors',
									target: 'validationError',
									actions: ['setValidationErrors'],
								},
								{
									cond: 'eventCreditNotCreated',
									target: '#creditNotCreated',
								},
								{
									target: '#unknownError',
								},
							],
						},
					},
					displayBankIdSignIn: {
						tags: ['creditRequestStartedOrFailed', 'loading'],
						always: [
							{
								target: 'loadingSignFrameModal',
								cond: 'shouldDisplaySignIframeModal',
							},
							{
								target: 'loadingSignWindow',
							},
						],
					},
					loadingSignFrameModal: {
						entry: 'openSignIframeModal',
						tags: ['creditRequestStartedOrFailed', 'loading'],
						on: {
							SIGN_FRAME_LOAD_SUCCESS: {
								target: 'waitingForCreditToBeCreated',
							},
						},
					},
					loadingSignWindow: {
						tags: ['creditRequestStartedOrFailed', 'loading'],
						invoke: {
							id: 'openSignWindow',
							src: 'openSignWindow',
							onDone: {
								target: 'waitingForCreditToBeCreated',
								actions: 'setSignWindowRef',
							},
							onError: {
								target: 'waitingForCreditToBeCreated',
								actions: 'setErrorOpeningSignWindow',
							},
						},
					},
					waitingForCreditToBeCreated: {
						tags: ['creditRequestStartedOrFailed', 'loading'],
						on: {
							CLOSE: {
								target: 'waitingForCreditRequest',
								actions: [
									'removeSignIframeModal',
									'setRequestCreditButtonStateIdle',
									'closeSignWindow',
								],
							},
							RETRY_OPEN_SIGN_WINDOW: {
								target: 'loadingSignWindow',
								actions: 'resetErrorOpeningSignWindow',
							},
						},
						after: {
							POLL_CREDIT_REQUEST_DELAY: {
								target: 'checkingCreditRequestStatus',
							},
						},
					},
					checkingCreditRequestStatus: {
						tags: 'loading',
						invoke: {
							id: 'pollCustomerApi',
							src: 'pollCustomerApi',
							onDone: [
								{
									cond: 'creditRequestIsPending',
									target: 'waitingForCreditToBeCreated',
									actions: 'setPendingCustomerTicket',
								},
								{
									cond: 'creditRequestSuccessful',
									target: '#success',
									actions: ['setResolvedCustomerTicket'],
								},
								{
									cond: 'creditRequestDenied',
									target: '#creditNotCreated',
									actions: 'setResolvedCustomerTicket',
								},
								{
									cond: 'signingCanceled',
									target: '#signingCanceled',
									actions: 'setResolvedCustomerTicket',
								},
							],
							onError: [
								{
									cond: 'eventCreditNotCreated',
									target: '#creditNotCreated',
								},
								{
									target: 'waitingForCreditToBeCreated',
									actions: 'setFailedPollingNumber',
								},
								{ target: '#unknownError' },
							],
						},
					},
					validationError: {
						tags: 'creditRequestStartedOrFailed',
						entry: 'setRequestCreditButtonStateFailure',
						on: {
							JULA_CLUB_APPLY_FOR_CREDIT: {
								target: 'requestNew',
							},
							JULA_CLUB_RAISE_CREDIT: {
								target: 'requestRaise',
							},
							JULA_PRO_REQUEST_CREDIT: {
								target: 'julaProCreditRequest',
							},
						},
					},
					unknownError: {
						tags: 'creditRequestStartedOrFailed',
						id: 'unknownError',
						entry: 'setRequestCreditButtonStateFailure',
						on: {
							JULA_CLUB_APPLY_FOR_CREDIT: {
								target: 'requestNew',
							},
							JULA_CLUB_RAISE_CREDIT: {
								target: 'requestRaise',
							},
							JULA_PRO_REQUEST_CREDIT: {
								target: 'julaProCreditRequest',
							},
						},
					},
				},
				on: {
					SIGN_CREDIT_DONE: {
						actions: ['removeSignIframeModal', 'closeSignWindow'],
					},
				},
			},
			success: {
				tags: 'doneCreatingCredit',
				id: 'success',

				initial: 'refreshToken',
				states: {
					refreshToken: {
						invoke: {
							src: 'requestTokenRefresh',
							onDone: 'creditApplicationDone',
						},
					},
					creditApplicationDone: {
						on: {
							RESET: {
								target: '#creditRequest',
							},
						},
					},
				},
			},
			creditNotCreated: {
				tags: 'creditRequestStartedOrFailed',
				id: 'creditNotCreated',
				entry: 'setRequestCreditButtonStateFailure',
				on: {
					RESET: {
						target: '#creditRequest',
					},
				},
			},
			signingCanceled: {
				tags: 'creditRequestStartedOrFailed',
				id: 'signingCanceled',
				entry: 'setRequestCreditButtonStateFailure',
				on: {
					RESET: {
						target: '#creditRequest',
					},
				},
			},
		},
	},
	{
		guards: {
			shouldDisplaySignIframeModal: (_context) => isGuiIframe(),
			shouldSignBankId: (_context, event) => {
				if (isTestmode()) {
					return false;
				}
				const { data } = event;
				return data.responseData.clientAction === 'Redirect';
			},
			shouldPollForCreditRequest: (_context, event) => {
				if (isTestmode()) {
					return true;
				}
				const { data } = event;
				return data.responseData.clientAction === 'Polling';
			},
			creditRequestIsPending: (_context, event) => {
				const { data } = event;
				return data.responseData.clientAction === 'Polling';
			},
			creditRequestSuccessful: (_context, event) => {
				const { data } = event;
				return (
					data.responseData.ticketStatus === 'Resolved' &&
					data.responseData.data?.CreditResult === 'Approved'
				);
			},
			creditRequestDenied: (_context, event) => {
				const { data } = event;
				return (
					data.responseData.ticketStatus === 'Resolved' &&
					(data.responseData.data?.CreditResult === 'Denied' ||
						data.responseData.data?.CreditResult === 'Failed')
				);
			},
			signingCanceled: (_context, event) => {
				const { data } = event;
				return (
					data.responseData.ticketStatus === 'Resolved' &&
					data.responseData.data?.CreditResult === 'Canceled'
				);
			},
			eventCreditNotCreated: (_context, _event) => {
				const event = _event as any;
				const { data } = event;
				return data?.status === 404 || data?.status === 500;
			},
			eventHasValidationErrors: (_context, _event) => {
				const event = _event as any;
				const { data } = event;
				return data?.status === 400;
			},
		},
		delays: {
			POLL_CREDIT_REQUEST_DELAY: (context, _event) => {
				const delay =
					Number(context.pendingTicket?.data?.RefreshIntervallSeconds) || 2;
				return delay * 1000;
			},
		},
		actions: {
			setRequestCreditButtonStateLoading: assign({
				requestCreditButtonState: (_context) => 'loading' as ActionButtonState,
			}),
			setRequestCreditButtonStateSuccess: assign({
				requestCreditButtonState: (_context) => 'success' as ActionButtonState,
			}),
			setRequestCreditButtonStateFailure: assign({
				requestCreditButtonState: (_context) => 'failure' as ActionButtonState,
			}),
			setRequestCreditButtonStateIdle: assign({
				requestCreditButtonState: (_context) => 'idle' as ActionButtonState,
			}),
			setPendingCustomerTicket: assign({
				pendingTicket: (_context, event) => {
					const { data } = event;
					return data.responseData as PendingCustomerTicket;
				},
			}),
			setResolvedCustomerTicket: assign({
				resolvedTicket: (_context, event) => {
					const { data } = event;
					return data.responseData as ResolvedCustomerTicket;
				},
			}),
			setBankIdSignUrl: assign({
				bankIdSignUrl: (_context, event) => {
					const { data } = event;
					const pendingTicket = data.responseData as PendingCustomerTicket;
					return pendingTicket?.data?.Url;
				},
			}),
			openSignIframeModal: assign({
				signFrameOpen: (_context) => true,
			}),
			removeSignIframeModal: assign({
				signFrameOpen: (_context) => false,
			}),
			setSignWindowRef: assign({
				signWindow: (_context, event) => event.data,
			}),
			closeSignWindow: assign({
				signWindow: (context, _event) => {
					if (context.signWindow) context.signWindow.close();

					return null;
				},
			}),
			setErrorOpeningSignWindow: assign({
				errorOpeningSignWindow: (_context) => true,
			}),
			resetErrorOpeningSignWindow: assign({
				errorOpeningSignWindow: (_context) => false,
			}),
			setFailedPollingNumber: assign({
				pollingNumberFailed: (context) => context.pollingNumberFailed + 1,
			}),
			resetValidationErrors: assign({
				errors: (_context, _event) => undefined,
			}),
			setValidationErrors: assign({
				errors: (_context, _event) => {
					const event = _event as any;
					const { data } = event;
					return data;
				},
			}),
		},
		services: {
			listenForIframeMessage: () => (send) => {
				if (typeof window === 'undefined') {
					return undefined;
				}

				const listener = (event: MessageEvent) => {
					if (event.origin !== window.location.origin) {
						return;
					}

					if (event.data.type === POST_SIGN_EVENT) {
						send({ type: 'SIGN_CREDIT_DONE' });
					}
				};

				window.addEventListener('message', listener, false);

				return () => {
					window.removeEventListener('message', listener);
				};
			},
			requestNewCreditService: (_, event) =>
				requestJulaClubApplyForCredit(event.formData),
			requestRaiseCreditService: (_, event) =>
				requestJulaClubRaiseCredit(event.formData),
			applyOrRaiseCreditJulaPro: (_, event) =>
				requestJulaProCreditApi(event.formData),
			pollCustomerApi: (_context) => requestPollCredit(),
			requestTokenRefresh: (_context) => requestTokenRefresh() as any,
			openSignWindow: (context) => openSignWindow(context?.bankIdSignUrl),
		},
	},
);
export type CreditMachineState = StateFrom<typeof creditMachine>;
