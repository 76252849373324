import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import ArrowLink from 'components/ArrowLink';
import DiscountBubble from 'components/DiscountBubble';
import Img from 'components/Img';
import Link from 'components/Link';
import Text from 'components/Text';
import type { DigizuiteAsset } from 'models/asset';
import type { SimpleLink } from 'models/sitecore';

export interface Props {
	campaignBubbleLabel?: string;
	campaignBubbleText?: string;
	heading?: Field<string>;
	image: DigizuiteAsset;
	imageLoading?: 'eager' | 'lazy';
	link: SimpleLink;
}

export default function TeaserItem({
	campaignBubbleLabel,
	campaignBubbleText,
	heading,
	image,
	imageLoading,
	link,
}: Props) {
	return (
		<div className="flex w-full flex-col max-sm:mb-8 max-sm:mt-0">
			<Link
				href={link.href}
				isScreenReaderHidden={!image.alt && !campaignBubbleText}
				className="relative"
			>
				<Img
					src={image.src}
					alt={image.alt}
					width={393}
					height={393}
					loading={imageLoading}
					service="nextjs"
					className="w-full sm:object-cover"
				/>
				{campaignBubbleText && (
					<DiscountBubble
						label={campaignBubbleLabel}
						text={campaignBubbleText}
						isPositioned
					/>
				)}
			</Link>
			<div className="mt-6 flex flex-col items-start gap-4">
				{heading?.value && <Text as="h2" field={heading} />}
				<ArrowLink href={link.href} text={link.text} />
			</div>
		</div>
	);
}
TeaserItem.displayName = 'BlockTeaserCarousel_TeaserItem';
