import React from 'react';

import Text from 'components/Text';

interface Props {
	brandTitle: string | undefined;
	model: string | undefined;
	subTitle: string | undefined;
	title: string | undefined;
	titleExclModel: string | undefined;
}

export default function ProductPopoverTitle({
	brandTitle,
	model,
	subTitle,
	title,
	titleExclModel,
}: Props) {
	const heading =
		subTitle && titleExclModel
			? `${titleExclModel} ${subTitle}`
			: (titleExclModel ?? title);
	const subheading = model ? `${brandTitle} | ${model}` : brandTitle;
	return (
		<>
			<Text as="h2" text={heading} />
			<Text as="p" text={subheading} />
		</>
	);
}

ProductPopoverTitle.displayName = 'ProductPopoverTitle';
