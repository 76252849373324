/**
 * SearchField
 */

import React, { forwardRef, InputHTMLAttributes, MouseEvent } from 'react';
import clsx from 'clsx';

import Icon from 'components/Icon';
import cn from 'utils/cn';
import { is } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

interface BaseProps extends InputHTMLAttributes<HTMLInputElement> {
	className?: string;
	handleInputClearClick: (e: MouseEvent<HTMLButtonElement>) => void;
	hasInputClearButton?: boolean;
	hasSearchIcon?: boolean;
	id: string;
	inputClassName?: string;
	inputLabel: React.ReactNode;
	isLabelVisible?: boolean;
	isLoading?: boolean;
}

interface WithSubmitButtonProps extends BaseProps {
	hasSubmitButton?: true;
	submitButtonLabel: string;
}
interface WithoutSubmitButtonProps extends BaseProps {
	hasSubmitButton: false;
	submitButtonLabel?: never;
}

export type Props = WithSubmitButtonProps | WithoutSubmitButtonProps;

/** A search field with clear button, placeholder and button with icon */
const SearchField = forwardRef<HTMLInputElement, Props>(
	(
		{
			className,
			handleInputClearClick,
			hasInputClearButton,
			hasSubmitButton = true,
			hasSearchIcon,
			id,
			inputClassName,
			inputLabel,
			isLabelVisible = false,
			isLoading = false,
			submitButtonLabel,
			...inputAttrs
		},
		ref,
	) => {
		const { t } = useI18n();
		const hasClearButton = is.bool(hasInputClearButton)
			? hasInputClearButton
			: // Stringify to make zero count.
				Boolean(inputAttrs.value?.toString());

		return (
			<>
				<label className={clsx(!isLabelVisible && 'sr-only')} htmlFor={id}>
					{inputLabel}
				</label>
				<div className={cn('group/search-field flex w-full', className)}>
					<div className="relative w-full">
						{hasSearchIcon && (
							<Icon
								icon="search"
								className="absolute left-4 top-1/2 -translate-y-1/2"
							/>
						)}
						<input
							{...inputAttrs}
							type="text"
							id={id}
							autoCapitalize="none"
							autoComplete="off"
							autoCorrect="off"
							enterKeyHint="search"
							inputMode="search"
							ref={ref}
							className={clsx(
								'h-12 w-full px-4',
								'border border-greyDark group-hover/search-field:border-greyDarker group-hover/search-field:ring-1 group-hover/search-field:ring-greyDarker',
								hasSubmitButton && 'rounded-r-none border-r-0',
								'rounded',
								// Prevent iOS from zooming on focus by having the text at 16px+.
								'text-[1rem]',
								'focus:outline-none',
								'placeholder:text-inherit',
								// Match right padding with clear button width below
								hasClearButton && 'pr-10',
								hasSearchIcon && 'pl-12',
								inputClassName,
							)}
						/>
						<button
							type="button"
							className={clsx(
								// Match width with input right padding above
								'w-10',
								'absolute right-0 top-1/2 flex h-12 -translate-y-1/2 items-center justify-center border-none bg-none hover:opacity-80',
								!hasClearButton && 'hidden',
							)}
							onClick={handleInputClearClick}
						>
							<span className="sr-only">{t('screenreader_text_clear')}</span>
							<Icon icon="clear" color="grey" />
						</button>
					</div>
					{hasSubmitButton && (
						<button
							type="submit"
							className="relative size-12 flex-shrink-0 rounded-r border-none bg-julaRed hover:bg-julaRedDark group-hover/search-field:border-y-1 group-hover/search-field:border-r-1 group-hover/search-field:border-greyDarker group-hover/search-field:ring-1 group-hover/search-field:ring-greyDarker"
						>
							<span className="sr-only">
								{isLoading ? t('general_loading_text') : submitButtonLabel}
							</span>
							{isLoading && (
								<Icon
									icon="spinner"
									color="white"
									className="animate-spinFast"
								/>
							)}
							{!isLoading && <Icon icon="search" color="white" />}
						</button>
					)}
				</div>
			</>
		);
	},
);

export default SearchField;

SearchField.displayName = 'SearchField';
