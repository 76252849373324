import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import ComponentPlaceholder from 'components/ComponentPlaceholder';
import InfoBox from 'components/InfoBox';
import { LayoutContainer } from 'components/Layout';
import RichText from 'components/RichText';
import Text from 'components/Text';
import type { JulaComponentProps } from 'lib/component-props';
import { getEditorMargin } from 'utils/business-logic';

type Props = JulaComponentProps & {
	fields?: {
		heading?: Field<string>;
		description?: Field<string>;
		systemMessageActivated?: Field<boolean>;
		systemMessageHeading?: Field<string>;
		systemMessageText?: Field<string>;
	};
};

export default function FAQHeading({ params, fields }: Props) {
	if (!fields) {
		return null;
	}

	const {
		heading,
		description,
		systemMessageActivated,
		systemMessageHeading,
		systemMessageText,
	} = fields;

	if (!heading?.value || !description?.value) {
		return (
			<ComponentPlaceholder
				className={getEditorMargin(params)}
				componentName="FAQHeading"
				description="Requires a heading or description"
			/>
		);
	}

	return (
		<LayoutContainer
			id={params?.anchor}
			outerClassName={getEditorMargin(params)}
			withGrid
		>
			<div className="col-span-4 md:col-span-6">
				<Text as={params?.heading || 'h1'} className="mb-2" field={heading} />
				<Text as="pLarge" field={description} />
				{systemMessageActivated?.value && systemMessageText?.value && (
					<InfoBox
						heading={systemMessageHeading?.value}
						icon="info"
						className="mt-8"
					>
						<RichText html={systemMessageText.value} />
					</InfoBox>
				)}
			</div>
		</LayoutContainer>
	);
}
FAQHeading.displayName = 'FAQHeading';
