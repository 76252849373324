import React from 'react';

import { CountryList, CountryListItem } from 'components/CountryList';
import ErrorPageHeader from 'components/ErrorPageHeader';

// TODO ta bort? används?
export default function MaintenancePage() {
	return (
		<>
			<ErrorPageHeader imgSrc="/assets/images/logo-jula.png" imgAlt="Logo" />
			<CountryList>
				<CountryListItem
					imgSrc="/assets/images/icons/se.svg"
					heading="Wow! Hur snabb är du?!"
					preamble="Jula har massor av verktyg och vi trodde vi skulle hinna fixa färdigt på sajten. Men vi räknade inte med dig – sorry! Vi jobbar så fort vi kan för att få allt klart. Välkommen tillbaka om en stund!"
				/>
				<CountryListItem
					imgSrc="/assets/images/icons/no.svg"
					heading="Wow! Hur snabb är du?!"
					preamble="Jula har massor av verktyg och vi trodde vi skulle hinna fixa färdigt på sajten. Men vi räknade inte med dig – sorry! Vi jobbar så fort vi kan för att få allt klart. Välkommen tillbaka om en stund!"
				/>
				<CountryListItem
					imgSrc="/assets/images/icons/pl.svg"
					heading="Łał! Jak szybko jesteś?!"
					preamble="Jula ma wiele narzędzi i pomyśleliśmy, że będziemy mieli czas na dokończenie naprawy strony. Ale nie liczyliśmy na Ciebie - przepraszam! Pracujemy tak szybko, jak to możliwe, aby wszystko było gotowe. Witamy z powrotem za chwilę!"
				/>
			</CountryList>
		</>
	);
}
MaintenancePage.displayName = 'MaintenancePage';
