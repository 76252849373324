/**
 * Stepper
 */

import React from 'react';
import clsx from 'clsx';

interface Props {
	/** React children */
	children?: React.ReactNode;

	/** Pass optional classnames for the html */
	className?: string;
}

/** The stepper component that presents the different steps in the stepper */
export default function Stepper({ children, className }: Props) {
	return (
		<div
			// TODO: stepperwrapper styles should be inlined to Tailwind classes
			className={clsx(
				'stepperwrapper relative flex justify-between',
				className,
			)}
		>
			{children}
		</div>
	);
}
Stepper.displayName = 'Stepper';
