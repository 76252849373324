import React from 'react';

import ActionButton, { ActionButtonState } from 'components/ActionButton';
import Icon from 'components/Icon';
import { useFeatureToggle } from 'contexts';
import { getTestDataAttrFrom } from 'utils/helpers';
import { useI18n } from 'utils/i18n';

interface Props {
	onPurchaseButtonClick: () => void;
	onWishlistButtonClick: () => void;
	purchaseButtonDisabled: boolean;
	purchaseButtonState: ActionButtonState;
	wishlistButtonDisabled: boolean;
	wishlistButtonState: ActionButtonState;
}

export default function ButtonPanel({
	onPurchaseButtonClick,
	onWishlistButtonClick,
	purchaseButtonDisabled,
	purchaseButtonState,
	wishlistButtonDisabled,
	wishlistButtonState,
}: Props) {
	const { t } = useI18n();
	const { onlineCommerceEnabled, wishlistEnabled } = useFeatureToggle();

	return (
		<div className="mt-4 space-y-2 md:mt-6">
			{onlineCommerceEnabled && (
				<ActionButton
					data-cid="addToCart"
					displayWidth="full"
					size="large"
					variant="cta"
					customState={purchaseButtonState}
					disabled={purchaseButtonDisabled}
					onClick={onPurchaseButtonClick}
					data-cy={getTestDataAttrFrom('add-to-cart')}
				>
					<Icon
						icon={purchaseButtonDisabled ? 'cartDisabled' : 'cart'}
						className="mr-2"
					/>
					{t('product_details_buy_online_button')}
				</ActionButton>
			)}
			{wishlistEnabled && (
				<div className="w-full">
					<ActionButton
						data-cid="addToWishlist"
						displayWidth="full"
						size="medium"
						variant="secondary"
						customState={wishlistButtonState}
						disabled={wishlistButtonDisabled}
						onClick={onWishlistButtonClick}
						data-cy={getTestDataAttrFrom('add-to-wishlist')}
					>
						<Icon icon="shoppinglistAdd" className="mr-2" />
						{t('product_details_add_to_wishlist_button')}
					</ActionButton>
				</div>
			)}
		</div>
	);
}
ButtonPanel.displayName = 'ButtonPanel';
