import { ProductResponse } from 'models/api/variant';
import type { Product } from 'models/product';
import { filterTruthy } from 'utils/collection';

export interface Document {
	fileSize: number | undefined;
	fileType: string | undefined;
	name: string;
	url: string;
}
export function getDocuments(product: Product | ProductResponse): Document[] {
	return filterTruthy(
		product.assets
			?.find((asset) => asset.type === 'ProductDocument')
			?.versions?.map((version) => {
				const format =
					version.formats?.find((f) => f.type === 'PDF') ??
					version.defaultFormat;
				return {
					fileSize: format?.fileSize,
					fileType: format?.type,
					name: version.description,
					url: format?.url?.location,
				};
			}),
		'name',
		'url',
	);
}
