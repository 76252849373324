/**
 * Checkout
 */

import React from 'react';

import Link from 'components/Link';
import Text from 'components/Text';
import { DiscountCode, GiftCard, Summary, Variant } from 'models/api';
import { getProductImageFormat } from 'utils/business-logic';
import { is } from 'utils/helpers';

import PriceSummaries from './PriceSummaries';
import SummaryProduct from './SummaryProduct';

interface Props {
	/** All bonus discount codes added to the cart, used by the summary component */
	bonusDiscounts: DiscountCode[] | undefined;
	/** All gift cards added to the cart, used by the summary component */
	giftCards: GiftCard[] | undefined;
	/** Heading text of the cart summary component */
	heading?: string;
	/** The heading of the link text in the header */
	headingLinkText?: string;
	/** Total savings, if any, used by the summary component */
	savingTotalSumSummary: Summary | undefined;
	/** Summaries for the cart, used by the summary component */
	summaries: Summary[] | undefined;
	/** The cart variants */
	variants: Variant[] | undefined;
}

/** The cart summary component used in the cart page. */
export default function CheckoutSummary({
	bonusDiscounts,
	giftCards,
	heading,
	headingLinkText,
	savingTotalSumSummary,
	summaries,
	variants,
}: Props) {
	return (
		<div className="sticky top-8 rounded-lg md:p-6 md:shadow-summaryShadow">
			<div className="mb-2 mt-20 flex items-center justify-between md:mt-2">
				<Text as="h2">{heading}</Text>
				{headingLinkText && (
					<Text as="pSmall">
						<Link href="/Cart" underline>
							{headingLinkText}
						</Link>
					</Text>
				)}
			</div>
			{variants?.map((variant) => (
				<SummaryProduct
					key={variant.id}
					link={variant.url}
					heading={variant.title}
					placements={
						variant.customization
							? variant.customization.placements
									?.map((placement) => placement.title)
									.filter(is.defined)
							: undefined
					}
					imageSrc={
						getProductImageFormat(
							'Templated square transparent',
							variant.listImageFormats,
						)?.location || '/assets/images/placeholder-image.png'
					}
					articleNumber={variant.id}
					rowSum={variant?.rowSum}
					amount={variant.qty}
					discount={
						is.defined(variant.price.saveIncVat)
							? variant.price.saveIncVat.value > 0
							: false
					}
				/>
			))}
			<PriceSummaries
				className="mt-4"
				summaries={summaries}
				bonusDiscounts={bonusDiscounts}
				savingTotalSumSummary={savingTotalSumSummary}
				giftCards={giftCards}
			/>
		</div>
	);
}
CheckoutSummary.displayName = 'CheckoutSummary';
