import React from 'react';
import type { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import BlockThemedIntroductionChips from 'components/BlockThemedIntroductionChips';
import ComponentPlaceholder from 'components/ComponentPlaceholder';
import type { JulaComponentProps } from 'lib/component-props';
import type { DigizuiteAssetList } from 'models/asset';
import type { Theme } from 'models/sitecore';
import { getEditorMargin } from 'utils/business-logic';
import { filterTruthy } from 'utils/collection';
import { getAsset } from 'utils/helpers';

interface Link {
	href?: string;
	text?: string;
}

type Props = JulaComponentProps & {
	fields?: {
		description?: Field<string>;
		heading?: Field<string>;
		landscapeImage?: DigizuiteAssetList;
		links?: Link[];
		theme?: Theme;
	};
};

export default function ThemedIntroductionChips({ params, fields }: Props) {
	if (!fields) {
		return null;
	}

	const { description, heading, landscapeImage, theme } = fields;
	const links = filterTruthy(fields.links, 'href', 'text');

	if (!links?.length || !theme?.backgroundColor) {
		return (
			<ComponentPlaceholder
				className={getEditorMargin(params)}
				componentName="ThemedIntroductionChips"
				description="Requires links and a theme with a color"
			/>
		);
	}

	const topLeftImage = getAsset('Source Copy', theme.topLeftImageLandscape);
	const bottomRightImage = getAsset(
		'Source Copy',
		theme.bottomRightImageLandscape,
	);
	const centerImage = getAsset('Source Copy', landscapeImage);

	return (
		<BlockThemedIntroductionChips
			bottomRightImageSrc={bottomRightImage?.src}
			centerImageAlt={centerImage?.alt}
			centerImageSrc={centerImage?.src}
			className={getEditorMargin(params)}
			color={theme.backgroundColor}
			heading={heading}
			headingLevel={params?.heading}
			id={params?.anchor}
			links={links}
			text={description}
			topLeftImageSrc={topLeftImage?.src}
		/>
	);
}
ThemedIntroductionChips.displayName = 'ThemedIntroductionChips';
